import Vue from 'vue';
import _ from 'lodash';
import { fixBody, globalLoader } from '@/helpers/variables/index'
import { $http } from '@/https'
import {
    FIELD_SHOW_CATEGORY,
    FIELD_TYPES_RESET,
    FIELD_TYPES,
    FIELD_LIST,
    FIELD_RESET_LIST,
    FIELD_RESET_CURRENT,
    FIELD_INCREMENT_COUNT,
    FIELD_DECREMENT_COUNT,
    FIELD_CHANGE_COUNT,
    FIELD_REMOVE_TREES,
    FIELD_DETAIL,
    FIELD_SELL_YOUNG_TREE,
    FIELD_TYPES_SALE,
    RESET_FIELD_DETAIL,
    FIELD_TYPES_SALE_RESET,
    RESET_FIELD,
    FIELD_DETAIL_COUNT,

    FIELD_SETTING
} from '../../mutation-types';

const helper = (obj) => {
    obj.price_each_trees = (obj.price * obj.selectCount) - ((obj.price * obj.selectCount) * (obj.commission / 100));
    let index = _.findIndex(state.list, obj);
    Vue.set(state.list, index, obj);
};

const state = {
    list: [],
    selectedField: [],
    removeField: [],
    areas: null,
    areasSale: null,
    showCategory: false,
    detailTrees: null,
    countTreesDetail: null,

    setting: null
};

const getters = {
    countTreesDetail: state => state.countTreesDetail,
    areasSale: state => state.areasSale,
    detailTrees: state => state.detailTrees,
    list: state => state.list,
    removeField: state => state.removeField,
    selectedField: state => state.selectedField,
    showCategory: state => state.showCategory,
    areas: state => state.areas,
};

const actions = {
    [FIELD_TYPES]: async ({ commit }) => {
        try {
            const response = await  $http.get(`v1/trees?group_by=location&is_young=${1}`);
            commit(FIELD_TYPES_RESET);
            commit(FIELD_TYPES, response.data.data);
            return response;

        }catch (e) {
            throw e;
        }
    },
    [FIELD_TYPES_SALE]: async ({ commit }) => {
        try {
            const response = await  $http.get(`v1/trees?group_by=location&is_young=${1}&django=${1}`);
            commit(FIELD_TYPES_SALE_RESET);
            commit(FIELD_TYPES_SALE, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [FIELD_SELL_YOUNG_TREE]: async ({ commit }, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/first_sale`, payload);
            commit(RESET_FIELD);
            return response;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [FIELD_DETAIL]: async ({ commit }, payload) => {
        try {
            const response = await $http.get(`v1/trees?limit=${parseInt(localStorage.getItem('countView'))}&skip=${payload.skip}&field_ids=${payload.field_id}&is_young=${1}`);
            commit(RESET_FIELD_DETAIL);
            commit(FIELD_DETAIL, response.data.data);
            commit(FIELD_DETAIL_COUNT, response.data.meta.count);
            return response;
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [FIELD_TYPES](state, areas) {
        state.areas = areas;
    },
    [FIELD_SETTING](state, obj) {
        state.setting = obj;
    },
    [FIELD_LIST](state, area) {
        if (state.list.length <= 0) {
            state.list = area;
        } else {
            state.list.forEach((elem, index) => {
                area.forEach(t => {
                    if (elem.field_id !== t.field_id) {
                        state.list.push(t)
                    } else {
                        state.list.splice(index, 1, t);
                    }
                })
            });
            let newArr = _.uniq(state.list);
            state.list = newArr;
        }
    },
    [FIELD_TYPES_RESET](state) {
        state.areas = null;
    },
    [FIELD_RESET_CURRENT](state, trees) {
        state.currentField = null;
    },
    [FIELD_SHOW_CATEGORY](state, param) {
        fixBody(param);
        state.showCategory = param;
    },
    [FIELD_REMOVE_TREES](state, listDeleteTree) {
        if (state.list.length !== 0) {
            for (let i = state.list.length; i >= 0; i--) {
                for (let j = 0; j < listDeleteTree.length; j++) {
                    if (state.list[i] === listDeleteTree[j]) {
                        state.list.splice(i, 1);
                    }
                }
            }
        }
    },
    [FIELD_INCREMENT_COUNT](state, id) {
        let obj = state.list.find(elem => {
            return elem.field_id === id
        });
        obj.selectCount++;
        helper(obj);
    },
    [FIELD_DECREMENT_COUNT](state, id) {
        let obj = state.list.find(elem => {
            return elem.field_id === id
        });
        obj.selectCount--;
        helper(obj);
    },
    [FIELD_CHANGE_COUNT](state, payload) {
        let obj = state.list.find(elem => {
            return elem.field_id === payload.field_id
        });
        obj.selectCount = payload.selectCount;
        helper(obj);
    },
    [RESET_FIELD_DETAIL](state) {
        state.detailTrees = null;
    },
    [FIELD_DETAIL](state, trees) {
        state.detailTrees = trees;
    },
    [FIELD_DETAIL_COUNT](state, count) {
        state.countTreesDetail = count;
    },
    [FIELD_TYPES_SALE](state, list) {
        state.areasSale = list;
        state.areasSale.map(elem => {
            return list.map(t => {
                if (elem.field_id === t.field_id) {
                    elem.price = state.setting.defaultPriceTree;
                    elem.commission = state.setting.commissionFirstSale * 100;
                    elem.selectCount = 1;
                    elem.price_each_trees = (elem.price * elem.selectCount) - ((elem.price * elem.selectCount) * (elem.commission / 100));
                    elem.commissionPrice = ((elem.commission / 100) * elem.minPrice);
                    elem.totalCommissionPrice = elem.minPrice - elem.commissionPrice;
                }
            })
        });
    },
    [FIELD_RESET_LIST](state) {
        state.list = [];
    },
    [FIELD_TYPES_SALE_RESET](state) {
        state.areasSale = null;
    },
    [RESET_FIELD](state) {
        state.list = [];
        state.selectedField = [];
        state.removeField = [];
        state.areas = null;
        state.areasSale = null;
        state.showCategory = false;
        state.detailTrees = null;
        state.countTreesDetail = null;
    },

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
