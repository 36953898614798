import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    IS_INVESTOR_PAGE,
    FETCH_PAGE_INVESTOR,
    FEEDBACK_INVESTOR_SHOW,
} from "@/store/mutation-types";
export default{
    name: 'investor',
    computed: {
        ...mapGetters({
            content: `investor/content`
        })
    },
    created(){
        this.fetchContent();
        this.changeInvestorPage(true);
    },
    beforeRouteLeave (to, from, next) {
        this.changeInvestorPage(false);
        next()
    },
    metaInfo: {
        title: 'Как стать партнером | BioDeposit',
        meta: [
            { name: 'description', content: '【Как стать партнером BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
        ]
    },
    methods: {
        ...mapActions({
            fetchContent: `investor/${FETCH_PAGE_INVESTOR}`
        }),
        ...mapMutations({
            changeInvestorPage: `system/${IS_INVESTOR_PAGE}`,
            showPopup: `auth/${FEEDBACK_INVESTOR_SHOW}`,
        })
    }
}
