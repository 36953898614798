import { mapMutations, mapGetters, mapActions } from 'vuex'

import {
    GIFT_TREE_LOAD,
    REGISTRATION_SHOW
} from "@/store/mutation-types";

export default {
    name: 'gift-tree',
    data(){
        return{
        }

    },
    computed: {
        ...mapGetters({
            is_auth: `auth/is_auth`,
            content: `giftTree/content`,
        })
    },
    created(){
        this.fetchContent();
    },
    methods:{
        ...mapActions({
            fetchContent: `giftTree/${GIFT_TREE_LOAD}`,
        }),
        ...mapMutations({
            showRegistration: `auth/${REGISTRATION_SHOW}`,
        })
    },

}