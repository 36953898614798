import { mapGetters, mapActions } from 'vuex'
import Sidebar from './components/sidebar/index.vue'
import PopupDocuments from './components/helpers/popup-documents/index.vue'
export default {
    name: 'personal',
    created(){
        if(!this.user){
            this.fetchData();
        }
    },
    components: {
        Sidebar,
        PopupDocuments,
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
            showPopupDocuments: `trees/showPopupDocuments`,
        })
    },
    methods: {
        ...mapActions({
            fetchData: `profile/LOAD_USER_DATA`,
        }),
    }
}
