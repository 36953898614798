import {mapGetters, mapMutations, mapActions} from 'vuex'
import {
    REGISTRATION_SHOW,
    IS_LOGIN_USER,
    NOT_AVAIBLE,
    FETCH_MAIN_IMG_BUTTON,
    SHOW_VIDEO_HELP_CALCULATE,
    CHANGE_POPUP_EXPRESS, SHOW_CASH_POPUP, SHOW_FEEDBACK, SHOW_SING_IN, SHOW_HEADER_MENU
} from "@/store/mutation-types";
import CalculateIncome from '@/modules/calculateincome/index.vue';
import InfoTable from '@/components/info-table/index.vue';
import streamVideo from '@/components/strimm-player/index.vue';
import videoMain from './video';
import applePay from '@/components/widgets/apple-pay/index.vue'




export default {



    data() {
        return {
            hide: false,
            isMobile: false,
            video: {
                sources: [{
                    src: process.env.VUE_APP_VIDEO_URL,
                    // src: require('@/assets/video/BioDepositGeorgia.mp4'),
                    type: 'video/mp4',
                }],
                options: {
                    loop:true,
                    autoplay: true,
                    volume: 0.1,
                }
            },
        }
    },
    metaInfo: {
        title: 'BioDeposit — Инвестиции в оливковые деревья Грузии',
        meta: [
            {
                name: 'description',
                content: '【Инвестиционный проект BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!'
            },
            {
                name:'facebook-domain-verification',
                content:'7m8dqgumkqbq9orffidusxpav8x22d'
            },
        ]
    },
    components:{
        CalculateIncome,
        videoMain,
        InfoTable,
        streamVideo,
        applePay
    },
    computed: {
        ...mapGetters({
            showVideoHelp: `helpCalculate/showVideoHelp`,
            imgButton: `statistic/imgButton`,
            is_auth: `auth/is_auth`,
            helperContent: `helpCalculate/helperContent`,
            user: `profile/user`,
            setting:'main/setting',
        }),

    },
    mounted(){
        window.addEventListener("resize", this.resizeThrottler, false);
        const width = window.innerWidth;
        if(width < 800){
            this.isMobile = true;
        }else{
            this.isMobile = false;
        }



    },
    created(){
        this.fetchInfo();


    },
    methods: {
        ...mapMutations({
            showSingIn: `auth/${SHOW_SING_IN}`,
            showRegistration: `auth/${REGISTRATION_SHOW}`,
        }),
        buyYoungTree(){
            this.$analytics.fbq.event('InitiateCheckout');
            if(this.is_auth){
                this.$router.push({name: 'personal.buy_default'})
            }else{
                this.showRegistration(true)
            }
            // this.gtm('buytree');
        },
        goGift(){
            this.$analytics.fbq.event('InitiateCheckout');
            if(this.is_auth){
                this.$router.push({ name: 'giftTree' })
            }else{
                this.changePopupExpress({
                    show: true,
                    key: 'gift'
                })
            }

        },
        resizeThrottler(e){
            const windowWidth = e.target.innerWidth;
            if(windowWidth < 800){
                this.isMobile = true;
            }else{
                this.isMobile = false;
            }
        },
        ...mapMutations({
            notAvaible: `system/${NOT_AVAIBLE}`,
            showRegistration: `auth/${REGISTRATION_SHOW}`,
            forEnter: `system/${IS_LOGIN_USER}`,
            openVideoHelp: `helpCalculate/${SHOW_VIDEO_HELP_CALCULATE}`,
            changePopupExpress: `expressActions/${CHANGE_POPUP_EXPRESS}`,

        }),
        ...mapActions({
            fetchInfo: `statistic/${FETCH_MAIN_IMG_BUTTON}`,
        }),
        goRegistration(){
            this.showRegistration(true);
        },
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
    },

}
