//auth
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const REGISTRATION = 'REGISTRATION';
export const FEEDBACK_INVESTOR = 'FEEDBACK_INVESTOR';
export const REGISTRATION_SHOW = 'REGISTRATION_SHOW';
export const FEEDBACK_INVESTOR_SHOW = 'FEEDBACK_INVESTOR_SHOW';
export const RESTORE_PASSWORD_EMAIL = 'RESTORE_PASSWORD_EMAIL';
export const SHOW_RESTORE_PASSWORD_EMAIL = 'SHOW_RESTORE_PASSWORD_EMAIL';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const SHOW_SING_IN = 'SHOW_SING_IN';
export const CHECK_FREE_EMAIL = 'CHECK_FREE_EMAIL';
export const RE_ACTIVATION = 'RE_ACTIVATION';
export const CUSTOM_HEADERS_PARAMS_CID = 'CUSTOM_HEADERS_PARAMS_CID';
export const CUSTOM_HEADERS_PARAMS_MSG = 'CUSTOM_HEADERS_PARAMS_MSG';

//buy-tree
export const CALCULATE_UPDATE_DEPOSIT_YEAR = 'CALCULATE_UPDATE_DEPOSIT_YEAR';
export const CALCULATE_UPDATE_COUNT = 'CALCULATE_UPDATE_COUNT';
export const CALCULATE_UPDATE_PRICE = 'CALCULATE_UPDATE_PRICE';
export const CALCULATE_DELETE_TREE = 'CALCULATE_DELETE_TREE';
export const CALCULATE_ADD_TREE = 'CALCULATE_ADD_TREE';
export const CALCULATE_UPDATE_DATE = 'CALCULATE_UPDATE_DATE';
export const CALCULATE_UPDATE_SEASON = 'CALCULATE_UPDATE_SEASON';
export const CALCULATE_RESET_LIST_TREE = 'CALCULATE_RESET_LIST_TREE';
export const CALCULATE_ADD_TREE_PERSONAL = 'CALCULATE_ADD_TREE_PERSONAL';
export const CALCULATE_CURRENT_CATEGORY_PERSONAL = 'CALCULATE_CURRENT_CATEGORY_PERSONAL';
export const CALCULATE_INCREMENT_COUNT = 'CALCULATE_INCREMENT_COUNT';
export const CALCULATE_DECREMENT_COUNT = 'CALCULATE_DECREMENT_COUNT';
export const CALCULATE_CHANGE_COUNT = 'CALCULATE_CHANGE_COUNT';
export const CALCULATE_DECREMENT_PRICE = 'CALCULATE_DECREMENT_PRICE';
export const CALCULATE_INCREMENT_PRICE = 'CALCULATE_INCREMENT_PRICE';
export const CALCULATE_CHANGE_PRICE = 'CALCULATE_CHANGE_PRICE';
export const CALCULATE_POPUP_LIST_LENGTH = 'CALCULATE_POPUP_LIST_LENGTH';
export const CALCULATE_POPUP_CHANGE_OFFSET = 'CALCULATE_POPUP_CHANGE_OFFSET';
export const LOAD_TREE_FILTER = 'LOAD_TREE_FILTER';
export const SHOW_FILTER_TREE_POPUP = 'SHOW_FILTER_TREE_POPUP';
export const CHANGE_FILTER_SEARCH_PARAMS = 'CHANGE_FILTER_SEARCH_PARAMS';
export const LENGTH_LIST_TREE_SHOP = 'LENGTH_LIST_TREE_SHOP';
export const SHOW_CALCULATE_FILTER_RESULT = 'SHOW_CALCULATE_FILTER_RESULT';
export const SELECT_TREES_IN_SHOP = 'SELECT_TREES_IN_SHOP';
export const CALCULATE_PERSONAL_CHANGE_CHECK = 'CALCULATE_PERSONAL_CHANGE_CHECK';
export const CALCULATE_PERSONAL_CHANGE_ALL = 'CALCULATE_PERSONAL_CHANGE_ALL';
export const CALCULATE_SHOP_CHANGE_CHECK = 'CALCULATE_SHOP_CHANGE_CHECK';
export const CALCULATE_SHOP_CHANGE_ALL = 'CALCULATE_SHOP_CHANGE_ALL';
export const CALCULATE_CHANGE_COUNT_PRICE = 'CALCULATE_CHANGE_COUNT_PRICE';
export const RESET_LIST_PERSONAL_TREES = 'RESET_LIST_PERSONAL_TREES';
export const RESET_CALCULATE = 'RESET_CALCULATE';
export const RESET_LIST_SHOP_TREES = 'RESET_LIST_SHOP_TREES';
export const PAYMENT_LEOGAMING = 'PAYMENT_LEOGAMING';
export const BUY_YOUNG_TREE = 'BUY_YOUNG_TREE';
export const CALCULATE_LOAD_TREE_PERSONAL = 'CALCULATE_LOAD_TREE_PERSONAL';
export const SHOW_LOADING_PERSONAL_AREA_CALCULATE = 'SHOW_LOADING_PERSONAL_AREA_CALCULATE';

//package offer slider
export const LOAD_PACKAGE_OFFER = 'LOAD_PACKAGE_OFFER';

//help calculate
export const SHOW_TEXT_HELP = 'SHOW_TEXT_HELP';
export const SHOW_TEXT_HELP_CALCULATE = 'SHOW_TEXT_HELP_CALCULATE';
export const SHOW_VIDEO_HELP_CALCULATE = 'SHOW_VIDEO_HELP_CALCULATE';
//faq
export const FAQ_LOAD_CATEGORIES = 'FAQ_LOAD_CATEGORIES';
export const FAQ_LOAD_CONTENT = 'FAQ_LOAD_CONTENT';
export const FAQ_RESET_CONTENT = 'FAQ_RESET_CONTENT';
export const CHANGE_LOADING_FEEDBACK = 'CHANGE_LOADING_FEEDBACK';
//system
export const SHOW_SERVER_ERROR = 'SHOW_SERVER_ERROR';
export const IS_INVESTOR_PAGE = 'IS_INVESTOR_PAGE';
export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const NOT_AVAIBLE = 'NOT_AVAIBLE';
export const SHOW_HEADER_MENU = 'SHOW_HEADER_MENU';
export const FIX_BODY = 'FIX_BODY';
export const IS_LOGIN_USER = 'IS_LOGIN_USER';
export const SHOW_CASH_POPUP = 'SHOW_CASH_POPUP';
export const TEXT_POPUP_CONTENT = 'TEXT_POPUP_CONTENT';
export const RESET_TEXT_POPUP_CONTENT = 'RESET_TEXT_POPUP_CONTENT';
export const SHOW_PAYMENT_POPUP = 'SHOW_PAYMENT_POPUP';
export const FETCH_MAIN_IMG_BUTTON = 'FETCH_MAIN_IMG_BUTTON';
export const SHOW_POPUP_PROMO = 'SHOW_POPUP_PROMO';
export const SHOW_POPUP_PROMO_RESULT = 'SHOW_POPUP_PROMO_RESULT';
export const PROMO_RESULT = 'PROMO_RESULT';
export const GET_GIFT_TREE_PROMO = 'GET_GIFT_TREE_PROMO';
export const GET_GIFT_TREE_STORY = 'GET_GIFT_TREE_STORY';
export const SHOW_POPUP_INTRO = 'SHOW_POPUP_INTRO';


export const CONFIG = 'CONFIG';
export const SETTING = 'SETTING';
export const CHANGE_SETTING_COUNTRY = 'CHANGE_SETTING_COUNTRY';
export const CHANGE_LOADING_COUNTRY_CODE = 'CHANGE_LOADING_COUNTRY_CODE';

//take off
export const SHOW_POPUP_TAKE_OFF = 'SHOW_POPUP_TAKE_OFF';
export const TAKE_OFF_LIST = 'TAKE_OFF_LIST';
export const TAKE_OFF_PACK = 'TAKE_OFF_PACK';
export const TAKE_OFF_CHANGE_LOADING = 'TAKE_OFF_CHANGE_LOADING';
export const TAKE_OFF_RESET_LIST = 'TAKE_OFF_RESET_LIST';
export const TAKE_OFF_PIECE = 'TAKE_OFF_PIECE';
export const TAKE_OFF_LENGTH_LIST = 'TAKE_OFF_LENGTH_LIST';
export const TAKE_OFF_CHANGE_SKIP = 'TAKE_OFF_CHANGE_SKIP';
export const TAKE_OFF_CHANGE_PAGE = 'TAKE_OFF_CHANGE_PAGE';
export const TAKE_OFF_RESET_PAGINATION = 'TAKE_OFF_RESET_PAGINATION';
//feedback
export const SHOW_FEEDBACK = 'SHOW_FEEDBACK';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
//investor
export const FETCH_PAGE_INVESTOR = 'FETCH_PAGE_INVESTOR';
//about us
export const ABOUT_US_LOAD = 'ABOUT_US_LOAD';
export const ABOUT_US_INFO = 'ABOUT_US_INFO';
// search
export const SEARCH = 'SEARCH';
export const SHOW_SEARCH_LOADING = 'SHOW_SEARCH_LOADING';
export const RESET_SEARCH = 'RESET_SEARCH';
// news
export const NEWS_LIST = 'NEWS_LIST';
export const NEWS_HOME_LIST = 'NEWS_HOME_LIST';
export const NEWS_CHANGE_POSITION_SCROLL = 'NEWS_CHANGE_POSITION_SCROLL';
export const GO_NEWS_CHANGE_POSITION_SCROLL = 'GO_NEWS_CHANGE_POSITION_SCROLL';
export const NEWS_LENGTH = 'NEWS_LENGTH';
export const CHANGE_NEWS_SKIP = 'CHANGE_NEWS_SKIP';
export const CHANGE_IS_LOAD_NEWS = 'CHANGE_IS_LOAD_NEWS';
export const NEWS_LIST_LOADING = 'NEWS_LIST_LOADING';
// gallery
export const GALLERY_VIDEO_ALBUM = 'GALLERY_PHOTO_ALBUM';
export const GALLERY_NEWS_VIDEO = 'GALLERY_NEWS_VIDEO';
export const GALLERY_PHOTO_ALBUM = 'GALLERY_VIDEO_ALBUM';
export const GALLERY_PHOTO_ALBUM_LIMIT = 'GALLERY_PHOTO_ALBUM_LIMIT';
export const GALLERY_PHOTO = 'GALLERY_PHOTO';
export const GALLERY_VIDEO = 'GALLERY_VIDEO';
export const RESET_GALLERY_PHOTO = 'RESET_GALLERY_PHOTO';
export const RESET_GALLERY_VIDEO = 'RESET_GALLERY_VIDEO';
// news-detail
export const NEWS_DETAIL = 'NEWS_DETAIL';
export const RESET_NEWS_DETAIL = 'RESET_NEWS_DETAIL';
// privacy
export const PRIVACY_POLICY_CONTENT = 'PRIVACY_POLICY_CONTENT';
export const SHOW_POPUP_PRIVACY = 'SHOW_POPUP_PRIVACY';
export const IS_READ_PRIVACY_POLICY = 'IS_READ_PRIVACY_POLICY';

// terms of use
export const FETCH_TEAMS_OF_USE = 'FETCH_TEAMS_OF_USE';

//notification
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE';

//personal


export const LOAD_USER_CONFIDANT = 'LOAD_USER_CONFIDANT';
export const SET_USER_CONFIDANT = 'SET_USER_CONFIDANT';
export const UPDATE_USER_CONFIDANT = 'UPDATE_USER_CONFIDANT';
export const PERSONAL_VALIDATIONS_ERRORS = 'PERSONAL_VALIDATIONS_ERRORS';
export const CHANGE_FILTER_SEASON = 'CHANGE_FILTER_SEASON';
export const CHANGE_OLD_PASSWORD = 'CHANGE_OLD_PASSWORD';
export const CHANGE_NEW_PASSWORD = 'CHANGE_NEW_PASSWORD';
export const PERSONAL_RESET_PASSWORD = 'PERSONAL_RESET_PASSWORD';
export const CHANGE_CONFIRMATION_PASSWORD = 'CHANGE_CONFIRMATION_PASSWORD';
export const CHANGE_TEXT_FUTURES_BALANCE = 'CHANGE_TEXT_FUTURES_BALANCE';
export const PERSONAL_TREES = 'PERSONAL_TREES';
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const FETCH_PAYMENTS_SYSTEM = 'FETCH_PAYMENTS_SYSTEM';
export const RESET_PAYMENTS_SYSTEM = 'RESET_PAYMENTS_SYSTEM';
export const PERSONAL_LOADER_DATA = 'PERSONAL_LOADER_DATA';
export const PERSONAL_LOADER_PASSWORD = 'PERSONAL_LOADER_PASSWORD';
export const PERSONAL_CHANGE_EDIT_DATA_STATUS = 'PERSONAL_CHANGE_EDIT_DATA_STATUS';
export const PERSONAL_CHANGE_EDIT_PASSWORD_STATUS = 'PERSONAL_CHANGE_EDIT_PASSWORD_STATUS';
export const PERSONAL_CLEAR_ERRORS = 'PERSONAL_CLEAR_ERRORS';
export const PERSONAL_LOADING_TREES_DOCUMENT = 'PERSONAL_LOADING_TREES_DOCUMENT';
export const POPUP_ENABLE_TWO_FACTOR = 'POPUP_ENABLE_TWO_FACTOR';
export const POPUP_CODE_CONFIRM = 'POPUP_CODE_CONFIRM';
export const SEND_CODE_CONFIRM = 'SEND_CODE_CONFIRM';
export const SET_CODE_CONFIRM_STATUS = 'SET_CODE_CONFIRM_STATUS';
export const MY_TREE_LIST_FILE = 'MY_TREE_LIST_FILE';
export const CHECK_POPUP_INTRO = 'CHECK_POPUP_INTRO';

export const TRANSACTION_HISTORY = 'TRANSACTION_HISTORY';
export const TRANSACTION_HISTORY_CHANGE_TO = 'TRANSACTION_HISTORY_CHANGE_TO';
export const TRANSACTION_HISTORY_CHANGE_FROM = 'TRANSACTION_HISTORY_CHANGE_FROM';
export const TRANSACTION_HISTORY_CHANGE_SKIP = 'TRANSACTION_HISTORY_CHANGE_SKIP';
export const TRANSACTION_HISTORY_CHANGE_PAGE = 'TRANSACTION_HISTORY_CHANGE_PAGE';
export const RESET_TRANSACTION_HISTORY = 'RESET_TRANSACTION_HISTORY';
export const TRANSACTION_HISTORY_LENGTH = 'TRANSACTION_HISTORY_LENGTH';
export const TRANSACTION_HISTORY_LOADING = 'TRANSACTION_HISTORY_LOADING';
export const RESET_TRANSACTION_HISTORY_COMPONENT = 'RESET_TRANSACTION_HISTORY_COMPONENT';
export const TRANSACTION_HISTORY_HAS_TWO_FACTOR = 'TRANSACTION_HISTORY_HAS_TWO_FACTOR';


export const USER_TOKEN = 'USER_TOKEN';
export const POPUP_REMOVE_PROFILE = 'POPUP_REMOVE_PROFILE';
export const FETCH_USER_SALES = 'FETCH_USER_SALES';
export const FETCH_USER_SALES_RESET = 'FETCH_USER_SALES_RESET';
export const REMOVE_USER = 'REMOVE_USER';
export const RESET_USER_TOKEN = 'RESET_USER_TOKEN';
export const TREES_MAP_LIST = 'TREES_MAP_LIST';
export const TREES_MAP_AREA = 'TREES_MAP_AREA';
export const GET_COORDINATES_TREES_FIELD = 'GET_COORDINATES_TREES_FIELD';
export const GET_COORDINATES_TREES_FIELD_LOADING = 'GET_COORDINATES_TREES_FIELD_LOADING';
export const ACTIVE_AREA = 'ACTIVE_AREA';
export const RESET_MAP_LIST = 'RESET_MAP_LIST';
export const OPEN_SEARCH_TREE = 'OPEN_SEARCH_TREE';
export const LOADING_SEARCH_TREE = 'LOADING_SEARCH_TREE';
export const GENERATED_CERTIFICATE = 'GENERATED_CERTIFICATE';
export const GENERATED_CERTIFICATES = 'GENERATED_CERTIFICATES';
export const DOWNLOAD_CERTIFICATE = 'DOWNLOAD_CERTIFICATE';
export const GET_CERTIFICATE = 'GET_CERTIFICATE';
export const DOCUMENT_TREE_ID = 'DOCUMENT_TREE_ID';
export const DOCUMENT_TREE_ORDER_ID = 'DOCUMENT_TREE_ORDER_ID';
export const RESET_DOCUMENT_POPUP = 'RESET_DOCUMENT_POPUP';
export const CERTIFICATE_TYPE = 'CERTIFICATE_TYPE';
export const CERTIFICATE_LOADING = 'CERTIFICATE_LOADING';
export const MAP_CURRENT_LENGTH = 'MAP_CURRENT_LENGTH';
export const TREES_MAP_POPUP_AREA = 'TREES_MAP_POPUP_AREA';
export const SHOW_TREES_MAP_POPUP_AREA = 'SHOW_TREES_MAP_POPUP_AREA';
export const BUY_DEFAULT_SHOW_POPUP = 'BUY_DEFAULT_SHOW_POPUP';
export const CHECK_PROMO_CODE_LOADING = 'CHECK_PROMO_CODE_LOADING';
export const CHECK_PROMO_CODE = 'CHECK_PROMO_CODE';
export const TREES_MAP_LIST_RESET = 'TREES_MAP_LIST_RESET';
export const TREES_MAP_RESET = 'TREES_MAP_RESET';
export const FIELDS_MAP_LIST = 'FIELDS_MAP_LIST';
export const FIELDS_MAP_LIST_LOADING = 'FIELDS_MAP_LIST_LOADING';
export const FIELDS_MAP_LIST_RESET = 'FIELDS_MAP_LIST_RESET';
export const SING_DOCUMENT = 'SING_DOCUMENT';
export const BUY_COUNT_FREE_TREES = 'BUY_COUNT_FREE_TREES';
export const CHANGE_LOADING_USER_DATA = 'CHANGE_LOADING_USER_DATA';
export const GET_ORDER = 'GET_ORDER';
export const SHOW_POPUP_DOCUMENTS = 'SHOW_POPUP_DOCUMENTS';
export const BUY_SELECT_YOUNG_TREES = 'BUY_SELECT_YOUNG_TREES';
export const SEARCH_PARAMS_GIFT_STATUSES = 'SEARCH_PARAMS_GIFT_STATUSES';
export const CHANGE_TEXT_CONFIDANT = 'CHANGE_TEXT_CONFIDANT';

//two factor auth
export const TWO_AUTH_DATA = 'TWO_AUTH_DATA';
export const TWO_AUTH_ON = 'TWO_AUTH_ON';
export const TWO_AUTH_OFF = 'TWO_AUTH_OFF';
export const RESET_TWO_AUTH_DATA = 'RESET_TWO_AUTH_DATA';
export const SHOW_POPUP_TWO_AUTH_CODE = 'SHOW_POPUP_TWO_AUTH_CODE';
export const FAIL_REQUEST = 'FAIL_REQUEST';
export const DYNAMIC_PAGES = 'DYNAMIC_PAGES';


//
export const PURCHASES_CURRENT = 'PURCHASES_CURRENT';
export const PURCHASES_LIST = 'PURCHASES_LIST';
export const PURCHASES_RESET_CURRENT = 'PURCHASES_RESET_CURRENT';
export const PURCHASES_RESET_LIST = 'PURCHASES_RESET_LIST';
export const PURCHASES_SELECT_ITEM = 'PURCHASES_SELECT_ITEM';
export const PURCHASES_LOADING = 'PURCHASES_LOADING';
export const PURCHASES_LENGTH = 'PURCHASES_LENGTH';
export const PURCHASES_LIST_LENGTH = 'PURCHASES_LIST_LENGTH';
export const PURCHASES_INFO = 'PURCHASES_INFO';
//tree
export const TREES_SEARCH = 'TREES_SEARCH';
export const RESET_SEARCH_TREE = 'RESET_SEARCH_TREE';
export const PERSONAL_TREES_TYPE = 'PERSONAL_TREES_TYPE';
export const PERSONAL_SORT_CATEGORY = 'PERSONAL_SORT_CATEGORY';
export const PERSONAL_SELECT_CATEGORY = 'PERSONAL_SELECT_CATEGORY';
export const PERSONAL_TYPE_SEARCH = 'PERSONAL_TYPE_SEARCH';
export const TREE_SEARCH_LOCATION = 'TREE_SEARCH_LOCATION';
export const TREE_SEARCH_PURCHASE = 'TREE_SEARCH_PURCHASE';
export const TREE_SEARCH_PLANTING = 'TREE_SEARCH_PLANTING';
export const TREE_SEARCH_STATUS = 'TREE_SEARCH_STATUS';
export const SEARCH_PARAMS_STATUSES = 'SEARCH_PARAMS_STATUSES';
export const SEARCH_PARAMS_LOCATIONS = 'SEARCH_PARAMS_LOCATIONS';
export const RESULT_SEARCH = 'RESULT_SEARCH';
export const TREES_DETAIL = 'TREES_DETAIL';
export const TREE_COUNT = 'TREE_COUNT';
export const RESET_SEARCH_TREE_DETAIL = 'RESET_SEARCH_TREE_DETAIL';
export const TREE_COUNT_DETAIL = 'TREE_COUNT_DETAIL';
export const TREES_SKIP = 'TREES_SKIP';
export const TREES_LOAD_ALL_CATEGORIES = 'TREES_LOAD_ALL_CATEGORIES';
export const CHANGE_TREES_LIMIT = 'CHANGE_TREES_LIMIT';
export const DOWNLOAD_DOCUMENTS = 'DOWNLOAD_DOCUMENTS';
//give
export const GIVE_FETCH_PROMO_CODE = 'GIVE_FETCH_PROMO_CODE';
export const FIND_USER_IN_EMAIL_LOADING = 'FIND_USER_IN_EMAIL_LOADING';
export const FIND_USER_IN_EMAIL = 'FIND_USER_IN_EMAIL';
export const GIVE_RESET_PROMO = 'GIVE_RESET_PROMO';
export const GIVE_LOAD_CATEGORY = 'GIVE_LOAD_CATEGORY';
export const GIVE_REMOVE_TREES = 'GIVE_REMOVE_TREES';
export const GIVE_SELECTED_TREES = 'GIVE_SELECTED_TREES';
export const GIVE_SHOW_TREES = 'GIVE_SHOW_TREES';
export const GIVE_SHOW_CATEGORIES = 'GIVE_SHOW_CATEGORIES';
export const GIVE_LOAD_TREES = 'GIVE_LOAD_TREES';
export const GIVE_CURRENT_CATEGORY = 'GIVE_CURRENT_CATEGORY';
export const GIVE_RESET_TREES = 'GIVE_RESET_TREES';
export const GIVE_TREES = 'GIVE_TREES';
export const CHARITY_TREES = 'CHARITY_TREES';
export const GIVE_LIST_LENGTH = 'GIVE_LIST_LENGTH';
export const GIVE_RESET_CATEGORY = 'GIVE_RESET_CATEGORY';
export const GIVE_CHANGE_OFFSET = 'GIVE_CHANGE_OFFSET';
export const GIVE_CHANGE_CHECK = 'GIVE_CHANGE_CHECK';
export const GIVE_CHANGE_ALL = 'GIVE_CHANGE_ALL';
export const GIVE_RESET_AFTER_RESPONSE = 'GIVE_RESET_AFTER_RESPONSE';
export const GIVE_RESET = 'GIVE_RESET';
export const GIVE_LOADING_POPUP_TREE = 'GIVE_LOADING_POPUP_TREE';
export const GIVE_LOAD_RULES_POPUP = 'GIVE_LOAD_RULES_POPUP';
export const GIVE_USER_LIST = 'GIVE_USER_LIST';
export const GIVE_CHANGE_PHONE = 'GIVE_CHANGE_PHONE';
export const GIVE_CHANGE_MAIL = 'GIVE_CHANGE_MAIL';
export const GIVE_CHANGE_NOTIFICATION_TIME = 'GIVE_CHANGE_NOTIFICATION_TIME';
export const GIVE_CHANGE_BAN = 'GIVE_CHANGE_BAN';
export const GIVE_CHANGE_FROST = 'GIVE_CHANGE_FROST';
export const GIVE_CHANGE_TYPE_SELECT = 'GIVE_CHANGE_TYPE_SELECT';
export const GIVE_DECREMENT_BAN = 'GIVE_DECREMENT_BAN';
export const GIVE_INCREMENT_BAN = 'GIVE_INCREMENT_BAN';
export const GIVE_DECREMENT_FROST = 'GIVE_DECREMENT_FROST';
export const GIVE_INCREMENT_FROST = 'GIVE_INCREMENT_FROST';
//sell
export const SELL_CURRENT_CATEGORY = 'SELL_CURRENT_CATEGORY';
export const SELL_LOAD_TREES = 'SELL_LOAD_TREES';
export const SELL_RESET_TREES = 'SELL_RESET_TREES';
export const SELL_SELECTED_TREES = 'SELL_SELECTED_TREES';
export const SELL_SHOW_CATEGORIES = 'SELL_SHOW_CATEGORIES';
export const SELL_SHOW_TREES = 'SELL_SHOW_TREES';
export const SELL_LOAD_CATEGORY = 'SELL_LOAD_CATEGORY';
export const SELL_REMOVE_TREES = 'SELL_REMOVE_TREES';
export const SELL_INCREMENT_PRICE = 'SELL_INCREMENT_PRICE';
export const SELL_DECREMENT_PRICE = 'SELL_DECREMENT_PRICE';
export const SELL_CHANGE_PRICE = 'SELL_CHANGE_PRICE';
export const SELL_CHANGE_PERCENT = 'SELL_CHANGE_PERCENT';
export const SELL_INCREMENT_PERCENT = 'SELL_INCREMENT_PERCENT';
export const SELL_DECREMENT_PERCENT = 'SELL_DECREMENT_PERCENT';
export const SELL_TREES = 'SELL_TREES';
export const SELL_TREES_RESET = 'SELL_TREES_RESET';
export const GET_POSITION = 'GET_POSITION';
export const LOADING_POSITION = 'LOADING_POSITION';
export const SELL_TREE_LENGTH = 'SELL_TREE_LENGTH';
export const SELL_CHANGE_OFFSET = 'SELL_CHANGE_OFFSET';
export const SELL_RESET_CATEGORY = 'SELL_RESET_CATEGORY';
export const SELL_CHANGE_CHECK = 'SELL_CHANGE_CHECK';
export const SELL_CHANGE_ALL = 'SELL_CHANGE_ALL';
export const SELL_LOADING_TREES_POPUP = 'SELL_LOADING_TREES_POPUP';
export const SELL_RESET = 'SELL_RESET';
export const SHOP_COMISSION = 'SHOP_COMISSION';
//field
export const FIELD_TYPES = 'FIELD_TYPES';
export const FIELD_RESET_CURRENT = 'FIELD_RESET_CURRENT';
export const FIELD_SHOW_CATEGORY = 'FIELD_SHOW_CATEGORY';
export const FIELD_TYPES_RESET = 'FIELD_TYPES_RESET';
export const FIELD_LIST = 'FIELD_LIST';
export const FIELD_INCREMENT_COUNT = 'FIELD_INCREMENT_COUNT';
export const FIELD_DECREMENT_COUNT = 'FIELD_DECREMENT_COUNT';
export const FIELD_CHANGE_COUNT = 'FIELD_CHANGE_COUNT';
export const FIELD_REMOVE_TREES = 'FIELD_REMOVE_TREES';
export const FIELD_DETAIL = 'FIELD_DETAIL';
export const RESET_FIELD_DETAIL = 'RESET_FIELD_DETAIL';
export const FIELD_DETAIL_COUNT = 'FIELD_DETAIL_COUNT';
export const FIELD_SELL_YOUNG_TREE = 'FIELD_SELL_YOUNG_TREE';
export const FIELD_RESET_LIST = 'FIELD_RESET_LIST';
export const FIELD_TYPES_SALE = 'FIELD_TYPES_SALE';
export const FIELD_TYPES_SALE_RESET = 'FIELD_TYPES_SALE_RESET';
export const RESET_FIELD = 'RESET_FIELD';

//gift
export const SHOW_GET_GIFT_POPUP = 'SHOW_GET_GIFT_POPUP';
export const CURRENT_GIFT_ID = 'CURRENT_GIFT_ID';
export const SHOW_POPUP_GIFT_LIMIT = 'SHOW_POPUP_GIFT_LIMIT';
export const CURRENT_GIFT_OBJ = 'CURRENT_GIFT_OBJ';
export const GIFT_GET_TREES_LIST_RESET = 'GIFT_GET_TREES_LIST_RESET';
export const GIFT_GET_TREES_LIST_STORY_RESET = 'GIFT_GET_TREES_LIST_STORY_RESET';
export const GIFT_GET_TREES_LIST = 'GIFT_GET_TREES_LIST';
export const GIFT_GET_TREES_LIST_LENGTH = 'GIFT_GET_TREES_LIST_LENGTH';
export const GIFT_GET_TREES_LIST_LENGTH_STORY = 'GIFT_GET_TREES_LIST_LENGTH_STORY';
export const CANCEL_GIFT_GET_LIST = 'CANCEL_GIFT_GET_LIST';
export const CANCEL_GIFT_GET_LIST_LENGTH = 'CANCEL_GIFT_GET_LIST_LENGTH';
export const CANCEL_GIFT_GET_LIST_RESET = 'CANCEL_GIFT_GET_LIST_RESET';
export const GET_GIFT_TREE = 'GET_GIFT_TREE';
export const WITHDRAW_GIFT = 'WITHDRAW_GIFT';
export const CHANGE_GIFT_CODE = 'CHANGE_GIFT_CODE';
export const CHANGE_CANCEL_GIFT_SKIP = 'CHANGE_CANCEL_GIFT_SKIP';
export const CHANGE_CANCEL_GIFT_PAGE = 'CHANGE_CANCEL_GIFT_PAGE';
export const CHANGE_GIFT_LOADING = 'CHANGE_GIFT_LOADING';
export const CANCEL_GIFT_RESET = 'CANCEL_GIFT_RESET';
export const CHANGE_GET_GIFT_SKIP = 'CHANGE_GET_GIFT_SKIP';
export const CHANGE_GET_GIFT_SKIP_STORY = 'CHANGE_GET_GIFT_SKIP_STORY';
export const CHANGE_GET_GIFT_PAGE = 'CHANGE_GET_GIFT_PAGE';
export const CHANGE_GET_GIFT_PAGE_STORY = 'CHANGE_GET_GIFT_PAGE_STORY';
export const GET_GIFT_RESET = 'GET_GIFT_RESET';




//exchange-tree tree
export const SHOW_EXCHANGE_TREE_POPUP = 'SHOW_EXCHANGE_TREE_POPUP';
export const EXCHANGE_CURRENT_TREE = 'EXCHANGE_CURRENT_TREE';
export const RESET_EXCHANGE_CURRENT_TREE = 'RESET_EXCHANGE_CURRENT_TREE';
export const EXCHANGE_LIST_TREES = 'EXCHANGE_LIST_TREES';
export const EXCHANGE_LIST_TREES_LOADING = 'EXCHANGE_LIST_TREES_LOADING';
export const EXCHANGE_TREES_CHANGE_PAGE = 'EXCHANGE_TREES_CHANGE_PAGE';
export const EXCHANGE_TREES_CHANGE_SKIP = 'EXCHANGE_TREES_CHANGE_SKIP';
export const EXCHANGE_TREES_LENGTH = 'EXCHANGE_TREES_LENGTH';
export const EXCHANGE_TREES_RESET = 'EXCHANGE_TREES_RESET';
export const EXCHANGE_SELECT_TREE = 'EXCHANGE_SELECT_TREE';
export const RESET_EXCHANGE_SELECT_TREE = 'RESET_EXCHANGE_SELECT_TREE';
export const EXCHANGE_LOAD_TREES = 'EXCHANGE_LOAD_TREES';
export const EXCHANGE_LOADING_TREES_POPUP = 'EXCHANGE_LOADING_TREES_POPUP';
export const EXCHANGE_RESET_TREES_POPUP = 'EXCHANGE_RESET_TREES_POPUP';
export const EXCHANGE_TREES_POPUP_LENGTH = 'EXCHANGE_TREES_POPUP_LENGTH';
export const SHOW_EXCHANGE_TREES_POPUP = 'SHOW_EXCHANGE_TREES_POPUP';
export const SHOW_EXCHANGE_CATEGORIES_POPUP = 'SHOW_EXCHANGE_CATEGORIES_POPUP';
export const EXCHANGE_LOAD_CATEGORY_POPUP = 'EXCHANGE_LOAD_CATEGORY_POPUP';
export const EXCHANGE_RESET_CATEGORY_POPUP = 'EXCHANGE_RESET_CATEGORY_POPUP';
export const EXCHANGE_CURRENT_CATEGORY_POPUP = 'EXCHANGE_CURRENT_CATEGORY_POPUP';
export const EXCHANGE_CHANGE_CHECK_POPUP_TREES = 'EXCHANGE_CHANGE_CHECK_POPUP_TREES';
export const EXCHANGE_RESET_DETAIL = 'EXCHANGE_RESET_DETAIL';
export const EXCHANGE_CHANGE_PAGE_POPUP = 'EXCHANGE_CHANGE_PAGE_POPUP';

//home
export const MAIN_STATISTIC = 'MAIN_STATISTIC';
export const STATISTIC_TICK = 'STATISTIC_TICK';
export const HOME_ALL_VARIABLES = 'HOME_ALL_VARIABLES'
export const STATISTIC_PRICE = 'STATISTIC_PRICE';
export const LANG_LIST = 'LANG_LIST';
// guarantees
export const GUARANTEES_LOAD = 'GUARANTEES_LOAD';
// how it works
export const HOWITWORKS_LOAD = 'HOWITWORKS_LOAD';
// contacts
export const CONTACTS_LOAD = 'CONTACTS_LOAD';
export const SOCIAL_LOAD = 'SOCIAL_LOAD';
//shop
export const BUY_TREE_POPUP = 'BUY_TREE_POPUP';
export const SHOP_REQUEST_YEARS_LIST = 'SHOP_REQUEST_YEARS_LIST';
export const SHOP_SET_YEARS_LIST = 'SHOP_SET_YEARS_LIST';
export const SHOP_LIST = 'SHOP_LIST';
export const SHOP_YOUNG_TREE = 'SHOP_ONE_TREE';
export const SHOP_FILTER = 'SHOP_FILTER';
export const SHOP_BUY_TREE = 'SHOP_BUY_TREE';
export const POPUP_BUY_SUCCESS = 'POPUP_BUY_SUCCESS';
export const POPUP_BUY_IFRAME = 'POPUP_BUY_IFRAME';
export const SHOP_FILTER_SEARCH ='SHOP_FILTER_SEARCH';
export const SHOP_LIST_INFO = 'SHOP_LIST_INFO';
export const SHOP_LIST_PAGE_INFO = 'SHOP_LIST_PAGE_INFO';
export const SHOP_LOADING = 'SHOP_LOADING';
export const FILTER_POPUP = 'FILTER_POPUP';

//gift tree

export const GIFT_TREE_LOAD = 'GIFT_TREE_LOAD';


//get qr code
export const GET_QR_CODE = 'GET_QR_CODE';
export const SHOW_GET_QR_CODE = 'SHOW_GET_QR_CODE';
export const GET_QR_ID = 'GET_QR_ID';

// info modals
export const TREE_APPRECIATION_INFO = 'TREE_APPRECIATION_INFO';
export const TOTAL_PROFIT_FROM_THE_CROP_INFO = 'TOTAL_PROFIT_FROM_THE_CROP_INFO';
export const EXPECTED_PROFIT_INFO = 'EXPECTED_PROFIT_INFO';
export const PLANTING_DATE_INFO = 'PLANTING_DATE_INFO';
export const BONUS_ACCOUNT_INFO = 'BONUS_ACCOUNT_INFO';
export const TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION';
export const POSITION_COMMISSION = 'POSITION_COMMISSION';
export const SHOW_INFO_CALC_TEXT = 'SHOW_INFO_CALC_TEXT';
export const SHOW_TEXT_HELP_BONUS = 'SHOW_TEXT_HELP_BONUS';
export const HELPER_CONTENT_FILTER = 'HELPER_CONTENT_FILTER';
export const HELPER_CONTENT_LOADING_FILTER = 'HELPER_CONTENT_LOADING_FILTER';
export const TERM_OF_USE = 'TERM_OF_USE';
export const TERM_OF_USE_ID = 'TERM_OF_USE_ID';
//setting
export const SETTING_CALCULATE = 'SETTING_CALCULATE';
export const FIELD_SETTING = 'FIELD_SETTING';
export const GIVE_SETTING = 'GIVE_SETTING';
export const SELL_SETTING = 'SELL_SETTING';
export const PUT_UP_SETTING = 'PUT_UP_SETTING';
//basket
export const ADD_BASKET = 'ADD_BASKET';
export const REMOVE_BASKET = 'REMOVE_BASKET';
export const RESET_BASKET = 'RESET_BASKET';
//popup help
export const SHOW_POPUP_HELP = 'SHOW_POPUP_HELP';
export const FETCH_HELP_INFO = 'FETCH_HELP_INFO';
export const RESET_POPUP_HELP = 'RESET_POPUP_HELP';
export const SHOW_POPUP_STATISTIC = 'SHOW_POPUP_STATISTIC';
export const SHOW_USER_STATUS = 'SHOW_USER_STATUS';
export const SHOW_USER_NFT = 'SHOW_USER_NFT';
export const FETCH_STATISTIC_INFO = 'FETCH_STATISTIC_INFO';
export const RESET_POPUP_STATISTIC = 'RESET_POPUP_STATISTIC';
//label-documents
export const LABEL_DOCUMENTS = 'LABEL_DOCUMENTS';
export const LABEL_DOCUMENTS_RESET = 'LABEL_DOCUMENTS_RESET';
//express
export const CHANGE_POPUP_EXPRESS = 'CHANGE_POPUP_EXPRESS';
export const EXPRESS_ACTION = 'EXPRESS_ACTION';
export const CHANGE_POPUP_IFRAME = 'CHANGE_POPUP_IFRAME';
export const REDIRECT_TRANSACTION = 'REDIRECT_TRANSACTION';
export const EXPRESS_PURCHASES = 'EXPRESS_PURCHASES';
export const EXPRESS_PURCHASES_LOADING = 'EXPRESS_PURCHASES_LOADING';
export const EXPRESS_RE_BUY_TREES = 'EXPRESS_RE_BUY_TREES';
export const POPUP_EXPRESS_PROMO_CODE = 'POPUP_EXPRESS_PROMO_CODE';
export const EXPRESS_GET_TOKEN_DOWNLOAD = 'EXPRESS_GET_TOKEN_DOWNLOAD';
//put up gift
export const PUT_UP_SHOW_CATEGORY = 'PUT_UP_SHOW_CATEGORY';
export const PUT_UP_TYPES_RESET = 'PUT_UP_TYPES_RESET';
export const PUT_UP_TYPES = 'PUT_UP_TYPES';
export const PUT_UP_LIST = 'PUT_UP_LIST';
export const PUT_UP_RESET_LIST = 'PUT_UP_RESET_LIST';
export const PUT_UP_RESET_CURRENT = 'PUT_UP_RESET_CURRENT';
export const PUT_UP_INCREMENT_COUNT = 'PUT_UP_INCREMENT_COUNT';
export const PUT_UP_DECREMENT_COUNT = 'PUT_UP_DECREMENT_COUNT';
export const PUT_UP_CHANGE_COUNT = 'PUT_UP_CHANGE_COUNT';
export const PUT_UP_REMOVE_TREES = 'PUT_UP_REMOVE_TREES';
export const PUT_UP_DETAIL = 'PUT_UP_DETAIL';
export const PUT_UP_SELL_YOUNG_TREE = 'PUT_UP_SELL_YOUNG_TREE';
export const PUT_UP_TYPES_SALE = 'PUT_UP_TYPES_SALE';
export const PUT_UP_TYPES_SALE_RESET = 'PUT_UP_TYPES_SALE_RESET';
export const PUT_UP_FIELD_RESET = 'PUT_UP_FIELD_RESET';
//cancel put up gift
export const SHOW_POPUP_CANCEL_PUT_UP_GIFT = 'SHOW_POPUP_CANCEL_PUT_UP_GIFT';
export const CANCEL_PUT_UP_GIFT_LIST = 'CANCEL_PUT_UP_GIFT_LIST';
export const CANCEL_PUT_UP_GIFT_CHANGE_LOADING = 'CANCEL_PUT_UP_GIFT_CHANGE_LOADING';
export const CANCEL_PUT_UP_GIFT_RESET_LIST = 'CANCEL_PUT_UP_GIFT_RESET_LIST';
export const CANCEL_PUT_UP_GIFT_PIECE = 'CANCEL_PUT_UP_GIFT_PIECE';
export const CANCEL_PUT_UP_GIFT_LENGTH_LIST = 'CANCEL_PUT_UP_GIFT_LENGTH_LIST';
export const CANCEL_PUT_UP_GIFT_PACK = 'CANCEL_PUT_UP_GIFT_PACK';
export const CANCEL_PUT_UP_GIFT_CHANGE_SKIP = 'CANCEL_PUT_UP_GIFT_CHANGE_SKIP';
export const CANCEL_PUT_UP_GIFT_CHANGE_PAGE = 'CANCEL_PUT_UP_GIFT_CHANGE_PAGE';
export const CANCEL_PUT_UP_GIFT_RESET_PAGINATION = 'CANCEL_PUT_UP_GIFT_RESET_PAGINATION';

//withdrawal
export const GET_WITHDRAWAL_LOADING = 'GET_WITHDRAWAL_LOADING';
export const CHANGE_WITHDRAWAL_TYPE = 'CHANGE_WITHDRAWAL_TYPE';
//star partners
export const SET_STAR_PARTNERS = 'SET_STAR_PARTNERS';
export const SET_SHOW_DETAIL = 'SET_SHOW_DETAIL';
export const SET_STAR_INFO = 'SET_STAR_INFO';
export const RESET_STAR_INFO = 'RESET_STAR_INFO';
export const SET_LOADING_STAR_PARTNERS = 'SET_LOADING_STAR_PARTNERS';
export const CHANGE_STAR_PARTNERS_SKIP = 'CHANGE_STAR_PARTNERS_SKIP';
export const SET_META_STAR_PARTNER = 'SET_META_STAR_PARTNER';
export const CHANGE_PARAMS_DETAIL_PARTNERS = 'CHANGE_PARAMS_DETAIL_PARTNERS';
export const CHANGE_RANDOM_INDEX_PARTNER = 'CHANGE_RANDOM_INDEX_PARTNER';
//buy for gift
export const BUY_GIFT_DEFAULT_SHOW_POPUP = 'BUY_GIFT_DEFAULT_SHOW_POPUP';
export const BUY_GIFT_SELECT_YOUNG_TREES = 'BUY_GIFT_SELECT_YOUNG_TREES';
export const BUY_GIFT_COUNT_FREE_TREES = 'BUY_GIFT_COUNT_FREE_TREES';
export const BUY_FOR_GIFT = 'BUY_FOR_GIFT';
//withdrawal history
export const WITHDRAWAL_HISTORY = 'WITHDRAWAL_HISTORY';
export const RESET_WITHDRAWAL_HISTORY = 'RESET_WITHDRAWAL_HISTORY';
export const WITHDRAWAL_HISTORY_LENGTH = 'WITHDRAWAL_HISTORY_LENGTH';
export const WITHDRAWAL_HISTORY_CHANGE_TO = 'WITHDRAWAL_HISTORY_CHANGE_TO';
export const WITHDRAWAL_HISTORY_CHANGE_FROM = 'WITHDRAWAL_HISTORY_CHANGE_FROM';
export const WITHDRAWAL_HISTORY_CHANGE_SKIP = 'WITHDRAWAL_HISTORY_CHANGE_SKIP';
export const WITHDRAWAL_HISTORY_CHANGE_PAGE = 'WITHDRAWAL_HISTORY_CHANGE_PAGE';
export const WITHDRAWAL_HISTORY_LOADING = 'WITHDRAWAL_HISTORY_LOADING';
export const RESET_WITHDRAWAL_HISTORY_COMPONENT = 'RESET_WITHDRAWAL_HISTORY_COMPONENT';
export const WITHDRAWAL_HISTORY_HAS_TWO_FACTOR = 'WITHDRAWAL_HISTORY_HAS_TWO_FACTOR';
//promotions
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const SET_PROMOTION_LOADING = 'SET_PROMOTION_LOADING';
export const SET_PROMOTION = 'SET_PROMOTION';

//
export const SHOW_POPUP_AFTER_REGISTRATION = 'SHOW_POPUP_AFTER_REGISTRATION';
