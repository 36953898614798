import { mapMutations, mapGetters } from 'vuex'
import {IS_LOGIN_USER,SHOW_SING_IN,REGISTRATION_SHOW} from "@/store/mutation-types";
export default{
    name: 'server-error',
    computed: {
        ...mapGetters({
            errorMessage: `system/errorServerMessage`
        })
    },
    methods: {
        ...mapMutations({
            forEnter: `system/${IS_LOGIN_USER}`,
            showSingIn: `auth/${SHOW_SING_IN}`,
            showRegistration: `auth/${REGISTRATION_SHOW}`,
        }),
        goRegistration(){
            this.showRegistration(true);
        },
    }
}
