import {mapMutations, mapGetters, mapActions} from 'vuex'
import {globalLoader, fixBody} from '@/helpers/variables/index'
import {required, minLength} from 'vuelidate/lib/validators'
import axios from 'axios'
import {
    SHOW_POPUP_TWO_AUTH_CODE,
    FETCH_PAYMENTS_SYSTEM,
    BUY_COUNT_FREE_TREES,
    LOAD_USER_DATA,
    BUY_SELECT_YOUNG_TREES,
    SELL_TREES_RESET,
    TAKE_OFF_PACK,
    SELL_TREES,
    TAKE_OFF_PIECE,
    TAKE_OFF_RESET_LIST,
    SHOW_POPUP_TAKE_OFF,
    GIFT_GET_TREES_LIST,
    SHOW_GET_GIFT_POPUP,
    DOWNLOAD_CERTIFICATE,
    GET_GIFT_TREE,
    GIVE_TREES,
    CHARITY_TREES,
    WITHDRAW_GIFT,
    TRANSACTION_HISTORY,
    FIELD_TYPES,
    FIELD_SELL_YOUNG_TREE,
    UPDATE_USER_DATA,
    UPDATE_USER_PASSWORD,
    PERSONAL_VALIDATIONS_ERRORS,
    PERSONAL_CHANGE_EDIT_DATA_STATUS,
    PERSONAL_CHANGE_EDIT_PASSWORD_STATUS,
    PERSONAL_RESET_PASSWORD,
    SHOP_BUY_TREE,
    GIVE_FETCH_PROMO_CODE,
    BUY_YOUNG_TREE,
    PUT_UP_SELL_YOUNG_TREE,
    PUT_UP_FIELD_RESET,
    CANCEL_PUT_UP_GIFT_LIST,
    CANCEL_PUT_UP_GIFT_PIECE,
    CANCEL_PUT_UP_GIFT_PACK,
    BUY_FOR_GIFT,
    BUY_GIFT_SELECT_YOUNG_TREES
} from "@/store/mutation-types";

export default {
    name: 'popup-enter-code',
    data() {
        return {
            code: '',
        }
    },
    validations: {
        code: {
            required,
            minLength: minLength(6),
        }
    },
    computed: {
        ...mapGetters({
            failRequest: `profile/failRequest`,
            codeMsg: `gift/code`,
            giveTypeCheck: `give/giveTypeCheck`,
            setting: 'main/setting',
        })
    },
    watch: {},
    created() {

    },
    methods: {
        ...mapActions({
            //buy first
            fetchPayments: `profileBuyTree/${FETCH_PAYMENTS_SYSTEM}`,
            buyDefaultTree: `shop/${BUY_YOUNG_TREE}`,
            fetchParams: `profileBuyTree/${BUY_COUNT_FREE_TREES}`,
            //sell trees
            sellTrees: `sell/${SELL_TREES}`,
            //user data
            loadUserData: `profile/${LOAD_USER_DATA}`,
            //take off
            withdrawPack: `takeOff/${TAKE_OFF_PACK}`,
            withdrawPiece: `takeOff/${TAKE_OFF_PIECE}`,
            //get gift
            fetchListGifts: `gift/${GIFT_GET_TREES_LIST}`,
            getGiftTree: `gift/${GET_GIFT_TREE}`,
            //gift trees
            giveTrees: `give/${GIVE_TREES}`,
            charityTrees: `give/${CHARITY_TREES}`,
            //cancel get gift
            withdrawGift: `gift/${WITHDRAW_GIFT}`,
            //transaction history (get request)
            listTransactionHistory: `history/${TRANSACTION_HISTORY}`,
            //sell young trees
            fetchAreasYoung: `field/${FIELD_TYPES}`,
            sellTreeYoung: `field/${FIELD_SELL_YOUNG_TREE}`,
            //personal
            updateUserData: `profile/${UPDATE_USER_DATA}`,
            updateUserPassword: `profile/${UPDATE_USER_PASSWORD}`,
            //shop
            buyTreeInShop: `shop/${SHOP_BUY_TREE}`,
            //fetch promo
            fetchPromoCode: `give/${GIVE_FETCH_PROMO_CODE}`,
            //put_up_gift
            sellTree: `putUpGift/${PUT_UP_SELL_YOUNG_TREE}`,
            //cancel put-up-gift
            fetchListCancelPutUpGift: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_LIST}`,
            //cancel put up gift
            withdrawGiftPiece: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_PIECE}`,
            withdrawGiftPack: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_PACK}`,
            // buy for gift
            buyForGift: `buyForGift/${BUY_FOR_GIFT}`,
        }),
        ...mapMutations({
            changePopup: `profile/${SHOW_POPUP_TWO_AUTH_CODE}`,
            //buy first
            resetSelectYoungTrees: `profileBuyTree/${BUY_SELECT_YOUNG_TREES}`,
            //sell
            resetTreesList: `sell/${SELL_TREES_RESET}`,
            //take off
            resetList: `takeOff/${TAKE_OFF_RESET_LIST}`,
            showPopupTakeOff: `takeOff/${SHOW_POPUP_TAKE_OFF}`,
            //get gift
            showPopupGetGift: `gift/${SHOW_GET_GIFT_POPUP}`,
            downloadDocument: `trees/${DOWNLOAD_CERTIFICATE}`,
            //personal
            changeValidationErrors: `profile/${PERSONAL_VALIDATIONS_ERRORS}`,
            changeEditData: `profile/${PERSONAL_CHANGE_EDIT_DATA_STATUS}`,
            changeEditPassword: `profile/${PERSONAL_CHANGE_EDIT_PASSWORD_STATUS}`,
            resetPasswords: `profile/${PERSONAL_RESET_PASSWORD}`,
            //shop
            resetPutUp: `putUpGift/${PUT_UP_FIELD_RESET}`,
            // buy for gift
            changeSelectGiftTrees: `buyForGift/${BUY_GIFT_SELECT_YOUNG_TREES}`,
        }),
        showPopup() {
            fixBody(false);
            this.changePopup(false);
        },
        retryRequest() {
            localStorage.setItem('keyRequest', this.code);
            this.$v.code.$touch();
            if (!this.$v.code.$invalid) {
                let data = '';
                if (this.failRequest.data !== undefined) {
                    data = JSON.parse(this.failRequest.data);
                }
                axios.defaults.headers.common = {
                    'One-Time-Pass': this.code,
                    'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
                    'X-localization': this.$store.getters.currentLanguage.translationKey
                };
                //put_up_gift
                if (this.failRequest.url.includes('put_up_gift')) {
                    this.sellTree(data)
                        .then(response => {
                            this.resetPutUp();
                            this.changePopup(false);
                            this.$toaster.success(response.data.message);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$toaster.error(error.response.data.error.message);
                        })
                }
                //first_buy tree
                if (this.failRequest.url.includes('first_buy')) {
                    this.buyDefaultTree(data)
                        .then(() => {
                            this.fetchPayments({countryCode: this.setting.countryCode});
                            this.loadUserData();
                            this.resetSelectYoungTrees();
                            this.changePopup(false);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$toaster.error(error.response.data.error.message);
                        })
                }
                //buy for gift
                if (this.failRequest.url.includes('buy_gift')) {
                    this.buyForGift(data)
                        .then(() => {
                            this.changeSelectGiftTrees(0);
                            this.changePopup(false);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$toaster.error(error.response.data.error.message);
                        })
                }
                //sell trees
                if (this.failRequest.url.includes('add_tree')) {
                    this.sellTrees(data)
                        .then(response => {
                            this.$toaster.success(response.message);
                            this.resetTreesList();
                            this.changePopup(false);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$toaster.error(error.response.data.error.message);
                        })
                }
                if (this.failRequest.url.includes('withdraw')) {
                    if (data.type === 'sale') {
                        //take off pack
                        if (this.failRequest.url.includes('withdraw_pack')) {
                            this.withdrawPack(data)
                                .then(response => {
                                    this.$toaster.success(response.message);
                                    this.changePopup(false);
                                    fixBody(false)
                                })
                                .catch(error => {
                                    this.$toaster.error(error.response.data.error.message);
                                })
                        }
                        //take off piece
                        if (this.failRequest.url.includes('withdraw') &&
                            !this.failRequest.url.includes('withdraw_pack') &&
                            !this.failRequest.url.includes('withdraw_gift')
                        ) {
                            this.withdrawPiece(data)
                                .then(response => {
                                    this.$toaster.success(response.message);
                                    this.changePopup(false);
                                    this.showPopupTakeOff(false);
                                    fixBody(false)
                                })
                                .catch(error => {
                                    this.$toaster.error(error.response.data.error.message)
                                })
                        }
                    } else {
                        //take off gift pack
                        if (this.failRequest.url.includes('withdraw_pack')) {
                            this.withdrawGiftPack(data)
                                .then(response => {
                                    this.$toaster.success(response.message);
                                    this.changePopup(false);
                                    fixBody(false)
                                })
                                .catch(error => {
                                    this.$toaster.error(error.response.data.error.message);

                                })
                        }
                        //take off gift piece
                        if (this.failRequest.url.includes('withdraw') &&
                            !this.failRequest.url.includes('withdraw_pack') &&
                            !this.failRequest.url.includes('withdraw_gift')
                        ) {
                            this.withdrawGiftPiece(data)
                                .then(response => {
                                    this.$toaster.success(response.message);
                                    this.changePopup(false);
                                    this.showPopupTakeOff(false);
                                    fixBody(false)
                                })
                                .catch(error => {
                                    this.$toaster.error(error.response.data.error.message)
                                })
                        }
                    }
                }
                //get gift
                if (this.failRequest.url.includes('get_my_gifted_tree')) {
                    this.getGiftTree(data)
                        .then(() => {
                            this.$toaster.success(this.$t('getGiftSuccess'));
                            this.changePopup(false);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$toaster.error(error.response.data.error.message)
                        })
                }
                //give trees
                if (this.failRequest.url.includes('gift_trees') || this.failRequest.url.includes('generate_promo_code_for_trees') || this.failRequest.url.includes('charity_trees')) {
                    if (this.failRequest.url.includes('generate_promo_code_for_trees')) {
                        this.fetchPromoCode(data)
                            .then(response => {
                                this.changePopup(false);
                                this.downloadDocument(response.data.link);
                            })
                            .catch(error => {
                                this.$toaster.error(error.response.data.error.message)
                            });
                    } else {
                        if (this.giveTypeCheck === 'give') {
                            this.giveTrees(data)
                                .then(response => {
                                    this.$toaster.success(response.data.message);
                                    this.changePopup(false);
                                    fixBody(false)
                                }).catch(error => {
                                if (error.response.status === 422) {
                                    this.$toaster.error(error.response.data.error.message);
                                }
                            });
                        } else {
                            this.charityTrees(data)
                                .then(response => {
                                    this.$toaster.success(response.data.message);
                                    this.changePopup(false);
                                    fixBody(false)
                                }).catch(error => {
                                if (error.response.status === 422) {
                                    this.$toaster.error(error.response.data.error.message);
                                }
                            });
                        }
                    }
                }
                //cancel get gift
                if (this.failRequest.url.includes('withdraw_gift')) {
                    this.withdrawGift(data)
                        .then(response => {
                            this.$toaster.success(response.message);
                            this.changePopup(false);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$toaster.error(error.response.data.error.message)
                        })
                }
                //sell young trees
                if (this.failRequest.url.includes('first_sale')) {
                    this.sellTreeYoung(data)
                        .then(response => {
                            this.fetchAreasYoung();
                            this.$toaster.success(response.data.message);
                            this.changePopup(false);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$toaster.error(error.response.data.error.message)
                        })
                }
                //transactions history
                if (this.failRequest.url.includes('transactions_history')) {
                    globalLoader(true);
                    this.listTransactionHistory()
                        .then(() => {
                            this.$router.push({name: 'personal.history'});
                            this.changePopup(false);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$router.push({name: 'personal'});
                            this.$toaster.error(error.response.data.error.message)
                        })
                        .finally(() => {
                            globalLoader(false);
                        })
                }
                //change user data
                if (this.failRequest.url.includes('update')) {
                    globalLoader(true);
                    this.updateUserData(data)
                        .then(() => {
                            this.$toaster.success(this.$t('toasterSuccessProfileData'));
                            this.changeValidationErrors({});
                            this.changeEditData();
                            this.changePopup(false);
                            fixBody(false);
                        })
                        .catch(error => {
                            if (error.response.status === 422) {
                                if (error.response.data.errors) {
                                    for (let key in error.response.data.errors) {
                                        error.response.data.errors[key].forEach(elem => {
                                            this.$toaster.error(elem);
                                        });
                                    }
                                }
                                if (error.response.data.error) {
                                    this.$toaster.error(error.response.data.error.message);
                                }
                            }

                        })
                        .finally(() => {
                            globalLoader(false)
                        });
                }
                if (this.failRequest.url.includes('change_password')) {
                    globalLoader(true);
                    this.updateUserPassword(data)
                        .then(response => {
                            this.$toaster.success(response.data.message);
                            this.changeEditPassword();
                            this.changePopup(false);
                            fixBody(false);
                            this.resetPasswords();
                        })
                        .catch(error => {
                            if (error.response.status === 422) {
                                if (error.response.data.error) {
                                    this.$toaster.error(error.response.data.error.message);
                                }
                                if (error.response.data.errors) {
                                    for (let key in error.response.data.errors) {
                                        error.response.data.errors[key].forEach(elem => {
                                            this.$toaster.error(elem);
                                        });
                                    }
                                }
                            }
                        }).finally(() => {
                        globalLoader(false)
                    });

                }
                //shop buy
                if (this.failRequest.url.includes('buy_trees')) {
                    globalLoader(true);
                    this.buyTreeInShop(data)
                        .then(() => {
                            this.changePopup(false);
                            fixBody(false)
                        })
                        .catch(error => {
                            this.$toaster.error(error.response.data.error.message)
                        })
                        .finally(() => {
                            globalLoader(false);
                        })
                }

            }

        }
    },
    destroyed() {
        if (this.failRequest.method === 'post') {
            axios.defaults.headers.common = {};
        }
    }
}
