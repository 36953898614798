import { mapMutations, mapGetters, mapActions } from 'vuex'
import {globalLoader} from '@/helpers/variables/index'
import {
    SHOW_GET_GIFT_POPUP,
    GET_GIFT_TREE_PROMO,
    LOAD_USER_DATA,
    GIFT_GET_TREES_LIST,
    DOWNLOAD_CERTIFICATE,
    CHANGE_GIFT_CODE,
    SHOW_POPUP_PROMO
} from "@/store/mutation-types";
export default{
    name: 'get-gift-popup',
    data(){
        return {
            code: '',
        }
    },
    computed: {
        ...mapGetters({
            loading: `promo/loading`,
            codeMsg: `promo/code`,
        })
    },
    watch: {
        code(){
            this.changeGiftCode(this.code)
        }
    },
    methods: {
        ...mapActions({
            fetchList: `promo/${GIFT_GET_TREES_LIST}`,
            fetchUser: `profile/${LOAD_USER_DATA}`,
            getGiftTree: `promo/${GET_GIFT_TREE_PROMO}`,
        }),
        ...mapMutations({
            showPopup: `promo/${SHOW_GET_GIFT_POPUP}`,
            changeGiftCode: `promo/${CHANGE_GIFT_CODE}`,
            popupPromoShow: `promo/${SHOW_POPUP_PROMO}`,
        }),
        getGift() {
            let obj = {
                code: this.codeMsg
            };
            this.getGiftTree(obj)
                .then(response => {
                    this.$toaster.success(this.$t('getGiftSuccess'));
                })
                .catch(error => {
                    if(error.response.status === 424){
                        this.popupPromoShow(false)
                    }
                    this.$toaster.error(error.response.data.error.message)
                })
        },
    },
    destroyed(){
        this.changeGiftCode('');
    }
}
