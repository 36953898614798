export default {
    name: 'promocode-item',
    props: {
        item: {
            type: Object,
            default: {}
        }
    },
    methods: {
        clipboardCopy(){
            this.$toaster.success(this.$t('clipboardCode'));
        },
    }
}
