import {mapActions, mapGetters, mapMutations} from 'vuex'
import {
    BUY_YOUNG_TREE,
    CHECK_PROMO_CODE,
    FETCH_PAYMENTS_SYSTEM,
    LOAD_USER_DATA,
    PAYMENT_LEOGAMING,
    POPUP_BUY_IFRAME,
    BUY_GIFT_SELECT_YOUNG_TREES,
    BUY_GIFT_COUNT_FREE_TREES,
    BUY_FOR_GIFT
} from '@/store/mutation-types';
import _ from 'lodash'

export default {
    name: 'buy-default-tree',
    data() {
        return {
            countLoader: false,
            sliderValue: 0,
            showPayment: false,
            value: '',
            paySystem: null,
            payload: {
                tree_count: 0,
                payment_system: null,
                promoCode: '',
                promoArea: 'express_gift'
            },
        }
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
            setting: 'main/setting',
            isLoadingCountryCode: 'main/isLoadingCountryCode',
            countFreeTrees: `buyForGift/countFreeTrees`,
            payments: `profileBuyTree/payments`,
            selectYoungTrees: `buyForGift/selectYoungTrees`,
            promoData: `profileBuyTree/promoData`,
            promoDataLoading: `profileBuyTree/promoDataLoading`,
        }),
        totalPrice() {
            let total = this.setting.putUpGiftPrice * this.payload.tree_count;
            if(this.promoData){
                if(this.promoData.discount > 0){
                    total = total - (total * (this.promoData.discount / 100));
                    total = Math.round(total * 100) / 100
                }
            }
            if(this.payload.promoCode.length === 0){
                total = this.setting.putUpGiftPrice * this.payload.tree_count
            }
            return total;
        },
        watchCount() {
            if (this.payload.tree_count > this.countFreeTrees.all_gift_trees_count) {
                return true
            } else {
                return false
            }
        }
    },
    mounted(){
        this.fetchParams()
            .then(response => {
                if (this.selectYoungTrees !== 0) {
                    if (response.data.all_gift_trees_count < this.selectYoungTrees) {
                        this.payload.tree_count = response.data.all_gift_trees_count;
                    } else {
                        this.payload.tree_count = this.selectYoungTrees;
                    }
                }
            })
            .finally(() => {
                this.countLoader = true;
            });
        if(this.isLoadingCountryCode){
            this.fetchPayments({countryCode: this.setting.countryCode});
        }

    },
    watch: {
        isLoadingCountryCode(){
            if(this.isLoadingCountryCode){
                this.fetchPayments({countryCode: this.setting.countryCode});
            }
        }
    },
    methods: {
        requestBuy(){
            this.buy(this.payload)
                .then(() => {
                    this.setPromo(null);
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        if(error.response.data.hasOwnProperty('errors')){
                            this.validationErrors = error.response.data.errors;
                        }else{
                            this.$toaster.error(error.response.data.error);
                        }
                    }
                });
        },
        butTree() {
            if(!this.payload.payment_system){
                this.$toaster.error(this.$t('payMethod'));
                return false;
            }

            // find current item in payment system
            const currentItemPayment  = this.payments.find(e => e.payment_system === this.payload.payment_system);

            // has restriction item
            if(currentItemPayment.restriction){

                // check max price payment system for selected payment
                if(this.totalPrice < parseInt(currentItemPayment.restriction.max.restriction)){
                    this.requestBuy();
                }else{
                    this.$toaster.error(currentItemPayment.restriction.max.error);
                }
            }else{
                // not restriction object (balance)
                const total = this.user.balance + this.user.bonus_balance;

                // check user balance
                if(currentItemPayment.payment_system === 'balance'){
                    if(total < this.totalPrice){
                        this.$toaster.error(this.$t('notEnoughMoney'));
                    }else{
                        this.requestBuy();
                    }
                }else{
                    this.requestBuy();
                }
            }
        },
        ...mapActions({
            fetchPayments: `profileBuyTree/${FETCH_PAYMENTS_SYSTEM}`,
            buy: `buyForGift/${BUY_FOR_GIFT}`,
            fetchParams: `buyForGift/${BUY_GIFT_COUNT_FREE_TREES}`,
            fetchData: `profile/${LOAD_USER_DATA}`,
            buyYoungTrees: `shop/${BUY_YOUNG_TREE}`,
            checkPromoCode: `profileBuyTree/${CHECK_PROMO_CODE}`,
        }),
        ...mapMutations({
            resetSelectYoungTrees: `buyForGift/${BUY_GIFT_SELECT_YOUNG_TREES}`,
            setPromo: `profileBuyTree/${CHECK_PROMO_CODE}`,
            iframeLink: `shop/${PAYMENT_LEOGAMING}`,
            cashPopUpIframe: `shop/${POPUP_BUY_IFRAME}`,
        }),
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
        checkPromo: _.debounce(function () {
            if(this.payload.promoCode.length > 0) {
                this.checkPromoCode({
                    code: this.payload.promoCode,
                    promoArea: this.payload.promoArea
                })
                .finally(() => {
                    if(this.promoData.discount === 0){
                        this.$toaster.error(this.$t('errorPromoCode'));
                    }
                })
            }
        }, 2000),

    },
    destroyed() {
        this.setPromo(null);
        this.resetSelectYoungTrees(0);
    }
}
