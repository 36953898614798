import {mapGetters, mapActions, mapMutations} from 'vuex';


export default {
  name: 'profile',
  data() {
    return {}
  },

  watch: {},
  components: {},
  computed: {
    ...mapGetters({
      user: 'profile/user',
      settings: 'main/setting',
    }),
    progressBar() {
      let levels = Object.keys(this.settings.levels),
        levelProgress = this.user.level_progress
      if (levelProgress >= levels[levels.length - 1]){
        return 100;
      } else if (levelProgress > 0){
        let i = 0;
        levels.forEach((el)=>{
          if(levelProgress > el){
            i++
          }
        });
        return (i-1) * 100/(levels.length -1) + (levelProgress - levels[i-1]) * (100/(levels.length - 1))/(levels[i] - levels[i-1])
      }
      else return 0
    },
    activeTrees(){
      let levels = Object.keys(this.settings.levels),
        levelProgress = this.user.level_progress
      if(levelProgress > 0){
        if(levelProgress >= levels[levels.length - 1]){
          return levels.length - 1
        }
        else {
          let i = 0;
          levels.forEach((el)=>{
            if(levelProgress >= el){
              i++
            }
          });
          return i-1
        }
      }
      else return 0
    },
    nextStep(){
      let levels = Object.keys(this.settings.levels),
        levelProgress = this.user.level_progress
      if(levelProgress >= 0){
        if(levelProgress >= levels[levels.length - 1]){
          return 0
        }
        else {
          let i = 0;
          let difference = 0
          levels.forEach((el)=>{
            if(levelProgress >= el){
              i++
            }
          });
          difference = levels[i] - levelProgress
          return difference
        }
      }
      else return levels[0]
    },
  },
  created() {

  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      changeStatus: 'popups/SHOW_USER_STATUS'
    }),
  }
}
