import Vue from 'vue';
import store from './store';
import {
    AUTH_LOGOUT,
    SHOW_SERVER_ERROR,
    AUTH_LOGIN,
    SHOW_POPUP_TWO_AUTH_CODE,
    FAIL_REQUEST,
    POPUP_ENABLE_TWO_FACTOR
} from './store/mutation-types';
import router from './router';
import axios from "axios";
import VueAxios from "vue-axios";
import { fixBody } from '@/helpers/variables/index'
Vue.use(VueAxios, axios);

// / for multiple parallel requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

const apiUrl = process.env.VUE_APP_ROOT_API;
export const $http = axios.create({
    baseURL: apiUrl,
    headers: {
        'Authorization' : `Bearer ${localStorage.getItem('user_token')}`,
        'Accept': 'application/json',
    }
});
$http.interceptors.request.use((config) => {


    config.headers['Authorization'] = `Bearer ${localStorage.getItem('user_token')}`;
    config.headers['X-localization'] = store.getters.currentLanguage.translationKey;

    if (sessionStorage.getItem("X-LEELOO-MSG") !== undefined){
        if(sessionStorage.getItem("X-LEELOO-MSG") !== null){
            config.headers['X-LEELOO-MSG']  = sessionStorage.getItem("X-LEELOO-MSG");
        }
    }

    if (sessionStorage.getItem("X-LEELOO-CID") !== undefined){
        if(sessionStorage.getItem("X-LEELOO-CID") !== null){
            config.headers['X-LEELOO-CID']  = sessionStorage.getItem("X-LEELOO-CID");
        }
    }



    return config;
});
$http.interceptors.response.use((response) => {
    return response;
}, (error) => {
        const originalRequest = error.config;
        switch(error.response.status){
            case 500: {
                store.commit(`system/${SHOW_SERVER_ERROR}`, Math.random());
                break;
            }
            case 404: {
                router.push({ name: '404' });
                break;
            }
            case 423: {
                store.commit(`profile/${POPUP_ENABLE_TWO_FACTOR}`, true);
                break;
            }
            case 424: {
                store.commit(`profile/${FAIL_REQUEST}`, error.config);
                store.commit(`profile/${SHOW_POPUP_TWO_AUTH_CODE}`, true);
                break;
            }
            default: {
                break;
            }
        }
        if (error.response.status === 401 && !originalRequest.retry) {
            if (isRefreshing) {
                return new Promise(((resolve, reject) => {
                    failedQueue.push({resolve, reject});
                })).then((token) => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    return Vue.axios(originalRequest);
                }).catch(error => error);
            }

            originalRequest.retry = true;
            isRefreshing = true;

            return new Promise(((resolve, reject) => {
                $http.post('refresh', { token: localStorage.getItem('user_token') })
                    .then(response => {
                        store.commit(`auth/${AUTH_LOGIN}`, response.data.access_token);
                        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
                        processQueue(null, response.data.access_token);
                        resolve(Vue.axios(originalRequest));
                    })
                    .catch((error) => {
                        router.push({ name: 'home' });
                        store.commit(`auth/${AUTH_LOGOUT}`);
                        processQueue(error, null);
                        reject(error);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            }));
        }

        return Promise.reject(error);
    }

);

export default function install (Vue) {
    Object.defineProperty(Vue.prototype, '$http', {
        get () {
            return $http
        }
    })
}
