import {mapActions, mapGetters, mapMutations} from 'vuex';
import SliderChange from '@/components/slider-change/index.vue';
import {
    NEWS_LIST,
    NEWS_CHANGE_POSITION_SCROLL,
    OPEN_LINKS,
    CHANGE_NEWS_SKIP,
    CHANGE_IS_LOAD_NEWS
} from '@/store/mutation-types'
import InfiniteLoading from 'vue-infinite-loading';
import { $http } from '@/https';


export default {
    name:'news',
    data(){
        return{
            openShare:false,
            newsTypeStatus: true,
            page: 0,
            bottomOfWindow: false,
        }
    },
    metaInfo: {
        title: 'Новости | Инвестиционный проект BioDeposit',
        meta: [
            { name: 'description', content: '【Новости - BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
        ]
    },
    components: {
        SliderChange,
        InfiniteLoading
    },
    computed:{
        ...mapGetters({
            newsList: 'news/newsList',
            loading: 'news/loading',
            newsLength: 'news/newsLength',
            skip: 'news/skip',
            isLoad: 'news/isLoad',
            positionScroll: 'news/positionScroll',
            goToPositionScroll: 'news/goToPositionScroll',
        }),
        watchLength(){
            if(this.newsLength > this.skip){
                return true
            }else{
                return false
            }
        }
    },
    created(){
        if(this.newsList.length <= 0){
            this.fetchContent();
        }
        this.changeIsLoad(true);
    },
    mounted(){
        window.addEventListener('scroll', this.lazyLoad);
        window.scrollTo(0, 0);
        if(!this.goToPositionScroll && this.positionScroll !== 0){
            setTimeout(() => {
                window.scrollTo(0, this.positionScroll);
            }, 10)
        }

    },
     beforeRouteLeave(to, from, next) {
        this.changePositionScroll(window.pageYOffset || window.scrollTop);
         this.changeIsLoad(false);

        next();
    },
    methods:{
        ...mapActions({
            fetchContent: `news/${NEWS_LIST}`
        }),
        ...mapMutations({
            changePositionScroll: `news/${NEWS_CHANGE_POSITION_SCROLL}`,
            changeIsLoad: `news/${CHANGE_IS_LOAD_NEWS}`,
            changeNewsSkip: `news/${CHANGE_NEWS_SKIP}`
        }),
        lazyLoad() {
            this.bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 200;
            if (this.bottomOfWindow && this.isLoad && this.watchLength && !this.loading) {
                this.fetchContent()
                    .then(() => {})
                    .finally(()=> {})
            }
        },
    },
    destroyed(){
        window.removeEventListener('scroll', this.lazyLoad);
    }
}
