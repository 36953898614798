export default {
    name: 'bing-maps',
    data(){
      return {
          map: null,
      }
    },
    props: ['polygon', 'markers', 'polygons', 'zoom' ],
    mounted(){
        if (document.getElementById("scriptBingMaps")) {
            return; // already loaded
        }

        // Add a global function for the callback from Bing Maps api
        window.OnLoadBingMapsApi = () => this.initMap();

        // Add programmaticaly the external Bing maps api script
        const scriptTag = document.createElement("script");
        scriptTag.src = "https://www.bing.com/api/maps/mapcontrol?callback=OnLoadBingMapsApi";
        scriptTag.id = "scriptBingMaps";

        // Inject the dynamic script in the DOM
        document.head.appendChild(scriptTag);

    },
    methods: {
        initMap: function() {
            const mapElement = this.$refs.map;

            this.map = new Microsoft.Maps.Map(mapElement, {
                mapTypeId: Microsoft.Maps.MapTypeId.aerial,
                zoom: this.zoom ? parseInt(this.zoom) : 15 ,
                center: this.markers ? this.markers[0] : this.polygons[0].area[0],
                credentials: `AnnWKZqULr2VDmhnIXslI0dTcYq_0sG3RK5yxZMIMVPIRwRmwvta5LPd8ztqDsAd`,
                maxZoom: 21,
                showLocateMeButton: false,
                showMapTypeSelector: false,
                maxNetworkLinkDepth: 3
            });

            this.getPolygon();
            this.getPolygons();
            this.getMarkers();

        },
        getPolygon(){
            if(this.polygon){
                const polygon = new Microsoft.Maps.Polygon(this.polygon, {
                    fillColor: 'rgba(110,160,40,0.8)',
                    strokeColor: 'rgba(235, 87, 87,1)',
                    strokeThickness: 2
                });

                //Add the polyline to map
                this.map.entities.push(polygon);
            }else {
                return false
            }
        },
        getPolygons(){
            if(this.polygons){
                this.polygons.forEach(t => {
                    const polygon = new Microsoft.Maps.Polygon(t.area, {
                        fillColor: 'rgba(110,160,40,0.8)',
                        strokeColor: 'rgba(235, 87, 87,1)',
                        strokeThickness: 2
                    });
                    //Add the polyline to map
                    this.map.entities.push(polygon);

                    //add infoBox
                    let infobox = new Microsoft.Maps.Infobox(this.map.getCenter(), { visible: false });
                    infobox.setMap(this.map);

                    //add event
                    Microsoft.Maps.Events.addHandler(polygon, 'click',  e => {

                        const shape = e.primitive;
                        let loc;
                        let desc;

                        //Depending on the type of shape that was clicked.
                        if (shape instanceof Microsoft.Maps.Polygon) {
                            loc = e.location;
                            desc = `
                            ${t.name ? t.name+'<br>' : ''}
                            ${t.location ? t.location+'.' : ''} 
                            ${t.province ? t.province+'.' : ''}<br>
                            ${this.$t('field_cadastral_number')} — ${t.cadastral_number} <br>
                            ${t.planting_year ? `${this.$t('yearPlanting')} — `+t.planting_year+'<br>' : ''} 
                            ${this.$t('countTrees')} ${t.trees_count}<br>
                            ${t.description ? t.description : ''}`;
                        }

                        // Display the infobox with an updated location and description.
                        infobox.setOptions({
                            location: loc,
                            description: desc,
                            visible: true
                        });

                        //change colors polygon
                        // polygon.setOptions({
                        //     fillColor: 'rgba(68,165,86,0.76)',
                        //     strokeColor: 'rgb(224,66,30)',
                        //     strokeThickness: 2
                        // });
                    });
                });
            }
        },
        getMarkers(){
            if(this.markers){
                let arr = [];
                this.markers.forEach(e => {
                    const pin = new Microsoft.Maps.Pushpin(e, {
                        icon: require('@/assets/images/svg/marker-tree.svg'),
                    });
                    arr.push(pin);
                });

                //Add the pushpin to the map.
                this.map.entities.push(arr);
            }
        }
    },
    destroyed(){
        // Update Bing maps (ez)
        document.getElementById('scriptBingMaps').remove();
    }

}
