import { $http } from '@/https';
import {globalLoader} from '@/helpers/variables/index'
import {
    LABEL_DOCUMENTS,
    LABEL_DOCUMENTS_RESET,
} from '@/store/mutation-types';

const state = {
    content: null,
};

const getters = {
    content: state => state.content,
};

const actions = {
    [LABEL_DOCUMENTS]: async ({ commit }, payload) => {
        globalLoader(true);
        try {
            const result = await $http.get(`v1/documents`);
            commit(LABEL_DOCUMENTS, result.data.data);
        } catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
};

const mutations = {
    [LABEL_DOCUMENTS](state, content) {
        state.content = content;
    },
    [LABEL_DOCUMENTS_RESET](state) {
        state.content = null;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
