import {googleKey, globalLoader} from '@/helpers/variables/index'
import Vue from 'vue';
import {mapGetters, mapActions, mapMutations} from 'vuex'
import VueGeolocation from 'vue-browser-geolocation';
import TextHelp from '@/modules/personal/components/sidebar/text-help/index.vue';
import popupStatistic from '@/components/statistic-popup/index.vue'
import promocodeItem from './promocode-item/index.vue'
Vue.use(VueGeolocation);

import {
    LOAD_USER_DATA,
    TRANSACTION_HISTORY,
    SHOW_POPUP_PROMO,
    SHOW_USER_NFT,
    SHOW_TEXT_HELP_BONUS,
    SHOW_POPUP_STATISTIC
} from "@/store/mutation-types";

export default {
    name: 'personal-sidebar',
    data() {
        return {
            lengthListWallet: 0,
            time: {
                minutes: '00',
                hours: '00'
            },
            countTrees: 0,
            capital: 0,
            mobileToggle: true
        }
    },
    components:{
        TextHelp,
        popupStatistic,
        promocodeItem
    },
    created() {
        if(!this.user){
            this.fetchUser();
        }
    },
    computed: {
        ...mapGetters({
            loading: `profile/loading`,
            user: 'profile/user',
            setting: 'main/setting',
            showTextHelpBonus: `statistic/showTextHelpBonus`,
            openStatisticPopup: `statistic/openStatisticPopup`,

        }),
        ...mapGetters([
            'languages',
            'currentLanguage',
        ]),
        insuranceText(){
            return this.currentLanguage.key === 'ru'
                ? 'Страхование'
                : 'languages'
        },
        activeWallet() {
            if (this.$route.path.indexOf('wallets') > 0) {
                this.lengthListWallet = document.querySelectorAll('.personal__sidebar-sub li').length;
                return true
            } else {
                return false
            }
        },
        activePurchase() {
            if (this.$route.path.indexOf('purchases') > 0) {
                return true
            } else {
                return false
            }
        },
        getDate() {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            let currentMount = currentDate.getMonth() + 1;
            let currentDay = currentDate.getDate();
            if (currentMount <= 9) {
                currentMount = '0' + currentMount
            }
            if (currentDay <= 9) {
                currentDay = '0' + currentDay
            }
            return currentYear + ' ' + currentMount + ' ' + currentDay;
        },
        getTime() {
            setInterval(() => {
                let currentDate = new Date();
                this.time.hours = currentDate.getHours();
                this.time.minutes = currentDate.getMinutes();
                if (this.time.minutes <= 9) {
                    this.time.minutes = '0' + currentDate.getMinutes();
                } else {
                    this.time.minutes = currentDate.getMinutes();
                }
                if (this.time.hours <= 9) {
                    this.time.hours = '0' + currentDate.getHours();
                } else {
                    this.time.hours = currentDate.getHours();
                }

            }, 1000);
        },
        watchWidth() {
            return window.innerWidth
        }
    },
    mounted(){
        if (window.innerWidth < 980) {
            this.mobileToggle = false;
            this.lengthListWallet = document.querySelectorAll('.personal__sidebar-sub li').length;
        }
    },
    methods: {
        ...mapActions({
            fetchUser: `profile/${LOAD_USER_DATA}`,
            fetchList: `history/${TRANSACTION_HISTORY}`,
        }),
        ...mapMutations({
            popupPromoShow: `promo/${SHOW_POPUP_PROMO}`,
            toggleTextHelpBonus: `statistic/${SHOW_TEXT_HELP_BONUS}`,
            changeStatisticPopup: `statistic/${SHOW_POPUP_STATISTIC}`,
            changeNftPopup: `popups/${SHOW_USER_NFT}`,
        }),
        toggleSubMenu() {
            if (this.watchWidth < 980) {
                if(this.user.investor){
                    this.$router.push({name: 'personal.field'})
                }else{
                    this.$router.push({name: 'personal.buy_default'})
                }
            }
        },
        goTrees(){
            this.$router.push({ name: 'personal' })
        },
        goTransaction(){
            this.$router.push({ name: 'personal.history' })
        },

    }
}
