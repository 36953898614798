import {$http} from '@/https';
import {FETCH_PROMOTION, FETCH_PROMOTIONS} from '../action-types.js';
import {SET_PROMOTION, SET_PROMOTION_LOADING, SET_PROMOTIONS} from '../mutation-types.js';
import store from '@/store'

const state = {
  content: null,
  contentItem: null,
  contentItemLoading: false,
};

const getters = {
  content: state => state.content,
  contentItem: state => state.contentItem,
  contentItemLoading: state => state.contentItemLoading,
};

const actions = {
  [FETCH_PROMOTIONS]: async ({commit}) => {
    try {
      const response = await $http.get('v1/special_offer');
      commit(SET_PROMOTIONS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [FETCH_PROMOTION]: async ({commit}, payload) => {
    try {
      commit(SET_PROMOTION_LOADING, true);
      const result = await $http.get(`v1/special_offer/${payload}`);
      commit(SET_PROMOTION, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_PROMOTION_LOADING, false);
    }
  },
};

const mutations = {
  [SET_PROMOTIONS](state, list) {
    // const host = window.location.hostname.split('.').pop().split('').join('');
    const host = window.location.origin
    const isAuth = store.state.auth.isAuthentificated;
    const lang =  store.state['vue-i18n-manager'].currentLanguage.translationKey;
    if (!isAuth) {
      const newList = list.map(e => {
        const arr = [];
        e.views.map(t => {
          let result = t.match(/<a href="(.*?personal.*?)" class="/);
          if (result !== null) {
            let outloginLink = `${host}/${lang}/?showSingIn=true&link=${lang}/personal/wallets/buy_default`
            const newContent = t.replace(result[1], outloginLink);
            arr.push(newContent)
            return t
          } else {
            arr.push(t)
          }
        });
        e.views = arr
        return e
      });
      state.content = newList;
    } else {
      state.content = list;
    }

  },
  [SET_PROMOTION](state, content) {
    const host = window.location.origin
    const isAuth = store.state.auth.isAuthentificated;
    const lang =  store.state['vue-i18n-manager'].currentLanguage.translationKey;
    if(!isAuth && content){
      const arr = [];
      content.views.map(t => {
        let result = t.match(/<a href="(.*?personal.*?)" class="/);
        if (result !== null) {
          let outloginLink = `${host}/${lang}/?showSingIn=true&link=${lang}/personal/wallets/buy_default`
          const newContent = t.replace(result[1], outloginLink);
          arr.push(newContent)
          return t
        } else {
          arr.push(t)
        }
      });
      content.views = arr
    }
    state.contentItem = content;
  },
  [SET_PROMOTION_LOADING](state, status) {
    state.contentItemLoading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
