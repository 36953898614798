import {mapActions, mapGetters, mapMutations} from 'vuex';
import {
    TAKE_OFF_LIST,
    SHOW_POPUP_TAKE_OFF,
    TAKE_OFF_RESET_LIST,
    TAKE_OFF_PACK,
    TAKE_OFF_CHANGE_SKIP,
    TAKE_OFF_CHANGE_PAGE
} from '@/store/mutation-types';
import Popup from './popup/index.vue';
import VueAdsPagination from 'vue-ads-pagination';
import {VueAdsPageButton} from 'vue-ads-pagination';

export default {
    name: 'take-off',
    data(){
      return{
          type: 'sale'
      }
    },
    components: {
        Popup,
        VueAdsPagination,
        VueAdsPageButton
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
            list: `takeOff/list`,
            page: `takeOff/page`,
            limit: `takeOff/limit`,
            loading: `takeOff/loading`,
            length: `takeOff/length`,
            showTakeOff: `takeOff/showTakeOff`,
        }),
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.fetchList();
        }
    },
    created() {
        this.fetchList();
    },
    methods: {
        ...mapActions({
            fetchList: `takeOff/${TAKE_OFF_LIST}`,
            withdrawPack: `takeOff/${TAKE_OFF_PACK}`,
        }),
        ...mapMutations({
            showPopup: `takeOff/${SHOW_POPUP_TAKE_OFF}`,
            resetList: `takeOff/${TAKE_OFF_RESET_LIST}`,
            changeSkip: `takeOff/${TAKE_OFF_CHANGE_SKIP}`,
            changePage: `takeOff/${TAKE_OFF_CHANGE_PAGE}`,
        }),
        sendRequest(obj) {
            this.withdrawPack(obj)
                .then(response => {
                    this.$toaster.success(response.message)
                })
                .catch(error => {
                    this.$toaster.error(error.response.data.error.message)
                })
        },
        withdrawPackYoung(id) {
            let obj = {
                type: this.type,
                sale_pack_id: id,
                is_young: 1
            };
            this.sendRequest(obj);
        },
        withdrawPackAdult(id) {
            let obj = {
                type: this.type,
                tree_id: id,
                is_young: 0
            };
            this.sendRequest(obj);
        },
    },
    destroyed() {
        this.resetList();
        this.changeSkip(0);
    }
}
