import { $http } from '@/https'
import { fixBody } from '@/helpers/variables/index'
import {
    SHOW_FEEDBACK,
    SEND_FEEDBACK,
    CHANGE_LOADING_FEEDBACK
} from '../mutation-types';

const state = {
    showFeedback: false,
    loading: false
};

const getters = {
    showFeedback: state => state.showFeedback,
    loading: state => state.loading,
};

const actions = {
    [SEND_FEEDBACK]: async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING_FEEDBACK, true);

            return await $http.post(`v1/feedback`, payload);
        }catch (e) {
            throw e;
        }finally {
            commit(CHANGE_LOADING_FEEDBACK, false);
        }
    },
};

const mutations = {
    [SHOW_FEEDBACK](state, status) {
        state.showFeedback = status;
        fixBody(status)
    },
    [CHANGE_LOADING_FEEDBACK](state, status) {
        state.loading = status;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
