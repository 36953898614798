import { mapMutations, mapGetters } from "vuex"
import {
    CHANGE_TREES_LIMIT,
} from "@/store/mutation-types";
export default {
    name: 'view-counter',
    data(){
        return {
            counter: [
                {
                    id: 1,
                    count: 10,
                },
                {
                    id: 2,
                    count: 20
                },
                {
                    id: 3,
                    count: 50
                }
            ]
        }
    },
    computed:{
        ...mapGetters({
            limit: 'trees/limit'
        })
    },
    methods: {
        changeCount(elem){
            localStorage.setItem('countView', elem.count);
            this.changeLimit(elem.count);
            this.active(elem);
            this.$emit('change-count');
        },
        active(elem){
            if(elem.count === this.limit){
                return true
            }
        },
        ...mapMutations({
            changeLimit: `trees/${CHANGE_TREES_LIMIT}`
        })
    }
}
