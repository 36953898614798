import { $http } from '@/https'
import { fixBody } from '@/helpers/variables/index'
import {
    WITHDRAWAL_HISTORY,
    RESET_WITHDRAWAL_HISTORY,
    WITHDRAWAL_HISTORY_LENGTH,
    WITHDRAWAL_HISTORY_CHANGE_TO,
    WITHDRAWAL_HISTORY_CHANGE_FROM,
    WITHDRAWAL_HISTORY_CHANGE_SKIP,
    WITHDRAWAL_HISTORY_CHANGE_PAGE,
    WITHDRAWAL_HISTORY_LOADING,
    RESET_WITHDRAWAL_HISTORY_COMPONENT,
    WITHDRAWAL_HISTORY_HAS_TWO_FACTOR,
    SHOW_POPUP_TWO_AUTH_CODE
} from '../../mutation-types';

function transformDate(date){
    const day = date.substr(8,2);
    const mounth = date.substr(5,2);
    const year = date.substr(0,4);
    if(date.length > 0){
        return `${day}.${mounth}.${year}`;
    }else{
        return '';
    }
}

const state = {
    list: null,
    lengthList: 0,
    from: '',
    fromTransform: '',
    to: '',
    toTransform: '',
    limit: 18,
    skip: 0,
    page: 0,
    loading: false,
    hasTwoFactor: false
};

const getters = {
    hasTwoFactor: state => state.hasTwoFactor,
    skip: state => state.skip,
    page: state => state.page,
    limit: state => state.limit,
    from: state => state.from,
    to: state => state.to,
    list: state => state.list,
    lengthList: state => state.lengthList,
    loading: state => state.loading,
};

const actions = {
    [WITHDRAWAL_HISTORY]: async ({ commit }) => {
        try {
            commit(WITHDRAWAL_HISTORY_LOADING, true);

            const response = await $http.get(`v1/withdraw?from_date=${state.fromTransform}&to_date=${state.toTransform}&limit=${state.limit}&skip=${state.skip}`);
            commit(RESET_WITHDRAWAL_HISTORY);
            commit(WITHDRAWAL_HISTORY, response.data.data);
            commit(WITHDRAWAL_HISTORY_LENGTH, response.data.meta.count);
            return response;
        }catch (e) {
            if(e.response.status === 422){
                commit(`profile/${SHOW_POPUP_TWO_AUTH_CODE}`, true, {root: true});
                commit(RESET_WITHDRAWAL_HISTORY);
                commit(WITHDRAWAL_HISTORY_HAS_TWO_FACTOR, true);
            }
            throw e;
        }finally {
            commit(WITHDRAWAL_HISTORY_LOADING, false);
        }
    },
};

const mutations = {
    [WITHDRAWAL_HISTORY](state, history) {
        state.list = history;
    },
    [RESET_WITHDRAWAL_HISTORY](state) {
        state.list = null;
    },
    [RESET_WITHDRAWAL_HISTORY_COMPONENT](state) {
        state.list = null;
        state.from = '';
        state.fromTransform = '';
        state.to = '';
        state.toTransform = '';
        state.limit = 18;
        state.skip = 0;
        state.page = 0;
    },
    [WITHDRAWAL_HISTORY_LENGTH](state, val) {
        state.lengthList = val;
    },
    [WITHDRAWAL_HISTORY_CHANGE_FROM](state, from) {
        state.fromTransform = transformDate(from);
        state.from = from;
    },
    [WITHDRAWAL_HISTORY_CHANGE_TO](state, to) {
        state.toTransform = transformDate(to);
        state.to = to;
    },
    [WITHDRAWAL_HISTORY_CHANGE_SKIP](state, skip) {
        state.skip = skip;
    },
    [WITHDRAWAL_HISTORY_CHANGE_PAGE](state, page) {
        state.page = page;
    },
    [WITHDRAWAL_HISTORY_LOADING](state, loading) {
        state.loading = loading;
    },
    [WITHDRAWAL_HISTORY_HAS_TWO_FACTOR](state, status) {
        state.hasTwoFactor = status;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
