import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_POPUP_TAKE_OFF,
    TAKE_OFF_LIST,
    TAKE_OFF_PIECE,
    FETCH_USER_SALES
} from "@/store/mutation-types";
export default{
    name: 'take-off-popup-documents',
    data(){
      return {
          check: '',
          count: '',
          type: 'sale',
          payload: {
              limit: 11,
              skip: 0,
          }
      }
    },
    computed: {
        ...mapGetters({
            loading: `takeOff/loading`,
            user: `profile/user`,
            userSales: `profile/userSales`,
        })
    },
    created(){
        this.fetchUserSales();
    },
    methods: {
        ...mapActions({
            withdrawPiece: `takeOff/${TAKE_OFF_PIECE}`,
            fetchList: `takeOff/${TAKE_OFF_LIST}`,
            fetchUserSales: `profile/${FETCH_USER_SALES}`,
        }),
        ...mapMutations({
            showPopup: `takeOff/${SHOW_POPUP_TAKE_OFF}`
        }),
        submitInvestor() {
            let obj = {
                type: this.type,
                is_young: this.check,
                tree_count: this.count
            };
            this.withdrawPiece(obj)
                .then(response => {
                    this.$toaster.success(response.message);
                    this.showPopup(false);
                    this.fetchList(this.payload);
                })
                .catch(error => {
                    if(error.response.status === 424){
                        this.showPopup(false);
                    }
                    this.$toaster.error(error.response.data.error.message)
                })
        },
        submitUser() {
            let obj = {
                type: this.type,
                is_young: 0,
                tree_count: this.count
            };
            this.withdrawPiece(obj)
                .then(response => {
                    this.$toaster.success(response.message);
                    this.showPopup(false);
                    this.fetchList(this.payload);
                })
                .catch(error => {
                    if(error.response.status === 424){
                        this.showPopup(false);
                    }
                    this.$toaster.error(error.response.data.error.message)
                })
        },
    }
}
