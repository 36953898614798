import {$http} from '@/https';
import {
    GALLERY_PHOTO_ALBUM,
    GALLERY_PHOTO_ALBUM_LIMIT,
    GALLERY_VIDEO_ALBUM,
    GALLERY_PHOTO,
    GALLERY_VIDEO,
    RESET_GALLERY_PHOTO,
    RESET_GALLERY_VIDEO,
    GALLERY_NEWS_VIDEO
} from '../mutation-types.js';

const state = {
    galleryPhotoAlbum:null,
    galleryPhotoAlbumCol: null,
    galleryVideoAlbum:null,
    galleryPhoto: null,
    galleryVideo:null,
    galleryLink:[],
    galleryVideoLink:[],
    videoLinks:[],
    galleryLimit:2,
    homeVideo: []
};

const getters = {
    galleryPhotoAlbum: state => state.galleryPhotoAlbum,
    galleryPhotoAlbumCol: state => state.galleryPhotoAlbumCol,
    galleryVideoAlbum: state => state.galleryVideoAlbum,
    galleryLink: state => state.galleryLink,
    galleryPhoto: state => state.galleryPhoto,
    galleryVideoLink: state => state.galleryVideoLink,
    videoLinks: state => state.videoLinks,
    galleryLimit: state => state.galleryLimit,
    homeVideo: state => state.homeVideo
};

const actions = {
    [GALLERY_PHOTO_ALBUM]: async ({commit}) => {
        try {
            const response = await $http.get(`/v1/image_categories?limit=${state.galleryLimit}`);
            commit(GALLERY_PHOTO_ALBUM, response.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [GALLERY_VIDEO_ALBUM]: async ({commit}) => {
        try {
            const response = await $http.get('/v1/video_categories');
            commit(GALLERY_VIDEO_ALBUM, response.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [GALLERY_PHOTO]: async ({commit}, id) => {
        try {
            const response = await $http.get(`/v1/image/${id}`);
            commit(GALLERY_PHOTO, response.data.data);
        }catch (e) {
            throw e;
        }
    },
    [GALLERY_VIDEO]: async ({commit}, id) => {
        try {
            const response = await $http.get(`/v1/video/${id}`);
            commit(GALLERY_VIDEO, response.data.data);
        }catch (e) {
           throw e;
        }
    },
    [GALLERY_NEWS_VIDEO]: async ({commit}) => {
        try {
            const response = await $http.get(`/v1/widgets/video`);
            commit(GALLERY_NEWS_VIDEO, response.data);
        }catch (e) {
           throw e;
        }
    }
};

const mutations = {
    [GALLERY_PHOTO_ALBUM](state, status){
        state.galleryPhotoAlbum = status.data;
        state.galleryPhotoAlbumCol = status.meta;
    },
    [GALLERY_VIDEO_ALBUM](state, status){
        state.galleryVideoLink = [];
        state.galleryVideoAlbum = status.data;
        state.galleryVideo = status.video;

        state.galleryVideo.map((item)=>{
            let linkLength = item.link.length;
            let positionStartLink = item.link.lastIndexOf('/');
            let videoLink = item.link.slice(positionStartLink+1,linkLength);
            state.galleryVideoLink.push(videoLink);
        })
    },
    [GALLERY_PHOTO](state, status){
        state.galleryLink = [];
        state.galleryPhoto = status;
        state.galleryPhoto.map((item)=>{
            state.galleryLink.push(item.link)
        })
    },
    [RESET_GALLERY_PHOTO](state){
        state.galleryPhoto = null;
    },
    [GALLERY_VIDEO](state, status){
        state.videoLinks = [];
        state.galleryVideo = status;
        state.galleryVideo.map((item)=> {
            let linkLength = item.link.length;
            let positionStartLink = item.link.lastIndexOf('/');
            let videoLink = item.link.slice(positionStartLink + 1, linkLength);
            state.videoLinks.push(videoLink);
        })
    },
    [RESET_GALLERY_VIDEO](state){
        state.videoLinks = [];
    },
    [GALLERY_PHOTO_ALBUM_LIMIT](state, status){
        status = status + 2;
        state.galleryLimit = status;
    },
    [GALLERY_NEWS_VIDEO](state, status){
        // state.homeVideo = homeVideo;
        state.videoLinks = [];
        state.homeVideo = status;
        state.homeVideo.map((item)=> {
            let linkLength = item.video.length;
            let positionStartLink = item.video.lastIndexOf('/');
            let videoLink = item.video.slice(positionStartLink + 1, linkLength);
            state.videoLinks.push(videoLink);
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
