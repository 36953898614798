import { mapActions, mapGetters, mapMutations } from "vuex";
import {
    SING_IN_WITH_GOGGLE,
} from '@/store/action-types';
import {
    LOAD_USER_DATA,
    SHOW_SING_IN,
    REGISTRATION_SHOW
} from '@/store/mutation-types';
import Vue from 'vue';
import GSignInButton from 'vue-google-signin-button';
Vue.use(GSignInButton);

export default {
    name: 'google-widget',
    data() {
        return {
            // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
            googleSignInParams: {
                client_id: process.env.VUE_APP_GOOGLE_IDENTITY
            },
        }
    },
    components: {
        GSignInButton
    },
    methods: {
        ...mapActions({
            auth: `auth/${SING_IN_WITH_GOGGLE}`,
            fetchUser: `profile/${LOAD_USER_DATA}`,
        }),
        ...mapMutations({
            showSingIn: `auth/${SHOW_SING_IN}`,
            showRegistration: `auth/${REGISTRATION_SHOW}`,
        }),
        onSignInSuccess(googleUser) {
            const token = googleUser.getAuthResponse('access_token').access_token;
            this.auth({ access_token: token })
                .then(() => {
                    this.$toaster.success(this.$t('successAuth'));
                    this.fetchUser().then(() => {
                        this.showSingIn(false);
                        this.showRegistration(false);
                        this.$router.push({ name: 'personal' });
                    });
                })
                .catch(error => {
                    if(error.response.status === 418){
                        this.$toaster.error(error.response.data.data.error.message).goAway(5000);
                    }
                })

        },
        onSignInError(error){
            console.log(error);
        }
    }
}
