import {$http} from '@/https'
import {fixBody, globalLoader} from '@/helpers/variables/index'
import {
    SHOW_POPUP_TAKE_OFF,
    TAKE_OFF_LIST,
    TAKE_OFF_CHANGE_LOADING,
    TAKE_OFF_RESET_LIST,
    TAKE_OFF_PIECE,
    TAKE_OFF_LENGTH_LIST,
    TAKE_OFF_PACK,
    TAKE_OFF_CHANGE_SKIP,
    TAKE_OFF_CHANGE_PAGE,
    TAKE_OFF_RESET_PAGINATION,
    PUT_UP_TYPES
} from '@/store/mutation-types';


const state = {
    showTakeOff: false,
    list: null,
    loading: false,
    length: 0,
    limit: 11,
    skip: 0,
    page: 0,
};

const getters = {
    list: state => state.list,
    page: state => state.page,
    showTakeOff: state => state.showTakeOff,
    skip: state => state.skip,
    limit: state => state.limit,
    loading: state => state.loading,
    length: state => state.length,
};

const actions = {
    [TAKE_OFF_LIST]: async ({commit}) => {
        try {
            commit(TAKE_OFF_CHANGE_LOADING, true);

            const response = await $http.get(`v1/withdraw_list?type=sale&limit=${state.limit}&skip=${state.skip}`);
            commit(TAKE_OFF_LIST, response.data.data);
            commit(TAKE_OFF_LENGTH_LIST, response.data.meta.count);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(TAKE_OFF_CHANGE_LOADING, false);
        }
    },
    [TAKE_OFF_PACK]: async ({dispatch, commit}, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/withdraw_pack`, payload);
            commit(TAKE_OFF_RESET_PAGINATION);
            dispatch(TAKE_OFF_LIST);
            return response.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [TAKE_OFF_PIECE]: async ({dispatch, commit}, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/withdraw`, payload);
            commit(TAKE_OFF_RESET_PAGINATION);
            dispatch(TAKE_OFF_LIST);
            return response.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
};

const mutations = {
    [SHOW_POPUP_TAKE_OFF](state, status) {
        fixBody(status);
        state.showTakeOff = status
    },
    [TAKE_OFF_LIST](state, list) {
        state.list = list;
    },
    [TAKE_OFF_CHANGE_LOADING](state, status) {
        state.loading = status
    },
    [TAKE_OFF_RESET_LIST](state) {
        state.list = null
    },
    [TAKE_OFF_LENGTH_LIST](state, length) {
        state.length = length
    },
    [TAKE_OFF_CHANGE_SKIP](state, skip) {
        state.skip = skip
    },
    [TAKE_OFF_CHANGE_PAGE](state, page) {
        state.page = page
    },
    [TAKE_OFF_RESET_PAGINATION](state) {
        state.page = 0;
        state.skip = 0;
    },
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
