import {mapActions, mapGetters, mapMutations} from 'vuex';
import {
    TAKE_OFF_LIST,
    SHOW_POPUP_TAKE_OFF,
    TAKE_OFF_RESET_LIST,
    TAKE_OFF_PACK,
    TAKE_OFF_CHANGE_SKIP,
    TAKE_OFF_CHANGE_PAGE
} from '@/store/mutation-types';
import Popup from './popup/index.vue';
import VueAdsPagination from 'vue-ads-pagination';
import {VueAdsPageButton} from 'vue-ads-pagination';

export default {
    name: 'take-off',
    components: {
        Popup,
        VueAdsPagination,
        VueAdsPageButton
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
            list: `giftInfo/list`,
            page: `giftInfo/page`,
            limit: `giftInfo/limit`,
            loading: `giftInfo/loading`,
            length: `giftInfo/length`,
            showTakeOff: `giftInfo/showTakeOff`,
        }),
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.fetchList();
        }
    },
    created() {
        this.fetchList();
    },
    methods: {
        ...mapActions({
            fetchList: `giftInfo/${TAKE_OFF_LIST}`,
            withdrawPack: `giftInfo/${TAKE_OFF_PACK}`,
        }),
        ...mapMutations({
            showPopup: `giftInfo/${SHOW_POPUP_TAKE_OFF}`,
            resetList: `giftInfo/${TAKE_OFF_RESET_LIST}`,
            changeSkip: `giftInfo/${TAKE_OFF_CHANGE_SKIP}`,
            changePage: `giftInfo/${TAKE_OFF_CHANGE_PAGE}`,
        }),
        sendRequest(obj) {
            this.withdrawPack(obj)
                .then(response => {
                    this.$toaster.success(response.message)
                })
                .catch(error => {
                    this.$toaster.error(error.response.data.error.message)
                })
        },
        withdrawPackYoung(id) {
            let obj = {
                sale_pack_id: id,
                is_young: 1
            };
            this.sendRequest(obj);
        },
        withdrawPackAdult(id) {
            let obj = {
                tree_id: id,
                is_young: 0
            };
            this.sendRequest(obj);
        },
    },
    destroyed() {
        this.resetList();
        this.changeSkip(0);
    }
}
