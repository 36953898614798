import { $http } from '@/https';
import {fixBody, globalLoader} from '@/helpers/variables/index';
import {
    CHANGE_POPUP_EXPRESS,
    EXPRESS_ACTION,
    CHANGE_POPUP_IFRAME,
    PAYMENT_LEOGAMING,
    AUTH_LOGIN,
    REDIRECT_TRANSACTION,
    EXPRESS_RE_BUY_TREES,
    POPUP_EXPRESS_PROMO_CODE,
    EXPRESS_GET_TOKEN_DOWNLOAD
} from '../mutation-types';

const state = {
    popup: {
      show: false,
      key: 'buy'
    },
    popupPromoCode: {
        show: false,
        link: ''
    }
};

const getters = {
    popup: state => state.popup,
    popupPromoCode: state => state.popupPromoCode,
};

const actions = {
    [EXPRESS_ACTION]: async ({ commit }, payload) => {
        globalLoader(true);
        try {
            const result = await $http.post('quick_register', payload);
            commit(`shop/${REDIRECT_TRANSACTION}`, false, {root: true});
            commit(CHANGE_POPUP_EXPRESS, { show: false, key: '' });
            commit(`shop/${PAYMENT_LEOGAMING}`, result.data, {root: true}) ;
            commit(`shop/${CHANGE_POPUP_IFRAME}`, true, {root: true});
            commit(`auth/${AUTH_LOGIN}`, result.data.access_token, {root: true});

            // if(result.data.hasOwnProperty('gift')){
            //     commit(POPUP_EXPRESS_PROMO_CODE,{
            //         show: true,
            //         link: result.data.gift
            //     })
            // }
            return result;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [EXPRESS_RE_BUY_TREES]: async ({ commit }, payload) => {
        globalLoader(true);
        try {
            const result = await $http.post('v1/shop/rebuy_trees', payload);
            commit(`shop/${REDIRECT_TRANSACTION}`, false, {root: true});
            commit(`shop/${PAYMENT_LEOGAMING}`, result.data, {root: true}) ;
            commit(`shop/${CHANGE_POPUP_IFRAME}`, true, {root: true});

            // if(result.data.hasOwnProperty('gift')){
            //     commit(POPUP_EXPRESS_PROMO_CODE,{
            //         show: true,
            //         link: result.data.gift
            //     })
            // }
            return result;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [EXPRESS_GET_TOKEN_DOWNLOAD]: async ({ commit }, payload) => {
        try {
            return await $http.get(payload);
        } catch (e) {
            throw e;
        }
    }

};

const mutations = {
    [CHANGE_POPUP_EXPRESS](state, params) {
        state.popup.show = params.show;
        fixBody(params.show);
        state.popup.key = params.key;
    },
    [POPUP_EXPRESS_PROMO_CODE](state, params) {
        state.popupPromoCode.show = params.show;
        state.popupPromoCode.link = params.link;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
