import {mapActions, mapGetters, mapMutations} from "vuex";
import {
    GIFT_GET_TREES_LIST,
    SING_DOCUMENT,
    GET_ORDER,
    CURRENT_GIFT_OBJ,
    SHOW_POPUP_GIFT_LIMIT,
    SHOW_GET_GIFT_POPUP,
    CHANGE_GET_GIFT_PAGE,
    CHANGE_GET_GIFT_SKIP,
    CHANGE_GET_GIFT_SKIP_STORY,
    GET_GIFT_RESET,
    GET_GIFT_TREE_STORY,
    SHOW_POPUP_PROMO,
    SHOW_TREES_MAP_POPUP_AREA,
    CHANGE_GET_GIFT_PAGE_STORY,
    DOWNLOAD_DOCUMENTS,
    SHOW_POPUP_DOCUMENTS
} from "@/store/mutation-types";
import VueAdsPagination from 'vue-ads-pagination';
import { VueAdsPageButton } from 'vue-ads-pagination';
export default {
    name: 'search-detail',
    computed:{
        ...mapGetters({
            loading: `gift/loading`,
            listLength: `gift/giftListLength`,
            listLengthStory: `gift/giftListLengthStory`,
            list: `gift/giftList`,
            page: `gift/getPage`,
            pageStory: `gift/getPageStory`,
            skip: `gift/getSkip`,
            skipStory: `gift/getSkipStory`,
            limit: `gift/getLimit`,
            giftListStory: `gift/giftListStory`,
        }),
        currentSeason(){
            let route = this.$route.query.season;
            if(route){
                if(route === 2){
                    return this.$t('spring');
                }
                if(route === 4){
                    return this.$t('autumn');
                }
            }
        },
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.loadNewDataList();
        },
        pageStory(){
            this.changeSkipStory(this.pageStory * this.limit);
            this.loadNewDataListStory();
        }
    },
    created(){
        this.loadNewDataList();
        this.giftStory();
    },
    methods: {
        ...mapActions({
            fetchList: `gift/${GIFT_GET_TREES_LIST}`,
            singDocument: `trees/${SING_DOCUMENT}`,
            loadOrderId: `trees/${GET_ORDER}`,
            giftStory: `gift/${GET_GIFT_TREE_STORY}`,
            download: `trees/${DOWNLOAD_DOCUMENTS}`,
        }),
        ...mapMutations({
            changeGetGiftPopup: `gift/${SHOW_GET_GIFT_POPUP}`,
            currentObjGift: `gift/${CURRENT_GIFT_OBJ}`,
            showPopupGiftLimit: `gift/${SHOW_POPUP_GIFT_LIMIT}`,
            changePage: `gift/${CHANGE_GET_GIFT_PAGE}`,
            changePageStory: `gift/${CHANGE_GET_GIFT_PAGE_STORY}`,
            changeSkip: `gift/${CHANGE_GET_GIFT_SKIP}`,
            changeSkipStory: `gift/${CHANGE_GET_GIFT_SKIP_STORY}`,
            changePopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
            reset: `gift/${GET_GIFT_RESET}`,
            popupPromoShow: `promo/${SHOW_POPUP_PROMO}`,
            showPopupDocuments: `trees/${SHOW_POPUP_DOCUMENTS}`,
        }),
        showGiftLimitPopup(item){
            this.currentObjGift(item);
            this.showPopupGiftLimit(true);
        },
        loadNewDataList(){
            let obj ={
                skip: this.skip,
                limit: this.limit,
            };
            this.fetchList(obj)
        },
        loadNewDataListStory(){
            let obj ={
                skip: this.skipStory,
                limit: this.limit,
            };
            this.giftStory(obj)
        },
        goBack(){
            this.$router.go(-1)
        },
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        downloadDocuments(elem){
            let obj = {
                packet_type: 'gift',
                packet_id: elem.id
            };
            this.download(obj);
        },
        hasBans(elem) {
            let divined = false;
            let sale = false;
            if (elem.dividend_frozen_to && elem.dividend_frozen_to !== null) {
                divined = true
            } else {
                divined = false
            }
            if (elem.sale_frozen_to && elem.sale_frozen_to !== null) {
                sale = true
            } else {
                sale = false
            }
            if (divined || sale) {
                return true
            }
        },

    },
    destroyed() {
        this.reset();
    }
}
