import {$http} from '@/https';
import {
    NEWS_LIST,
    NEWS_CHANGE_POSITION_SCROLL,
    GO_NEWS_CHANGE_POSITION_SCROLL,
    RESET_NEWS_DETAIL,
    NEWS_LENGTH,
    NEWS_DETAIL,
    CHANGE_NEWS_SKIP,
    NEWS_LIST_LOADING,
    CHANGE_IS_LOAD_NEWS,
    NEWS_HOME_LIST
} from '../mutation-types.js';
import _ from 'lodash';

const state = {
    newsList: [],
    skip: 0,
    limit: 3,
    isLoad: false,
    newsLength: 13,
    goToPositionScroll: false,
    positionScroll: 0,
    newsDetail:null,
    loading: false,
    newsHomeList: []
};

const getters = {
    loading: state => state.loading,
    isLoad: state => state.isLoad,
    newsLength: state => state.newsLength,
    skip: state => state.skip,
    newsList: state => state.newsList,
    goToPositionScroll: state => state.goToPositionScroll,
    positionScroll: state => state.positionScroll,
    newsDetail: state => state.newsDetail,
    newsHomeList: state => state.newsHomeList
};

const actions = {
    [NEWS_LIST]: async ({commit}) => {
        try {
            commit(NEWS_LIST_LOADING, true);

            const response = await $http.get(`v1/news?limit=${state.limit}&skip=${state.skip}`);
            commit(NEWS_LIST, response.data.data);
            commit(NEWS_LENGTH, response.data.meta.count);
            commit(CHANGE_NEWS_SKIP);
        }catch (e) {
            throw e;
        }finally {
            commit(NEWS_LIST_LOADING, false)
        }
    },
    [NEWS_DETAIL]: async ({commit}, id) => {
        try {
            const response = await $http.get(`v1/news/${id}`);
            commit(NEWS_DETAIL, response.data.data);
        }catch (e) {
            throw e;
        }
    },
    [NEWS_HOME_LIST]: async ({commit}) => {
        try {
            const response = await $http.get(`v1/widgets/comments`);
            commit(NEWS_HOME_LIST, response.data);
        }catch (e) {
            throw e;
        }
    }
};

const mutations = {
    [NEWS_LIST](state, newsList) {
        state.newsList.push(...newsList);
        let uniqList = _.uniq(state.newsList);
        state.newsList = uniqList;
    },
    [NEWS_HOME_LIST](state, newsHomeList) {
        // state.newsHomeList.push(...newsHomeList);
        // let uniqList = _.uniq(state.newsHomeList);
        // state.newsHomeList = uniqList;
        state.newsHomeList = newsHomeList;
    },
    [NEWS_CHANGE_POSITION_SCROLL](state, offsetTop) {
        state.positionScroll = offsetTop;
    },
    [NEWS_LIST_LOADING](state, status) {
        state.loading = status;
    },
    [GO_NEWS_CHANGE_POSITION_SCROLL](state, status) {
        state.goToPositionScroll = status;
    },
    [NEWS_DETAIL](state, newsDetail){
        state.newsDetail = newsDetail;
    },
    [RESET_NEWS_DETAIL](state){
        state.newsDetail = null;
    },
    [NEWS_LENGTH](state, length){
        state.newsLength = length;
    },
    [CHANGE_NEWS_SKIP](state){
        state.skip = state.skip+3;
    },
    [CHANGE_IS_LOAD_NEWS](state, status) {
        state.isLoad = status
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
