<template>
  <div :class="{'fix': fixBody}"
       class="wrapper">
    <div id="app"
         class="app"
         v-if="setting"
         data-server-rendered="true"
         :class="{'page__investor': isInvestorPage}">
      <Header/>
      <transition name="component-fade" mode="out-in">
        <router-view :key="$route.fullPath"/>
      </transition>
      <popups/>
      <footer-main/>
    </div>
    <div class="start__loader" v-else>
      <loader></loader>
    </div>
    <div class="global__loader" v-show="globalLoader">
      <global-loader/>
    </div>
  </div>
</template>
<script>
  import Header from '@/components/header/index.vue';
  import Popups from '@/components/popups/index.vue';
  import FooterMain from '@/components/footer/index.vue';
  import GlobalLoader from '@/components/helpers/global-loader/index.vue';

  import axios from 'axios'
  import {googleKey} from '@/helpers/variables/index'

  import {mapActions, mapGetters, mapMutations} from 'vuex'
  import { $http } from './https'
  import store from "./store";


  export default {
    name: 'app',
    components: {
      GlobalLoader,
      FooterMain,
      Popups,
      Header,
    },
    created() {
      let parameters = this.$route.query

      // localStorage.setItem('X-LEELOO-CID',  parameters.leeloo_cid);
      // localStorage.setItem('X-LEELOO-MSG',  parameters.msg);
      // this.$session.set("X-LEELOO-MSG", parameters.msg);
      // this.$session.set("X-LEELOO-CID", parameters.leeloo_cid);


      // this.changeCustomHeadersParamsCid(parameters.leeloo_cid)
      // this.changeCustomHeadersParamsMsg(parameters.msg)
      // console.log(this.$session.get("X-LEELOO-MSG"))


      if (parameters.msg  !== undefined ){
        if (parameters.msg  !== null ){
          sessionStorage.setItem('X-LEELOO-MSG', parameters.msg);
          $http.interceptors.request.use((config) => {
            config.headers['X-LEELOO-MSG'] = parameters.msg;
            return config;
          });
        }
      }

      if (parameters.leeloo_cid !== undefined ){
        if (parameters.leeloo_cid !== null ){
          sessionStorage.setItem('X-LEELOO-CID',  parameters.leeloo_cid);
          $http.interceptors.request.use((config) => {
            config.headers['X-LEELOO-CID'] = parameters.leeloo_cid;
            return config;
          });
        }
      }


      this.fetchVariables()
      if (this.is_auth) {
        this.fetchNotification();
        this.fetchUser();
      }
      this.fetchConfig().then(() => {
        if (this.getIntroId && this.getIntroDesc) {
          if (!localStorage.introId) {
            localStorage.setItem('introId', this.getIntroId);
            if (!localStorage.introView) {
              localStorage.setItem('introView', '0');
              this.changePopupIntro(true);
            }
          } else {
            if (localStorage.introId !== this.getIntroId) {
              localStorage.setItem('introId', this.getIntroId);
              if (!localStorage.introView) {
                localStorage.setItem('introView', '0');
                this.changePopupIntro(true);
              } else {
                localStorage.setItem('introView', '0');
                this.changePopupIntro(true);
              }
            } else {
              if (!localStorage.introView) {
                localStorage.setItem('introView', '0');
                this.changePopupIntro(true);
              } else {
                if (localStorage.introView === '0') {
                  this.changePopupIntro(true);
                }
              }
            }
          }
        }
      });
      this.fetchSetting()
        .then(() => {
          this.getAddress();
        });
      if (this.$route.query.ref) {
        this.changePopupRegistration(true)
      }
      if (!localStorage.countView) {
        localStorage.setItem('countView', '10')
      }
    },
    mounted() {
      if (this.$route.fullPath.includes('openRegister')) {
        this.changePopupRegistration(true);
      }
      if (this.$route.fullPath.includes('expressBuy')) {
        this.changePopupExpress({show: true, key: 'buy'});
      }
      if (this.$route.fullPath.includes('expressGift')) {
        this.changePopupExpress({show: true, key: 'gift'});
      }
      if(!this.is_auth){
        if (this.$route.query.hasOwnProperty('showSingIn') && this.$route.query.hasOwnProperty('link')) {
          this.changePopupSingIn(true)
        }
      }

      if (this.is_auth) {
        this.fetchUser().then(()=>{
          let response = this.fetchUserData
          this.setUuidAsync(response.uuid)
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(
                  {
                    event:'userId',
                    user_id:response.uuid
                  });
        });
      }

    },
    watch: {
      serverError() {
        this.$toaster.error(this.$t('error500'));
      }
    },
    computed: {
      ...mapGetters({
        is_auth: `auth/is_auth`,
        isInvestorPage: `system/isInvestorPage`,
        globalLoader: `system/globalLoader`,
        fixBody: `system/fixBody`,
        setting: 'main/setting',
        config: 'main/config',
        serverError: 'system/serverError',
        fetchUserData: `profile/user`,
      }),
      getIntroId() {
        for (let i in this.config) {
          if (this.config[i].key === 'introId') {
            return this.config[i].value;
          }
        }
      },
      getIntroDesc() {
        let result = false;
        for (let i in this.config) {
          if (this.config[i].key === 'introDesc') {
            result = true
          }
        }
        return result
      }
    },
    methods: {
      ...mapActions({
        fetchVariables: `statistic/HOME_ALL_VARIABLES`,
        fetchVideo: `gallery/GALLERY_VIDEO`,
        fetchVideoView: `gallery/GALLERY_VIDEO_ALBUM`,
        fetchNotification: `notifications/NOTIFICATIONS`,
        fetchConfig: `main/CONFIG`,
        fetchSetting: `main/SETTING`,
        fetchUser: `profile/LOAD_USER_DATA`,
      }),
      ...mapActions('new_user',{
        setUuidAsync: 'setUuidAsync',
      }),
      getAddress() {
        if (this.setting.country && this.setting.countryCode) {
          this.changeSettingCountry({
            country: this.setting.country,
            countryCode: this.setting.countryCode
          });
          this.changeLoadingCountryCode(true);
        } else {
          axios.defaults.headers.common = {};
          this.$getLocation()
            .then(coordinates => {

              axios({
                url: `https://maps.googleapis.com/maps/api/geocode/json?latlng= + ${coordinates.lat} + , + ${coordinates.lng} + &key= +${googleKey}`,
                method: 'get',
                headers: {}
              })
                .then(response => {
                  let length = response.data.results.length - 1;
                  this.changeSettingCountry({
                    country: response.data.results[length].formatted_address,
                    countryCode: response.data.results[length].address_components[0].short_name
                  });
                  this.changeLoadingCountryCode(true);
                })
                .catch(() => {

                })
            })
            .catch(() => {
              this.changeSettingCountry({
                country: '',
                countryCode: ''
              });
              this.changeLoadingCountryCode(true);

            })
        }
      },
      gtm(label) {
        this.$gtm.trackEvent({
          event: 'biodep',
          eventCategory: 'userclicks',
          eventAction: 'button',
          eventLabel: label,
        });
      },
      ...mapMutations({
        changePopupSingIn: `auth/SHOW_SING_IN`,
        changeCustomHeadersParamsMsg:`auth/CUSTOM_HEADERS_PARAMS_MSG`,
        changeCustomHeadersParamsCid: `auth/CUSTOM_HEADERS_PARAMS_CID`,
        changePopupRegistration: `auth/REGISTRATION_SHOW`,
        changeHelpPopup: `popups/SHOW_POPUP_HELP`,
        changeSettingCountry: `main/CHANGE_SETTING_COUNTRY`,
        changeLoadingCountryCode: `main/CHANGE_LOADING_COUNTRY_CODE`,
        changePopupExpress: `expressActions/CHANGE_POPUP_EXPRESS`,
        changePopupIntro: `popups/SHOW_POPUP_INTRO`,
      }),
    }
  }
</script>
<style src="./assets/style/style.scss" lang="scss"></style>
