import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_EXCHANGE_CATEGORIES_POPUP,
    EXCHANGE_LOAD_CATEGORY_POPUP,
    EXCHANGE_CURRENT_CATEGORY_POPUP,
    SHOW_EXCHANGE_TREES_POPUP,
    EXCHANGE_RESET_CATEGORY_POPUP
} from "@/store/mutation-types";
export default {
    name: 'exchange-category-popup-documents',
    data() {
        return {
            selectCategories: []
        }
    },
    computed: {
        ...mapGetters({
            category: `exchangeTree/categoriesPopup`
        })
    },
    created(){
        this.fetchCategory();
    },
    methods: {
        ...mapMutations({
            showPopup: `exchangeTree/${SHOW_EXCHANGE_CATEGORIES_POPUP}`,
            showTreesPopup: `exchangeTree/${SHOW_EXCHANGE_TREES_POPUP}`,
            currentCategory: `exchangeTree/${EXCHANGE_CURRENT_CATEGORY_POPUP}`,
            reset: `exchangeTree/${EXCHANGE_RESET_CATEGORY_POPUP}`,
        }),
        ...mapActions({
            fetchCategory: `exchangeTree/${EXCHANGE_LOAD_CATEGORY_POPUP}`
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        selectCategory(){
            this.currentCategory(this.selectCategories);
            this.showPopup(false);
            this.showTreesPopup(true);
        },
    },
    destroyed(){
        this.reset();
    }
}
