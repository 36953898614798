import {
    SHOW_GLOBAL_LOADER,
    SHOW_SERVER_ERROR,
    FIX_BODY,
    SHOW_TEXT_HELP,
} from "@/store/mutation-types";
import store from '@/store'

export function globalLoader(state){
    store.commit(`system/${SHOW_GLOBAL_LOADER}`, state)
}
export function transformDate(elem){
    let toYear = elem.substr(0,4);
    let toMonth = elem.substr(5,2);
    let toDay = elem.substr(8,2);
    return `${toDay}.${toMonth}.${toYear}`
}
export function fixBody(state){
    store.commit(`system/${FIX_BODY}`, state)
}
export function showTextPopup(status){
    store.commit(`popups/${SHOW_TEXT_HELP}`, status);
    store.commit(`system/${FIX_BODY}`, status)
}
export const googleKey = 'AIzaSyCbahXtn2gKKhLCW5SepkC_DNefw80aAf0';
