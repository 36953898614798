import {mapActions, mapGetters, mapMutations} from "vuex";
import {
    FIELD_DETAIL,
    SING_DOCUMENT,
    GET_ORDER,
    SHOW_TREES_MAP_POPUP_AREA,
    DOCUMENT_TREE_ORDER_ID,
    SHOW_POPUP_DOCUMENTS,
    CERTIFICATE_TYPE,
    CURRENT_GIFT_OBJ,
    SHOW_POPUP_GIFT_LIMIT,
    SHOW_GET_GIFT_POPUP,
    CURRENT_GIFT_ID,
    DOCUMENT_TREE_ID,
    NOT_AVAIBLE
} from "@/store/mutation-types";
import VueAdsPagination from 'vue-ads-pagination';
import { VueAdsPageButton } from 'vue-ads-pagination';
export default {
    name: 'search-detail',
    data(){
        return{
            idOrder: null,
            page: 0,
            skip: 0,
            loading: false
        }
    },
    computed:{
        ...mapGetters({
            countTreesDetail: `field/countTreesDetail`,
            detailTrees: `field/detailTrees`,
            limit: 'trees/limit'
        }),
        currentSeason(){
            let route = this.$route.query.season;
            if(route){
                if(route === 2){
                    return this.$t('spring');
                }
                if(route === 4){
                    return this.$t('autumn');
                }
            }
        },
        showPagination(){
            if(10 > this.countTreesDetail){
                return false
            }else{
                return true
            }
        }
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    watch: {
        page(){
            this.skip = this.page * this.limit;
            this.load();
        }
    },
    created(){
        this.load();
    },
    methods: {
        ...mapActions({
            treesDetail: `field/${FIELD_DETAIL}`,
            singDocument: `trees/${SING_DOCUMENT}`,
            loadOrderId: `trees/${GET_ORDER}`,
        }),
        ...mapMutations({
            changePopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
            changeTreeOrderId: `trees/${DOCUMENT_TREE_ORDER_ID}`,
            showPopupDocuments: `trees/${SHOW_POPUP_DOCUMENTS}`,
            certificateType: `trees/${CERTIFICATE_TYPE}`,
            changeGetGiftPopup: `gift/${SHOW_GET_GIFT_POPUP}`,
            currentGiftId: `gift/${CURRENT_GIFT_ID}`,
            currentObjGift: `gift/${CURRENT_GIFT_OBJ}`,
            showPopupGiftLimit: `gift/${SHOW_POPUP_GIFT_LIMIT}`,
            changeTreeId: `trees/${DOCUMENT_TREE_ID}`,
            notAvaible: `system/${NOT_AVAIBLE}`,
        }),
        hasBans(elem) {
            let divined = false;
            let sale = false;
            if (elem.dividend_frozen_to && elem.dividend_frozen_to !== null) {
                divined = true
            } else {
                divined = false
            }
            if (elem.sale_frozen_to && elem.sale_frozen_to !== null) {
                sale = true
            } else {
                sale = false
            }
            if (divined || sale) {
                return true
            }
        },
        changeDocumentPopup(id){
            this.certificateType(false);
            this.changeTreeId(id);
            this.showPopupDocuments(true);
        },
        getGiftPopup(id){
            this.changeGetGiftPopup(true);
            this.currentGiftId(id)
        },
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        showGiftLimitPopup(item){
            this.currentObjGift(item);
            this.showPopupGiftLimit(true);
        },
        loadOrder(id){
            this.loadOrderId(id)
                .then(response => {
                    this.idOrder = response.packet_id;
                    let obj = {
                        packet_type: response.packet_type,
                        packet_id: response.packet_id
                    };
                    this.singDocument(obj)
                        .then(() => {
                            this.certificateType(true);
                            this.changeTreeOrderId(this.idOrder);
                            this.showPopupDocuments(true);
                            this.load();
                        })
                })
        },
        load(){
            this.loading = true;
            let obj ={
                skip: this.skip,
                field_id: this.$route.query.field_id !== undefined ? this.$route.query.field_id: '',
            };
            this.treesDetail(obj)
                .then(() => {
                    this.loading = false;
                })
        },
        goBack(){
            this.$router.go(-1)
        },
    }
}
