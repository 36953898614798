import {mapActions, mapGetters, mapMutations} from 'vuex';
import {
    CANCEL_PUT_UP_GIFT_LIST,
    SHOW_POPUP_CANCEL_PUT_UP_GIFT,
    CANCEL_PUT_UP_GIFT_RESET_LIST,
    CANCEL_PUT_UP_GIFT_PACK,
    CANCEL_PUT_UP_GIFT_CHANGE_SKIP,
    CANCEL_PUT_UP_GIFT_CHANGE_PAGE
} from '@/store/mutation-types';
import Popup from './popup/index.vue';
import VueAdsPagination from 'vue-ads-pagination';
import {VueAdsPageButton} from 'vue-ads-pagination';

export default {
    name: 'cancel-put-up-gift',
    data(){
        return{
            type: 'gift'
        }
    },
    components: {
        Popup,
        VueAdsPagination,
        VueAdsPageButton
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
            list: `cancelPutUpGift/list`,
            page: `cancelPutUpGift/page`,
            limit: `cancelPutUpGift/limit`,
            loading: `cancelPutUpGift/loading`,
            length: `cancelPutUpGift/length`,
            showTakeOff: `cancelPutUpGift/showTakeOff`,
        }),
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.fetchList();
        }
    },
    created() {
        this.fetchList();
    },
    methods: {
        ...mapActions({
            fetchList: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_LIST}`,
            withdrawPack: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_PACK}`,
        }),
        ...mapMutations({
            showPopup: `cancelPutUpGift/${SHOW_POPUP_CANCEL_PUT_UP_GIFT}`,
            resetList: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_RESET_LIST}`,
            changeSkip: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_CHANGE_SKIP}`,
            changePage: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_CHANGE_PAGE}`,
        }),
        cancelGift(e) {
            let obj = {
                type: this.type,
                sale_pack_id: e.sale_pack_id,
                is_young: 1
            };
            this.withdrawPack(obj)
                .then(response => {
                    this.fetchList();
                    this.$toaster.success(response.message)
                })
                .catch(error => {
                    this.$toaster.error(error.response.data.error.message)
                })
        },
    },
    destroyed() {
        this.resetList();
        this.changeSkip(0);
    }
}
