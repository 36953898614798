import popupFeedback from '@/components/popup-feedback/index.vue'
import PopupMap from '@/modules/personal/components/helpers/popup-map/index.vue'
import Registration from '@/components/registration/index.vue'
import RegistrationInvestor from '@/components/registration-investor/index.vue'
import SingIn from '@/components/sing-in/index.vue'
import RestorePasswordEmail from '@/components/restore-password-email/index.vue'
import notAvaible from '@/components/not-avaible/index.vue'
import PopupPrivacyPolicy from '@/components/popup-privacy-policy/index.vue'
import PopupGetGift from '@/modules/personal/components/helpers/popup-get-gift/index.vue'
import PopupGiftLimit from '@/modules/personal/components/helpers/popup-gift-limit/index.vue'
import PopupTwoAuthCode from '@/modules/personal/components/helpers/popup-enter-code/index.vue'
import ExchangeTreePopup from '@/modules/personal/components/helpers/exhange-tree-popup/index.vue'
import PopupRemoveProfile from '@/modules/personal/components/helpers/popup-remove-profile/index.vue'
import PopupEnableTwoFactor from '@/modules/personal/components/helpers/popup-enable-two-factor/index.vue'
import popupPromo from '@/components/popup-promo/index.vue'
import popupPromoResult from '@/components/popup-promo/popup-result/index.vue'
import cashPopUp from '@/components/cash/index.vue'
import textPopup from '@/modules/helpers/text-popup/index.vue'
import videoPopup from '@/modules/helpers/video-info/index.vue'
import popupSuc from '@/modules/shop/popup-success/index.vue'
import popupIframe from '@/modules/shop/popup-iframe/index.vue'
import paymentPopup from '@/components/shop-payment/index.vue'
import popupQr from '@/components/popup-qr/index.vue'
import popupUserStatus from '@/components/popup-user-status/index.vue'
import popupHelp from '@/components/help-popup/index.vue'
import popupExpress from '@/components/popup-express/index.vue'
import popupIntro from '@/components/popup-intro/index.vue'
import popupAfterRegistration from '@/components/popup-after-registration/index.vue'
import popupExpressPromoCode from '@/components/popup-express-promocode/index.vue'
import forEnter from '@/components/for-enter/index.vue';
import popupCodeConfirm from '@/components/popup-confidant-confirm/index.vue';
import popupInfoConfidant from '@/components/popup-info-confidant/index.vue';
import popupFuturesBalance from '../popup-futures-balance/index.vue';
import popupUserNft from '../popup-nft/index.vue';

import { mapGetters } from 'vuex'

export default {
    name: 'popups',
    components: {
        popupCodeConfirm,
        popupInfoConfidant,
        popupHelp,
        textPopup,
        popupIntro,
        videoPopup,
        popupExpressPromoCode,
        RestorePasswordEmail,
        PopupEnableTwoFactor,
        RegistrationInvestor,
        PopupPrivacyPolicy,
        PopupRemoveProfile,
        ExchangeTreePopup,
        PopupTwoAuthCode,
        popupPromoResult,
        PopupGiftLimit,
        popupFeedback,
        Registration,
        PopupGetGift,
        popupIframe,
        popupPromo,
        notAvaible,
        popupQr,
        popupUserStatus,
        SingIn,
        PopupMap,
        cashPopUp,
        forEnter,
        popupSuc,
        paymentPopup,
        popupExpress,
        popupAfterRegistration,
        popupFuturesBalance,
        popupUserNft,
    },
    computed: {
        ...mapGetters({
            showPopupCurrentArea: `map/showPopupCurrentArea`,
            openExpressPopup: `expressActions/popup`,
            popupPromoCode: `expressActions/popupPromoCode`,
            openHelpPopup: `popups/openHelpPopup`,
            showFeedback: `feedback/showFeedback`,
            showExchangeTreePopup: `exchangeTree/showExchangeTreePopup`,
            showPopupEnterCode: `profile/showPopupEnterCode`,
            showPopupCodeConfirm: `profile/showPopupCodeConfirm`,
            showPopupFuturesBalance: `profile/showPopupFuturesBalance`,
            showPopupInfoConfidant: `profile/showPopupInfoConfidant`,
            showPopupEnableTwoFactor: `profile/showPopupEnableTwoFactor`,
            is_auth: `auth/is_auth`,
            showRegistration: `auth/showRegistration`,
            showRegistrationInvestor: `auth/showRegistrationInvestor`,
            showSingIn: `auth/showSingIn`,
            showRestorePasswordEmail: `auth/showRestorePasswordEmail`,
            isInvestorPage: `system/isInvestorPage`,
            showTextPopup: `popups/showTextHelp`,
            showVideoPopup: `helpCalculate/showVideoHelp`,
            notAvaible: `system/notAvaible`,
            fixBody: `system/fixBody`,
            buyTreePopUp: 'system/buyTreePopUp',
            showPopupGetGift: 'gift/showPopupGetGift',
            showPopupGiftLimit: 'gift/showPopupGiftLimit',
            cashPopUp:'system/cashPopUp',
            forEnter: 'system/forEnter',
            setting:'main/setting',
            config:'main/config',
            social:'contacts/social',
            showPopupPrivacy: 'privacyPolicy/showPopupPrivacy',
            cashPopUpSuccess: 'shop/cashPopUpSuccess',
            cashPopUpIframe: 'shop/cashPopUpIframe',
            serverError: 'system/serverError',
            paymentPopup: 'system/paymentPopup',
            showPopupPromo: `promo/showPopupPromo`,
            showPromoResult: `promo/showPromoResult`,
            showPopupQr: `getQrCode/showPopupQr`,
            popupRemoveProfile: `profile/popupRemoveProfile`,
            showPopupIntro: `popups/showPopupIntro`,
            showUserStatus: `popups/showUserStatus`,
            showUserNft: `popups/showUserNft`,
            popupAfterRegistrationStatus: `popups/popupAfterRegistrationStatus`,
        })
    }
}
