import { $http } from '@/https'
import {fixBody, globalLoader} from '@/helpers/variables/index';
import store from '@/store'
import {
    BUY_GIFT_DEFAULT_SHOW_POPUP,
    BUY_GIFT_SELECT_YOUNG_TREES,
    BUY_GIFT_COUNT_FREE_TREES,
    BUY_FOR_GIFT,
    PAYMENT_LEOGAMING,
    REDIRECT_TRANSACTION,
    CHANGE_POPUP_IFRAME,
    POPUP_BUY_SUCCESS
} from '../../mutation-types';

const state = {
    showPopup: false,
    selectYoungTrees: 0,
    countFreeTrees: null,
};

const getters = {
    selectYoungTrees: state => state.selectYoungTrees,
    countFreeTrees: state => state.countFreeTrees,
    showPopup: state => state.showPopup,
    buyLoading: state => state.buyLoading,
};

const actions = {
    [BUY_GIFT_COUNT_FREE_TREES]: async ({ commit }) => {
        try {
            const response = await $http.get(`v1/gift_trees_count`);
            commit(BUY_GIFT_COUNT_FREE_TREES, response.data);
            return response;
        }catch (e) {
            throw e
        }
    },
    [BUY_FOR_GIFT]: async ({ commit }, payload) => {
        globalLoader(true);
        try {
            const response = await $http.post(`v1/shop/buy_gift`, payload);
            if(response.data.type === 'url'){
                commit(`shop/${PAYMENT_LEOGAMING}`, response.data.frame_url, {root: true});
                commit(`shop/${REDIRECT_TRANSACTION}`, true, {root: true});
                commit(`shop/${CHANGE_POPUP_IFRAME}`, true, {root: true});
            }
            if(payload.payment_system === 'balance'){
                commit(`shop/${REDIRECT_TRANSACTION}`, false, {root: true});
                commit(`shop/${POPUP_BUY_SUCCESS}`, true, {root: true});
            }
            return response;
        }catch (e) {
            throw e
        }finally {
            globalLoader(false);
        }
    },
};

const mutations = {
    [BUY_GIFT_DEFAULT_SHOW_POPUP](state, param) {
        fixBody(param);
        state.showPopup = param;
    },
    [BUY_GIFT_SELECT_YOUNG_TREES](state, count) {
        state.selectYoungTrees = count;
    },
    [BUY_GIFT_COUNT_FREE_TREES](state, count) {
        state.countFreeTrees = count;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
