import {$http} from '@/https';
import {
    GIFT_TREE_LOAD,
} from '../mutation-types.js';

const state = {
    content: null,
};

const getters = {
    content: state => state.content,
};

const actions = {
    [GIFT_TREE_LOAD]: async ({commit}) => {
        try {
            const response = await $http.get('v1/pages/gift-tree');
            commit(GIFT_TREE_LOAD, response.data.data);
        } catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [GIFT_TREE_LOAD](state, content) {
        state.content = content;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
