import {mapActions, mapGetters, mapMutations} from 'vuex'
import {validationMixin} from 'vuelidate';
import {email, minLength,maxLength, required, sameAs} from 'vuelidate/lib/validators';
import _ from 'lodash';

import FacebookWidget from '@/components/widgets/facebook/index.vue';
import GoogleWidget from '@/components/widgets/google/index.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


export default {
  name: 'registration',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        first_name: '',
        last_name: '',
        codePromo:'',
        phone: '',
        hiddenPhone: '',
        email: '',
        password: '',
        password_confirmation: '',
        checkbox: false,
        ref: this.$route.query.ref ? this.$route.query.ref : '',
        // trace: b24Tracker.guest.getTrace()
      },
      phone: {
        isValid: false,
      },
      visiblePassword: false,
      validationErrors: {},
      timer: 60,
      disabled: true,
      showSuccess: false,
      translations: {
        countrySelectorLabel: this.$t('phoneLabelsCountrySelectorLabel'),
        phoneNumberLabel: this.$t('phoneLabelsPhoneNumberLabel'),
        example: this.$t('phoneLabelsExample'),
      }
    }
  },
  validations: {
    payload: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email
      },
      codePromo: {
        maxLength: maxLength(20)
      },
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
      hiddenPhone: {
        required,
      },
      checkbox: {required}
    }
  },
  components: {
    VuePhoneNumberInput,
    FacebookWidget,
    GoogleWidget
  },
  watch: {
    readPrivacyPolicy() {
      this.payload.checkbox = this.readPrivacyPolicy;
    },
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      readPrivacyPolicy: `privacyPolicy/readPrivacyPolicy`,
      showRegistration: `privacyPolicy/showRegistration`,
      setting: `main/setting`,
    }),
    firstNameErrors() {
      let error = [];
      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    promoCodeErrors() {
      let error = [];
      if (!this.$v.payload.codePromo.$dirty) {
        return error;
      }
      if (!this.$v.payload.codePromo.maxLength) {
        error.push(this.$t('validationMaxLength20'));
      }
      if (this.validationErrors.codePromo) {
        this.validationErrors.codePromo.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    lastNameErrors() {
      let error = [];
      if (!this.$v.payload.last_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }

      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.hiddenPhone.$dirty) {
        return error;
      }
      if (!this.phone.isValid) {
        error.push(this.$t('validationMinPhone'))
      }
      if (this.validationErrors.hiddenPhone) {
        this.validationErrors.hiddenPhone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin8'));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    passwordRepeatErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        });
      }

      return error;
    },
    checkboxError() {
      let error = '';
      if (!this.$v.payload.checkbox.$dirty) {
        return error;
      }
      if (this.payload.checkbox === false) {
        error = this.$t('validationChecked');
      }

      return error;
    },
  },
  methods: {
    gtm(label) {
      this.$gtm.trackEvent({
        event: 'biodep',
        eventCategory: 'send',
        eventAction: 'form',
        eventLabel: label,
      });
    },
    onTelInput({formatNational, isValid, formattedNumber, phoneNumber}) {
      this.payload.phone = formattedNumber;
      this.payload.hiddenPhone = phoneNumber;
      this.phone.isValid = isValid;
    },
    ...mapActions({
      registration: `auth/REGISTRATION`,
      checkEmail: `auth/CHECK_FREE_EMAIL`,
      reActivation: `auth/RE_ACTIVATION`,
    }),
    ...mapMutations({
      changeShowPopup: `auth/REGISTRATION_SHOW`,
      showPopupPrivacy: `privacyPolicy/SHOW_POPUP_PRIVACY`,
      changeReadPrivacyPolicy: `privacyPolicy/IS_READ_PRIVACY_POLICY`,
      changePopupAfterRegister: `popups/SHOW_POPUP_AFTER_REGISTRATION`,
    }),
    callbackTimer() {
      this.disabled = true;
      this.timer = 60;
      setInterval(() => {
        this.timer = this.timer - 1;
        if (this.timer <= 0) {
          this.disabled = false;
          this.timer = '';
        }
      }, 1000);
    },
    reSend() {
      this.reActivation(this.payload.email)
        .then(response => {
          this.callbackTimer();
          this.$toaster.success(response.data.message);
        })
        .catch(error => {
          this.$toaster.error(error.response.data.message);
        })
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.payload.checkbox === true && this.phone.isValid === true) {
        this.registration(this.payload)
          .then(response => {
            this.gtm('reg');
            this.$analytics.fbq.event('CompleteRegistration');
            if (response.status === 200) {
              this.showSuccess = true;
              this.callbackTimer();
              this.changePopupAfterRegister(true);
            }
          }).catch((error) => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
        });
      }
    },
    changeEmailInput: _.debounce(function (email) {
      if (this.$v.payload.email.email) {
        this.checkEmail({email: email})
          .then(response => {
            this.validationErrors = {};
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.validationErrors = error.response.data.errors;
            }
          })
      }

    }, 1000),
  },
  destroyed() {
    this.changeReadPrivacyPolicy(false)
  }
}
