import {mapMutations, mapGetters} from 'vuex'


export default {
    name: 'popup-intro',
    computed: {
        ...mapGetters({
            config:'main/config',
        }),
        getDescription(){
            for( let i in this.config ){
                if(this.config[i].key.includes('contentPopupAfterRegistration')){
                    return this.config[i];
                }
            }
        },
    },
    methods: {
        ...mapMutations({
            changePopup: `popups/SHOW_POPUP_AFTER_REGISTRATION`,
        }),
    },
    destroyed() {

    }
}
