import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    GIVE_SHOW_TREES,
    CALCULATE_ADD_TREE_PERSONAL,
    CALCULATE_LOAD_TREE_PERSONAL,
    CALCULATE_POPUP_CHANGE_OFFSET,
    CALCULATE_PERSONAL_CHANGE_CHECK,
    CALCULATE_PERSONAL_CHANGE_ALL,
    RESET_LIST_PERSONAL_TREES
} from "@/store/mutation-types";
import VueAdsPagination, {VueAdsPageButton} from "vue-ads-pagination";
export default {
    name: 'calculate-trees-popup',
    data() {
        return {
            loading: false,
            page: 0,
            limit: 20,
            localNumber: 0,
        }
    },
    computed: {
        ...mapGetters({
            trees: `calculate/listTreePersonal`,
            lengthListTree: `calculate/lengthListTree`,
            currentCategory: `calculate/currentCategory`,
            selectTrees: `calculate/selectTreesPersonal`,
        })
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    watch: {
        page(){
            this.currentTrees(this.selectTrees);
            this.localNumber = this.page * this.limit;
            this.changeOffset(this.page * this.limit);
            this.loading = true;
            this.fetchTrees(this.currentCategory)
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    created(){
        this.loading = true;
        this.fetchTrees(this.currentCategory)
            .finally(() => {
                this.loading = false;
            })
    },
    methods: {
        ...mapActions({
            fetchTrees: `calculate/${CALCULATE_LOAD_TREE_PERSONAL}`
        }),
        ...mapMutations({
            showPopup: `give/${GIVE_SHOW_TREES}`,
            currentTrees: `calculate/${CALCULATE_ADD_TREE_PERSONAL}`,
            changeOffset: `calculate/${CALCULATE_POPUP_CHANGE_OFFSET}`,
            changeCheckbox: `calculate/${CALCULATE_PERSONAL_CHANGE_CHECK}`,
            changeAllTrees: `calculate/${CALCULATE_PERSONAL_CHANGE_ALL}`,
            resetListTree: `calculate/${RESET_LIST_PERSONAL_TREES}`,
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        addTrees(){
            this.currentTrees(this.selectTrees);
            this.showPopup(false)
        },
    },
    destroyed(){
        this.resetListTree();
    }
}
