import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Carousel, Slide } from 'vue-carousel';
import {
    LOAD_PACKAGE_OFFER,
    BUY_SELECT_YOUNG_TREES,
    NOT_AVAIBLE,
    IS_LOGIN_USER
} from "@/store/mutation-types";
export default{
    name: 'package-offer',
    components: {
        Carousel,
        Slide
    },
    computed: {
        ...mapGetters({
            list: `packageOfferSlider/list`,
            is_auth: `auth/is_auth`,
        })
    },
    created(){
        if(!this.list){
            this.fetchList();
        }
    },
    methods: {
        ...mapActions({
            fetchList: `packageOfferSlider/${LOAD_PACKAGE_OFFER}`
        }),
        changeCountTree(count){
            this.$analytics.fbq.event('InitiateCheckout');
            if(this.is_auth){
                this.changeSelectYoungTrees(count);
                this.gtm('buyavt');
                this.$router.push({ name: 'personal.buy_default' });
            }else{
                this.gtm('buy');
                this.forEnter(true)
            }
        },
        ...mapMutations({
            changeSelectYoungTrees: `profileBuyTree/${BUY_SELECT_YOUNG_TREES}`,
            notAvaible: `system/${NOT_AVAIBLE}`,
            forEnter: `system/${IS_LOGIN_USER}`,
        }),
        nextSlide() {
            this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
        },
        prevSlide() {
            this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
        },
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
    }
}
