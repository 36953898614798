import { $http } from '@/https'
import { globalLoader, fixBody } from '@/helpers/variables/index'
import {
    SHOW_EXCHANGE_TREE_POPUP,
    EXCHANGE_CURRENT_TREE,
    RESET_EXCHANGE_CURRENT_TREE,
    EXCHANGE_LIST_TREES,
    EXCHANGE_LIST_TREES_LOADING,
    EXCHANGE_TREES_CHANGE_PAGE,
    EXCHANGE_TREES_CHANGE_SKIP,
    EXCHANGE_TREES_LENGTH,
    EXCHANGE_TREES_RESET,
    EXCHANGE_SELECT_TREE,
    RESET_EXCHANGE_SELECT_TREE,
    EXCHANGE_LOAD_TREES,
    EXCHANGE_LOADING_TREES_POPUP,
    EXCHANGE_RESET_TREES_POPUP,
    EXCHANGE_TREES_POPUP_LENGTH,
    SHOW_EXCHANGE_TREES_POPUP,
    SHOW_EXCHANGE_CATEGORIES_POPUP,
    EXCHANGE_LOAD_CATEGORY_POPUP,
    EXCHANGE_RESET_CATEGORY_POPUP,
    EXCHANGE_CURRENT_CATEGORY_POPUP,
    EXCHANGE_CHANGE_OFFSET_POPUP,
    EXCHANGE_SELECTED_TREE_POPUP,
    EXCHANGE_CHANGE_CHECK_POPUP_TREES,
    EXCHANGE_RESET_DETAIL,
    EXCHANGE_CHANGE_PAGE_POPUP
} from '@/store/mutation-types';

const state = {
    showExchangeTreePopup: false,
    currentTree: null,
    list: null,
    page: 0,
    pageTreePopup: 0,
    limit: 15,
    limitTreePopup: 20,
    skip: 0,
    skipTreePopup: 0,
    length: 0,
    loading: false,
    selectCurrentExchange: null,
    listTreePopup: [],
    loadTreePopup: false,
    lengthTreesPopup: 0,
    showCategoryPopup: false,
    showTreesPopup: false,
    categoriesPopup: null,
    currentCategory: null,
    selectCurrentTree: null
};

const getters = {
    loading: state => state.loading,
    selectCurrentTree: state => state.selectCurrentTree,
    pageTreePopup: state => state.pageTreePopup,
    limitTreePopup: state => state.limitTreePopup,
    skipTreePopup: state => state.skipTreePopup,
    currentCategory: state => state.currentCategory,
    categoriesPopup: state => state.categoriesPopup,
    showCategoryPopup: state => state.showCategoryPopup,
    showTreesPopup: state => state.showTreesPopup,
    lengthTreesPopup: state => state.lengthTreesPopup,
    loadTreePopup: state => state.loadTreePopup,
    listTreePopup: state => state.listTreePopup,
    showExchangeTreePopup: state => state.showExchangeTreePopup,
    list: state => state.list,
    currentTree: state => state.currentTree,
    length: state => state.length,
    skip: state => state.skip,
    limit: state => state.limit,
    page: state => state.page,
    selectCurrentExchange: state => state.selectCurrentExchange,
};

const actions = {
    [EXCHANGE_LIST_TREES]: async ({ commit }) => {
        try {
            commit(EXCHANGE_LIST_TREES_LOADING, true);

            const response = await $http.get(`v1/trees?limit=${state.limit}&skip=${state.skip}`);
            commit(EXCHANGE_LIST_TREES, response.data.data);
            commit(EXCHANGE_TREES_LENGTH, response.data.meta.count);
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(EXCHANGE_LIST_TREES_LOADING, false);
        }
    },
    [EXCHANGE_SELECT_TREE]: async ({ commit }, payload) => {
        try {
            commit(EXCHANGE_LIST_TREES_LOADING, true);

            const response = await $http.get(`v1/trees/${payload.id}`);
            commit(EXCHANGE_SELECT_TREE, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(EXCHANGE_LIST_TREES_LOADING, false);
        }
    },
    [EXCHANGE_LOAD_TREES]: async ({commit}, payload) => {
        try {
            commit(EXCHANGE_LOADING_TREES_POPUP, true);

            const response = await $http.get(`v1/trees?seasons=${payload.categories}&django=${1}&limit=${state.limitTreePopup}&skip=${state.skipTreePopup}`);
            commit(EXCHANGE_RESET_TREES_POPUP);
            commit(EXCHANGE_LOAD_TREES, response.data.data);
            commit(EXCHANGE_TREES_POPUP_LENGTH, response.data.meta.count);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(EXCHANGE_LOADING_TREES_POPUP, false);
        }
    },
    [EXCHANGE_LOAD_CATEGORY_POPUP]: async ({commit}) => {
        try {
            commit(EXCHANGE_RESET_CATEGORY_POPUP);

            const response = await $http.get(`v1/trees?group_by=season&django=${1}`);
            commit(EXCHANGE_RESET_CATEGORY_POPUP);
            commit(EXCHANGE_LOAD_CATEGORY_POPUP, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [EXCHANGE_CURRENT_CATEGORY_POPUP](state, category) {
        state.currentCategory = null;
        let payload = {
            categories: null,
            limit: state.limitTreePopup,
            offset: state.skipTreePopup,
        };
        let newArr =[];
        for(let i = 0; i < category.length; i++){
            let newVal = '';
            let year = category[i].planting_year;
            let season = category[i].season;
            newVal = year + '-' + season;
            newArr.push(newVal.toString())
        }
        payload.categories = newArr.join();
        payload.limit = state.limitTreePopup;
        payload.offset = state.skipTreePopup;
        state.currentCategory = payload;
    },
    [EXCHANGE_CHANGE_CHECK_POPUP_TREES](state, obj) {
        state.listTreePopup.map(elem => {
            if(obj.id === elem.id){
                elem.check = true;
            }else{
                elem.check = false
            }
        });
        state.selectCurrentTree = obj;
    },
    [SHOW_EXCHANGE_TREE_POPUP](state, status) {
        state.showExchangeTreePopup = status;
        fixBody(status)
    },
    [EXCHANGE_CURRENT_TREE](state, tree) {
        state.currentTree = tree;
    },
    [RESET_EXCHANGE_CURRENT_TREE](state) {
        state.currentTree = null;
    },
    [EXCHANGE_LIST_TREES](state, list) {
        state.list = list;
    },
    [EXCHANGE_LIST_TREES_LOADING](state, loading) {
        state.loading = loading;
    },
    [EXCHANGE_TREES_CHANGE_PAGE](state, page) {
        state.page = page;
    },
    [EXCHANGE_TREES_CHANGE_SKIP](state, skip) {
        state.skip = skip;
    },
    [EXCHANGE_TREES_LENGTH](state, length) {
        state.length = length;
    },
    [EXCHANGE_SELECT_TREE](state, tree) {
        state.selectCurrentExchange = tree;
    },
    [EXCHANGE_LOAD_TREES](state, trees) {
        state.listTreePopup = trees;
        state.listTreePopup.map(elem => {
            elem.check = false;
            if(state.selectCurrentTree){
                if (elem.id === state.selectCurrentTree.id) {
                    elem.check = true
                }
            }
        });
    },
    [EXCHANGE_CHANGE_PAGE_POPUP](state, page) {
        state.pageTreePopup = page;
    },
    [EXCHANGE_LOAD_CATEGORY_POPUP](state, categories) {
        state.categoriesPopup = categories;
    },
    [EXCHANGE_TREES_POPUP_LENGTH](state, length) {
        state.lengthTreesPopup = length;
    },
    [EXCHANGE_LOADING_TREES_POPUP](state, status) {
        state.loadTreePopup = status;
    },
    [EXCHANGE_SELECTED_TREE_POPUP](state, tree) {
        state.selectCurrentTree = tree;
    },
    [SHOW_EXCHANGE_TREES_POPUP](state, status) {
        state.showTreesPopup = status;
    },
    [SHOW_EXCHANGE_CATEGORIES_POPUP](state, status) {
        state.showCategoryPopup = status;
    },
    [RESET_EXCHANGE_SELECT_TREE](state) {
        state.selectCurrentExchange = null;
        state.selectCurrentTree = null;
    },
    [EXCHANGE_RESET_TREES_POPUP](state) {
        state.listTreePopup = [];
        state.lengthTreesPopup = 0;
    },
    [EXCHANGE_RESET_CATEGORY_POPUP](state) {
        state.categoriesPopup = null;
    },
    [EXCHANGE_RESET_DETAIL](state) {
        state.selectCurrentTree = null;
    },
    [EXCHANGE_CHANGE_OFFSET_POPUP](state, count) {
        state.skipTreePopup = count;
    },
    [EXCHANGE_TREES_RESET](state) {
        state.list = null;
        state.skip = 0;
        state.length = 0;
        state.page = 0;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
