import {mapGetters, mapMutations, mapActions} from 'vuex'
import {
    DYNAMIC_PAGES,
} from "@/store/mutation-types";
export default {
    name: 'dynamic-page',
    data(){
        return {

        }
    },
    computed:{
        ...mapGetters({
            content: `main/page`
        })
    },
    created(){
        this.fetchContent(this.$route.params.slug)
            .catch(() => {
                this.$router.push({ name: 'not-found' });
            });
    },
    methods: {
        ...mapActions({
            fetchContent: `main/${DYNAMIC_PAGES}`
        })
    },
    destroyed(){

    }
}