import { $http } from '@/https';
import {
    FETCH_PAGE_INVESTOR
} from '../mutation-types';

const state = {
    content: null
};

const getters = {
    content: state => state.content
};

const actions = {
    [FETCH_PAGE_INVESTOR]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/pages/investor');
            commit(FETCH_PAGE_INVESTOR, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [FETCH_PAGE_INVESTOR](state, content) {
        state.content = content;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
