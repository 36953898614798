import searchContent from './components/search-content/index.vue'
import noFound from './components/no-found/index.vue'
import {
    SEARCH
} from '@/store/mutation-types'
import { mapActions, mapGetters } from "vuex";
export default {
    name: 'search',
    data(){
        return{
            searchInput: '',
        }
    },
    components:{
        searchContent,
        noFound
    },
    computed: {
        ...mapGetters({
            showSearchLoading: 'search/showSearchLoading'
        })
    },
    methods:{
        searchBlock(){
            this.gtm(this.searchInput);
            this.fetchContent(this.searchInput);
        },
        ...mapActions({
            fetchContent:`search/${SEARCH}`
        }),
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'search',
                eventAction: 'key',
                eventLabel: label,
            });
        },
    },
}
