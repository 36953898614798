import { mapMutations, mapGetters, mapActions } from 'vuex'
import {SHOW_VIDEO_HELP_CALCULATE} from '@/store/mutation-types';
export default{
    name: 'help-video',
    computed: {
        ...mapGetters({
            video: `main/setting`
        }),

        getId () {
            return this.$youtube.getIdFromUrl(this.video.helperVideo)
        },
    },
    methods: {
        ...mapMutations({
            closeVideoHelp: `helpCalculate/${SHOW_VIDEO_HELP_CALCULATE}`
        }),

    }
}
