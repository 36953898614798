import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'footer-main',
  computed: {
    ...mapGetters({
      config: 'main/config',
      social: 'contacts/social',
    }),
    getYear() {
      const date = new Date();
      return date.getFullYear();
    },
    showButtonInPersonal() {
      return this.$route.path.includes('personal');
    },
    getBtnInfo() {
      for (let i in this.config) {
        if (this.config[i].key.includes('bottom_button')) {
          return this.config[i].value;
        }
      }
    }

  },
  created() {
    this.fetchSocial();
  },
  methods: {
    ...mapActions({
      fetchSocial: `contacts/SOCIAL_LOAD`,
    }),

    ...mapMutations({
      changeNotAvaible: `system/NOT_AVAIBLE`,
      changeHelpPopup: `popups/SHOW_POPUP_HELP`,
    }),
    gtm(label) {
      this.$gtm.trackEvent({
        event: 'biodep',
        eventCategory: 'userclicks',
        eventAction: 'button',
        eventLabel: label,
      });
    },
  },
  destroyed() {

  }
}
