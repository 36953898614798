import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
    PUT_UP_FIELD_RESET,
    PUT_UP_SELL_YOUNG_TREE,
    PUT_UP_SHOW_CATEGORY,
    PUT_UP_DECREMENT_COUNT,
    PUT_UP_INCREMENT_COUNT,
    PUT_UP_CHANGE_COUNT,
    PUT_UP_REMOVE_TREES
} from '@/store/mutation-types';
import Categories from './components/category-popup/index.vue'
export default {
    name: 'investor-field',
    data(){
        return{
            deletedArea: [],
            showDeleteIcons: false,
            validationErrors: {}
        }
    },
    components: {
        Categories
    },
    computed: {
        ...mapGetters({
            userData: `profile/user`,
            list: `putUpGift/list`,
            watchCategory: `putUpGift/showCategory`,
        }),
        totalIsCommissionPrice(){
            let result = 0;
            this.list.forEach(elem => {
                result += elem.totalCommissionPrice;
            });
            return result
        },
        totalNotCommissionPrice(){
            let result = 0;
            this.list.forEach(elem => {
                result += elem.price_each_trees;
            });
            return result
        }
    },
    created(){

    },
    methods: {
        ...mapActions({
            sellTree: `putUpGift/${PUT_UP_SELL_YOUNG_TREE}`,
        }),
        ...mapMutations({
            showCategory: `putUpGift/${PUT_UP_SHOW_CATEGORY}`,
            decrementCount: `putUpGift/${PUT_UP_DECREMENT_COUNT}`,
            incrementCount: `putUpGift/${PUT_UP_INCREMENT_COUNT}`,
            changeCountStore: `putUpGift/${PUT_UP_CHANGE_COUNT}`,
            removeAreaStore: `putUpGift/${PUT_UP_REMOVE_TREES}`,
            resetField: `putUpGift/${PUT_UP_FIELD_RESET}`,
        }),
        changeDeleteIcons() {
            this.showDeleteIcons = !this.showDeleteIcons;
            document.querySelector('.area__scroll').scrollLeft = 0;
            if (!this.showDeleteIcons && this.deletedArea.length > 0) {
                this.removeAreaStore(this.deletedArea)
                this.deletedArea = [];
            }
        },
        changeCount(event, id){
            let payload = {
                field_id: id,
                selectCount: +event
            };
            this.changeCountStore(payload);
        },
        submit(){
            let newArrTrees = [];
            let valid = true;
            this.list.forEach(elem => {
                let obj = {
                    tree_count: elem.selectCount,
                    field_id: elem.field_id
                };
                newArrTrees.push(obj);
                if(elem.selectCount > elem.trees_count || elem.selectCount === 0){
                    valid = false;
                }
            });
            if(valid){
                this.sellTree(newArrTrees)
                    .then(response => {
                        this.$toaster.success(response.data.message);
                    })
                    .catch(error => {
                        this.$toaster.error(error.response.data.error.message);
                    })
            }else{
                this.$toaster.error(this.$t('toasterErrorField'));
            }
        }
    },
    destroyed(){
        this.resetField();
    }
}
