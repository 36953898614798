import { mapGetters, mapActions, mapMutations } from "vuex";
import {fixBody} from '@/helpers/variables/index';
import {
    SET_SHOW_DETAIL,
    RESET_STAR_INFO,
    CHANGE_PARAMS_DETAIL_PARTNERS
} from '@/store/mutation-types';
import {
    GET_STAR_INFO,
    GET_STAR_PARTNERS
} from '@/store/action-types'
export default {
    name: 'star-partners',
    props: {
      id:{
          type: Number,
          default: 0
      }
    },
    data(){
        return {

        }
    },
    created(){

    },
    computed:{
        ...mapGetters({
            paramsDetail: 'starPartners/paramsDetail',
            content: 'starPartners/content',
            list: 'starPartners/list',
            activeIndex: 'starPartners/activeIndex',
            lengthList: 'starPartners/lengthList',
            loadingList: 'starPartners/loading',
        }),
        getVideoId () {
            return this.$youtube.getIdFromUrl(this.content.videoUrl)
        },
    },
    watch: {
        paramsDetail(){
            if(this.paramsDetail.show){
                this.fetchContent();
                if(this.lengthList > this.list.length){
                    if(this.activeIndex === this.list.length - 1){
                        this.fetchList();
                    }
                }
            }else{
                this.reset();
            }
            fixBody(this.paramsDetail.show);
        }
    },
    methods:{
        ...mapActions({
            fetchContent: `starPartners/${GET_STAR_INFO}`,
            fetchList: `starPartners/${GET_STAR_PARTNERS}`
        }),
        ...mapMutations({
            setShowDetail: `starPartners/${SET_SHOW_DETAIL}`,
            reset: `starPartners/${RESET_STAR_INFO}`,
            changeParams: `starPartners/${CHANGE_PARAMS_DETAIL_PARTNERS}`,
        }),
        prevItem(param){
            this.changeParams(param);
            this.reset();
            this.fetchContent();
        },
        nextItem(param){
            this.reset();
            if(this.activeIndex === this.list.length-2 && this.list.length !== this.lengthList){
                this.changeParams(param);
                this.fetchList().then(() => {
                    this.fetchContent();
                })
            }else{
                this.changeParams(param);
                this.fetchContent();
            }
        }
    },
    destroyed(){
        this.reset();
    }
}
