import { mapMutations, mapGetters, mapActions} from 'vuex'
import {
    SHOW_CASH_POPUP,
    REMOVE_BASKET,
    SHOW_PAYMENT_POPUP,
} from "@/store/mutation-types";

export default {
    name: 'shop',
    data(){
        return{
            payload:{
                tree_ids:[]
            },
            filter:{
                season: [],
                min_planting_year:'',
                max_planting_year:'',
                min_price:'',
                max_price:'',
                limit:12,
                skip:0,
            },
        }
    },
    computed:{
        ...mapGetters({
            list: 'shop/list',
            basket: 'shop/basket',
            totalPrice: 'shop/totalPrice',
            treeIds: 'shop/treeIds',
            treePrice: `shop/treePrice`
        }),
        totalPrice(){
            let price = 0;
            this.basket.forEach(t => {
                price += parseInt(t.price)
            });
            return price
        },
    },
    methods:{
        ...mapMutations({
            remove: `shop/${REMOVE_BASKET}`,
            cashPopUp: `system/${SHOW_CASH_POPUP}`,
            paymentPopup: `system/${SHOW_PAYMENT_POPUP}`,
        }),
        removeItemBasket(index, item){
            this.remove({
                index: index,
                item: item
            })
        },
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
        goPaymentPopUp(){
            this.paymentPopup(true);
            this.cashPopUp(false);
            this.gtm('koplate')
        }
    }
}
