import {mapGetters, mapActions, mapMutations} from 'vuex'
import {
    GIVE_REMOVE_TREES,
    GIVE_SHOW_CATEGORIES,
    GIVE_TREES,
    GIVE_SHOW_TREES,
    CHARITY_TREES,
    FIND_USER_IN_EMAIL_LOADING,
    GIVE_RESET,
    GIVE_LOAD_RULES_POPUP,
    GIVE_CHANGE_PHONE,
    GIVE_CHANGE_MAIL,
    GIVE_CHANGE_NOTIFICATION_TIME,
    GIVE_CHANGE_BAN,
    GIVE_CHANGE_FROST,
    GIVE_CHANGE_TYPE_SELECT,
    GIVE_DECREMENT_BAN,
    GIVE_DECREMENT_FROST,
    GIVE_INCREMENT_BAN,
    GIVE_INCREMENT_FROST,
    GIVE_RESET_PROMO,
    DOWNLOAD_CERTIFICATE,
    GIVE_FETCH_PROMO_CODE,
    FIND_USER_IN_EMAIL
} from "@/store/mutation-types";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from "flatpickr/dist/l10n/ru";
import {english} from "flatpickr/dist/l10n/default";
import {email, required, minLength} from "vuelidate/lib/validators";
import Categories from './components/category-popup/index.vue'
import Trees from './components/trees-popup/index.vue'
import { showTextPopup } from '@/helpers/variables/index';
import _ from 'lodash';

export default {
    name: 'sell',
    data() {
        return {
            openSelectMail: false,
            openSelectPhone: false,
            phoneList: [],
            download: true,
            conf: true,
            generatedPromo: false,
            deletedTree: [],
            config: {
                wrap: true,
                altFormat: 'M j, Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                locale: this.$store.getters.urlPrefix === 'ru' ? Russian : english,
                minDate: "today"
            },
            showDeleteIcons: false,
            validationErrors: {},
            giveType: [
                {
                    type: 'give',
                    description: this.$t('giveGift')
                },
                {
                    type: 'charity',
                    description: this.$t('giveCharity')
                }
            ]
        }
    },
    validations: {
        email: {
            email
        },
        conf:{
            required
        },
        phone: {
            minLength: minLength(12),
        }
    },
    components: {
        flatPickr,
        Trees,
        Categories
    },
    computed: {
        ...mapGetters({
            list: `give/list`,
            infoRules: `give/infoRules`,
            category: `give/category`,
            removeTrees: `give/removeTrees`,
            watchCategory: `give/showCategory`,
            watchTrees: `give/showTrees`,
            userList: `give/userList`,
            phone: `give/phone`,
            email: `give/email`,
            notificationTime: `give/notificationTime`,
            ban: `give/ban`,
            frost: `give/frost`,
            giveTypeCheck: `give/giveTypeCheck`,
            userData: `profile/user`,
            userListLoading: `give/userListLoading`,
        }),
        phoneErrors() {
            let error = [];
            if (!this.$v.phone.$dirty) {
                return error;
            }
            if (!this.$v.phone.minLength) {
                error.push(this.$t('validationMinPhone'))
            }
            if (this.validationErrors.phone) {
                this.validationErrors.phone.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        emailErrors() {
            let error = [];
            if (!this.$v.email.$dirty) {
                return error;
            }
            if (!this.$v.email.email) {
                error.push(this.$t('validationEmail'));
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        checkboxErrors() {
            let error = [];
            if (!this.$v.conf.$touch) {
                return error;
            }
            if (!this.$v.conf.required) {
                error.push(this.$t('validationRequired'));
            }
            return error;
        },
        step2Success() {
            if (this.giveTypeCheck.length <= 0) {
                return false
            } else {
                return true
            }

        },
        step3Success() {
            if (this.list) {
                if (this.list.length !== 0 && this.frost !== '' && this.ban !== '') {
                    return true
                }
            }else{
                return false
            }
        },
        disabledPromo(){
            if(this.email.length > 0 || this.phone.length > 0){
                this.generatedPromo = false;
                return true
            }
        }
    },
    watch:{
        generatedPromo(){
            if(this.generatedPromo){
                this.giveResetPromo();
            }
        }
    },
    created(){

    },
    methods: {
        ...mapActions({
            giveTrees: `give/${GIVE_TREES}`,
            charityTrees: `give/${CHARITY_TREES}`,
            fetchPopupTextContent: `give/${GIVE_LOAD_RULES_POPUP}`,
            fetchUserList: `give/${FIND_USER_IN_EMAIL}`,
            fetchPromoCode: `give/${GIVE_FETCH_PROMO_CODE}`,
        }),
        ...mapMutations({
            giveResetPromo: `give/${GIVE_RESET_PROMO}`,
            removeTreesList: `give/${GIVE_REMOVE_TREES}`,
            showTrees: `give/${GIVE_SHOW_TREES}`,
            showCategory: `give/${GIVE_SHOW_CATEGORIES}`,
            giveReset: `give/${GIVE_RESET}`,
            changeFrost: `give/${GIVE_CHANGE_FROST}`,
            changeBan: `give/${GIVE_CHANGE_BAN}`,
            decrementBan: `give/${GIVE_DECREMENT_BAN}`,
            decrementFrost: `give/${GIVE_DECREMENT_FROST}`,
            incrementBan: `give/${GIVE_INCREMENT_BAN}`,
            incrementFrost: `give/${GIVE_INCREMENT_FROST}`,
            checkedType: `give/${GIVE_CHANGE_TYPE_SELECT}`,
            changeNotificationDate: `give/${GIVE_CHANGE_NOTIFICATION_TIME}`,
            changePhone: `give/${GIVE_CHANGE_PHONE}`,
            changeEmail: `give/${GIVE_CHANGE_MAIL}`,
            downloadPdf: `trees/${DOWNLOAD_CERTIFICATE}`,
            setLoadingUserList: `give/${FIND_USER_IN_EMAIL_LOADING}`,
        }),
        querySearchPhone(query){
            this.changePhone(query);
            this.openSelectPhone =  true;
            let list = this.sortList;
            this.phoneList = query ? list.filter(this.createFilterPhone(query)) : list;
            if(this.phoneList.length !== 0){
                if(this.phone === this.phoneList[0].phone){
                    this.openSelectPhone =  false;
                }
            }
        },
        createFilterPhone(query) {
            return link => {
                return link.phone.toLowerCase().includes(query.toLowerCase());
            };
        },
        getEmails: _.debounce(function (email) {
            this.fetchUserList(email);
        }, 800),
        querySearchEmail(query){
            this.openSelectMail =  true;
            this.setLoadingUserList(true);
            this.changeEmail(query);
            if(this.userData.giver){
                if(query.length > 1){
                    this.getEmails(query);
                }
            }
        },
        selectPhone(phone){
            this.openSelectPhone =  true;
            this.changePhone(phone);
        },
        selectEmail(email){
            this.openSelectMail = false;
            this.changeEmail(email);
        },
        unfocusPhone(){
            this.openSelectPhone = false;
            this.$v.phone.$touch();
        },
        unfocusEmail(){
            this.openSelectMail = false;
            this.$v.email.$touch();
        },
        openTextPopup(){
            //show popup and fix body
            showTextPopup(true);
            //content popup
            this.fetchPopupTextContent();
        },
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        submit() {
            if(this.conf){
                if(this.disabledPromo || this.generatedPromo){
                    if(!this.generatedPromo){
                        this.$v.$touch();
                        if (!this.$v.$invalid) {
                            let arr = [];
                            this.list.forEach(t => {
                                let obj = {
                                    phone: this.phone,
                                    email: this.email,
                                    sale_frozen_to: this.ban,
                                    tree_id: t.id,
                                    notification_date: this.notificationTime,
                                    dividend_frozen_to: this.frost,
                                };
                                arr.push(obj);
                            });
                            if(this.giveTypeCheck === 'give'){
                                this.giveTrees(arr)
                                    .then(response => {
                                        this.downloadPdf(response.data.link);
                                        this.$toaster.success(this.$t('toasterSuccessGive'));
                                    }).catch(error => {
                                    if (error.response.status === 422) {
                                        this.$toaster.error(error.response.data.error.message);
                                    }
                                });
                            }else{
                                this.charityTrees(arr)
                                    .then(() => {
                                        this.$toaster.success(this.$t('toasterSuccessGive'));
                                    }).catch(error => {
                                    if (error.response.status === 422) {
                                        this.$toaster.error(error.response.data.error.message);
                                    }
                                });
                            }
                        }
                    }else{
                        let treesArr = [];
                        this.list.forEach(e => {
                            treesArr.push(e.id)
                        });
                        let obj = {
                            tree_ids: treesArr.join(','),
                            sale_frozen_to: this.ban,
                            dividend_frozen_to: this.frost,
                        };
                        this.fetchPromoCode(obj)
                            .then(response => {
                                this.downloadPdf(response.data.link);
                            })
                            .catch(error => {
                                this.$toaster.error(error.response.data.error.message)
                            });
                    }
                }else{
                    this.$toaster.error(this.$t('giveValidationErrorDataUser'));
                }
            }else{
                this.$toaster.error(this.$t('giveValidationErrorCheckTerms'));
            }


        },
        changeDeleteIcons() {
            this.showDeleteIcons = !this.showDeleteIcons;
            if (!this.showDeleteIcons && this.deletedTree.length > 0) {
                this.removeTreesList(this.deletedTree);
                this.deletedTree = [];
            }
        }
    },
    destroyed(){
        this.giveReset();
    }
}
