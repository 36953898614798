import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
    PURCHASES_LIST,
    PURCHASES_SELECT_ITEM
} from "@/store/mutation-types";
import VueAdsPagination from 'vue-ads-pagination';
import { VueAdsPageButton } from 'vue-ads-pagination';
import { transformDate } from '@/helpers/variables/index'
export default{
    name: 'purchases',
    data(){
        return{
            giveTypeCheck: '',
            page: 0,
            payload: {
                limit: 16,
                skip: 0
            },
        }
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    computed: {
        ...mapGetters({
            loading: `purchases/loading`,
            length: `purchases/length`,
            list: `purchases/list`,
            user: `profile/user`,
        }),
    },
    watch: {
        page(){
            this.payload.skip = this.page * this.payload.limit;
            this.sendRequest();
        }
    },
    created(){
        this.fetchList(this.payload);
    },
    methods: {
        ...mapActions({
            fetchList: `purchases/${PURCHASES_LIST}`,
        }),
        ...mapMutations({
           changeItem: `purchases/${PURCHASES_SELECT_ITEM}`
        }),
        sendRequest(){
            this.fetchList(this.payload)
        },
    }
}
