import {$http} from '@/https'
import _ from 'lodash';
import {fixBody} from '@/helpers/variables/index'
import {
  CALCULATE_UPDATE_DEPOSIT_YEAR,
  CALCULATE_UPDATE_COUNT,
  CALCULATE_ADD_TREE,
  CALCULATE_UPDATE_DATE,
  CALCULATE_UPDATE_SEASON,
  CALCULATE_LOAD_TREE_PERSONAL,
  CALCULATE_PERSONAL_CHANGE_CHECK,
  CALCULATE_PERSONAL_CHANGE_ALL,
  CALCULATE_UPDATE_PRICE,
  SHOW_LOADING_PERSONAL_AREA_CALCULATE,
  CALCULATE_DELETE_TREE,
  CALCULATE_ADD_TREE_PERSONAL,
  CALCULATE_RESET_LIST_TREE,
  CALCULATE_CURRENT_CATEGORY_PERSONAL,
  CALCULATE_INCREMENT_COUNT,
  CALCULATE_DECREMENT_COUNT,
  CALCULATE_CHANGE_COUNT,
  CALCULATE_DECREMENT_PRICE,
  CALCULATE_INCREMENT_PRICE,
  CALCULATE_CHANGE_PRICE,
  CALCULATE_POPUP_LIST_LENGTH,
  CALCULATE_POPUP_CHANGE_OFFSET,
  SHOW_FILTER_TREE_POPUP,
  LOAD_TREE_FILTER,
  CHANGE_FILTER_SEARCH_PARAMS,
  LENGTH_LIST_TREE_SHOP,
  SELECT_TREES_IN_SHOP,
  SHOW_CALCULATE_FILTER_RESULT,
  CALCULATE_SHOP_CHANGE_CHECK,
  CALCULATE_SHOP_CHANGE_ALL,
  CALCULATE_CHANGE_COUNT_PRICE,
  RESET_LIST_PERSONAL_TREES,
  RESET_LIST_SHOP_TREES,
  RESET_CALCULATE,

  SETTING_CALCULATE
} from '../mutation-types'
import Vue from "vue";

const regex = /^[0-9]*$/;

const state = {
  selectTreesShop: [],
  selectTreesPersonal: [],
  lengthListTreeShop: 0,
  showCalculateFilter: false,
  showCalculateFilterResult: false,
  listShop: null,
  searchParams: {
    season: '',
    minYear: '',
    maxYear: '',
    minPrice: '',
    maxPrice: '',
    limit: 20,
    offset: 0
  },
  checkedTrees: [],
  listTreePersonal: null,
  deleteTreeInListLocal: [],
  idTree: 9999999,
  loadPersonalArea: false,
  list: [],
  paramsTree: {
    depositYears: 0
  },
  currentCategory: null,
  currentCategoryShop: null,
  lengthListTree: 0,
  offset: 0,
  limit: 20

};

const getters = {
  selectTreesShop: state => state.selectTreesShop,
  selectTreesPersonal: state => state.selectTreesPersonal,
  searchParams: state => state.searchParams,
  listShop: state => state.listShop,
  showCalculateFilter: state => state.showCalculateFilter,
  showCalculateFilterResult: state => state.showCalculateFilterResult,
  lengthListTreeShop: state => state.lengthListTreeShop,
  lengthListTree: state => state.lengthListTree,
  currentCategory: state => state.currentCategory,
  currentCategoryShop: state => state.currentCategoryShop,
  list: state => state.list,
  paramsTree: state => state.paramsTree,
  listTreePersonal: state => state.listTreePersonal,
  checkedTrees: state => state.checkedTrees,
  loadPersonalArea: state => state.loadPersonalArea,
  depositYears: state => state.paramsTree.depositYears,
  totalTreePrice: state => state.list.map(elem => {
    let result = elem.price * elem.count;
    state.totalSumTree += Math.round(result);
    return result;
  }),
  updatePriceTree: state => state.list.map(elem => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let deposit = state.paramsTree.depositYears;
    let currentPriceTree = state.paramsTree.defaultPriceTree;
    let defaultPriceTree = state.paramsTree.defaultPriceTree;
    if (deposit < 1) {
      return 0
    }
    let threeTotalYear = 0;

    if (currentYear >= elem.year) {
      threeTotalYear = currentYear + deposit - elem.year;
    } else {
      threeTotalYear = deposit;
    }

    if (elem.year > currentYear) {
      threeTotalYear = deposit - (elem.year - currentYear)
    }

    for (let i = 0; i <= threeTotalYear; i++) {
      if (i > 0 && i <= 4) {
        currentPriceTree = currentPriceTree * state.paramsTree.upPriceTree
      } else if (i <= 10 && i >= 5) {
        currentPriceTree = currentPriceTree * state.paramsTree.upPriceTreeAdult
      }
    }
    let result = (elem.count * currentPriceTree);
    if (elem.count > 0 && threeTotalYear !== 0) {
      result = (result - (defaultPriceTree * elem.count)) * 100;
      if (result < 0) {
        result = 0
      }
      let resultTotal = Math.round(result);
      if (currentYear - elem.year > 10) {
        return 0
      }
      return (+resultTotal / 100);
    } else {
      return 0;
    }
  }),
  incomeTreesAllDeposit: state => state.list.map(elem => {
    let deposit = state.paramsTree.depositYears;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const priceOil = (state.paramsTree.priceOil / 1000) / 5;
    let yieldCurrent = 0;
    let yieldTotal = 0;

    function yieldCurrentTime(i) {
      return state.paramsTree.upperYieldTree.find((elem, index) => {
        if (i === index) {
          return elem;
        }
      })
    }

    let yearsTreeThisMoment = 0;
    if (currentYear > elem.year) {
      yearsTreeThisMoment = (currentYear - elem.year);
    } else {
      yearsTreeThisMoment = 0;
    }
    let iterationValue = 0;
    let totalSumIteration = 0;
    let totalSum = 0;

    //before income from current year
    if (deposit > 0) {
      for (let i = 1; i <= yearsTreeThisMoment; i++) {
        if (i <= 10 && i >= 4) {
          yieldCurrent = yieldCurrentTime(i);
          yieldTotal = yieldTotal + yieldCurrentTime(i);
          totalSumIteration = Math.round(((1 - state.paramsTree.commissionInvestor) * (((yieldCurrent * elem.count) * priceOil))) * 10000) / 10000;
          iterationValue++;
        } else if (i < 4) {
          totalSum = 0;
          iterationValue++;
        } else {
          yieldTotal = yieldTotal + yieldCurrentTime(9);
          totalSum = 0;
          iterationValue = 10;
        }

      }
    }

    if (elem.year > currentYear) {
      deposit = deposit - (elem.year - currentYear)
    }
    //after income from current year
    for (let i = iterationValue; i <= iterationValue + deposit; i++) {
      if (i <= 10 && i >= 4) {
        yieldCurrent = yieldCurrentTime(i);
        yieldTotal = yieldTotal + yieldCurrentTime(i);
        totalSumIteration = Math.round((1 - state.paramsTree.commissionInvestor) * ((yieldCurrent * elem.count) * priceOil) * 10000) / 10000;
        totalSum += totalSumIteration;
      } else if (i < 4) {
        totalSum = 0;
      } else {
        yieldTotal = yieldTotal + yieldCurrentTime(9);
        totalSumIteration = Math.round((1 - state.paramsTree.commissionInvestor) * ((yieldCurrent * elem.count) * priceOil) * 10000) / 10000;
        totalSum += totalSumIteration;
      }
    }
    if (deposit > 0) {
      return {
        amount: totalSum,
        oil: ((yieldTotal * 0.6) / 5) // 0.6 - commission platform 40%
      }
    } else {
      return {
        amount: 0,
        oil: 0
      }
    }
  })
};

const actions = {
  [CALCULATE_LOAD_TREE_PERSONAL]: async ({commit}, payload) => {
    try {
      const response = await $http.get(`v1/trees?seasons=${payload.categories}&limit=${payload.limit}&skip=${state.offset}`);
      commit(RESET_LIST_PERSONAL_TREES);
      commit(CALCULATE_POPUP_LIST_LENGTH, response.data.meta.count);
      commit(CALCULATE_LOAD_TREE_PERSONAL, response.data.data);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  [LOAD_TREE_FILTER]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/shop/filter?season=${state.searchParams.season}&planting_year=${state.searchParams.minYear}${state.searchParams.maxYear === '' ? '' : '-'}${state.searchParams.maxYear}&price=${state.searchParams.minPrice}${state.searchParams.maxPrice === '' ? '' : '-'}${state.searchParams.maxPrice}&limit=${state.searchParams.limit}&skip=${state.searchParams.offset}`);
      commit(RESET_LIST_SHOP_TREES);
      commit(LOAD_TREE_FILTER, response.data.data);
      commit(LENGTH_LIST_TREE_SHOP, response.data.meta.count);
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [CALCULATE_LOAD_TREE_PERSONAL](state, listTreePersonal) {
    let newArr = listTreePersonal.map(elem => {
      //push years
      let arrYears = [];
      for (let i = elem.year - 15; i <= elem.year + 50; i++) {
        arrYears.push(i)
      }
      elem.years = arrYears;
      //push seasons
      elem.price = Math.round(elem.current_price);
      elem.count = 1;
      elem.countPrice = elem.price * elem.count;
      let param = state.selectTreesPersonal.find(t => {
        if (elem.id === t.id) {
          return true
        } else {
          return false
        }
      });
      elem.check = param === undefined ? false : true;
      return elem;
    });
    state.listTreePersonal = newArr;
  },
  [SETTING_CALCULATE](state, obj) {
    Object.assign(state.paramsTree, obj);
  },
  [CALCULATE_PERSONAL_CHANGE_CHECK](state, obj) {
    obj.check = !obj.check;
    if (obj.check) {
      state.selectTreesPersonal.push(obj);
    } else {
      let index = _.findIndex(state.selectTreesPersonal, e => e.id === obj.id);
      state.selectTreesPersonal.splice(index, 1);
    }
  },
  [CALCULATE_PERSONAL_CHANGE_ALL](state, status) {
    if (status) {
      let notCheckedTrees = _.filter(state.listTreePersonal, e => !e.check);
      let checkedTreesAll = state.listTreePersonal.map(e => {
        e.check = true;
        return e;
      });
      state.listTreePersonal = checkedTreesAll;
      state.selectTreesPersonal.push(...notCheckedTrees);
    } else {
      let resetTreesAll = state.listTreePersonal.map(e => {
        e.check = false;
        return e;
      });
      state.listTreePersonal = resetTreesAll;
      state.listTreePersonal.forEach((t) => {
        _.remove(state.selectTreesPersonal, elem => {
          return elem.id === t.id
        });
      });
    }
  },
  [CALCULATE_ADD_TREE_PERSONAL](state, checkedTrees) {
    let notCheckedTrees = _.filter(state.listTreePersonal, e => !e.check);
    notCheckedTrees.forEach((t) => {
      _.remove(state.list, elem => {
        return elem.id === t.id
      });
    });
    state.list.push(...checkedTrees);
    state.list = _.uniqBy(state.list, 'id');
  },
  [CALCULATE_DELETE_TREE](state, listDeleteTree) {
    if (state.list.length !== 0) {
      for (let i = state.list.length; i >= 0; i--) {
        for (let j = 0; j < listDeleteTree.length; j++) {
          if (state.list[i] === listDeleteTree[j]) {
            state.list.splice(i, 1);
            state.selectTreesPersonal.splice(i, 1);
            state.selectTreesShop.splice(i, 1);
          }
        }
      }
    }
  },
  [CALCULATE_ADD_TREE](state) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMounth = currentDate.getMonth();
    let season = 0;
    if (currentMounth + 1 <= 5) {
      season = 2
    } else {
      season = 4
    }
    let arrYears = [];
    for (let i = currentYear - 15; i <= currentYear + 50; i++) {
      arrYears.push(i)
    }
    let obj = {
      year: currentYear,
      season: season,
      id: state.idTree++,
      price: state.paramsTree.defaultPriceTree,
      count: 1,
      countPrice: state.paramsTree.defaultPriceTree,
      years: arrYears,
    };
    state.list.push(obj);
  },
  [CALCULATE_UPDATE_DEPOSIT_YEAR](state, depositYear) {
    if (regex.test(depositYear)) {
      state.paramsTree.depositYears = +depositYear
    }
  },
  [CALCULATE_UPDATE_DATE](state, payload) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    let currentPriceTree = state.paramsTree.defaultPriceTree;
    let threeTotalYear = 0;

    threeTotalYear = currentYear - payload.year;
    if (threeTotalYear > 0) {
      for (let i = 0; i <= threeTotalYear; i++) {
        if (i > 0 && i <= 4) {
          currentPriceTree = currentPriceTree * state.paramsTree.upPriceTree
        } else if (i <= 10 && i >= 5) {
          currentPriceTree = currentPriceTree * 1.12
        }
      }
      currentPriceTree = Math.round(currentPriceTree)
    }

    state.list.find(elem => {
      if (elem.id === payload.id) {
        elem.year = payload.year;
        elem.price = currentPriceTree;
        elem.countPrice = currentPriceTree * elem.count;
      }
    });
  },
  [CALCULATE_UPDATE_SEASON](state, payload) {
    state.list.find(elem => {
      if (elem.id === payload.id) {
        elem.season = payload.season
      }
    });
  },
  [CALCULATE_UPDATE_PRICE](state, payload) {
    state.list.find(elem => {
      if (elem.id === payload.id) {
        if (regex.test(payload.price)) {
          elem.price = payload.price;
        }
      }
    });
  },
  [CALCULATE_UPDATE_COUNT](state, payload) {
    state.list.find(elem => {
      if (elem.id === payload.id) {
        if (regex.test(payload.count)) {
          elem.count = payload.count;
        }
      }
    });
  },
  [SHOW_LOADING_PERSONAL_AREA_CALCULATE](state, loadPersonalArea) {
    state.loadPersonalArea = loadPersonalArea;
    fixBody(loadPersonalArea)
  },
  [CALCULATE_RESET_LIST_TREE](state) {
    state.list = [];
  },
  [CALCULATE_CURRENT_CATEGORY_PERSONAL](state, category) {
    state.currentCategory = null;
    let payload = {
      categories: null,
      limit: state.limit,
      offset: state.offset,
    };
    let newArr = [];
    for (let i = 0; i < category.length; i++) {
      let newVal = '';
      let year = category[i].planting_year;
      let season = category[i].season;
      newVal = year + '-' + season;
      newArr.push(newVal.toString())
    }
    payload.categories = newArr.join();
    payload.limit = state.limit;
    payload.offset = state.offset;
    state.currentCategory = payload;
  },
  [CALCULATE_POPUP_LIST_LENGTH](state, count) {
    state.lengthListTree = count;
  },
  [CALCULATE_POPUP_CHANGE_OFFSET](state, val) {
    state.offset = val;
  },
  [CALCULATE_INCREMENT_COUNT](state, id) {
    let obj = state.list.find(elem => {
      return elem.id === id
    });
    if (obj.count >= 0) {
      obj.count++;
      obj.countPrice = obj.price * obj.count;
    }
    let index = _.findIndex(state.list, obj);
    Vue.set(state.list, index, obj);
  },
  [CALCULATE_DECREMENT_COUNT](state, id) {
    let obj = state.list.find(elem => {
      return elem.id === id
    });
    if (obj.count > 0) {
      obj.count--;
      obj.countPrice = obj.price * obj.count;
    }
    let index = _.findIndex(state.list, obj);
    Vue.set(state.list, index, obj);
  },
  [CALCULATE_CHANGE_COUNT](state, payload) {

    if (regex.test(payload.count)) {
      let obj = state.list.find(elem => {
        return elem.id === payload.id
      });

      obj.count = payload.count;
      obj.countPrice = obj.price * obj.count;
      let index = _.findIndex(state.list, obj);
      Vue.set(state.list, index, obj);
    }
  },
  [CALCULATE_INCREMENT_PRICE](state, id) {
    let obj = state.list.find(elem => {
      return elem.id === id
    });
    if (obj.price >= 0) {
      obj.price++;
      obj.countPrice = obj.price * obj.count;
    }
    let index = _.findIndex(state.list, obj);
    Vue.set(state.list, index, obj);
  },
  [CALCULATE_DECREMENT_PRICE](state, id) {
    let obj = state.list.find(elem => {
      return elem.id === id
    });
    if (obj.price > 0) {
      obj.price--;
      obj.countPrice = obj.price * obj.count;
    }
    let index = _.findIndex(state.list, obj);
    Vue.set(state.list, index, obj);
  },
  [CALCULATE_CHANGE_PRICE](state, payload) {
    if (regex.test(payload.price)) {
      let obj = state.list.find(elem => {
        return elem.id === payload.id
      });
      obj.countPrice = payload.price * obj.count;
      obj.price = payload.price;
      let index = _.findIndex(state.list, obj);
      Vue.set(state.list, index, obj);
    }
  },
  [CALCULATE_CHANGE_COUNT_PRICE](state, payload) {
    let obj = state.list.find(elem => {
      return elem.id === payload.id
    });
    if (payload.price < state.paramsTree.defaultPriceTree) {
      obj.countPrice = state.paramsTree.defaultPriceTree;
    } else {
      obj.countPrice = payload.price;
    }
    obj.count = Math.floor(obj.countPrice / obj.price);
    setTimeout(() => {
      obj.countPrice = obj.price * obj.count;
      let index = _.findIndex(state.list, obj);
      Vue.set(state.list, index, obj);
    }, 50)
  },
  [SHOW_FILTER_TREE_POPUP](state, status) {
    fixBody(status);
    state.showCalculateFilter = status;
  },
  [LOAD_TREE_FILTER](state, list) {
    let newArr = list.map(elem => {
      //push years
      let arrYears = [];
      for (let i = elem.year - 15; i <= elem.year + 50; i++) {
        arrYears.push(i)
      }
      elem.years = arrYears;
      elem.count = 1;
      elem.countPrice = elem.price * elem.count;
      let param = state.selectTreesShop.find(t => {
        if (elem.id === t.id) {
          return true
        } else {
          return false
        }
      });
      elem.check = param !== undefined;
      return elem;
    });
    state.listShop = newArr;
  },
  [CALCULATE_SHOP_CHANGE_CHECK](state, obj) {
    obj.check = !obj.check;
    if (obj.check) {
      state.selectTreesShop.push(obj);
    } else {
      let index = _.findIndex(state.selectTreesShop, e => e.id === obj.id);
      state.selectTreesShop.splice(index, 1);
    }
  },
  [CALCULATE_SHOP_CHANGE_ALL](state, status) {
    if (status) {
      let notCheckedTrees = _.filter(state.listShop, e => !e.check);
      let checkedTreesAll = state.listShop.map(e => {
        e.check = true;
        return e;
      });
      state.listShop = checkedTreesAll;
      state.selectTreesShop.push(...notCheckedTrees);
    } else {
      let resetTreesAll = state.listShop.map(e => {
        e.check = false;
        return e;
      });
      state.listShop = resetTreesAll;
      state.listShop.forEach((t) => {
        _.remove(state.selectTreesShop, elem => {
          return elem.id === t.id
        });
      });
    }
  },
  [SELECT_TREES_IN_SHOP](state, list) {
    let notCheckedTrees = _.filter(state.listShop, e => !e.check);
    notCheckedTrees.forEach((t) => {
      _.remove(state.list, elem => {
        return elem.id === t.id
      });
    });
    state.list.push(...list);
    state.list = _.uniqBy(state.list, 'id');
  },
  [CHANGE_FILTER_SEARCH_PARAMS](state, params) {
    state.searchParams = params;
  },
  [LENGTH_LIST_TREE_SHOP](state, length) {
    state.lengthListTreeShop = length;
  },
  [SHOW_CALCULATE_FILTER_RESULT](state, status) {
    fixBody(status);
    state.showCalculateFilterResult = status;
  },
  [RESET_LIST_PERSONAL_TREES](state) {
    state.listTreePersonal = null;
    state.offset = 0;
  },
  [RESET_LIST_SHOP_TREES](state) {
    state.listShop = null;
    state.searchParams.offset = 0;
  },
  [RESET_CALCULATE](state) {
    state.selectTreesShop = [];
    state.selectTreesPersonal = [];
    state.lengthListTreeShop = 0;
    state.showCalculateFilter = false;
    state.showCalculateFilterResult = false;
    state.listShop = null;
    state.searchParams.season = '';
    state.searchParams.minYear = '';
    state.searchParams.maxYear = '';
    state.searchParams.minPrice = '';
    state.searchParams.maxPrice = '';
    state.searchParams.limit = 20;
    state.searchParams.offset = 0;
    state.checkedTrees = [];
    state.listTreePersonal = null;
    state.deleteTreeInListLocal = [];
    state.idTree = 1;
    state.loadPersonalArea = false;
    state.list = [];
    state.currentCategory = null;
    state.currentCategoryShop = null;
    state.lengthListTree = 0;
    state.offset = 0;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
