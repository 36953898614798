import { $http } from '@/https'
import { globalLoader, fixBody } from '@/helpers/variables/index'
import {
    SEND_CODE_CONFIRM,
    SET_CODE_CONFIRM_STATUS,
    LOAD_USER_DATA,
    UPDATE_USER_CONFIDANT,
    LOAD_USER_CONFIDANT,
    SET_USER_CONFIDANT,
    UPDATE_USER_DATA,
    UPDATE_USER_PASSWORD,
    CHANGE_LOADING_USER_DATA,
    TWO_AUTH_DATA,
    TWO_AUTH_ON,
    TWO_AUTH_OFF,
    RESET_TWO_AUTH_DATA,
    SHOW_POPUP_TWO_AUTH_CODE,
    FAIL_REQUEST,
    PERSONAL_LOADER_DATA,
    PERSONAL_LOADER_PASSWORD,
    PERSONAL_CHANGE_EDIT_DATA_STATUS,
    PERSONAL_CHANGE_EDIT_PASSWORD_STATUS,
    CHANGE_OLD_PASSWORD,
    CHANGE_NEW_PASSWORD,
    CHANGE_TEXT_CONFIDANT,
    CHANGE_TEXT_FUTURES_BALANCE,
    CHANGE_CONFIRMATION_PASSWORD,
    PERSONAL_RESET_PASSWORD,
    PERSONAL_VALIDATIONS_ERRORS,
    PERSONAL_CLEAR_ERRORS,
    POPUP_ENABLE_TWO_FACTOR,
    POPUP_CODE_CONFIRM,
    POPUP_REMOVE_PROFILE,
    REMOVE_USER,
    FETCH_USER_SALES,
    FETCH_USER_SALES_RESET,
    CHECK_POPUP_INTRO
} from '../../mutation-types';

const state = {
    user: null,
    userConfidant: null,
    popupRemoveProfile: false,
    loading: false,
    twoFactorData: null,
    codeTwoAuth: '',
    failRequest: {},
    showPopupEnterCode: false,
    showPopupEnableTwoFactor: false,
    showPopupCodeConfirm: false,
    showPopupFuturesBalance: false,
    setCodeConfirmStatus:false,
    loaderData: false,
    loaderPassword: false,
    editData: false,
    editPassword: false,
    oldPassword: '',
    passwordConfirmation: '',
    newPassword: '',
    validationErrors: {},
    clearErrors: false,
    showPopupInfoConfidant: false,
    userSales: null,
};

const getters = {
    userSales: state => state.userSales,
    userConfidant: state => state.userConfidant,
    showPopupInfoConfidant: state => state.showPopupInfoConfidant,
    userConfidantCode: state => state.userConfidant,
    setCodeConfirmStatus: state => state.setCodeConfirmStatus,
    popupRemoveProfile: state => state.popupRemoveProfile,
    showPopupCodeConfirm: state => state.showPopupCodeConfirm,
    showPopupFuturesBalance: state => state.showPopupFuturesBalance,
    clearErrors: state => state.clearErrors,
    showPopupEnableTwoFactor: state => state.showPopupEnableTwoFactor,
    validationErrors: state => state.validationErrors,
    passwordConfirmation: state => state.passwordConfirmation,
    newPassword: state => state.newPassword,
    oldPassword: state => state.oldPassword,
    user: state => state.user,
    oldPasswordError: state => state.oldPasswordError,
    editData: state => state.editData,
    editPassword: state => state.editPassword,
    loaderData: state => state.loaderData,
    loaderPassword: state => state.loaderPassword,
    failRequest: state => state.failRequest,
    twoFactorData: state => state.twoFactorData,
    loading: state => state.loading,
    showPopupEnterCode: state => state.showPopupEnterCode,
};

const actions = {
    [LOAD_USER_DATA]: async ({ commit }) => {
        try {
            commit(CHANGE_LOADING_USER_DATA, true);

            const response = await $http.get('v1/me?include=setting');
            commit(LOAD_USER_DATA, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }finally {
            commit(CHANGE_LOADING_USER_DATA, false);
        }
    },
    [LOAD_USER_CONFIDANT]: async ({ commit }) => {
        try {
            commit(CHANGE_LOADING_USER_DATA, true);
            const response = await $http.get('v1/confidant');
            console.log('1111')
            console.log(response)
            commit(SET_CODE_CONFIRM_STATUS, response.data.data.is_confirmed);
            commit(SET_USER_CONFIDANT, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }finally {
            commit(CHANGE_LOADING_USER_DATA, false);
        }
    },
    [FETCH_USER_SALES]: async ({ commit }) => {
        try {
            commit(FETCH_USER_SALES_RESET);

            const response = await $http.get('v1/user/sales');
            commit(FETCH_USER_SALES_RESET);
            commit(FETCH_USER_SALES, response.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [UPDATE_USER_DATA]: async ({ commit }, payload) => {
        try {
            commit(PERSONAL_LOADER_DATA, true);
            return $http.post('v1/users/update', payload);
        }catch (e) {
            throw e;
        }finally {
            commit(PERSONAL_LOADER_DATA, false);
        }
    },
    [UPDATE_USER_CONFIDANT]: async ({ commit }, payload) => {
        try {
            commit(PERSONAL_LOADER_DATA, true);
            let resp = await $http.post('v1/confidant', payload);
            if(resp.data.is_confirmed === false){
                commit(SET_CODE_CONFIRM_STATUS, false);
            }
            return resp
        }catch (e) {
            throw e;
        }finally {
            commit(PERSONAL_LOADER_DATA, false);
        }
    },
    [SEND_CODE_CONFIRM]: async ({ commit }, payload) => {
        try {
            commit(PERSONAL_LOADER_DATA, true);
            const response = await $http.post('v1/confidant/confirm', payload);
            commit(SET_CODE_CONFIRM_STATUS, response.data.is_confirmed);
            return response
        }catch (e) {
            throw e;
        }finally {
            commit(PERSONAL_LOADER_DATA, false);
        }
    },
    [REMOVE_USER]: async ({ commit }) => {
        globalLoader(true);
        try {
            const result =  await $http.get('v1/account/delete');
            commit(POPUP_REMOVE_PROFILE, false);
            return result;
        }catch (e) {
            throw e
        }finally {
            globalLoader(false)
        }
    },
    [UPDATE_USER_PASSWORD]: async ({ commit }, payload) => {
        try {
            commit(PERSONAL_LOADER_PASSWORD, true);

            const response = await $http.post('v1/users/change_password', payload);
            commit(PERSONAL_CLEAR_ERRORS);
            return response;
        }catch (e) {
            throw e;
        }finally {
            commit(PERSONAL_LOADER_PASSWORD, false);
        }
    },
    [TWO_AUTH_DATA]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/enable2factor');
            commit(TWO_AUTH_DATA, response.data);
            return response;
        }catch (e) {
          throw e;
        }
    },
    [TWO_AUTH_ON]: async ({ commit }, payload) => {
        try {
            globalLoader(true);

            return await $http.post('v1/complete2factor', payload);
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [TWO_AUTH_OFF]: async ({ commit }, payload) => {
        try {
            globalLoader(true);

            return await $http.post('v1/disable2factor', payload);
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [CHECK_POPUP_INTRO]: async ({ commit }, payload) => {
        try {
            return await $http.post('v1/checked', payload);
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [LOAD_USER_DATA](state, user) {
        state.user = user;
    },
    [SET_USER_CONFIDANT](state,  confidant) {
        state.userConfidant = confidant;
    },
    [FETCH_USER_SALES](state, data) {
        state.userSales = data;
    },
    [FETCH_USER_SALES_RESET](state) {
        state.userSales = null;
    },
    [POPUP_REMOVE_PROFILE](state, status) {
        state.popupRemoveProfile = status;
        fixBody(status)
    },
    [UPDATE_USER_DATA](state, newUser) {
        state.user = newUser;
    },
    [UPDATE_USER_CONFIDANT](state, newConfidant) {
        state.userConfidant = newConfidant;
    },
    [SEND_CODE_CONFIRM](state, status) {
        state.userConfidantCode = status;
    },
    [CHANGE_LOADING_USER_DATA](state, status) {
        state.loading = status;
    },
    [TWO_AUTH_DATA](state, data) {
        state.twoFactorData = data;
    },
    [RESET_TWO_AUTH_DATA](state) {
        state.twoFactorData = null;
    },
    [CHANGE_TEXT_CONFIDANT](state,data) {
        state.showPopupInfoConfidant = data;
    },
    [CHANGE_TEXT_FUTURES_BALANCE](state,data) {
        state.showPopupFuturesBalance = data;

    },
    [SHOW_POPUP_TWO_AUTH_CODE](state, status) {
        state.showPopupEnterCode = status;
    },
    [FAIL_REQUEST](state, data) {
        state.failRequest = data;
    },
    [PERSONAL_LOADER_DATA](state, status) {
        state.loaderData = status;
    },
    [PERSONAL_LOADER_PASSWORD](state, status) {
        state.loaderPassword = status;
    },
    [PERSONAL_CHANGE_EDIT_DATA_STATUS](state) {
        state.editData = !state.editData;
    },
    [PERSONAL_CHANGE_EDIT_PASSWORD_STATUS](state) {
        state.editPassword = !state.editPassword;
    },
    [CHANGE_OLD_PASSWORD](state, oldPassword) {
        state.oldPassword = oldPassword;
    },
    [CHANGE_NEW_PASSWORD](state, newPassword) {
        state.newPassword = newPassword;
    },
    [CHANGE_CONFIRMATION_PASSWORD](state, passwordConfirmation) {
        state.passwordConfirmation = passwordConfirmation;
    },
    [PERSONAL_VALIDATIONS_ERRORS](state, errors) {
        state.validationErrors = errors;
    },
    [PERSONAL_CLEAR_ERRORS](state) {
        state.clearErrors = !state.clearErrors;
    },
    [POPUP_ENABLE_TWO_FACTOR](state, status) {
        state.showPopupEnableTwoFactor = status;
        fixBody(status)
    },
    [POPUP_CODE_CONFIRM](state, status) {
        state.showPopupCodeConfirm = status;
        fixBody(status)
    },
    [SET_CODE_CONFIRM_STATUS](state, status) {
        state.setCodeConfirmStatus = status;
    },
    [PERSONAL_RESET_PASSWORD](state) {
        state.oldPassword = '';
        state.newPassword = '';
        state.passwordConfirmation = '';
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
