import Vue from 'vue';
import Router from 'vue-router';

import Home from './modules/home/index.vue'
import CalculateIncome from './modules/calculateincome/index.vue';
import BuyTree from './modules/buy-tree/index.vue';
import FieldMap from './modules/map-fields/index.vue';
import News from './modules/news/index.vue';
import NewsDetail from './modules/news/components/news-detail/index.vue';
import Faq from './modules/faq/index.vue';
import Search from './modules/search/index.vue';
import AboutUs from './modules/about-us/index.vue';
import Investor from './modules/investor/index.vue';
import GalleryPhoto from './modules/gallery-albums/components/gallery-photo/index.vue';
import GalleryVideo from './modules/gallery-albums/components/gallery-video/index.vue';
import GalleryAlbums from './modules/gallery-albums/index.vue';
import NotFound from './components/404/index';
import RestorePassword from './components/restore-password/index.vue';
import SliderChange from './components/slider-change/index.vue';
import Notifications from './modules/notifications/index.vue';
import PrivacyPolicy from './modules/privacy-policy/index.vue';
import TermsOfUse from './modules/terms-of-use/index.vue';
import Shop from './modules/shop/index.vue';
import ShopYear from './modules/shop-year/index.vue';
import Guarantees from './modules/guarantees/index.vue';
import HowItWorks from './modules/how-it-works/index.vue'
import Contacts from './modules/contacts/index.vue'
import GiftTree from './modules/gift-tree/index.vue'
import ExchangeTree from './modules/exchange-tree/index.vue'
import ExchangeTreeDetail from './modules/exchange-tree/components/detail/index.vue'
import PaymentReview from './modules/payment-review/index.vue'
import PaymentError from './modules/payment-error/index.vue'
import DynamicPage from './modules/dynamic-page/index.vue'
import LegalDocuments from './modules/legal-documents/index.vue'
import Strimm from './modules/strimm/index.vue'
import Promotions from './modules/promotions/index.vue'
import Promotion from './modules/promotions/detail/index.vue'
import StarPartners from './modules/star-partners/index.vue'


//personal
import Personal from './modules/personal/index.vue';
import BuyForGift from './modules/personal/components/buyForGift/index.vue';
import Trees from './modules/personal/components/trees/index.vue';
import TreesDetail from './modules/personal/components/trees/components/detail/index.vue';
import Map from './modules/personal/components/map/index.vue';
import Profile from './modules/personal/components/profile/index.vue';
import Status from './modules/personal/components/status/index.vue';
import BuyDefault from './modules/personal/components/buyDefaultTree/index.vue';
import CancelPutUpGift from './modules/personal/components/cancelPutUpGift/index.vue';
import PutUpGift from './modules/personal/components/putUpGift/index.vue';
import Token from './modules/personal/components/token/index.vue';
import TakeOff from './modules/personal/components/takeOff/index.vue';
import GiftInfo from './modules/personal/components/giftInfo/index.vue';
import Give from './modules/personal/components/give/index.vue';
import Sell from './modules/personal/components/sell/index.vue';
import Field from './modules/personal/components/field/index.vue';
import TopUpWallet from './modules/personal/components/TopUpWallet/index.vue';
import FieldDetail from './modules/personal/components/field/components/detail/index.vue';
import History from './modules/personal/components/history/index.vue';
import WithdrawalHistory from './modules/personal/components/withdrawal-history/index.vue';
import WithdrawalMoney from './modules/personal/components/withdrawal-money/index.vue';
import Purchases from './modules/personal/components/purchases/index.vue';
import CancelGiftTree from './modules/personal/components/cancelGetGift/index.vue';
import GetGiftTree from './modules/personal/components/getGift/index.vue';
import PurchasesDetail from './modules/personal/components/purchases/components/purchases-details/index.vue';
import store from './store'

const ifAuthenticated = (to, from, next) => {
    if (store.state.auth.isAuthentificated) {
        next();
        return
    }
    next('/')
};


Vue.use(Router);


const router =  new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/:lang(.{2})?/',
            name: 'home',
            component: Home,
        },
        {
            path: '/:lang?/buy-tree',
            name: 'buy-tree',
            component: BuyTree,
            // beforeEnter: (to, from, next) => {
            //     next('/');
            //     return;
            // },
        },
        {
            path: '/:lang?/about-magazine-biodeposit',
            name: 'about-us',
            component: AboutUs,
        },
        {
            path: '/:lang?/privacy-policy',
            name: 'privacy-policy',
            component: PrivacyPolicy,
        },
        {
            path: '/:lang?/promotion',
            name: 'promotion',
            component: Promotions,
        },
        {
            path: '/:lang?/promotion/:slug',
            name: 'promotion.detail',
            component: Promotion,
        },
        {
            path: '/:lang?/page/:slug',
            name: 'dynamic-page',
            component: DynamicPage,
        },
        {
            path: '/:lang?/legal-documents',
            name: 'legal-documents',
            component: LegalDocuments,
        },
        {
            path: '/:lang?/terms-of-use',
            name: 'terms-of-use',
            component: TermsOfUse,
        },
        {
            path: '/:lang?/gallery-photo/:id',
            name: 'gallery-photo',
            component: GalleryPhoto,
        },
        {
            path: '/:lang?/gallery-video/:id',
            name: 'gallery-video',
            component: GalleryVideo,
        },
        {
            path: '/:lang?/gallery-albums',
            name: 'gallery-albums',
            component: GalleryAlbums,
        },
        {
            path: '/:lang?/news',
            name: 'news',
            component: News,
        },
        {
            path: '/:lang?/news/:id',
            name: 'news-detail',
            component: NewsDetail,
        },
        {
            path: '/:lang?/faq',
            name: 'faq',
            component: Faq,
        },
        {
            path: '/:lang?/map-fields',
            name: 'map-fields',
            component: FieldMap,
        },
        {
            path: '/:lang?/calculateincome',
            name: 'calculateincome',
            component: CalculateIncome,
        },
        {
            path: '/:lang?/404',
            name: 'not-found',
            component: NotFound,
        },
        {
            path: '/:lang?/guarantees',
            name: 'guarantees',
            component: Guarantees,
        },
        {
            path: '/:lang?/How-it-works',
            name: 'howItWorks',
            component: HowItWorks,
        },
        {
            path: '/:lang?/star-partners',
            name: 'star-partners',
            component: StarPartners,
        },
        {
            path: '/:lang?/Contacts',
            name: 'contacts',
            component: Contacts,
        },
        {
            path:'/:lang?/slider-change',
            name: 'slider-change',
            component: SliderChange,
        },
        {
            path: '/:lang?/restore',
            name: 'restore',
            component: RestorePassword,
        },
        {
            path: '/:lang?/investor',
            name: 'investor',
            component: Investor,
        },
        {
            path: '/:lang?/search',
            name: 'search',
            component: Search,
        },
        {
            path: '/:lang?/strimm',
            name: 'strimm',
            component: Strimm,
        },
        {
            path: '/:lang?/notifications',
            name: 'notifications',
            component: Notifications,
        },
        //TODO Временно закрыли тристор
        {
            path: '/:lang?/shop',
            name: 'shop',
            component: Shop,
        },
        {
            path: '/:lang?/shop/:year',
            name: 'shop-year',
            component: ShopYear,
        },
        {
            path: '/:lang?/gift-tree',
            name: 'giftTree',
            component: GiftTree,
        },
        {
            path: '/:lang?/exchange',
            name: 'exchange',
            component: ExchangeTree,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/:lang?/exchange/:id',
            name: 'exchange.detail',
            component: ExchangeTreeDetail,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/:lang?/payment-review/',
            name: 'paymentReview',
            component: PaymentReview,
        },
        {
            path: '/:lang?/payment-error/',
            name: 'paymentError',
            component: PaymentError,
        },
        {
            path: '/:lang?/',
            component: Personal,
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: '/:lang?/personal',
                    name: 'personal',
                    component: Trees,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/map',
                    name: 'personal.map',
                    component: Map,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/detail',
                    name: 'personal.detail',
                    component: TreesDetail,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/status',
                    name: 'personal.status',
                    component: Status,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/profile',
                    name: 'personal.profile',
                    component: Profile,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/buy-for-gift',
                    name: 'personal.buy-for-gift',
                    component: BuyForGift,
                    beforeEnter: (to, from, next) => {
                        next('/')
                        return false
                    },
                },
                {
                    path: '/:lang?/personal/wallets/buy_default',
                    name: 'personal.buy_default',
                    component: BuyDefault,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/history',
                    name: 'personal.history',
                    component: History,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/withdrawal-history',
                    name: 'personal.withdrawal-history',
                    component: WithdrawalHistory,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/withdrawal-money',
                    name: 'personal.withdrawalMoney',
                    component: WithdrawalMoney,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/token',
                    name: 'personal.token',
                    component: Token,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/take-off',
                    name: 'personal.takeOff',
                    component: TakeOff,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/gift-info',
                    name: 'personal.giftInfo',
                    component: GiftInfo,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/give',
                    name: 'personal.give',
                    component: Give,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/cancel-gift',
                    name: 'personal.cancel_gift',
                    component: CancelGiftTree,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/get-gift',
                    name: 'personal.get_gift',
                    component: GetGiftTree,
                    beforeEnter: ifAuthenticated,
                },
                //TODO временно закрыли продажу
                {
                    path: '/:lang?/personal/wallets/sell',
                    name: 'personal.sell',
                    component: Sell,
                    beforeEnter: ifAuthenticated,
                },
                //TODO временно закрыл пополнение баланса
                // {
                //     path: '/:lang?/personal/wallets/top_up_wallet',
                //     name: 'personal.top_up_wallet',
                //     component: TopUpWallet,
                //     beforeEnter: ifAuthenticated,
                // },
                {
                    path: '/:lang?/personal/wallets/future_purchase_fund',
                    name: 'personal.future_top_up_wallet',
                    component: TopUpWallet,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/purchases',
                    name: 'personal.purchases',
                    component: Purchases,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/field',
                    name: 'personal.field',
                    component: Field,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/put_up_gift',
                    name: 'personal.putUpGift',
                    component: PutUpGift,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/cancel_put_up_gift',
                    name: 'personal.cancelPutUpGift',
                    component: CancelPutUpGift,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/wallets/field/detail',
                    name: 'personal.field.detail',
                    component: FieldDetail,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/:lang?/personal/purchases/:id',
                    name: 'personal.purchasesDetail',
                    component: PurchasesDetail,
                    beforeEnter: ifAuthenticated,
                },
            ]
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        let el = document.querySelector('.app')
        if (to.path !== from.path) {
            if (savedPosition) {
                return savedPosition
            } else {
                el.scrollLeft = 0;
                el.scrollTop = 0;

                return {x: 0, y: 0}
            }
        }
    }
});

router.beforeEach((to, from, next) => {
    store.state.system.openMenu = false;
    if (!to.matched.length) {
        next('/404');
        next();
    } else {
        next();
    }

});
export default router
