const Blade1 = () => import('./components/blade-1/index.vue');
const Blade2 = () => import('./components/blade-2/index.vue');
const Blade3 = () => import('./components/blade-3/index.vue');
const Blade4 = () => import('./components/blade-4/index.vue');
const Blade5 = () => import('./components/blade-5/index.vue');
const Blade6 = () => import('./components/blade-6/index.vue');
const Blade7 = () => import('./components/blade-7/index.vue');
const Blade8 = () => import('./components/blade-8/index.vue');
const Blade9 = () => import('./components/blade-9/index.vue');
const Blade10 = () => import('./components/blade-10/index.vue');
const Blade11 = () => import('./components/blade-11/index.vue');
import {mapActions, mapGetters} from "vuex";
import {FETCH_PROMOTIONS} from '@/store/action-types'

export default {
  name: 'promotions',
  data() {
    return {}
  },
  components: {
    // Blade1,
    // Blade2,
    // Blade3,
    // Blade4,
    // Blade5,
    // Blade6,
    // Blade7,
    // Blade8,
    // Blade9,
    // Blade10,
    // Blade11,
  },
  computed: {
    ...mapGetters({
      content: 'promotion/content',
      is_auth: `auth/is_auth`,
    })
  },
  created() {
    this.fetchData().then(() => {
    })
  },
  metaInfo: {
    title: 'Текущие события проекта | BioDeposit',
    meta: [
      { name: 'description', content: '【События - BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
    ]
  },
  methods: {
    ...mapActions({
      fetchData: `promotion/${FETCH_PROMOTIONS}`
    }),
  },

  destroyed() {

  }
}
