import { $http } from '@/https'
import {fixBody} from '@/helpers/variables/index'
import {
    TREES_MAP_LIST,
    TREES_MAP_AREA,
    ACTIVE_AREA,
    MAP_CURRENT_LENGTH,
    RESET_MAP_LIST,
    TREES_MAP_POPUP_AREA,
    TREES_MAP_RESET,
    TREES_MAP_LIST_RESET,
    SHOW_TREES_MAP_POPUP_AREA,
    GET_COORDINATES_TREES_FIELD,
    GET_COORDINATES_TREES_FIELD_LOADING,
    FIELDS_MAP_LIST,
    FIELDS_MAP_LIST_LOADING,
    FIELDS_MAP_LIST_RESET
} from '../../mutation-types';

const state = {
    popupCurrentArea: null,
    popupParamInitArea: null,
    showPopupCurrentArea: false,
    activeArea: null,
    currentArea: null,
    welcome: null,
    list: null,
    coordinatesTreesField: null,
    coordinatesTreesFieldLoading: false,
    length: 0,
    fieldsMapList: null,
    fieldsMapListLoading: false,
};

const getters = {
    fieldsMapList: state => state.fieldsMapList,
    fieldsMapListLoading: state => state.fieldsMapListLoading,
    activeArea: state => state.activeArea,
    coordinatesTreesField: state => state.coordinatesTreesField,
    coordinatesTreesFieldLoading: state => state.coordinatesTreesFieldLoading,
    popupParamInitArea: state => state.popupParamInitArea,
    popupCurrentArea: state => state.popupCurrentArea,
    showPopupCurrentArea: state => state.showPopupCurrentArea,
    currentArea: state => state.currentArea,
    length: state => state.length,
    list: state => state.list,
};

const actions = {
    [TREES_MAP_LIST]: async ({ commit }, payload) => {
        try {
            const response = await $http.get(`v1/trees?field_ids=${payload.id}&limit=${parseInt(localStorage.getItem('countView'))}&skip=${payload.offset}`);
            commit(RESET_MAP_LIST);
            commit(ACTIVE_AREA, payload.id);
            commit(TREES_MAP_LIST, response.data.data);
            commit(MAP_CURRENT_LENGTH, response.data.meta.count);
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    [TREES_MAP_AREA]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/fields');
            commit(TREES_MAP_AREA, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    [TREES_MAP_POPUP_AREA]: async ({ commit }) => {
        if(state.popupParamInitArea.field_id != null){
            try {
                const response = await $http.get(`v1/fields/${state.popupParamInitArea.field_id}`);
                commit(TREES_MAP_POPUP_AREA, response.data.data);
                return response.data.data;
            }catch (e) {
                throw e;
            }
        }else{
            commit(TREES_MAP_POPUP_AREA, state.popupParamInitArea.coordinates)
        }


    },
    [GET_COORDINATES_TREES_FIELD]: async ({ commit }, payload) => {
        try {
            commit(GET_COORDINATES_TREES_FIELD_LOADING, true);

            const response = await $http.get(`v1/trees/field/${payload}`);
            commit(GET_COORDINATES_TREES_FIELD, response.data.data);
        }catch (e) {
            throw e;
        }finally {
            commit(GET_COORDINATES_TREES_FIELD_LOADING, false);
        }
    },
    [FIELDS_MAP_LIST]: async ({ commit }) => {
        try {
            commit(FIELDS_MAP_LIST_LOADING, true);

            const response = await $http.get(`v1/fields/all`);
            commit(FIELDS_MAP_LIST, response.data.data);
        }catch (e) {
            throw e;
        }finally {
            commit(FIELDS_MAP_LIST_LOADING, false);
        }
    },
};

const mutations = {
    [TREES_MAP_LIST](state, trees) {
        const list = state.list.find(elem => {
            if(elem.id === state.activeArea){
                return elem.trees = trees;
            }
        });
        state.currentArea = list;
        state.list.forEach((elem, index)=> {
           if(elem.id === list.id){
               state.list.splice(index, list);
           }
        });
        state.list.map(e => {
            e.trees.map(t => {
                t.coordinates.latitude = t.coordinates.lat;
                t.coordinates.longitude = t.coordinates.lng;
            });
        });
    },
    [TREES_MAP_AREA](state, area) {
        state.list = area;
        state.list.map(elem => {
            return elem.area.push(elem.area[0]);
        });
        state.list.map(e => {
            e.area.map(t => {
                t.latitude = t.lat;
                t.longitude = t.lng;
            });
        });
    },
    [TREES_MAP_POPUP_AREA](state, area) {
        state.popupCurrentArea = area;
        const firstElement = state.popupCurrentArea.area[0];
        state.popupCurrentArea.area.push(firstElement);
        state.popupCurrentArea.area.map(elem => {
            elem.latitude = elem.lat;
            elem.longitude = elem.lng;
        });
    },
    [SHOW_TREES_MAP_POPUP_AREA](state, status) {
        fixBody(status);
        state.popupParamInitArea = status;
        state.showPopupCurrentArea = status;
    },
    [ACTIVE_AREA](state, id) {
        state.activeArea = id;
    },

    [FIELDS_MAP_LIST_LOADING](state, status) {
        state.fieldsMapListLoading = status;
    },
    [FIELDS_MAP_LIST](state, list) {
        state.fieldsMapList = list;
        state.fieldsMapList.map(e => {
            //add first coords in end array
            e.area.push(e.area[0]);
            e.stream.data.map(t => {
               t.playlist.map(s => {
                   s.sources[0].src = s.sources[0].src.toLowerCase();
                   s.sources[0].type = 'video/mp4'
               })
            });
            //transform array
            e.area.map(t => {
                t.latitude = t.lat;
                t.longitude = t.lng;
            });
        });
    },
    [FIELDS_MAP_LIST_RESET](state) {
        state.fieldsMapList = null;
    },

    [GET_COORDINATES_TREES_FIELD_LOADING](state, status) {
        state.coordinatesTreesFieldLoading = status;
    },
    [MAP_CURRENT_LENGTH](state, count) {
        state.length = count;
    },
    [GET_COORDINATES_TREES_FIELD](state, count) {
        state.coordinatesTreesField = count;
    },
    [TREES_MAP_LIST_RESET](state) {
        state.list = null;
        state.currentArea = null;
    },
    [TREES_MAP_RESET](state) {
        state.currentArea = null;
    },
    [RESET_MAP_LIST](state) {
        state.list.forEach(elem => {
            elem.trees = []
        });
    },

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
