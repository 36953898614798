import {$http} from '@/https';
import {fixBody, globalLoader} from '@/helpers/variables/index';
import _ from 'lodash';
import Vue from 'vue';
import {
  ADD_BASKET,
  BUY_YOUNG_TREE,
  CHANGE_POPUP_IFRAME,
  FILTER_POPUP,
  PAYMENT_LEOGAMING,
  POPUP_BUY_IFRAME,
  POPUP_BUY_SUCCESS,
  REDIRECT_TRANSACTION,
  REMOVE_BASKET,
  REPLENIST_BALANCE,
  RESET_BASKET,
  SHOP_BUY_TREE,
  SHOP_REQUEST_YEARS_LIST,
  SHOP_SET_YEARS_LIST,
  SHOP_FILTER,
  SHOP_FILTER_SEARCH,
  SHOP_LIST_INFO,
  SHOP_LOADING
} from '../mutation-types.js';

function save() {
  const parsed = JSON.stringify(state.basket);
  localStorage.setItem('trees', parsed);
}

const state = {
  basket: localStorage.getItem('trees') ? JSON.parse(localStorage.getItem('trees')) : [],
  yearsList: [],
  shopListInfo: null,
  redirectTransaction: false,
  youngTree: null,
  shopFilter: null,
  shopFilterStandard: null,
  cashPopUpSuccess: false,
  cashPopUpIframe: false,
  loading: false,
  addTreeForCash: [],
  treeIds: [],
  shopFilterSearch: null,
  treePrice: 0,
  filterPopUp: false,
  cashLocalTist: {},
  shopListStatus: false,
  paymentLeogaming: null,
};

const getters = {
  basket: state => state.basket || [],
  yearsList: state => state.yearsList || [],
  countElement: state => state.basket.length,
  treeIds: state => state.treeIds,
  redirectTransaction: state => state.redirectTransaction,
  loading: state => state.loading,
  shopListInfo: state => state.shopListInfo,
  youngTree: state => state.youngTree,
  addTreeForCash: state => state.addTreeForCash,
  shopFilter: state => state.shopFilter,
  cashPopUpSuccess: state => state.cashPopUpSuccess,
  cashPopUpIframe: state => state.cashPopUpIframe,
  shopFilterSearch: state => state.shopFilterSearch,
  treePrice: state => state.treePrice,
  filterPopUp: state => state.filterPopUp,
  shopListStatus: state => state.shopListStatus,
  shopFilterStandard: state => state.shopFilterStandard,
  cashLocalTist: state => state.cashLocalTist,
  paymentLeogaming: state => state.paymentLeogaming
};

const actions = {
  [SHOP_BUY_TREE]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const response = await $http.post('v1/shop/buy_trees', payload);

      if (response.data.type === 'url' || response.data.type === 'html') {
        commit(PAYMENT_LEOGAMING, response.data);
        commit(CHANGE_POPUP_IFRAME, true);
        commit(REDIRECT_TRANSACTION, true);
      } else {
        commit(REDIRECT_TRANSACTION, false);
      }

      commit(SHOP_BUY_TREE);

      return response;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false)
    }
  },
  [REPLENIST_BALANCE]: async ({commit}, payload) => {
    try {
      globalLoader(true);

      const response = await $http.post(`v1/users/${payload.type}`, payload);

      if (response.data.type === 'url' || response.data.type === 'html') {
        commit(PAYMENT_LEOGAMING, response.data);
        commit(REDIRECT_TRANSACTION, true);
        commit(CHANGE_POPUP_IFRAME, true);
      }

      return response;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [BUY_YOUNG_TREE]: async ({commit}, payload) => {
    try {
      globalLoader(true);
      const response = await $http.post('v1/first_buy', payload);

      if (response.data.type === 'url' || response.data.type === 'html') {
        commit(PAYMENT_LEOGAMING, response.data);
        commit(REDIRECT_TRANSACTION, true);
        commit(CHANGE_POPUP_IFRAME, true);
      }

      if (payload.payment_system === 'balance') {
        commit(REDIRECT_TRANSACTION, false);
        commit(POPUP_BUY_SUCCESS, true);
      }


      return response;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }

  },

  [SHOP_REQUEST_YEARS_LIST]: async ({commit}) => {
    try {
      commit(SHOP_LOADING, true);

      const response = await $http.get('v1/shop/trees/years');

      commit(SHOP_SET_YEARS_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SHOP_LOADING, false)
    }
  },

  [SHOP_FILTER_SEARCH]: async ({commit}, filter) => {
    try {
      commit(SHOP_LOADING, true);

      const response = await $http.get(`v1/shop/filter?season=${filter.season}&planting_year=${filter.min_planting_year}-${filter.max_planting_year}&price=${filter.min_price}-${filter.max_price}&limit=${filter.limit}&skip=${filter.skip}`);
      commit(SHOP_FILTER_SEARCH, response.data.data);
      commit(SHOP_LIST_INFO, response.data.meta.count);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(SHOP_LOADING, false)
    }
  },
  [SHOP_FILTER]: async ({commit}) => {
    try {
      const response = await $http.get('v1/shop/get_filter_values');
      commit(SHOP_FILTER, response.data);
      return response;
    } catch (e) {
      throw e;
    }
  },
};
const mutations = {
  [PAYMENT_LEOGAMING](state, payload) {
    const updatedPayment = {};

    if (payload.type === 'url') {
      updatedPayment.type = 'frame';
      updatedPayment.value = payload.value;
    }

    if (payload.type === 'html') {
      const styles = `
        <style type="text/css">
          .form {
            position: relative;
            width: 95%;
            min-height: 240px;
            height: 100%;
            overflow: hidden;
            min-width: 320px;
            background-color: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            padding-top: 50px;
            padding-left: 20px;
            padding-right: 20px;
            font-weight: 500;
            font: 100% FiraSans-Medium,Arial,Tahoma,sans-serif;
            font-size: 14px;
          }
          
          .form .form-title {
            text-align: center;
            margin-bottom: 30px;
            color: #6ea028;
            font-size: 30px;
          }
          
          .form form {
            width: 100%;
            max-width: 500px;
            padding: 0 15px;
            margin: 0 auto;
          }
          
          .form .form-btn {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }

          .form .form-btn > button[type="submit"] {
            position: relative;
            min-width: 170px;
            height: 48px;
            padding: 0 34px;
            border: none;
            text-align: center;
            cursor: pointer;
            background: transparent;
          }
          
          .form .form-btn > button[type="submit"]::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: -webkit-gradient(linear, right top, left top, from(#AADC50), to(#6EA028));
            background: linear-gradient(270deg, #AADC50 0%, #6EA028 100%);
            border-radius: 10em;
            opacity: 0;
            z-index: 1;
            -webkit-transition: opacity .2s ease-in-out;
            transition: opacity .2s ease-in-out;
          }
          
          .form .form-btn > button[type="submit"]::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: -webkit-gradient(linear, left top, right top, from(#AADC50), to(#6EA028));
            background: linear-gradient(90deg, #AADC50 0%, #6EA028 100%);
            border-radius: 10em;
            -webkit-transition: opacity .2s ease-in-out;
            transition: opacity .2s ease-in-out;
          }
          
          .form .form-btn > button[type="submit"] > span {
            position: relative;
            z-index: 2;
            white-space: normal;
            color: #fff;
            font: 16px "FiraSans-Bold",Arial,Tahoma,sans-serif;
            line-height: 1;
          }
        </style>
      `;

      updatedPayment.type = 'html';
      updatedPayment.value = styles + payload.value;
    }

    state.paymentLeogaming = updatedPayment;
  },

  [SHOP_SET_YEARS_LIST] (state, list) {
    state.yearsList = list;
  },

  [SHOP_FILTER](state, shopFilter) {
    state.shopFilter = shopFilter;
    state.shopFilterStandard = shopFilter
  },

  [SHOP_FILTER_SEARCH](state, shopFilterSearch) {
    state.shopFilterSearch = shopFilterSearch;
    state.shopFilterSearch.map(e => {
      e.isBasket = false;
      if (state.basket) {
        state.basket.forEach(t => {
          if (t.id === e.id) {
            e.isBasket = true;
          }
        });
      }
      return e;
    });
  },

  [SHOP_LIST_INFO](state, shopListInfo) {
    state.shopListInfo = shopListInfo;
  },

  [POPUP_BUY_SUCCESS](state, status) {
    state.cashPopUpSuccess = status
  },

  [CHANGE_POPUP_IFRAME](state, status) {
    state.cashPopUpIframe = status
  },

  [POPUP_BUY_IFRAME](state, status) {
    state.cashPopUpIframe = status;
    fixBody(status);
  },

  [SHOP_BUY_TREE](state) {
    state.treeIds = [];
    state.addTreeForCash = [];
    if (state.paymentLeogaming === null) {
      state.cashPopUpSuccess = true
    }
  },

  [SHOP_LOADING](state, status) {
    state.loading = status
  },

  [FILTER_POPUP](state, status) {
    state.filterPopUp = status;
  },

  [REDIRECT_TRANSACTION](state, status) {
    state.redirectTransaction = status;
  },

  [ADD_BASKET](state, obj) {
    obj.item.isBasket = true;
    state.basket.push(obj.item);
    state.basket = _.uniqBy(state.basket, 'id');
    const indexProduct = _.findIndex(state.shopFilterSearch, e => e.id === obj.item.id);

    Vue.set(state.shopFilterSearch, indexProduct, obj.item);
    save();
  },

  [REMOVE_BASKET](state, obj) {
    obj.item.isBasket = false;
    state.basket.splice(obj.index, 1);
    if (state.shopFilterSearch) {
      const indexProduct = _.findIndex(state.shopFilterSearch, e => e.id === obj.item.id);

      Vue.set(state.shopFilterSearch, indexProduct, obj.item);
    }
    save();
  },

  [RESET_BASKET](state) {
    localStorage.removeItem('trees');
    state.basket = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
