import {mapActions, mapGetters, mapMutations} from 'vuex'
import {CHANGE_POPUP_EXPRESS, SHOP_REQUEST_YEARS_LIST, TEXT_POPUP_CONTENT} from "@/store/mutation-types";
import shopTable from '@/components/shop-table/index.vue';
import TextHelp from '@/modules/shop/text-help/index.vue';
import {showTextPopup} from '@/helpers/variables/index';
import store from "@/store";

export default {
  name: 'shop',
  components: {
    shopTable,
    TextHelp,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      config: 'main/config',
      is_auth: 'auth/is_auth',
      setting: 'main/setting',
      yearsList: 'shop/yearsList',
      loading: 'shop/loading',
      user: 'profile/user',
      showTextHelpBonus: 'statistic/showTextHelpBonus',
    }),
    shopYoungTreeText(){
      let lang = store.state['vue-i18n-manager'].currentLanguage.translationKey;
      return lang === 'ru'
          ? 'дерева 2023 г.'
          : ' Young Tree of 2023'
    }
  },
  metaInfo: {
    title: 'Биржа оливковых деревьев TreeStore | BioDeposit',
    meta: [
      { name: 'description', content: '【Оливковые деревья TreeStore】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
    ]
  },
  mounted() {
    this.yearsListRequest();
  },
  methods: {
    ...mapActions({
      yearsListRequest: `shop/${SHOP_REQUEST_YEARS_LIST}`,
    }),
    ...mapMutations({
      changePopupExpress: `expressActions/${CHANGE_POPUP_EXPRESS}`,
      setPopupContent: `popups/${TEXT_POPUP_CONTENT}`
    }),
    changePopupText(status) {
      showTextPopup(status);
      this.setPopupContent(this.getConfigContent('infoTreestore'));
    },
    getConfigContent(key) {
      for (let i in this.config) {
        if (this.config[i].key === key) {
          return this.config[i];
        }
      }
    },
    gtm(label) {
      this.$gtm.trackEvent({
        event: 'biodep',
        eventCategory: 'userclicks',
        eventAction: 'button',
        eventLabel: label,
      });
    },
    declensionWord(count) {
      let titles = [this.$t('trees1'), this.$t('trees2'), this.$t('trees3')];
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
    },
  },
}
