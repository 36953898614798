import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    GET_QR_CODE,
    SHOW_GET_QR_CODE,
} from "@/store/mutation-types";
export default{
    name: 'get-gift-popup',
    data(){
        return {
            code: '',
        }
    },
    computed: {
        ...mapGetters({
            getQrCode: `getQrCode/getQrCodeLink`,
            numbQrIdNow: `getQrCode/numbQrId`
        })
    },
    created(){
        this.getCode();
    },
    methods: {
        ...mapActions({
            getCode: `getQrCode/${GET_QR_CODE}`
        }),
        ...mapMutations({
            showPopupQr: `getQrCode/${SHOW_GET_QR_CODE}`,
        })
    },
}
