import videojs from 'video.js';
import 'video.js/dist/video-js.css'

import playlistMaker from "videojs-playlist/src/playlist-maker";

const plugin = function (list, item) {
  playlistMaker(this, list, item);
};

videojs.registerPlugin("playlist", plugin);

export default {
  name: 'strimm-player-playlist',
  props: {
    playlist: {
      default: [],
      type: Array
    }
  },
  data() {
    return {
      playerOptions: {
        autoplay: false,
        controls: true,
        muted: true,
        playback: true,
        nativeControlsForTouch: false,
        customControlsOnMobile: true,
        poster: 'https://flowertimes.ru/wp-content/uploads/2015/12/derevo_maslina.jpg',
        playbackRates: [2, 4],
        html5: {
          hls: {
            overrideNative: true
          },
          controls: true,
          nativeAudioTracks: false,
          nativeVideoTracks: false
        },
        controlBar: {
          remainingTimeDisplay: false,
          timeDivider: false,
        },
      },
      player: null,
      readyIndex: false
    }
  },
  components: {},
  watch: {
    playlist() {
      const player = this.player;
      player.playlist([]);
      player.playlist(this.playlist);
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.playerOptions, this.onPlayerReady)
  },
  computed: {
    currentVideoIndex() {
      if (this.readyIndex) {
        return this.player.playlist.currentItem();
      }
    }
  },
  created() {

  },
  methods: {
    onPlayerReady() {
      const player = this.player;

      player.playlist(this.playlist);

      this.setButtons(player);

      this.setPlaylistSize();

      this.readyIndex = true;

      player.playlist.autoadvance(1);
    },
    setCurrentVideo(index) {
      this.player.playlist.currentItem(index);
    },
    setPlaylistSize() {
      setTimeout(() => {
        if(document.querySelector('.stream-playlist')){
          const heightPlayer = this.$refs['videoPlayer'].clientHeight;

          const el = this.$refs['videoPlaylist'];
          el.style.height = `${heightPlayer}px`;
        }
      }, 1000)
    },
    setButtons(player) {
      const Button = videojs.getComponent('Button');
      const PrevButton = videojs.extend(Button, {
        constructor() {
          Button.apply(this, arguments);
        },

        createEl: function () {
          return Button.prototype.createEl('button', {
            className: 'vjs-prev-button vjs-control vjs-button',
            innerHTML: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="step-backward" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-step-backward fa-w-14 fa-2x"><path fill="currentColor" d="M64 468V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v176.4l195.5-181C352.1 22.3 384 36.6 384 64v384c0 27.4-31.9 41.7-52.5 24.6L136 292.7V468c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12z" class=""></path></svg>`
          });
        },

        handleClick() {
          player.playlist.previous();
        }
      });

      const NextButton = videojs.extend(Button, {
        constructor() {
          Button.apply(this, arguments);
        },
        createEl: function () {
          return Button.prototype.createEl('button', {
            className: 'vjs-next-button vjs-control vjs-button',
            innerHTML: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="step-forward" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-step-forward fa-w-14 fa-2x"><path fill="currentColor" d="M384 44v424c0 6.6-5.4 12-12 12h-48c-6.6 0-12-5.4-12-12V291.6l-195.5 181C95.9 489.7 64 475.4 64 448V64c0-27.4 31.9-41.7 52.5-24.6L312 219.3V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12z" class=""></path></svg>`
          });
        },

        handleClick() {
          player.playlist.next();
        }
      });

      videojs.registerComponent('NextButton', NextButton);
      videojs.registerComponent('PrevButton', PrevButton);

      player.getChild('controlBar').addChild('PrevButton', {}, 0);
      player.getChild('controlBar').addChild('NextButton', {}, 2);
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
