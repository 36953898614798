import {mapGetters, mapMutations, mapActions} from 'vuex';
import {
    EXCHANGE_SELECT_TREE,
    RESET_EXCHANGE_SELECT_TREE,
    SHOW_EXCHANGE_CATEGORIES_POPUP,
    SHOW_TREES_MAP_POPUP_AREA,
    NOT_AVAIBLE
} from '@/store/mutation-types';
import Categories from '../category-popup/index.vue'
import Trees from '../trees-popup/index.vue'
export default{
    name: 'exchange-tree-detail',
    data() {
        return {

        }
    },
    created(){
        this.fetchTree({id: this.$route.params.id})
    },
    components: {
        Categories,
        Trees
    },
    computed: {
        ...mapGetters({
            tree: `exchangeTree/selectCurrentExchange`,
            showCategoryPopup: `exchangeTree/showCategoryPopup`,
            showTreesPopup: `exchangeTree/showTreesPopup`,
            selectCurrentTree: `exchangeTree/selectCurrentTree`,
        })
    },
    methods: {
        ...mapActions({
            fetchTree: `exchangeTree/${EXCHANGE_SELECT_TREE}`
        }),
        ...mapMutations({
            notAvaible: `system/${NOT_AVAIBLE}`,
            openPopup: `exchangeTree/${SHOW_EXCHANGE_CATEGORIES_POPUP}`,
            reset: `exchangeTree/${RESET_EXCHANGE_SELECT_TREE}`,
            changeMapPopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
        }),
    },
    destroyed(){
        this.reset();
    }
}