import {mapMutations, mapGetters, mapActions} from 'vuex'
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators';

export default {
  name: 'popup-confidant-confirm',
  data() {
    return {
      codeConfirm: '',
    }
  },
  validations: {
    codeConfirm: {
      required,
      minLength: minLength(6),
    },
  },

  computed: {
    codeConfirmErrors() {
      let error = [];
      if (!this.$v.codeConfirm.$dirty) {
        return error;
      }
      if (!this.$v.codeConfirm.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.codeConfirm.minLength) {
        error.push('Код не верный')
      }
      return error;
    },
  },
  methods: {
    ...mapMutations({
      changePopup: `profile/POPUP_CODE_CONFIRM`,
    }),
    ...mapActions({
      setCodeConfirm: 'profile/SEND_CODE_CONFIRM',
    }),


    sendCode() {
      this.$v.codeConfirm.$touch();
      if (!this.$v.codeConfirm.$invalid) {
        this.setCodeConfirm({
          confirm_code: this.codeConfirm,
        })
          .then(() => {
            this.$toaster.success(this.$t('toasterSuccessProfileData'));
            this.changePopup(false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              if (error.response.data.errors) {
                for (let key in error.response.data.errors) {
                  error.response.data.errors[key].forEach(elem => {
                    this.$toaster.error(elem);
                  });
                }
              }
              if (error.response.data.error) {
                this.$toaster.error(error.response.data.error.message);
              }
            }

          })
          .finally(() => {
          });
      }

    }

  },

}
