import {mapActions, mapGetters, mapMutations} from "vuex";
import { globalLoader} from '@/helpers/variables/index';
import {
    TREES_DETAIL,
    SHOW_TREES_MAP_POPUP_AREA,
    NOT_AVAIBLE,
    SING_DOCUMENT,
    GET_ORDER,
    DOCUMENT_TREE_ORDER_ID,
    CERTIFICATE_TYPE,
    DOCUMENT_TREE_ID,
    SHOW_POPUP_DOCUMENTS,
    CURRENT_GIFT_OBJ,
    SHOW_POPUP_GIFT_LIMIT,
    GET_CERTIFICATE
} from "@/store/mutation-types";
import VueAdsPagination from 'vue-ads-pagination';
import { VueAdsPageButton } from 'vue-ads-pagination';
import store from "@/store";
export default {
    name: 'search-detail',
    data(){
        return{
            idOrder: null,
            page: 0,
            skip: 0,
            loading: false
        }
    },
    computed:{
        ...mapGetters({
            lengthListCurrent: `trees/lengthListCurrent`,
            currentTrees: `trees/treesSearchDetail`,
            location: `trees/location`,
            purchase: `trees/purchasePeriod`,
            planting: `trees/plantingPeriod`,
            status: `trees/status`,
            limit: `trees/limit`,
            fetchUserData: `profile/user`,
        }),
        ...mapGetters('new_user',{
            uuid: "uuid",
        }),
        currentSeason(){
            let route = parseInt(this.$route.query.season);
            if(route){
                if(route === 2){
                    return this.$t('spring');
                }
                if(route === 4){
                    return this.$t('autumn');
                }
            }
        },
        showPagination(){
            if(10 > this.lengthListCurrent){
                return false
            }else{
                return true
            }
        }
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    watch: {
        page(){
            this.skip = this.page * this.limit;
            this.load();
        }
    },
    created(){
        this.load();
    },
    mounted() {
        this.fetchUser().then(() => {
            this.setUuidAsync(this.fetchUserData.uuid)
        })
    },
    methods: {
        ...mapActions('new_certificate',{
            downloadCertificateAsync: 'downloadCertificateAsync',
        }),
        ...mapActions('new_user',{
            setUuidAsync: 'setUuidAsync',
        }),
        ...mapActions({
            treesDetail: `trees/${TREES_DETAIL}`,
            singDocument: `trees/${SING_DOCUMENT}`,
            loadOrderId: `trees/${GET_ORDER}`,
            getCertificate: `trees/${GET_CERTIFICATE}`,
        }),
        ...mapMutations({
            changePopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
            notAvaible: `system/${NOT_AVAIBLE}`,
            changeTreeId: `trees/${DOCUMENT_TREE_ID}`,
            changeTreeOrderId: `trees/${DOCUMENT_TREE_ORDER_ID}`,
            showPopupDocuments: `trees/${SHOW_POPUP_DOCUMENTS}`,
            certificateType: `trees/${CERTIFICATE_TYPE}`,
            currentObjGift: `gift/${CURRENT_GIFT_OBJ}`,
            showPopupGiftLimit: `gift/${SHOW_POPUP_GIFT_LIMIT}`,
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        hasBans(elem) {
            let divined = false;
            let sale = false;
            if (elem.dividend_frozen_to && elem.dividend_frozen_to !== null) {
                divined = true
            } else {
                divined = false
            }
            if (elem.sale_frozen_to && elem.sale_frozen_to !== null) {
                sale = true
            } else {
                sale = false
            }
            if (divined || sale) {
                return true
            }
        },
        updateList(){
            this.load();
        },
        changeDocumentPopup(id){
            this.certificateType(false);
            this.changeTreeId(id);
            this.showPopupDocuments(true);
        },
        showGiftLimitPopup(item){
            this.currentObjGift(item);
            this.showPopupGiftLimit(true);
        },
        getCertificateLocal(id){

            globalLoader(true);
            this.getCertificate(id).finally(() => globalLoader(false))
        },
        loadOrder(id){
            this.loadOrderId(id)
                .then(response => {
                    this.idOrder = response.packet_id;
                    let obj = {
                        packet_type: response.packet_type,
                        packet_id: response.packet_id
                    };
                    this.singDocument(obj)
                        .then(() => {
                            this.certificateType(true);
                            this.changeTreeOrderId(this.idOrder);
                            this.showPopupDocuments(true);
                            this.load();
                        })
                })
        },
        load(){
            this.loading = true;
            let obj ={
                location: this.location,
                purchase_period: this.purchase,
                planting_period: this.planting,
                status: this.status,
                skip: this.skip,
                field_id: this.$route.query.field_id !== undefined ? this.$route.query.field_id: '',
                planting_year: this.$route.query.planting_year !== undefined ? this.$route.query.planting_year: '',
                season: this.$route.query.season !== undefined ? this.$route.query.season: ''
            };
            this.treesDetail(obj)
                .then(() => {
                    this.loading = false;
                })
        },
        goBack(){
            this.$router.go(-1)
        },
        downloadCertificate(id){
            let lang = store.state['vue-i18n-manager'].currentLanguage.translationKey;
            lang = lang === 'ru' ? 'rus' : 'eng'

            let payload = {
                id: id
            }
            let headers = {
                auth: this.uuid,
                lang: lang,
            }
            this.downloadCertificateAsync({
                payload:payload,
                headers:headers,
            });
        },
    }
}
