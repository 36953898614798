import { required, email, minLength, sameAs    } from 'vuelidate/lib/validators';
import twoFactorAuth from './two-factor-auntentificate/index.vue';
import trustee from './trustee/index.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    LOAD_USER_DATA,
    UPDATE_USER_DATA,
    UPDATE_USER_PASSWORD,
    PERSONAL_CHANGE_EDIT_DATA_STATUS,
    PERSONAL_CHANGE_EDIT_PASSWORD_STATUS,
    CHANGE_OLD_PASSWORD,
    CHANGE_NEW_PASSWORD,
    CHANGE_CONFIRMATION_PASSWORD,
    PERSONAL_RESET_PASSWORD,
    PERSONAL_VALIDATIONS_ERRORS,
    POPUP_REMOVE_PROFILE
} from '@/store/mutation-types';

export default {
    name: 'profile',
    data(){
        return {
            showPassword: true,
        }
    },
    validations: {
        user: {
            email: {
                required,
                email
            },
            info: {
                data: {
                    last_name: {
                        required
                    },
                    phone: {
                        required,
                        minLength: minLength(11),
                    },
                    first_name: {
                        required
                    },
                }
            }
        },
        password: {
            required,
            minLength: minLength(8)
        },
        password_confirmation: {
            required,
            minLength: minLength(8),
            sameAsPassword: sameAs('password')
        },
        old_password: {
            required,
        }
    },
    watch: {
        clearErrors(){
           this.resetErrorsPassword();
        }
    },
    components: {
        twoFactorAuth,
        trustee
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
            clearErrors: `profile/clearErrors`,
            loaderPassword: `profile/loaderPassword`,
            loaderData: `profile/loaderData`,
            editData: `profile/editData`,
            editPassword: `profile/editPassword`,
            oldPasswordError: `profile/oldPasswordError`,
            old_password: `profile/oldPassword`,
            password_confirmation: `profile/passwordConfirmation`,
            password: `profile/newPassword`,
            validationErrors: `profile/validationErrors`,
        }),
        lastNameErrors() {
            let error = [];
            if (!this.$v.user.info.data.last_name.$dirty) {
                return error;
            }
            if (!this.$v.user.info.data.last_name.required) {
                error.push(this.$t('validationRequired'))
            }
            if (this.validationErrors.last_name) {
                this.validationErrors.last_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        firstNameErrors() {
            let error = [];
            if (!this.$v.user.info.data.first_name.$dirty) {
                return error;
            }
            if (!this.$v.user.info.data.first_name.required) {
                error.push(this.$t('validationRequired'))
            }
            if (this.validationErrors.first_name) {
                this.validationErrors.first_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        emailErrors() {
            let error = [];
            if (!this.$v.user.email.$dirty) {
                return error;
            }
            if (!this.$v.user.email.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.user.email.email) {
                error.push(this.$t('validationEmail'));
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        phoneErrors() {
            let error = [];
            if (!this.$v.user.info.data.phone.$dirty) {
                return error;
            }
            if (!this.$v.user.info.data.phone.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.user.info.data.phone.minLength) {
                error.push(this.$t('validationMinPhone'))
            }
            if (this.validationErrors.phone) {
                this.validationErrors.phone.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        passwordErrors() {
            let error = [];
            if (!this.$v.password.$dirty) {
                return error;
            }
            if (!this.$v.password.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.password.minLength) {
                error.push(this.$t('validationMin8'));
            }
            if (this.validationErrors.password) {
                this.validationErrors.password.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        passwordRepeatErrors() {
            let error = [];
            if (!this.$v.password_confirmation.$dirty) {
                return error;
            }
            if (!this.$v.password_confirmation.sameAsPassword) {
                error.push(this.$t('validationSameAs'));
            }
            if (this.validationErrors.password_confirmation) {
                this.validationErrors.password_confirmation.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        passwordCurrentErrors() {
            let error = [];
            if (!this.$v.old_password.$dirty) {
                return error;
            }
            if (!this.$v.old_password.required) {
                error.push(this.$t('validationRequired'));
            }
            return error;
        },
    },
    created(){

    },
    methods: {
        ...mapActions({
            fetchUser: `profile/${LOAD_USER_DATA}`,
            updateUserData: `profile/${UPDATE_USER_DATA}`,
            updateUserPassword: `profile/${UPDATE_USER_PASSWORD}`,
        }),
        ...mapMutations({
            changeEditData: `profile/${PERSONAL_CHANGE_EDIT_DATA_STATUS}`,
            changeEditPassword: `profile/${PERSONAL_CHANGE_EDIT_PASSWORD_STATUS}`,
            changeOldPassword: `profile/${CHANGE_OLD_PASSWORD}`,
            changeNewPassword: `profile/${CHANGE_NEW_PASSWORD}`,
            changeConfirmationPassword: `profile/${CHANGE_CONFIRMATION_PASSWORD}`,
            resetPasswords: `profile/${PERSONAL_RESET_PASSWORD}`,
            changeValidationErrors: `profile/${PERSONAL_VALIDATIONS_ERRORS}`,
            changePopupRemoveProfile: `profile/${POPUP_REMOVE_PROFILE}`,
        }),
        showPopupRemoveProfile(){

        },
        resetErrorsPassword(){
            this.$v.old_password.$reset();
            this.$v.password.$reset();
            this.$v.password_confirmation.$reset()
        },
        resetEditData(){
            this.fetchUser();
            this.changeEditData();
        },
        resetEditPassword(){
            this.resetPasswords();
            this.changeEditPassword();
            this.resetErrorsPassword();
        },
        updateData(){
            this.$v.user.info.data.first_name.$touch();
            this.$v.user.info.data.last_name.$touch();
            this.$v.user.info.data.phone.$touch();
            this.$v.user.email.$touch();
            if (
                !this.$v.user.info.data.first_name.$invalid &&
                !this.$v.user.info.data.last_name.$invalid &&
                !this.$v.user.info.data.phone.$invalid &&
                !this.$v.user.email.$invalid
            ) {
                this.updateUserData({
                    first_name: this.user.info.data.first_name,
                    last_name: this.user.info.data.last_name,
                    email: this.user.email,
                    phone: this.user.info.data.phone,
                })
                    .then(() => {
                        this.$toaster.success(this.$t('toasterSuccessProfileData'));
                        this.changeValidationErrors({});
                        this.changeEditData();
                    })
                    .catch(error => {
                        if(error.response.status === 422){
                            if(error.response.data.errors){
                                for (let key in error.response.data.errors) {
                                    error.response.data.errors[key].forEach(elem => {
                                        this.$toaster.error(elem);
                                    });
                                }
                            }
                            if(error.response.data.error){
                                this.$toaster.error(error.response.data.error.message);
                            }
                        }

                    })
                    .finally(() => {});
            }

        },
        updatePassword(){
            this.$v.old_password.$touch();
            this.$v.password.$touch();
            this.$v.password_confirmation.$touch();
            if (
                !this.$v.old_password.$invalid &&
                !this.$v.password.$invalid &&
                !this.$v.password_confirmation.$invalid
            ) {
                this.updateUserPassword({
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    old_password: this.old_password,
                })
                    .then(response => {
                        this.resetEditPassword();
                        this.$toaster.success(response.data.message);
                        this.changeEditPassword();
                    }).catch(error => {
                        if(error.response.status === 422){
                            if(error.response.data.error){
                                this.$toaster.error(error.response.data.error.message);
                            }
                            if(error.response.data.errors){
                                for (let key in error.response.data.errors) {
                                    error.response.data.errors[key].forEach(elem => {
                                        this.$toaster.error(elem);
                                    });
                                }
                            }
                        }
                    }).finally(() => {});
            }
        }
    }
}
