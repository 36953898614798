import _ from 'lodash';
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {
    SELL_SHOW_TREES,
    SELL_REMOVE_TREES,
    SELL_INCREMENT_PRICE,
    SELL_DECREMENT_PRICE,
    SELL_CHANGE_PRICE,
    SELL_SHOW_CATEGORIES,
    SELL_CHANGE_PERCENT,
    SELL_DECREMENT_PERCENT,
    GET_POSITION,
    SELL_INCREMENT_PERCENT,
    SELL_TREES,
    SELL_TREES_RESET,
    LOADING_POSITION,
    SELL_RESET,
    POSITION_COMMISSION,
    SHOP_COMISSION
} from '@/store/mutation-types';
import Categories from './components/category-popup/index.vue'
import Trees from './components/trees-popup/index.vue'
export default{
    name: 'sell',
    data(){
        return{
            deletedTree: [],
            showDeleteIcons: false,
            validationErrors: {},
            payload: {},
            loadingPosition: false,
        }
    },
    components: {
        Trees,
        Categories
    },
    computed: {
        ...mapGetters({
            list: `sell/list`,
            setting: `main/setting`,
            watchCategory: `sell/showCategory`,
            watchTrees: `sell/showTrees`,
            shopComission: `sell/shopComission`,
            user: `profile/user`,
            positionCommission: `statistic/positionCommission`,
        }),
        totalIncomeTree(){
            let result = 0;
            this.list.forEach(elem => {
                result += elem.minPrice;
            });
            return result
        },
        commissionPrice(){
            let result = 0;
            this.list.forEach(elem => {
                result = ((elem.commission / 100) * elem.minPrice);
            });
            return result.toFixed(0)
        },
        totalIsCommissionPrice(){
            let result = 0;
            this.list.forEach(elem => {
                result += elem.totalCommissionPrice;
            });
            return result
        }
    },
    watch: {
        list(){
            this.loadingPosition = true;
            this.changePosition();
        }
    },
    created(){
      this.fetchPositionCommission();
      this.fetchshopComission();
    },
    methods: {
        ...mapActions({
            sellTrees: `sell/${SELL_TREES}`,
            getPosition: `sell/${GET_POSITION}`,
            fetchPositionCommission: `statistic/${POSITION_COMMISSION}`,
            fetchshopComission: `sell/${SHOP_COMISSION}`,
        }),
        ...mapMutations({
            removeTreesList: `sell/${SELL_REMOVE_TREES}`,
            resetTreesList: `sell/${SELL_TREES_RESET}`,
            showTrees: `sell/${SELL_SHOW_TREES}`,
            decrementMinPrice: `sell/${SELL_DECREMENT_PRICE}`,
            incrementMinPrice: `sell/${SELL_INCREMENT_PRICE}`,
            incrementPercent: `sell/${SELL_INCREMENT_PERCENT}`,
            decrementPercent: `sell/${SELL_DECREMENT_PERCENT}`,
            changeMinPrice: `sell/${SELL_CHANGE_PRICE}`,
            changeMinPercent: `sell/${SELL_CHANGE_PERCENT}`,
            showCategory: `sell/${SELL_SHOW_CATEGORIES}`,
            loadingPositionStatus: `sell/${LOADING_POSITION}`,
            sellReset: `sell/${SELL_RESET}`,
        }),
        changeDeleteIcons() {
            this.showDeleteIcons = !this.showDeleteIcons;
            document.querySelector('.sell__scroll').scrollLeft = 0;
            if (!this.showDeleteIcons && this.deletedTree.length > 0) {
                this.removeTreesList(this.deletedTree);
                this.deletedTree = [];
            }
        },
        decrementMinPriceLocal(id){
           this.decrementMinPrice(id);
            this.changePosition();
        },
        incrementMinPriceLocal(id){
           this.incrementMinPrice(id);
           this.changePosition();
        },
        incrementPercentLocal(id){
           this.incrementPercent(id);
           this.changePosition();
        },
        decrementPercentLocal(id){
           this.decrementPercent(id);
           this.changePosition();
        },
        changePosition: _.debounce(function () {
            let arr =[];
            this.list.forEach(elem => {
                let obj ={
                    tree_id: elem.id,
                    price: elem.minPrice,
                    commission: elem.commission
                };
                arr.push(obj)
            });
            this.getPosition(arr)
                .finally(() => {
                    this.loadingPosition = false;
                })
        }, 700),
        changePrice(event, id){
            let payload = {
                id: id,
                minPrice: +event
            };
            this.changeMinPrice(payload);
            this.changePosition();

        },
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        changePercent(event, id){
            let payload = {
                id: id,
                commission: +event
            };
            this.changeMinPercent(payload);
            this.changePosition();
        },
        sell(){
            let valid = true;
            this.list.forEach(elem => {
                if(elem.minPrice < elem.current_price){
                    valid = false;
                }
            });
            if(valid){
                if(this.list.length <= 20){
                    let arr = [];
                    this.list.forEach(elem => {
                        let obj ={
                            tree_id: elem.id,
                            price: elem.minPrice,
                            commission: elem.commission
                        };
                        arr.push(obj)
                    });
                    this.sellTrees(arr)
                        .then(response => {
                            this.$toaster.success(response.message);
                            this.resetTreesList();
                        })
                        .catch(error => {
                            if(error.response.status === 422){
                                this.$toaster.error(`${error.response.data.error.message}`);
                            }
                        })
                        .finally(() => {
                        })
                }else{
                    this.$toaster.error(this.$t('toasterErrorSellLimit'));
                }
            }else{
                this.$toaster.error(this.$t('toasterErrorSellData'));
            }
        }
    },
    destroyed(){
       this.sellReset();
    }
}
