import { $http } from '@/https'
import {
    MAIN_STATISTIC,
    STATISTIC_TICK,
    FETCH_MAIN_IMG_BUTTON,
    LANG_LIST,
    TREE_APPRECIATION_INFO,
    TOTAL_PROFIT_FROM_THE_CROP_INFO,
    EXPECTED_PROFIT_INFO,
    PLANTING_DATE_INFO,
    BONUS_ACCOUNT_INFO,
    TWO_FACTOR_AUTHENTICATION,
    POSITION_COMMISSION,
    SHOW_INFO_CALC_TEXT,
    SHOW_TEXT_HELP_BONUS,
    SHOW_POPUP_STATISTIC,
    FETCH_STATISTIC_INFO,
    RESET_POPUP_STATISTIC,
    HOME_ALL_VARIABLES
} from '../mutation-types';

const state = {
    plantingTime: null,
    harvestTime: null,
    alltrees: null,
    salesTime: null,
    statistic: null,
    statisticPrice:[],
    countTotalTrees: [],
    imgButton: null,
    locales:null,
    treeAppreciationInfo: null,
    totalProfitFromTheCropInfo: null,
    expectedProfitInfo: null,
    plantingDateInfo: null,
    bonusAccountInfo: null,
    twoFactorAuthentication: '',
    positionCommission: '',
    showInfoCalcKey: '',
    showTextHelpBonus: false,
    openStatisticPopup: false,
    contentStatisticPopup: null,
};

const getters = {
    plantingTime: state => state.plantingTime,
    harvestTime: state => state.harvestTime,
    alltrees: state => state.alltrees,
    salesTime: state => state.salesTime,
    statistic: state => state.statistic,
    statisticPrice: state => state.statisticPrice,
    countTotalTrees: state => state.countTotalTrees,
    imgButton: state => state.imgButton,
    locales: state => state.locales,
    treeAppreciationInfo: state => state.treeAppreciationInfo,
    totalProfitFromTheCropInfo: state => state.totalProfitFromTheCropInfo,
    expectedProfitInfo: state => state.expectedProfitInfo,
    plantingDateInfo: state => state.plantingDateInfo,
    bonusAccountInfo: state => state.bonusAccountInfo,
    twoFactorAuthentication: state => state.twoFactorAuthentication,
    positionCommission: state => state.positionCommission,
    showInfoCalcKey: state => state.showInfoCalcKey,
    showTextHelpBonus: state => state.showTextHelpBonus,
    openStatisticPopup: state => state.openStatisticPopup,
    contentStatisticPopup: state => state.contentStatisticPopup,
};

const actions = {
    [MAIN_STATISTIC]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/widgets/home_info_tables');
            commit(MAIN_STATISTIC, response.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [LANG_LIST]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/locales');
            commit(LANG_LIST, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [FETCH_MAIN_IMG_BUTTON]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/main_page_button');
            commit(FETCH_MAIN_IMG_BUTTON, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [TREE_APPRECIATION_INFO]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/variables/tree_appreciation_info');
            commit(TREE_APPRECIATION_INFO, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [HOME_ALL_VARIABLES]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/variables');
            commit(HOME_ALL_VARIABLES, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [TOTAL_PROFIT_FROM_THE_CROP_INFO]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/variables/total_profit_from_the_crop_info');
            commit(TOTAL_PROFIT_FROM_THE_CROP_INFO, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [EXPECTED_PROFIT_INFO]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/variables/expected_profit_info');
            commit(EXPECTED_PROFIT_INFO, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [PLANTING_DATE_INFO]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/variables/planting_date_info');
            commit(PLANTING_DATE_INFO, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [BONUS_ACCOUNT_INFO]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/variables/bonus_account_info');
            commit(BONUS_ACCOUNT_INFO, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [TWO_FACTOR_AUTHENTICATION]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/variables/two_factor_authentication');
            commit(TWO_FACTOR_AUTHENTICATION, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [POSITION_COMMISSION]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/variables/position_commission');
            commit(POSITION_COMMISSION, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [FETCH_STATISTIC_INFO]: async ({ commit }) => {
        try {
            const result = await $http.get('v1/info');
            commit(FETCH_STATISTIC_INFO, result.data.data);
        }catch (e) {
            throw e
        }finally {

        }
    },
};

const mutations = {
    [SHOW_TEXT_HELP_BONUS](state, status){
        state.showTextHelpBonus = status;
    },
    [SHOW_INFO_CALC_TEXT](state, status){
        state.showInfoCalcKey = status;
    },
    [BONUS_ACCOUNT_INFO](state, status){
        state.bonusAccountInfo = status;
    },
    [TWO_FACTOR_AUTHENTICATION](state, status){
        state.twoFactorAuthentication = status;
    },
    [POSITION_COMMISSION](state, status){
        state.positionCommission = status;
    },
    [PLANTING_DATE_INFO](state, status){
        state.plantingDateInfo = status;
    },
    [EXPECTED_PROFIT_INFO](state, status){
        state.expectedProfitInfo = status;
    },
    [TREE_APPRECIATION_INFO](state, status){
        state.treeAppreciationInfo = status;
    },
    [TOTAL_PROFIT_FROM_THE_CROP_INFO](state, status){
        state.totalProfitFromTheCropInfo = status;
    },
    [LANG_LIST](state, status){
        state.locales = status;
    },
    [FETCH_MAIN_IMG_BUTTON](state, status){
        state.imgButton = status;
    },
    [MAIN_STATISTIC](state, statistic) {
        state.statistic = statistic[0];
        //
        let allPrice = Math.floor(state.statistic[9].value);
        allPrice = allPrice.toString();
        allPrice = new Intl.NumberFormat('en-En').format(allPrice);
        state.statisticPrice =  allPrice.split('');
        //
        let allCount = Math.floor(state.statistic[8].value);
        allCount = allCount.toString();
        allCount = new Intl.NumberFormat('en-En').format(allCount);
        state.countTotalTrees =  allCount.split('');
    },
    [STATISTIC_TICK](state) {
        state.plantingTime = parseInt(state.statistic[3].value);
        // state.harvestTime = parseInt(state.statistic[7].value);
        state.salesTime = parseInt(state.statistic[6].value);
        state.alltrees = state.statistic[8].value;
        state.alltrees = new Intl.NumberFormat('usa-Usa').format(state.alltrees);
        state.plantingTime = newStatistic(state.plantingTime);
        // state.harvestTime = newStatistic(state.harvestTime);
        state.salesTime = newStatistic(state.salesTime);
        function newStatistic(sec) {
            let realTime =  new Date();
            let realMinutes = realTime.getMinutes();
            let realSeconds = realTime.getSeconds();
            let realTimeInSeconds = (realMinutes * 60) + realSeconds;
            let timeInSecond = parseInt(sec, 10);
            timeInSecond = timeInSecond -realTimeInSeconds;
            let timeInHours = Math.floor(timeInSecond / 3600);
            let timeInDays = Math.floor(timeInHours / 24);
            let realHours = timeInHours - (timeInDays * 24);
            let timeInMinutes = Math.floor((timeInSecond - (timeInHours * 3600)) / 60);
            let realSecond = timeInSecond - (timeInHours * 3600) - (timeInMinutes * 60);
            let allTime =
                (timeInDays < 10 ? `0${timeInDays}`: timeInDays) +' : '+
                (realHours < 10 ? `0${realHours}` : realHours) + ' : ' +
                (timeInMinutes < 10 ? `0${timeInMinutes}` : timeInMinutes) + " : " +
                (realSecond < 10 ? `0${realSecond}` : realSecond);
            if(timeInDays >= 0 && realHours >= 0 && timeInMinutes >= 0 ){
                return allTime
            }else{
                allTime = '00 : 00 : 00 : 00';
                return allTime;
            }
        }
    },
    [SHOW_POPUP_STATISTIC](state, status) {
        state.openStatisticPopup = status;
    },
    [FETCH_STATISTIC_INFO](state, content) {
        state.contentStatisticPopup = content
    },
    [RESET_POPUP_STATISTIC](state) {
        state.contentStatisticPopup = null;
    },
    [HOME_ALL_VARIABLES](state, status) {
        state.harvestTime = status
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
