import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    POPUP_EXPRESS_PROMO_CODE,
    DOWNLOAD_CERTIFICATE,
    EXPRESS_GET_TOKEN_DOWNLOAD
} from "@/store/mutation-types";
export default {
    name: 'help-popup',
    data(){
        return {
            loading: false
        }
    },
    computed:{
        ...mapGetters({
            popupInfo: `expressActions/popupPromoCode`
        })
    },
    created(){

    },
    methods: {
        ...mapActions({
           getPromoFile: `expressActions/${EXPRESS_GET_TOKEN_DOWNLOAD}`
        }),
        ...mapMutations({
            changePopup: `expressActions/${POPUP_EXPRESS_PROMO_CODE}`,
            download: `trees/${DOWNLOAD_CERTIFICATE}`
        }),
        downloadPromoCode(link){
            this.loading = true;
            this.getPromoFile(link)
                .then(response => {
                    this.download(response.data.link);
                    this.changePopup({ show: false, link: '' })
                })
                .catch(error => {})
                .finally(() => {
                    this.loading = false;
                });

        }
    },
    destroyed(){

    }
}
