import { mapGetters, mapActions } from 'vuex'
import {
    GUARANTEES_LOAD,
} from "@/store/mutation-types";


export default {
    name: 'guarantees',
    computed: {
        ...mapGetters({
            content: `guarantees/content`,
        })
    },
    metaInfo: {
        title: 'Гарантии | Инвестиционный проект BioDeposit',
        meta: [
            { name: 'description', content: '【Гарантии - BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
        ]
    },
    created(){
        this.fetchContent();
    },
    beforeRouteLeave (to, from, next) {
        next()
    },
    methods: {
        ...mapActions({
            fetchContent: `guarantees/${GUARANTEES_LOAD}`,
        }),
    }

}
