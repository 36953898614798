import { $http } from '@/https'
import {
    PURCHASES_LIST,
    PURCHASES_LIST_LENGTH,
    PURCHASES_CURRENT,
    PURCHASES_RESET_CURRENT,
    PURCHASES_RESET_LIST,
    PURCHASES_SELECT_ITEM,
    PURCHASES_LOADING,
    PURCHASES_LENGTH,
    PURCHASES_INFO,
} from '../../mutation-types';

const state = {
    loading: false,
    welcome: null,
    currentItem: null,
    list: null,
    lengthList: 0,
    length: 0,
    selectItem: null,
    info: null
};

const getters = {
    length: state => state.length,
    info: state => state.info,
    loading: state => state.loading,
    lengthList: state => state.lengthList,
    selectItem: state => state.selectItem,
    currentItem: state => state.currentItem,
    list: state => state.list
};

const actions = {
    [PURCHASES_LIST]: async ({ commit }, payload) => {
        try {
            commit(PURCHASES_LOADING, true);

            const response = await $http.get(`v1/orders?skip=${payload.skip}&limit=${payload.limit}`);
            commit(PURCHASES_LIST, response.data.data);
            commit(PURCHASES_LIST_LENGTH, response.data.meta.count);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(PURCHASES_LOADING, false);
        }
    },
    [PURCHASES_INFO]: async ({ commit }, payload) => {
        try {
            const response = await $http.get(`v1/orders/${payload.id}`);
            commit(PURCHASES_INFO, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    [PURCHASES_CURRENT]: async ({ commit }, payload) => {
        try {
            commit(PURCHASES_LOADING, true);

            const response = await $http.get(`v1/order_details?order=${payload.id}&skip=${payload.skip}&limit=${parseInt(localStorage.getItem('countView'))}`);
            commit(PURCHASES_CURRENT, response.data.data);
            commit(PURCHASES_LENGTH, response.data.meta.count);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(PURCHASES_LOADING, false);
        }
    },
};

const mutations = {
    [PURCHASES_LIST](state, list) {
        state.list = list;
    },
    [PURCHASES_RESET_LIST](state) {
        state.list = null;
    },
    [PURCHASES_CURRENT](state, item) {
        state.currentItem = item;
    },
    [PURCHASES_RESET_CURRENT](state) {
        state.currentItem = null;
    },
    [PURCHASES_SELECT_ITEM](state, obj) {
        state.selectItem = obj;
    },
    [PURCHASES_LOADING](state, status) {
        state.loading = status;
    },
    [PURCHASES_LENGTH](state, length) {
        state.lengthList = length;
    },
    [PURCHASES_LIST_LENGTH](state, length) {
        state.length = length;
    },
    [PURCHASES_INFO](state, data) {
        state.info = data;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
