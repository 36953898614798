import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru';
import {english} from 'flatpickr/dist/l10n/default';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import VueAdsPagination from 'vue-ads-pagination';
import {VueAdsPageButton} from 'vue-ads-pagination';
import {
  TRANSACTION_HISTORY,
  TRANSACTION_HISTORY_CHANGE_TO,
  TRANSACTION_HISTORY_CHANGE_FROM,
  TRANSACTION_HISTORY_CHANGE_SKIP,
  TRANSACTION_HISTORY_CHANGE_PAGE,
  SHOW_POPUP_TWO_AUTH_CODE,
  RESET_TRANSACTION_HISTORY_COMPONENT
} from '@/store/mutation-types';
import axios from "axios";

export default {
  name: 'history-transaction',
  data() {
    return {
      localKey: [],
      config: {
        wrap: true,
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: this.$store.getters.urlPrefix === 'ru' ? Russian : english,
        maxDate: "today"
      },
      listId: [],
    }
  },
  components: {
    flatPickr,
    VueAdsPagination,
    VueAdsPageButton
  },
  computed: {
    ...mapGetters({
      user: `profile/user`,
      list: `history/list`,
      skip: `history/skip`,
      page: `history/page`,
      to: `history/to`,
      limit: `history/limit`,
      from: `history/from`,
      length: `history/lengthList`,
      loading: `history/loading`,
    }),
  },
  watch: {
    page() {
      this.changeSkip(this.page * this.limit);
      this.listId = [];
      this.fetchList()
        .catch(error => {
          if (error.response.status === 422) {
            this.$toaster.error(this.$t('validationTwoFactor'));
            this.$router.push({name: 'personal'});
          }
          if (error.response.status === 424) {
            this.$toaster.error(error.response.data.error.message)
          }
        })
    }
  },
  created() {
    this.fetchList()
      .catch(error => {
        if (error.response.status === 422) {
          this.$toaster.error(this.$t('validationTwoFactor'));
          this.$router.push({name: 'personal'});
        }
        if (error.response.status === 424) {
          this.$toaster.error(error.response.data.error.message)
        }
      });
    axios.defaults.headers.common = {
      'One-Time-Pass': localStorage.getItem('keyRequest'),
    };
  },
  methods: {
    ...mapActions({
      fetchList: `history/${TRANSACTION_HISTORY}`
    }),
    ...mapMutations({
      changePopup: `profile/${SHOW_POPUP_TWO_AUTH_CODE}`,
      changeTo: `history/${TRANSACTION_HISTORY_CHANGE_TO}`,
      changeFrom: `history/${TRANSACTION_HISTORY_CHANGE_FROM}`,
      changeSkip: `history/${TRANSACTION_HISTORY_CHANGE_SKIP}`,
      changePage: `history/${TRANSACTION_HISTORY_CHANGE_PAGE}`,
      reset: `history/${RESET_TRANSACTION_HISTORY_COMPONENT}`,
    }),
    resetHeaders() {
      axios.defaults.headers.common = {
        'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
        'X-localization': this.$store.getters.currentLanguage.translationKey
      };
    },
    listActiveClass(tag) {
      const isSelected = this.listId.includes(tag);
      return {
        'active': isSelected,
      }
    },
    listToggle(tag) {
      return this.listId.includes(tag);
    },
    toggleList(id) {
      if (this.listId.length !== 0) {
        let index = this.listId.indexOf(id);
        if (index === -1) {
          this.listId.push(id);
        } else {
          this.listId.splice(index, 1);
        }

      } else {
        this.listId.push(id);
      }
    },
    selectDateTo(selectedDates, dateStr, instance) {
      this.changeSkip(0);
      this.changePage(0);
      this.changeTo(dateStr);
      this.fetchList()
        .catch(error => {
          if (error.response.status === 422) {
            this.$toaster.error(this.$t('validationTwoFactor'));
            this.$router.push({name: 'personal'});
          }
          if (error.response.status === 424) {
            this.$toaster.error(error.response.data.error.message)
          }
        })
    },
    selectDateFrom(selectedDates, dateStr, instance) {
      this.changeSkip(0);
      this.changePage(0);
      this.changeFrom(dateStr);
      this.fetchList()
        .catch(error => {
          if (error.response.status === 422) {
            this.$toaster.error(this.$t('validationTwoFactor'));
            this.$router.push({name: 'personal'});
          }
          if (error.response.status === 424) {
            this.$toaster.error(error.response.data.error.message)
          }
        })
    }
  },
  destroyed() {
    this.reset();
    this.resetHeaders();
  }
}
