import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_FILTER_TREE_POPUP,
    SHOW_CALCULATE_FILTER_RESULT,
    CHANGE_FILTER_SEARCH_PARAMS,
    LOAD_TREE_FILTER
} from "@/store/mutation-types";
export default {
    name: 'popup-documents-shop-filter',
    data(){
        return{

        }
    },
    computed: {
        ...mapGetters({
            searchParams: `calculate/searchParams`
        })
    },
    methods: {
        ...mapActions({
           fetchTrees: `calculate/${LOAD_TREE_FILTER}`
        }),
        loadTrees(){
            this.showPopup(false);
            this.showPopupResult(true);
            this.fetchTrees();
        },
        ...mapMutations({
            showPopup: `calculate/${SHOW_FILTER_TREE_POPUP}`,
            showPopupResult: `calculate/${SHOW_CALCULATE_FILTER_RESULT}`,
            changeSearchParams: `calculate/${CHANGE_FILTER_SEARCH_PARAMS}`,
        })
    }
}
