export default {
  name: 'google-pay',
  props: {
    amount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      allowedPaymentMethods: ['CARD' ], // принимаемые способы оплаты: CARD — обычный ввод данных карты, // TOKENIZED_CARD — карта, привязанная к Android Pay в телефоне
      allowedCardNetworks: ['MASTERCARD', 'VISA'],// принимаемые типы карт: для России имеет смысл только MASTERCARD и VISA // МИР и Маэстро не поддерживаются,
      tokenizationParameters: {
        tokenizationType: 'PAYMENT_GATEWAY', // *** в случае приема оплаты через один из шлюзов, // указанных в Google. Если шлюз другой, то нужно использовать DIRECT
        parameters: {
          'gateway': 'tinkoff', // ***заменить на название реального шлюза, например,
          'gatewayMerchantId': '1573713901734' // ***заменить на идентификатор, выданный ШЛЮЗОМ
        }
      },
      cardImg: ''
    }
  },
  mounted() {

    if (document.getElementById("scriptGooglePay")) {
      return; // already loaded
    } else {
      window.OnLoadGPay = () => this.onGooglePayLoaded();
      const scriptTag = document.createElement("script");
      scriptTag.src = "https://pay.google.com/gp/p/js/pay.js";
      scriptTag.allowpaymentrequest = "allowpaymentrequest";
      scriptTag.id = "scriptGooglePay";

      // Inject the dynamic script in the DOM
      document.head.appendChild(scriptTag);
    }


    // настройки приема платежей


    setTimeout(() => {
      this.onGooglePayLoaded();
    }, 500);

  },
  methods: {
    onGooglePaymentButtonClicked() {
      // обработчик нажатия кнопки Google Pay

      const paymentDataRequest = this.getGooglePaymentDataConfiguration();

      paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();

      const paymentsClient = this.getGooglePaymentsClient();

      paymentsClient.loadPaymentData(paymentDataRequest)
        .then(paymentData => {
          this.processPayment(paymentData);
        })
        .catch(err => {
          if (err.statusCode === "DEVELOPER_ERROR") {
            this.$toaster.error(err.statusMessage);
          }
          console.error(err); // *** здесь необходимо добавить обработчик ситуаций, когда платеж не прошел.
        });
    },
    prefetchGooglePaymentData() {
      // предварительное получение данных для платежа

      const paymentDataRequest = this.getGooglePaymentDataConfiguration();
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
        currencyCode: 'USD'
      };
      const paymentsClient = this.getGooglePaymentsClient();
      paymentsClient.prefetchPaymentData(paymentDataRequest);
      console.log(paymentsClient);
      console.log(paymentsClient.prefetchPaymentData(paymentDataRequest));
    },
    processPayment(paymentData) {
      // обработчик данных об успешно прошедшем платеже
      // *** его содержимое нужно заменить на свое!
      console.log(paymentData);
      // мы для примера просто сохраняем все данные в скрытое поле и отправляем на сервер
    },
    getGoogleTransactionInfo() {
      // формирование данных о транзакции (сумма и валюта)

      return {
        currencyCode: 'RUB', // *** код валюты, если у вас не рубли — замените на нужный
        totalPriceStatus: 'FINAL',
        // FINAL означает, что цена не будет изменяться в зависимости от региона доставки и тому подобных параметров
        // totalPrice: this.amount.toString() // *** сюда подставляется сумма платежа.
        totalPrice: '0.01' // *** сюда подставляется сумма платежа.
      };
    },
    getGooglePaymentDataConfiguration() {
      // формирование платежных данных

      return {
        merchantId: 'BCR2DN6TTPNJBJ3Z', // *** MerchantID, полученный непосредственно от GOOGLE после проверки сайта.
        //Не путать с MerchantID, выдаваемым шлюзом!
        paymentMethodTokenizationParameters: this.tokenizationParameters,
        allowedPaymentMethods: this.allowedPaymentMethods,
        cardRequirements: {
          allowedCardNetworks: this.allowedCardNetworks,
          billingAddressRequired: false, // не требовать от пользователя ввода адреса регистрации
        },
        emailRequired: false, // требовать ввода EMail
        phoneNumberRequired: false // требовать номера телефона
      };
    },
    onGooglePayLoaded() {
      // Обработчик, который вызывается, когда скрипт Google Pay загрузился
      // В нем создается объект PaymentsClient и делается запрос

      const paymentsClient = this.getGooglePaymentsClient();
      paymentsClient.isReadyToPay({allowedPaymentMethods: this.allowedPaymentMethods})
        .then(response => {
          console.log(response);
          if (response.result) {
            this.addGooglePayButton(paymentsClient);
            this.prefetchGooglePaymentData();
          }
        })
        .catch(err => {
          // вывод даных для отладки
          console.error(err);
        });
    },
    addGooglePayButton(paymentsClient) {
      console.log(paymentsClient);
      const button = paymentsClient.createButton({onClick: this.onGooglePaymentButtonClicked});
      document.getElementById('g-pay-button-wrap').appendChild(button);
    },
    getGooglePaymentsClient() {
      // Создание объекта PaymentsClient
      return (new google.payments.api.PaymentsClient({environment: 'PRODUCTION'})); // *** TEST заменить на PRODUCTION при начале приема реальных платежей
    }
  },
  beforeDestroy() {
    document.getElementById("scriptGooglePay").remove();
  }
}
