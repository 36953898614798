import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    PRIVACY_POLICY_CONTENT,
} from "@/store/mutation-types";

export default {
    name: 'about-us',
    computed: {
        ...mapGetters({
            content: `privacyPolicy/contentPrivacy`,
        })
    },
    created(){
        this.fetchContent();
    },
    beforeRouteLeave (to, from, next) {
        next()
    },
    methods: {
        ...mapActions({
            fetchContent: `privacyPolicy/${PRIVACY_POLICY_CONTENT}`,
        }),
    }

}
