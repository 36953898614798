import {
    GET_ORDER,
    DOCUMENT_TREE_ID,
    SHOW_POPUP_DOCUMENTS,
    DOCUMENT_TREE_ORDER_ID,
    CERTIFICATE_TYPE,
    CURRENT_GIFT_OBJ,
    SHOW_TREES_MAP_POPUP_AREA,
    SHOW_POPUP_GIFT_LIMIT,
    GENERATED_CERTIFICATE,
    SING_DOCUMENT
} from '@/store/mutation-types';
import {mapActions, mapMutations} from 'vuex';

export default {
    name: 'tree-item',
    props: ['item'],
    data() {
        return {
            idOrder: null,
            payload: {
                tree_id: ''
            }
        }
    },
    computed: {
        hasDivined() {
            let divined = false;
            let sale = false;
            const elem = this.$props.item;
            if (elem.dividend_frozen_to) {
                if (elem.dividend_frozen_to !== null) {
                    divined = true
                } else {
                    divined = false
                }
            } else {
                divined = false
            }
            if (elem.sale_frozen_to) {
                if (elem.sale_frozen_to !== null) {
                    sale = true
                } else {
                    sale = false
                }
            } else {
                sale = false
            }
            if (divined || sale) {
                return true
            }
        }
    },
    methods: {
        ...mapActions({
            generatedCertificate: `trees/${GENERATED_CERTIFICATE}`,
            singDocument: `trees/${SING_DOCUMENT}`,
            loadOrderId: `trees/${GET_ORDER}`,
        }),
        ...mapMutations({
            changePopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
            changeTreeId: `trees/${DOCUMENT_TREE_ID}`,
            showPopupDocuments: `trees/${SHOW_POPUP_DOCUMENTS}`,
            changeTreeOrderId: `trees/${DOCUMENT_TREE_ORDER_ID}`,
            certificateType: `trees/${CERTIFICATE_TYPE}`,
            currentObjGift: `gift/${CURRENT_GIFT_OBJ}`,
            showPopupGiftLimit: `gift/${SHOW_POPUP_GIFT_LIMIT}`,
        }),
        computedCurrentSeason(val) {
            if (val === 2) {
                return this.$t('spring');
            }
            if (val === 4) {
                return this.$t('autumn');
            }
        },
        changeDocumentPopup(id) {
            this.changeTreeId(id);
            this.showPopupDocuments(true);
        },
        showGiftLimitPopup(item) {
            this.currentObjGift(item);
            this.showPopupGiftLimit(true);
        },
        loadOrder(id) {
            this.loadOrderId(id)
                .then(response => {
                    this.idOrder = response.packet_id
                    let obj = {
                        packet_type: response.packet_type,
                        packet_id: response.packet_id
                    };
                    this.singDocument(obj)
                        .then(() => {
                            this.certificateType(true);
                            this.changeTreeOrderId(this.idOrder);
                            this.showPopupDocuments(true);
                            this.search();
                        })
                })
        },
    },
    destroyed() {

    }
}
