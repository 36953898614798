import StrimmComponent from '../../components/strimm-player/index.vue'
import GooglePay from '@/components/widgets/google-pay/index.vue';
export default {
    name: 'strimm',
    data(){
        return {

        }
    },
    components: {
        StrimmComponent,
        GooglePay
    },
    computed:{

    },
    created(){

    },
    methods: {

    },
    destroyed(){

    }
}
