import { $http } from '@/https'
import { fixBody, globalLoader } from '@/helpers/variables/index'
import {
    GET_WITHDRAWAL_LOADING,
    CHANGE_WITHDRAWAL_TYPE
} from '../../mutation-types';
import {
    GET_WITHDRAWAL,
} from '../../action-types';


const state = {
    withdrawalType: '',
};

const getters = {
    withdrawalType: state => state.withdrawalType,
};

const actions = {
    [GET_WITHDRAWAL]: async ({ commit }, payload) => {
        try {
            globalLoader(true);
            commit(GET_WITHDRAWAL_LOADING, true);

            const response = await $http.post(`v1/withdraw/${payload.type}`, payload);
            return response;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
            commit(GET_WITHDRAWAL_LOADING, false);
        }
    },
};

const mutations = {
    [GET_WITHDRAWAL_LOADING](state, status) {
        state.loading = status;
    },
    [CHANGE_WITHDRAWAL_TYPE](state, name) {
        state.withdrawalType = name;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
