import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru';
import {english} from 'flatpickr/dist/l10n/default';
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {
    OPEN_SEARCH_TREE,
    PERSONAL_TREES,
    TREES_SEARCH,
    TREE_SEARCH_LOCATION,
    TREE_SEARCH_PURCHASE,
    TREE_SEARCH_PLANTING,
    TREE_SEARCH_STATUS,
    SEARCH_PARAMS_STATUSES,
    SEARCH_PARAMS_LOCATIONS,
    TREES_SKIP,
    TREES_LIMIT,
    PERSONAL_TYPE_SEARCH,
    CHANGE_FILTER_SEASON,
} from '@/store/mutation-types';

export default {
    name: 'personal-popup-search',
    data() {
        return {
            payload: {
                purchase: '',
                planting: '',
                skip: 0
            },
            config: {
                mode: 'range',
                wrap: true,
                altFormat: 'M j, Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                locale: Russian,
                maxDate: new Date()
            },
            openSelect: false,
            openSelect2: false,
            openSelect3: false,
            openSelect4: false,
            seasons: [
                {
                    name: this.$t('spring'),
                    value: 2,
                },
                {
                    name: this.$t('autumn'),
                    value: 4,
                }
            ]
        }
    },
    components: {
        flatPickr,
    },
    updated() {
        document.addEventListener('click', () => {
            if (this.openSelect === true) {
                this.openSelect = false;
            }
            if (this.openSelect2 === true) {
                this.openSelect2 = false;
            }
            if (this.openSelect3 === true) {
                this.openSelect3 = false;
            }
            if (this.openSelect4 === true) {
                this.openSelect4 = false;
            }
        });
    },
    computed: {
        ...mapGetters({
            setting: `main/setting`,
            sortType: `trees/sortType`,
            skip: `trees/skip`,
            limit: `trees/limit`,
            sortTypeCategory: `trees/sortTypeCategory`,
            location: `trees/location`,
            purchase: `trees/purchasePeriod`,
            planting: `trees/plantingPeriod`,
            status: `trees/status`,
            listStatuses: `trees/listStatuses`,
            listLocations: `trees/listLocations`,
            currentStatus: `trees/currentStatus`,
            currentLocation: `trees/currentLocation`,
            plantingPeriodLocal: `trees/plantingPeriodLocal`,
            purchasePeriodLocal: `trees/purchasePeriodLocal`,
            filterSeason: `trees/filterSeason`,
        }),
        rangeYearsPlanting() {
            let arr = [];
            for (let i = this.setting.minYearPlanting; i <= this.setting.maxYearPlanting; i++) {
                arr.push(i)
            }
            return arr;
        },
        getStatus(){
            if(this.status === ''){
                return ''
            }else{
                return `${this.status.type}_${this.status.id}`
            }
        }
    },
    created() {
        if (this.listStatuses.length === 0) {
            this.fetchStatuses();
        }
        this.fetchLocation();
    },
    methods: {
        search() {
            if (!this.sortType) {

            } else {
                this.changeLimit(100)
            }
            let obj = {
                location: this.location,
                purchase_period: this.purchase,
                planting_period: this.planting,
                status: this.getStatus,
                limit: this.limit,
                group_by: this.sortTypeCategory,
                skip: this.skip
            };
            this.searchTrees(obj)
                .then(() => {

                });
            this.openSearch(false);
            this.typeSearchStatus(true)
        },
        ...mapActions({
            fetchStatuses: `trees/${SEARCH_PARAMS_STATUSES}`,
            fetchLocation: `trees/${SEARCH_PARAMS_LOCATIONS}`,
            fetchTrees: `trees/${PERSONAL_TREES}`,
            searchTrees: `trees/${TREES_SEARCH}`,
        }),
        ...mapMutations({
            openSearch: `trees/${OPEN_SEARCH_TREE}`,
            changeLocation: `trees/${TREE_SEARCH_LOCATION}`,
            changePurchase: `trees/${TREE_SEARCH_PURCHASE}`,
            changeYearPlanting: `trees/${TREE_SEARCH_PLANTING}`,
            changeStatus: `trees/${TREE_SEARCH_STATUS}`,
            typeSearchStatus: `trees/${PERSONAL_TYPE_SEARCH}`,
            changeSkip: `trees/${TREES_SKIP}`,
            changeLimit: `trees/${TREES_LIMIT}`,
            changeSeason: `trees/${CHANGE_FILTER_SEASON}`,
        }),
        openSelectLocation() {
            setTimeout(() => {
                this.openSelect = !this.openSelect
            }, 10)
        },
        openSelectState() {
            setTimeout(() => {
                this.openSelect2 = !this.openSelect2
            }, 10)
        },
        openSelectYearPlanting() {
            setTimeout(() => {
                this.openSelect3 = !this.openSelect3
            }, 10)
        },
        openSelectSeasonPlanting() {
            setTimeout(() => {
                this.openSelect4 = !this.openSelect4
            }, 10)
        },
        resetSearch() {
            this.changeLocation({
                id: '',
                name: ''
            });
            this.changePurchase('');
            this.changeYearPlanting('');
            this.changeSeason({name: '', value: ''});
            this.changeStatus({
                id: '',
                title: '',
                type: ''
            });
            this.search();
            this.openSearch(false)
        }
    }
}
