import axios from "axios";

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    getAxios: async ({commit}, payload) => {
        return axios.create({
            baseURL: process.env.VUE_APP_NEW_API_URL,
            headers: {
                Accept: 'application/json',
                'X-Lang-Header': payload.lang,
                Authorization: payload.auth,
            },
        });
    },
    createInsuranceRequestAsync: async ({dispatch,commit}, {headers}) => {
        const myAxios = await dispatch('getAxios', headers);
        return await myAxios.post('/api/services/insurance/create-request')
            .then(({data}) => {
                return {
                    isSuccess: true,
                }
            })
            .catch(e => {
                return {
                    isSuccess: false,
                    exception: e.response.data
                }
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
