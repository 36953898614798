import {mapGetters, mapMutations} from 'vuex';
import {
    SHOW_CASH_POPUP,
    IS_LOGIN_USER,
    BUY_TREE_POPUP,
    ADD_BASKET
} from '@/store/mutation-types'

export default {
    name:'search-content',
    computed:{
        ...mapGetters({
            search: 'search/search',
            is_auth: `auth/is_auth`,
        })
    },
    methods:{
        ...mapMutations({
            addBasket: `shop/${ADD_BASKET}`,
            buyTreePopUp: `system/${BUY_TREE_POPUP}`,
            forEnter: `system/${IS_LOGIN_USER}`,
            cashPopUp: `system/${SHOW_CASH_POPUP}`,
        }),
        addToBasket(item, index){
            if(this.is_auth){
                this.gtm('buyavt');
                this.$analytics.fbq.event('AddToCart');
            }else{
                this.gtm('buy');
            }
            this.cashPopUp(true);
            this.addBasket({
                index: index,
                item: item
            });
        },
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
        changeWord(number){
            let titles = [this.$t('year'), this.$t('years'), this.$t('yearsOld')];
            let cases = [2, 0, 1, 1, 1, 2];
            return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
        },
        addBasketNoAuth(){
            this.forEnter(true);
            this.gtm('buy')
        }
    },
}
