import {mapActions, mapGetters, mapMutations} from 'vuex'


export default {
  name: 'popup-info-confidant',

  computed: {

  },
  methods: {
    ...mapMutations({
      showFeedback: `profile/CHANGE_TEXT_CONFIDANT`,
    }),
  }
}
