import {mapGetters, mapActions, mapMutations} from 'vuex'
import {
    FAQ_LOAD_CATEGORIES,
    FAQ_LOAD_CONTENT,
    SHOW_FEEDBACK
} from "@/store/mutation-types";

export default {
    name: 'faq',
    data() {
        return {
            listId: [],
            categoryId: [],
            currentTitle: 'Регистрация',
            show: true,
            showLoading: false
        }
    },
    metaInfo: {
        title: 'Часто задаваемые вопросы | BioDeposit',
        meta: [
            { name: 'description', content: '【Часто задаваемые вопросы - BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
        ]
    },
    computed: {
        ...mapGetters({
            list: `faq/list`,
            categories: `faq/categories`,
        })
    },
    mounted() {
        this.fetchCategories()
            .then(response => {
                if (this.$route.query.hasOwnProperty('category') && this.$route.query.hasOwnProperty('item')) {
                    const category = parseInt(this.$route.query.category);
                    const item = parseInt(this.$route.query.item);
                    let indexContent = 0;
                    let indexCategory = 0;
                    //find index category
                    response.data.data.forEach((f, k) => {
                       if(f.id === category){
                           indexCategory = k;
                       }
                    });
                    this.currentTitle = response.data.data[indexCategory].name;
                    this.categoryId.push(category);
                    this.fetchList(this.categoryId)
                        .then(resp => {
                            //find index content
                            resp.data.data.forEach((t, i) => {
                                if (t.id === item) {
                                    indexContent = i;
                                }
                            });
                            setTimeout(() => {
                                window.scrollTo({
                                    'behavior': 'smooth',
                                    'left': 0,
                                    'top': document.querySelectorAll('.faq__content-item')[indexContent].offsetTop
                                });
                            }, 200);

                            this.toggleList(indexContent)
                        })
                } else {
                    this.currentTitle = response.data.data[0].name;
                    this.categoryId.push(response.data.data[0].id);
                    this.fetchList(this.categoryId);
                }
            });
        if (window.innerWidth < 750) {
            this.show = false
        }
    },
    methods: {
        ...mapActions({
            fetchList: `faq/${FAQ_LOAD_CONTENT}`,
            fetchCategories: `faq/${FAQ_LOAD_CATEGORIES}`,
        }),
        ...mapMutations({
            changeFeedback: `feedback/${SHOW_FEEDBACK}`,
        }),
        listActiveClass(tag) {
            const isSelected = this.listId.includes(tag);
            return {
                'active': isSelected,
            }
        },
        listToggle(tag) {
            return this.listId.includes(tag);
        },
        toggleList(id) {
            if (this.listId.length !== 0) {
                let index = this.listId.indexOf(id);
                if (index === -1) {
                    this.listId.push(id);
                } else {
                    this.listId.splice(index, 1);
                }
            } else {
                this.listId.push(id);
            }
        },
        categoryActiveClass(item) {
            const isSelected = this.categoryId.includes(item.id);
            return {
                'active': isSelected,
            }
        },
        loadCategory(item) {
            if (window.innerWidth < 750) {
                this.show = false
            }
            this.showLoading = true;
            this.fetchList(item.id)
                .finally(() => {
                    this.showLoading = false
                });
            this.currentTitle = item.name;
            this.categoryId = [];
            this.listId = [];
        },
    }

}
