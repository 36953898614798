import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
    NOT_AVAIBLE,
    USER_TOKEN,
} from '@/store/mutation-types';

export default {
    name: 'buy-default-tree',
    data(){
        return {
            sliderValue: 0,
        }
    },
    computed: {
        ...mapGetters({
            token: `exchangeToken/token`,
        }),
        totalPrice(){
            return this.token.price * this.sliderValue;
        },
        watchCount(){
            if(this.sliderValue > this.token.count){
                return true
            }else{
                return false
            }
        }
    },
    created(){
        this.fetchParams();
    },
    methods: {
        ...mapActions({
            fetchParams: `exchangeToken/${USER_TOKEN}`,
        }),
        ...mapMutations({
            notAvaible: `system/${NOT_AVAIBLE}`,
        }),
        buyTreeToken(){
            if(this.paySystem !== null){
                this.showPopup = true;
            }else{
                this.showPayment = true
                setTimeout(() => {
                    this.showPayment = false;
                }, 700)
            }

        }
    }
}
