import {mapActions, mapGetters, mapMutations} from 'vuex'


export default {
  name: 'popup-futures-balance',

  computed: {

  },
  methods: {
    ...mapMutations({
      showPopupFuturesBalance: `profile/CHANGE_TEXT_FUTURES_BALANCE`,
    }),
  }
}
