import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import { english } from 'flatpickr/dist/l10n/default';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VueAdsPagination from 'vue-ads-pagination';
import { VueAdsPageButton } from 'vue-ads-pagination';
import {
    WITHDRAWAL_HISTORY,
    WITHDRAWAL_HISTORY_CHANGE_TO,
    WITHDRAWAL_HISTORY_CHANGE_FROM,
    WITHDRAWAL_HISTORY_CHANGE_SKIP,
    WITHDRAWAL_HISTORY_CHANGE_PAGE,
    SHOW_POPUP_TWO_AUTH_CODE,
    RESET_WITHDRAWAL_HISTORY_COMPONENT
} from '@/store/mutation-types';
import axios from "axios";
export default {
    name: 'history-withdrawal',
    data(){
        return {
            localKey: [],
            config: {
                wrap: true,
                altFormat: 'M j, Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                locale: this.$store.getters.urlPrefix === 'ru' ? Russian : english,
                maxDate:  "today"
            },
            listId: [],
        }
    },
    components: {
        flatPickr,
        VueAdsPagination,
        VueAdsPageButton
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
            list: `withdrawalHistory/list`,
            skip: `withdrawalHistory/skip`,
            page: `withdrawalHistory/page`,
            to: `withdrawalHistory/to`,
            limit: `withdrawalHistory/limit`,
            from: `withdrawalHistory/from`,
            length: `withdrawalHistory/lengthList`,
            loading: `withdrawalHistory/loading`,
        }),
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.listId = [];
            this.fetchList()
                .catch(error => {
                    if(error.response.status === 422){
                        this.$toaster.error(this.$t('validationTwoFactor'));
                        this.$router.push({ name: 'personal' });
                    }
                    if(error.response.status === 424){
                        this.$toaster.error(error.response.data.error.message)
                    }
                })
        }
    },
    created(){
        this.fetchList()
            .catch(error => {
                if(error.response.status === 422){
                    this.$toaster.error(this.$t('validationTwoFactor'));
                    this.$router.push({ name: 'personal' });
                }
                if(error.response.status === 424){
                    this.$toaster.error(error.response.data.error.message)
                }
            });
        axios.defaults.headers.common = {
            'One-Time-Pass' : localStorage.getItem('keyRequest'),
        };
    },
    methods: {
        ...mapActions({
           fetchList: `withdrawalHistory/${WITHDRAWAL_HISTORY}`
        }),
        ...mapMutations({
            changePopup: `profile/${SHOW_POPUP_TWO_AUTH_CODE}`,
            changeTo: `withdrawalHistory/${WITHDRAWAL_HISTORY_CHANGE_TO}`,
            changeFrom: `withdrawalHistory/${WITHDRAWAL_HISTORY_CHANGE_FROM}`,
            changeSkip: `withdrawalHistory/${WITHDRAWAL_HISTORY_CHANGE_SKIP}`,
            changePage: `withdrawalHistory/${WITHDRAWAL_HISTORY_CHANGE_PAGE}`,
            reset: `withdrawalHistory/${RESET_WITHDRAWAL_HISTORY_COMPONENT}`,
        }),
        resetHeaders(){
            axios.defaults.headers.common = {
                'Authorization' : `Bearer ${localStorage.getItem('user_token')}`,
                'X-localization' : this.$store.getters.currentLanguage.translationKey
            };
        },
        listActiveClass(tag){
            const isSelected = this.listId.includes(tag);
            return {
                'active': isSelected,
            }
        },
        listToggle(tag){
            return this.listId.includes(tag);
        },
        toggleList(id){
            if(this.listId.length !== 0){
                let index = this.listId.indexOf(id);
                if(index === -1){
                    this.listId.push(id);
                }else{
                    this.listId.splice(index, 1);
                }

            }else{
                this.listId.push(id);
            }
        },
        selectDateTo(selectedDates, dateStr, instance){
            this.changeSkip(0);
            this.changePage(0);
            this.changeTo(dateStr);
            this.fetchList()
                .catch(error => {
                    if(error.response.status === 422){
                        this.$toaster.error(this.$t('validationTwoFactor'));
                        this.$router.push({ name: 'personal' });
                    }
                    if(error.response.status === 424){
                        this.$toaster.error(error.response.data.error.message)
                    }
                })
        },
        selectDateFrom(selectedDates, dateStr, instance){
            this.changeSkip(0);
            this.changePage(0);
            this.changeFrom(dateStr);
            this.fetchList()
                .catch(error => {
                    if(error.response.status === 422){
                        this.$toaster.error(this.$t('validationTwoFactor'));
                        this.$router.push({ name: 'personal' });
                    }
                    if(error.response.status === 424){
                        this.$toaster.error(error.response.data.error.message)
                    }
                })
        }
    },
    destroyed(){
        this.reset();
        this.resetHeaders();
    }
}
