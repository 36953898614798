import {$http} from '@/https';
import {globalLoader} from '@/helpers/variables/index'
import {
  CHANGE_LOADING_COUNTRY_CODE,
  CHANGE_SETTING_COUNTRY,
  CONFIG,
  DYNAMIC_PAGES,
  FIELD_SETTING,
  GIVE_SETTING,
  PUT_UP_SETTING,
  SELL_SETTING,
  SETTING,
  SETTING_CALCULATE
} from '@/store/mutation-types';

const state = {
  config: null,
  setting: null,
  page: null,
  isLoadingCountryCode: false,
};

const getters = {
  config: state => state.config,
  page: state => state.page,
  setting: state => state.setting,
  isLoadingCountryCode: state => state.isLoadingCountryCode,
};

const actions = {
  [CONFIG]: async ({commit}) => {
    try {
      const result = await $http.get('v1/configs');
      commit(CONFIG, result.data.data);
    } catch (e) {
      throw e;
    }
  },
  [DYNAMIC_PAGES]: async ({commit}, payload) => {
    try {
      globalLoader(true);

      const result = await $http.get(`v1/page/${payload}`);
      commit(DYNAMIC_PAGES, result.data);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [SETTING]: async ({commit}) => {
    try {
      const result = await $http.get('v1/settings');
      //transform result
      const setting = {
        country: result.data.country,
        countryCode: result.data.country_code,
        upperYieldTree: result.data.upperYieldTree,
        defaultPriceTree: result.data.tree_price_first_sale,
        upPriceTree: result.data.upPriceTree_percent_to_first_period + 1,
        upPriceTreeAdult: result.data.upPriceTree_percent_after_first_period + 1,
        commission: result.data.commissionClient,
        commissionInvestor: result.data.commissionInvestor,
        commissionSale: result.data.shop_commission / 100,
        priceOil: result.data.oil_price * 1000,
        commissionFirstSale: result.data.shop_commission_first_sale / 100,
        giveDefaultBan: result.data.sale_freezing_period.from,
        giveDefaultFrost: result.data.dividend_frozen_period.from,
        minYearPlanting: result.data.min_year_planting,
        maxYearPlanting: result.data.max_year_planting,
        expressMaxBuyTrees: result.data.expressMaxBuyTrees,
        expressMaxGiftTrees: result.data.expressMaxGiftTrees,
        putUpGiftCommission: result.data.shop_commission_gift_sale,
        putUpGiftPrice: result.data.tree_price_gift_sale,
        helperVideo: result.data.helper_video,
        selectCountExpressSale: result.data.selectCountExpressSale,
        lastStarPartner: result.data.famous_partner_photo,
        serverTime: result.data.server_time,
        futuresMultiplier: result.data.futures_multiplier,
        futuresPrice: result.data.futures_price,
        levels: result.data.levels,
      };
      commit(`calculate/${SETTING_CALCULATE}`, setting, {root: true});
      commit(`field/${FIELD_SETTING}`, setting, {root: true});
      commit(`give/${GIVE_SETTING}`, setting, {root: true});
      commit(`putUpGift/${PUT_UP_SETTING}`, setting, {root: true});
      commit(`sell/${SELL_SETTING}`, setting, {root: true});
      commit(SETTING, setting);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  [CONFIG](state, config) {
    state.config = config;
  },
  [DYNAMIC_PAGES](state, page) {
    state.page = page;
  },
  [SETTING](state, setting) {
    state.setting = setting;
  },
  [CHANGE_LOADING_COUNTRY_CODE](state, status) {
    state.isLoadingCountryCode = status;
  },
  [CHANGE_SETTING_COUNTRY](state, obj) {
    state.setting.country = obj.country;
    state.setting.countryCode = obj.countryCode;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
