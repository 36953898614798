import {$http} from '@/https';
import {fixBody, globalLoader} from '@/helpers/variables/index'
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  CHECK_FREE_EMAIL,
  FEEDBACK_INVESTOR,
  FEEDBACK_INVESTOR_SHOW,
  RE_ACTIVATION,
  REGISTRATION,
  REGISTRATION_SHOW,
  RESET_BASKET,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD_EMAIL,
  SHOW_RESTORE_PASSWORD_EMAIL,
  SHOW_SING_IN,
  CUSTOM_HEADERS_PARAMS_CID,
  CUSTOM_HEADERS_PARAMS_MSG,
} from '../mutation-types';

import {SING_IN_WITH_FACEBOOK, SING_IN_WITH_GOGGLE} from '../action-types'

const state = {
  isAuthentificated: false,
  showRegistration: false,
  showSingIn: false,
  showRestorePasswordEmail: false,
  showRegistrationInvestor: false,
};

const getters = {
  is_auth: state => state.isAuthentificated,
  showRegistration: state => state.showRegistration,
  showRegistrationInvestor: state => state.showRegistrationInvestor,
  showSingIn: state => state.showSingIn,
  showCustomHeadersParamsCid: state => state.showCustomHeadersParamsCid,
  showCustomHeadersParamsMsg: state => state.showCustomHeadersParamsMsg,
  showRestorePasswordEmail: state => state.showRestorePasswordEmail,
};

const actions = {
  [CHECK_FREE_EMAIL]: async ({commit}, payload) => {
    try {
      return await $http.post('v1/check_email', payload);
    } catch (e) {
      throw e;
    }
  },
  [AUTH_LOGIN]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('login', payload);
      commit(AUTH_LOGIN, result.data.access_token);
      return result;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [SING_IN_WITH_GOGGLE]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('oauth/google/login', payload);
      commit(AUTH_LOGIN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [SING_IN_WITH_FACEBOOK]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/oauth/facebook/login', payload);
      commit(AUTH_LOGIN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [AUTH_LOGOUT]: async ({commit}) => {
    try {
      globalLoader(true);
      return await $http.post('logout');
    } catch (e) {
      throw e;
    } finally {
      commit(AUTH_LOGOUT);
      commit(`shop/${RESET_BASKET}`, [], {root: true});
      globalLoader(false);
    }
  },
  [REGISTRATION]: async ({commit}, payload) => {
    try {
      globalLoader(true);

      return await $http.post('base_register', payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [FEEDBACK_INVESTOR]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      return await $http.post('v1/investor/feedback', payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [RE_ACTIVATION]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      return await $http.get(`activation/resend?email=${payload}`);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [RESTORE_PASSWORD_EMAIL]: async ({commit}, payload) => {
    try {
      globalLoader(true);

      return await $http.post('password/remind', payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [RESTORE_PASSWORD]: async ({commit}, payload) => {
    try {
      globalLoader(true);

      return await $http.post('password/reset', payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
};

const mutations = {
  [AUTH_LOGIN](state, token) {
    localStorage.setItem('user_token', token);
    state.isAuthentificated = true;
  },
  [AUTH_LOGOUT](state) {
    localStorage.removeItem('user_token');
    state.isAuthentificated = false;
    setTimeout(() => {
      location.reload();
    }, 300)

  },
  [REGISTRATION_SHOW](state, status) {
    state.showRegistration = status;
    window.scrollTo(0, 0);
    setTimeout(function () {
      fixBody(status)
    }, 200)
  },
  [FEEDBACK_INVESTOR_SHOW](state, status) {
    state.showRegistrationInvestor = status;
    fixBody(status)
  },
  [SHOW_SING_IN](state, status) {
    state.showSingIn = status;
    window.scrollTo(0, 0);
    setTimeout(function () {
      fixBody(status)
    }, 200)
  },
  [CUSTOM_HEADERS_PARAMS_MSG](state, status) {
    state.showCustomHeadersParamsMsg = status;
    window.scrollTo(0, 0);
    setTimeout(function () {
      fixBody(status)
    }, 200)
  },
  [CUSTOM_HEADERS_PARAMS_CID](state, status) {
    state.showCustomHeadersParamsCid = status;
    window.scrollTo(0, 0);
    setTimeout(function () {
      fixBody(status)
    }, 200)
  },
  [SHOW_RESTORE_PASSWORD_EMAIL](state, status) {
    state.showRestorePasswordEmail = status;
    setTimeout(function () {
      fixBody(status)
    }, 200)
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
