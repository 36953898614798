import {$http} from '@/https';
import {fixBody} from '@/helpers/variables/index'
import {
    SHOW_VIDEO_HELP_CALCULATE,
    SHOW_TEXT_HELP_CALCULATE,
    TEXT_POPUP_CONTENT,
    HELPER_CONTENT_FILTER,
    HELPER_CONTENT_LOADING_FILTER
} from '../mutation-types';

const state = {
    videoUrl: '',
    showTextHelp: false,
    showVideoHelp: false,
    helperContent: null,
    helperContentFilter: null,
    helperContentFilterLoading: false,
};

const getters = {
    showTextHelpCalc: state => state.showTextHelp,
    helperContentFilterLoading: state => state.helperContentFilterLoading,
    helperContentFilter: state => state.helperContentFilter,
    showVideoHelp: state => state.showVideoHelp,
    helperContent: state => state.helperContent,
};

const actions = {
    [HELPER_CONTENT_FILTER]: async ({commit}) => {
        commit(HELPER_CONTENT_FILTER, null);
        commit(HELPER_CONTENT_LOADING_FILTER, true);
        try {
            let result = await $http.get(`v1/variables/filter_info`);
            commit(HELPER_CONTENT_FILTER, result.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(HELPER_CONTENT_LOADING_FILTER, false);
        }
    },
};

const mutations = {
    //filter
    [HELPER_CONTENT_FILTER](state, helpText) {
        state.helperContentFilter = helpText;
    },
    [HELPER_CONTENT_LOADING_FILTER](state, status) {
        state.helperContentFilterLoading = status;
    },
    //end
    [SHOW_TEXT_HELP_CALCULATE](state, param) {
        state.showTextHelp = param;
        fixBody(param)
    },
    [SHOW_VIDEO_HELP_CALCULATE](state, param) {
        state.showVideoHelp = param;
        fixBody(param)
    },

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
