import { mapGetters } from "vuex";
import axios from 'axios';
export default {
    name: 'apple-pay',
    props: {
        amount: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            session: null,
            merchantValidateURL: '',
            showBtn: true
        }
    },
    computed: {
      ...mapGetters({
          setting:'main/setting',
      })
    },
    mounted() {
        this.checkingDeviceApplePay();
    },
    methods: {
        checkingDeviceApplePay(){
            if (window.ApplePaySession) {
                console.log('Устройство поддерживает ApplePay (Web)');

                const merchantIdentifier = 'example.com.store';
                const promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
                promise.then(function (canMakePayments) {
                    if (canMakePayments){
                        // Display Apple Pay button here.
                        this.showBtn = true;
                    }
                });
            } else {
                console.log('Устройство не поддерживает ApplePay (Web)');
            }
        },
        onApplePaymentButtonClicked(){
            this.createApplePaySession();
            Promise.all([ this.onValidateMerchant, this.onPaymentAuthorized ])
                .then(() => {
                    this.session.begin();
                }).catch(error => {
                   console.log('Promise.all ->', error);
                })

        },
        createApplePaySession(){
            const request = {
                countryCode: this.setting.countryCode,
                currencyCode: 'USD',
                supportedNetworks: ['visa', 'masterCard'],
                merchantCapabilities: ['supports3DS'],
                total: { label: 'Your Merchant Name', amount: this.amount },
            };
            this.session = new ApplePaySession(3, request);
        },
        async onValidateMerchant(){
            this.session.onvalidatemerchant = async event => {
                const merchantValidateURL = '';
                const data = {
                    appleValidationUrl:  event.validationURL
                };
                try {
                    return await axios({
                        method: 'post',
                        url: merchantValidateURL,
                        data: data,
                        headers: {
                            'Content-Type' :  'application/json'
                        }
                    }).then(response => {
                        this.session.completeMerchantValidation(response.ValidationResult);
                    }).catch(error => {
                        console.log('onvalidatemerchant error ->', error)
                    })
                }catch (e) {
                    throw e;
                }
            }
        },
        async onPaymentAuthorized(){
            this.session.onpaymentauthorized = async event => {
                const merchantAuthorizeURL = 'https://securepay.tinkoff.ru/v2/FinishAuthorize';
                const token = event.payment.token.paymentData;
                const data = {
                    EncryptedPaymentData: btoa(JSON.stringify(token)),
                    TerminalKey: 'Ключ терминала магазина',
                    PaymentId: 'Номер платежа, полученный на шаге 3',
                    Route: 'ACQ',
                    Source: 'ApplePay',
                    DATA: { ApplePayWeb: true },
                };
                try {
                    return await axios({
                        method: 'post',
                        url: merchantAuthorizeURL,
                        data: data,
                        headers: {
                            'Content-Type' :  'application/json'
                        }
                    }).then(response => {
                        let status = JSON.parse(response.data);
                        if (response.status === 200 && response.Success) {
                            status = ApplePaySession.STATUS_SUCCESS;
                        } else {
                            status = ApplePaySession.STATUS_FAILURE;
                            console.error(response);
                        }
                        this.session.completePayment(status);
                    }).catch(error => {
                        this.session.completePayment(ApplePaySession.STATUS_FAILURE);
                        console.log('onpaymentauthorized error ->', error)
                    })
                }catch (e) {
                    throw e;
                }
            };
        }
    }
}
