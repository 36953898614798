import { Carousel, Slide } from 'vue-carousel';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import VueGallery from 'vue-gallery';
import {
    GALLERY_PHOTO,
    GALLERY_VIDEO,
    RESET_GALLERY_PHOTO,
} from '@/store/mutation-types'

export default {
    data(){
        return{
            activePhoto:true,
            activeVideo:false,
            sliderPhoto: [],
            index: null,
            currentId: this.$router.currentRoute.params.id,
        }
    },
    computed:{
        ...mapGetters({
            galleryPhoto: 'gallery/galleryPhoto',
            galleryLink: 'gallery/galleryLink',
            videoLinks: 'gallery/videoLinks'
        })
    },
    created(){
        this.fetchContent(this.currentId);
    },
    methods:{
        ...mapActions({
            fetchContent: `gallery/${GALLERY_PHOTO}`,
        }),
        ...mapMutations({
           reset: `gallery/${RESET_GALLERY_PHOTO}`
        }),
        activeItem(item){
            // reset all elemets active status
            this.sliderItem.map(element =>{
                return element.active = false;
            })
            //find clicked element by id
            const foundElement = this.sliderItem.find(element => {
                return element.id === item.id;
            })
            foundElement.active = true;
        },
        startPhoto(){
          this.activePhoto = true;
           this.activeVideo = false;
        },
        startVideo(){
            this.activePhoto = false;
            this.activeVideo = true;
        }
    },
    components: {
        Carousel,
        Slide,
        'gallery': VueGallery
    },
    destroyed(){
        this.reset();
    }
};