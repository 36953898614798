import BingMapsMulti from '@/components/multi-bing-maps/index.vue';

import { mapGetters, mapActions } from "vuex";
import {
    FIELDS_MAP_LIST
} from "@/store/mutation-types";

export default {
    name: 'map-fields',
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            fieldsMapList: `map/fieldsMapList`,
            fieldsMapListLoading: `map/fieldsMapListLoading`,
        })
    },
    components: {
        BingMapsMulti,
    },
    created(){
        this.fetchList();
    },
    methods: {
        ...mapActions({
            fetchList: `map/${FIELDS_MAP_LIST}`
        }),
        goBack(){
            this.$router.go(-1)
        }
    },
    destroyed(){

    }
}
