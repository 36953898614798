import {mapActions, mapGetters, mapMutations} from 'vuex';
import {
    NEWS_DETAIL,
    GO_NEWS_CHANGE_POSITION_SCROLL,
    RESET_NEWS_DETAIL
} from '@/store/mutation-types';

export default {
    name: 'news-detail',
    data(){
        return{
            currentId: +this.$router.currentRoute.params.id,
        }
    },
    computed:{
        ...mapGetters({
            newsDetail: 'news/newsDetail',
            positionScroll: 'news/positionScroll',
        })
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.rootScreen = from.fullPath === '/'
        })
    },
    created(){
        this.fetchContent(this.currentId);
    },
    mounted(){
        window.scrollTo(0, 0);
    },
    methods:{
        ...mapActions({
            fetchContent: `news/${NEWS_DETAIL}`
        }),
        ...mapMutations({
            changeGoBachType: `news/${GO_NEWS_CHANGE_POSITION_SCROLL}`,
            reset: `news/${RESET_NEWS_DETAIL}`
        }),
        goBack(){
            if(this.rootScreen){
                this.$router.push({ name: 'news' })
            }else{
                this.changeGoBachType(this.rootScreen);
                this.$router.push({ name: 'news' })
            }
        }
    },
    destroyed(){
        this.reset();
    }
}
