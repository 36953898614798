import { mapMutations, mapGetters, mapActions} from 'vuex';
import GooglePay from '@/components/widgets/google-pay/index.vue';
import ApplePay from '@/components/widgets/apple-pay/index.vue';
import {
    FETCH_PAYMENTS_SYSTEM,
    SHOW_CASH_POPUP,
    SHOP_BUY_TREE,
    POPUP_BUY_SUCCESS,
    LOAD_USER_DATA,
    SHOP_FILTER_SEARCH,
    SHOW_PAYMENT_POPUP,
    RESET_BASKET
} from "@/store/mutation-types";

export default {
    name: 'shop',
    data(){
        return{
            payload:{
                tree_ids:[],
                payment_system: null
            },
            filter:{
                season: [],
                min_planting_year:'',
                max_planting_year:'',
                min_price:'',
                max_price:'',
                limit:12,
                skip:0,
            },
            showSuccessPay: false,
            paySystem: null,
            showPayment: false,
        }
    },
    components: {
        GooglePay,
        ApplePay
    },
    computed:{
        ...mapGetters({
            list:'shop/list',
            treeIds:'shop/treeIds',
            addTreeForCash:'shop/addTreeForCash',
            treePrice: `shop/treePrice`,
            payments: `profileBuyTree/payments`,
            basket: 'shop/basket',
            setting: 'main/setting',
            user: `profile/user`,
        }),
        preparePayments(){
            return !!this.payments
                ? this.payments.filter(i => i.payment_system !== 'betatrans')
                : []
        },
        totalPrice(){
            let price = 0;
            this.basket.forEach(t => {
                price += parseInt(t.price);
            });
            return price
        }
    },
    created(){
        this.fetchPayments({countryCode: this.setting.countryCode});
    },
    methods:{
        ...mapActions({
            buyTree: `shop/${SHOP_BUY_TREE}`,
            updateUserData: `profile/${LOAD_USER_DATA}`,
            shopFilterSearch:`shop/${SHOP_FILTER_SEARCH}`,
            fetchPayments: `profileBuyTree/${FETCH_PAYMENTS_SYSTEM}`,
        }),
        ...mapMutations({
            cashPopUp: `system/${SHOW_CASH_POPUP}`,
            paymentPopup: `system/${SHOW_PAYMENT_POPUP}`,
            resetBasket :`shop/${RESET_BASKET}`,
            cashPopUpSuccess: `shop/${POPUP_BUY_SUCCESS}`,
        }),
        sendBuyRequest(){
            let arr = [];
            this.basket.forEach(t => {
                arr.push(t.id);
            });
            this.paymentPopup(false);
            this.payload.tree_ids = JSON.stringify(arr);
            this.buyTree(this.payload)
                .then(() => {
                    this.shopFilterSearch(this.filter);
                    this.updateUserData();
                    this.$router.push({name:'personal'})
                })
                .catch(error => {
                    if(error.response.status === 422){
                        this.$toaster.error(error.response.data.error.message);
                    }
                })
        },
        buyTreeLocal(){
            // error select pay method
            if(!this.payload.payment_system){
                this.$toaster.error(this.$t('payMethod'));
                return false;
            }

            // find current item in payment system
            const currentItemPayment  = this.payments.find(e => e.payment_system === this.payload.payment_system);

            // has restriction item
            if(currentItemPayment.restriction){

                // check max price payment system for selected payment
                if(this.totalPrice < parseInt(currentItemPayment.restriction.max.restriction)){
                    this.sendBuyRequest();
                }else{
                    this.$toaster.error(currentItemPayment.restriction.max.error);
                }
            }else{
                // not restriction object (balance)
                const total = this.user.balance + this.user.bonus_balance + this.user.futures_balance;

                // check user balance
                if(currentItemPayment.payment_system === 'balance'){
                    if(total < this.totalPrice){
                        this.$toaster.error(this.$t('notEnoughMoney'));
                    }else{
                        this.sendBuyRequest();
                    }
                }else{
                    this.sendBuyRequest();
                }
            }
        }
    }
}
