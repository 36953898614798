import {$http} from '@/https';
import _ from 'lodash';
import {
    SET_STAR_PARTNERS,
    SET_SHOW_DETAIL,
    SET_STAR_INFO,
    RESET_STAR_INFO,
    CHANGE_STAR_PARTNERS_SKIP,
    SET_META_STAR_PARTNER,
    SET_LOADING_STAR_PARTNERS,
    CHANGE_PARAMS_DETAIL_PARTNERS,
    CHANGE_RANDOM_INDEX_PARTNER
} from '../mutation-types';

import {
    GET_STAR_PARTNERS,
    GET_STAR_INFO
} from '../action-types';


function generateRandomNumber(){
    let index = _.random(0, state.list.length-1);
    if(index === state.randomIndex){
        generateRandomNumber();
    }else{
        state.randomIndex = index;
    }
}


const state = {
    list: [],
    paramsDetail: {
        show: false,
        id: 0
    },
    content: null,
    limit: 6,
    skip: 0,
    lengthList: 0,
    activeIndex: 0,
    randomIndex: 0,
    loading: false
};

const getters = {
    list: state => state.list,
    randomIndex: state => state.randomIndex,
    activeIndex: state => state.activeIndex,
    skip: state => state.skip,
    limit: state => state.limit,
    paramsDetail: state => state.paramsDetail,
    content: state => state.content,
    loading: state => state.loading,
    lengthList: state => state.lengthList,
};

const actions = {
    [GET_STAR_PARTNERS]: async ({commit}) => {
        try {
            commit(SET_LOADING_STAR_PARTNERS, true);

            const response = await $http.get(`v1/famous_partner?limit=${state.limit}&skip=${state.skip}`);
            commit(SET_STAR_PARTNERS, response.data.data);
            commit(SET_META_STAR_PARTNER, response.data.meta.count);
            setInterval(() => {
                commit(CHANGE_RANDOM_INDEX_PARTNER, response.data.meta.count)
            }, 10000);

            commit(CHANGE_STAR_PARTNERS_SKIP);
            return response;
        } catch (e) {
            throw e;
        }finally {
            commit(SET_LOADING_STAR_PARTNERS, false);
        }
    },
    [GET_STAR_INFO]: async ({commit}, payload) => {
        try {
            const response = await $http.get(`v1/famous_partner/${state.paramsDetail.id}`);
            commit(SET_STAR_INFO, response.data.data);
            return response;
        } catch (e) {
            throw e;
        }
    }
};

const mutations = {
    [SET_STAR_PARTNERS](state, list) {
        state.list.push(...list);
        let uniqList = _.uniq(state.list);
        state.list = uniqList;
    },
    [CHANGE_STAR_PARTNERS_SKIP](state){
        state.skip = state.skip+6;
    },
    [CHANGE_RANDOM_INDEX_PARTNER](state){
        if(state.list.length > 1){
            generateRandomNumber();
        }
    },
    [SET_STAR_INFO](state, content) {
        state.content = content;
    },
    [SET_META_STAR_PARTNER](state, count) {
        state.lengthList = count;
    },
    [RESET_STAR_INFO](state) {
        state.content = null;
    },
    [SET_SHOW_DETAIL](state, params) {
        state.paramsDetail = params;
        if(params.show){
            state.activeIndex = _.findIndex(state.list, e => e.id === params.id);
        }
    },
    [CHANGE_PARAMS_DETAIL_PARTNERS](state, param) {
        const index = _.findIndex(state.list, e => e.id === state.paramsDetail.id);
        if(param === 'prev'){
            state.activeIndex = index - 1;
            state.paramsDetail.id = state.list[state.activeIndex].id;
        }else{
            state.activeIndex = index + 1;
            state.paramsDetail.id = state.list[state.activeIndex].id;
        }
    },
    [SET_LOADING_STAR_PARTNERS](state, status) {
        state.loading = status
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
