import {mapActions, mapGetters, mapMutations} from 'vuex'
import {SEND_FEEDBACK, SHOW_FEEDBACK,} from "@/store/mutation-types";
import {validationMixin} from 'vuelidate';
import {email, required} from 'vuelidate/lib/validators';

export default {
  name: 'feedback',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        name: '',
        question: '',
        email: ''
      },
      currentLength: 0,
      maxLength: 500,
      validationErrors: {},
      showSuccess: false
    }
  },
  validations() {
    if (this.is_auth) {
      return {
        payload: {
          question: {
            required,
          }
        }
      }
    } else {
      return {
        payload: {
          name: {
            required,
          },
          email: {
            required,
            email
          },
          question: {
            required,
          }
        }
      }
    }
  },
  mounted() {
    if (this.is_auth) {
      this.payload.name = this.user.info.data.first_name;
      this.payload.email = this.user.email;
    }
  },
  computed: {
    ...mapGetters({
      loading: 'feedback/loading',
      user: 'profile/user',
      is_auth: 'auth/is_auth',
      social: 'contacts/social',
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push('');
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push('');
        });
      }
      return error;
    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.question.$dirty) {
        return error;
      }
      if (!this.$v.payload.question.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.question) {
        this.validationErrors.question.forEach((row) => {
          error.push('');
        });
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendFeedback: `feedback/${SEND_FEEDBACK}`
    }),
    ...mapMutations({
      showFeedback: `feedback/${SHOW_FEEDBACK}`,
    }),
    gtm(label) {
      this.$gtm.trackEvent({
        event: 'biodep',
        eventCategory: 'send',
        eventAction: 'form',
        eventLabel: label,
      });
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendFeedback(this.payload)
          .then(() => {
            this.gtm('contact');
            this.showSuccess = true;
          }).catch((error) => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
            let errors = error.response.data.errors;
            for (let key in errors) {
              this.$toaster.error(errors[key][0]);
            }
          }
        })
      }
    },
  }
}
