import _ from 'lodash';
import Vue from 'vue'
import {
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import vSelect from 'vue-select'
import {Money} from 'v-money'
import {showTextPopup} from '@/helpers/variables/index'

Vue.component('v-select', vSelect);

export default {
  name: 'calculate',
  components: {Money},
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false,
      },
      firstTutorial: true,
      currentId: +this.$router.currentRoute.params.id,
      deletedTree: [],
      showDeleteIcons: false,
      sliderValue: 7,
      val: 7,

    }
  },
  created() {
    this.addTree();
  },
  computed: {
    ...mapGetters({
      list: `calculate/list`,
      checkedTrees: `calculate/checkedTrees`,
      depositYears: `calculate/depositYears`,
      incomeTreesAllDeposit: `calculate/incomeTreesAllDeposit`,
      totalTreePrice: `calculate/totalTreePrice`,
      harvestIncome: `calculate/harvestIncome`,
      updatePriceTree: `calculate/updatePriceTree`,
      loadPersonalArea: `calculate/loadPersonalArea`,
      is_auth: `auth/is_auth`,
    }),
    totalResult() {
      return ((this.totalUpPriceTree + this.totalIncomeTree)).toFixed(2)
    },
    totalSumTree() {
      let result = 0;
      this.totalTreePrice.forEach(elem => {
        result += elem;
      });
      return result
    },
    totalUpPriceTree() {
      let result = 0;
      this.updatePriceTree.forEach(elem => {
        result += elem;
      });
      return result
    },
    totalIncomeTree() {
      let result = 0;
      this.incomeTreesAllDeposit.forEach(elem => {
        result += elem.amount;
      });
      return result
    },
    seasonDefault() {
      const season = [
        {label: this.$t('spring'), value: 2},
        {label: this.$t('autumn'), value: 4}
      ];
      return season;
    },
  },
  watch: {
    sliderValue() {
      if (this.sliderValue < 1 || this.sliderValue === '') {
        this.val = 1;
        setTimeout(() => {
          this.sliderValue = 1;
        }, 200)
      } else {
        this.val = this.sliderValue;

      }
    },
    list() {
      this.list.map(elem => {
        if (elem.season === 2) {
          elem.season = {
            label: this.$t('spring'),
            value: 2
          }
        }
        if (elem.season === 4) {
          elem.season = {
            label: this.$t('autumn'),
            value: 4
          }
        }
      });
    }
  },
  methods: {
    changeDeleteIcons() {
      this.showDeleteIcons = !this.showDeleteIcons;
      if (!this.showDeleteIcons && this.deletedTree.length > 0) {
        this.deleteTrees(this.deletedTree);
        this.deletedTree = [];
      }
    },
    ...mapMutations({
      updateYear: `calculate/CALCULATE_UPDATE_DEPOSIT_YEAR`,
      updatePrice: `calculate/CALCULATE_UPDATE_PRICE`,
      updateCount: `calculate/CALCULATE_UPDATE_COUNT`,
      addTree: `calculate/CALCULATE_ADD_TREE`,
      updateDate: `calculate/CALCULATE_UPDATE_DATE`,
      updateSeason: `calculate/CALCULATE_UPDATE_SEASON`,
      deleteTrees: `calculate/CALCULATE_DELETE_TREE`,
      notAvaible: `system/NOT_AVAIBLE`,
      showLoadPersonalArea: `calculate/SHOW_LOADING_PERSONAL_AREA_CALCULATE`,
      resetListTree: `calculate/CALCULATE_RESET_LIST_TREE`,
      showCategory: `give/GIVE_SHOW_CATEGORIES`,
      incrementCount: `calculate/CALCULATE_INCREMENT_COUNT`,
      decrementCount: `calculate/CALCULATE_DECREMENT_COUNT`,
      changeCount: `calculate/CALCULATE_CHANGE_COUNT`,
      incrementPrice: `calculate/CALCULATE_INCREMENT_PRICE`,
      decrementPrice: `calculate/CALCULATE_DECREMENT_PRICE`,
      changePrice: `calculate/CALCULATE_CHANGE_PRICE`,
      showCalculateFilter: `calculate/SHOW_FILTER_TREE_POPUP`,
      changeCountPrice: `calculate/CALCULATE_CHANGE_COUNT_PRICE`,
      resetCalculate: `calculate/RESET_CALCULATE`,
      openVideoHelp: `helpCalculate/SHOW_VIDEO_HELP_CALCULATE`,
    }),
    changeCountLocal(event, id) {
      let payload = {
        id: id,
        count: +event
      };
      this.changeCount(payload);
    },
    changePriceLocal(event, id) {
      let payload = {
        id: id,
        price: +event
      };
      this.changePrice(payload);
    },
    changeDate(event, id) {
      let payload = {
        id: id,
        year: event
      };
      this.updateDate(payload)
    },
    changeSeason(event, id) {
      let payload = {
        id: id,
        season: event
      };
      this.updateSeason(payload)
    },
    changeTotalTreePrice: _.debounce(function (event, id) {
      let payload = {
        id: id,
        price: +event
      };
      this.changeCountPrice(payload);
    }, 700),
    goBack() {
      this.$router.go(-1);
    },

    openTutorial() {
      if (this.list.length === 0) {
        this.addTree();
        setTimeout(() => {
          this.starTutorial();
          this.firstTutorial = false;
        }, 50)
      } else {
        setTimeout(() => {
          this.starTutorial();
          this.firstTutorial = false;
        }, 50)
      }
    },
    starTutorial() {
      var intro = introJs();
      intro.setOptions({
        nextLabel: this.$t('guideNext'),
        prevLabel: this.$t('guideBack'),
        skipLabel: 'Exit',
        steps: [
          {
            element: document.querySelector('#step1'),
            intro: "<h4>" + this.$t('guideStep1') + "</h4> <p>" + this.$t('guideCalculateStep1') + "</p>",
            position: 'top'
          },
          {
            element: document.querySelector('#step2'),
            intro: "<h4>" + this.$t('guideStep2') + "</h4> <p>" + this.$t('guideCalculateStep2') + "</p>",
            position: 'top'
          },
          {
            element: '#step3',
            intro: "<h4>" + this.$t('guideStep3') + "</h4> <p>" + this.$t('guideCalculateStep3') + "</p>",
            position: 'top'
          },
          {
            element: '#step4',
            intro: "<h4>" + this.$t('guideStep4') + "</h4> <p>" + this.$t('guideCalculateStep4') + "</p>",
            position: 'top'
          },
          {
            element: '#step5',
            intro: "<h4>" + this.$t('guideStep5') + "</h4> <p>" + this.$t('guideCalculateStep5') + "</p>",
            position: 'left',
            skipLabel: 'Exit',
          },
        ]
      });
      intro.start();
    },
  },
  destroyed() {
    this.resetCalculate();
  },
}
