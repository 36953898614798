import {$http} from '@/https';
import {
    SEARCH,
    RESET_SEARCH,
    SHOW_SEARCH_LOADING
} from '../mutation-types.js'

const state = {
    search: null,
    showSearchLoading: false
};

const getters = {
    search: state => state.search,
    showSearchLoading: state => state.showSearchLoading,
};

const actions = {
    [SEARCH]: async ({commit}, payload) => {
        try {
            commit(SHOW_SEARCH_LOADING, true);
            commit(RESET_SEARCH);

            const response = await $http.get(`/v1/search?search_text=${payload}`);
            commit(RESET_SEARCH);
            commit(SEARCH, response.data);
        }catch (e) {
            throw e;
        }finally {
            commit(SHOW_SEARCH_LOADING, false);
        }
    }
};

const mutations ={
    [SEARCH](state,list){
        state.search = list;
    },
    [SHOW_SEARCH_LOADING](state, status){
        state.showSearchLoading = status;
    },
    [RESET_SEARCH](state){
        state.search = null;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
