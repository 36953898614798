import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    HOWITWORKS_LOAD,
} from "@/store/mutation-types";


export default {
    name: 'how-it-works',
    computed: {
        ...mapGetters({
            content: `howItWorks/content`,
        })
    },
    metaInfo: {
        title: 'Как работают инвестиции в BioDeposit',
        meta: [
            { name: 'description', content: '【Как работает BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
        ]
    },
    created(){
        this.fetchContent();
    },
    beforeRouteLeave (to, from, next) {
        next()
    },
    methods: {
        ...mapActions({
            fetchContent: `howItWorks/${HOWITWORKS_LOAD}`
        }),
    }

}
