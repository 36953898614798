import {$http} from '@/https';
import { fixBody } from '@/helpers/variables/index'
import {
    GET_QR_CODE,
    GET_QR_ID,
    SHOW_GET_QR_CODE,
} from '../mutation-types.js';

const state = {
    getQrCodeLink: null,
    showPopupQr: false,
    numbQrId: null,
};

const getters = {
    getQrCodeLink: state => state.getQrCodeLink,
    showPopupQr: state => state.showPopupQr,
    numbQrId: state => state.numbQrId,
};

const actions = {
    [GET_QR_CODE]: async ({commit}) => {
        try {
            const response = await $http.get(`v1/trees/qr_code/${state.numbQrId}`);
            commit(GET_QR_CODE, response.data);
        }catch (e) {
            throw e;
        }
    }
};

const mutations = {
    [GET_QR_CODE](state, status){
        state.getQrCodeLink = status;
    },
    [SHOW_GET_QR_CODE](state, status){
        if(!status){
            state.getQrCodeLink = null;
        }
        state.showPopupQr = status;
        fixBody(status);
    },
    [GET_QR_ID](state, id){
        state.numbQrId = id;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
