import { required, email, minLength, sameAs    } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    LOAD_USER_DATA,
    LOAD_USER_CONFIDANT,
    UPDATE_USER_CONFIDANT,
    UPDATE_USER_DATA,
    UPDATE_USER_PASSWORD,
    PERSONAL_CHANGE_EDIT_DATA_STATUS,
    PERSONAL_CHANGE_EDIT_PASSWORD_STATUS,
    CHANGE_OLD_PASSWORD,
    CHANGE_NEW_PASSWORD,
    CHANGE_CONFIRMATION_PASSWORD,
    PERSONAL_RESET_PASSWORD,
    PERSONAL_VALIDATIONS_ERRORS,
    POPUP_REMOVE_PROFILE
} from '@/store/mutation-types';

export default {
    name: 'trustee',
    data(){
        return {
            showPassword: true,
            userConfidantEdit: false,
            checkFirstEdit: true,
            lastEmail:'',
        }
    },
    validations: {
        userConfidant: {
            birthday: {
                required
            },
            last_name: {
                required
            },
            first_name: {
                required
            },
            phone: {
                required,
                minLength: minLength(12),
            },
            email: {
                required,
                email
            },
        }

    },
    watch: {
        clearErrors(){
           this.resetErrorsPassword();
        },



    },
    components: {
    },
    mounted(){
        console.log('sdaasd', this.setCodeConfirmStatus)
        this.fetchUserConfidant()
          .then(()=>{
              this.lastEmail = this.userConfidant.email
              if(
                this.userConfidant.first_name === "" &&
                this.userConfidant.last_name === "" &&
                this.userConfidant.phone === "" &&
                this.userConfidant.email === "" &&
                this.userConfidant.birthday === null
              ){
                  this.checkFirstEdit = true
              }else {
                  this.checkFirstEdit = false
              }
          })


    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
            userConfidant: `profile/userConfidant`,
            setCodeConfirmStatus: `profile/setCodeConfirmStatus`,
            clearErrors: `profile/clearErrors`,
            loaderPassword: `profile/loaderPassword`,
            loaderData: `profile/loaderData`,
            editData: `profile/editData`,
            editPassword: `profile/editPassword`,
            oldPasswordError: `profile/oldPasswordError`,
            old_password: `profile/oldPassword`,
            password_confirmation: `profile/passwordConfirmation`,
            password: `profile/newPassword`,
            validationErrors: `profile/validationErrors`,
        }),
        lastNameErrors() {
            let error = [];
            if (!this.$v.userConfidant.last_name.$dirty) {
                return error;
            }
            if (!this.$v.userConfidant.last_name.required) {
                error.push(this.$t('validationRequired'))
            }
            if (this.validationErrors.last_name) {
                this.validationErrors.last_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        firstNameErrors() {
            let error = [];
            if (!this.$v.userConfidant.first_name.$dirty) {
                return error;
            }
            if (!this.$v.userConfidant.first_name.required) {
                error.push(this.$t('validationRequired'))
            }
            if (this.validationErrors.first_name) {
                this.validationErrors.first_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        emailErrors() {
            let error = [];
            if (!this.$v.userConfidant.email.$dirty) {
                return error;
            }
            if (!this.$v.userConfidant.email.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.userConfidant.email.email) {
                error.push(this.$t('validationEmail'));
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        phoneErrors() {
            let error = [];
            if (!this.$v.userConfidant.phone.$dirty) {
                return error;
            }
            if (!this.$v.userConfidant.phone.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.userConfidant.phone.minLength) {
                error.push(this.$t('validationMinPhone'))
            }
            if (this.validationErrors.phone) {
                this.validationErrors.phone.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        dateErrors(){
            let error = [];
            if (!this.$v.userConfidant.birthday.$dirty) {
                return error;
            }
            if (!this.isValidate(this.userConfidant.birthday)) {
                error.push(this.$t('incorrectDate'));
            }
            if (this.validationErrors.birthday) {
                this.validationErrors.birthday.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },

    },
    created(){

    },
    methods: {
        ...mapActions({
            fetchUser: `profile/${LOAD_USER_DATA}`,
            fetchUserConfidant: `profile/${LOAD_USER_CONFIDANT}`,
            updateUserData: `profile/${UPDATE_USER_DATA}`,
            updateUserConfidant: `profile/${UPDATE_USER_CONFIDANT}`,
            updateUserPassword: `profile/${UPDATE_USER_PASSWORD}`,
        }),
        ...mapMutations({
            changeEditData: `profile/${PERSONAL_CHANGE_EDIT_DATA_STATUS}`,
            changeEditPassword: `profile/${PERSONAL_CHANGE_EDIT_PASSWORD_STATUS}`,
            changeOldPassword: `profile/${CHANGE_OLD_PASSWORD}`,
            changeNewPassword: `profile/${CHANGE_NEW_PASSWORD}`,
            changeConfirmationPassword: `profile/${CHANGE_CONFIRMATION_PASSWORD}`,
            resetPasswords: `profile/${PERSONAL_RESET_PASSWORD}`,
            resetUserConfidant: `profile/SET_USER_CONFIDANT`,
            changeValidationErrors: `profile/${PERSONAL_VALIDATIONS_ERRORS}`,
            changePopupRemoveProfile: `profile/${POPUP_REMOVE_PROFILE}`,
            changePopupCodeConfirm: `profile/POPUP_CODE_CONFIRM`,
            changePopupTextConfidant: `profile/CHANGE_TEXT_CONFIDANT`,


        }),
        showPopupRemoveProfile(){

        },

        changeUserConfidantEdit(){
            this.userConfidantEdit = !this.userConfidantEdit
        },

        resetErrorsPassword(){
            this.$v.old_password.$reset();
            this.$v.password.$reset();
            this.$v.password_confirmation.$reset()
        },
        resetEditData(){
            this.$v.userConfidant.first_name.$reset();
            this.$v.userConfidant.last_name.$reset();
            this.$v.userConfidant.phone.$reset();
            this.$v.userConfidant.email.$reset();
            this.$v.userConfidant.birthday.$reset();
            // this.userConfidant.first_name  = ''
            // this.userConfidant.last_name = ''
            // this.userConfidant.phone= ''
            // this.userConfidant.email= ''
            // this.userConfidant.birthday= null

            this.fetchUserConfidant();
            this.changeUserConfidantEdit();
        },
        resetEditPassword(){
            this.resetPasswords();
            this.changeEditPassword();
            this.resetErrorsPassword();
        },
        getDateFormat(date){
            return  date.substring(4)+ '-' +date.substring(2,4) + '-' +date.substring(0,2)
        },

        getDateSlash(date){
            return date.substring(0,2) + '/' +date.substring(2,4) + '/' + date.substring(4)
        },
        isValidate(date) {
            if(date.length < 8){
                return false;
            }

            let dateStr = this.getDateSlash(date)
            let s = dateStr.split('/');
            let d = new Date(+s[2], s[1]-1, +s[0]);
            if (Object.prototype.toString.call(d) === "[object Date]") {
                if (!isNaN(d.getTime()) && d.getDate() == s[0] &&
                  d.getMonth() == (s[1] - 1)) {
                    return true;
                }
            }
            return false;
        },


        sendEmail(){
            this.changePopupCodeConfirm(true)
        },

        updateData(){
            this.$v.userConfidant.first_name.$touch();
            this.$v.userConfidant.last_name.$touch();
            this.$v.userConfidant.phone.$touch();
            this.$v.userConfidant.email.$touch();
            this.$v.userConfidant.birthday.$touch();
            if (
                !this.$v.userConfidant.first_name.$invalid &&
                !this.$v.userConfidant.last_name.$invalid &&
                !this.$v.userConfidant.phone.$invalid &&
                !this.$v.userConfidant.email.$invalid &&
                 this.isValidate(this.userConfidant.birthday)
            ) {


                this.updateUserConfidant({
                    first_name: this.userConfidant.first_name,
                    last_name: this.userConfidant.last_name,
                    email: this.userConfidant.email,
                    phone: this.userConfidant.phone,
                    birthday: this.getDateFormat(this.userConfidant.birthday),
                })
                    .then(() => {
                        if(this.lastEmail !== this.userConfidant.email){
                            this.$toaster.success('Довереному лицу было отправлено код подтверждения на е-майл');
                        }else {
                            this.$toaster.success(this.$t('toasterSuccessProfileData'));
                        }

                        this.changeValidationErrors({});
                        this.changeUserConfidantEdit();
                    })
                    .catch(error => {
                        if(error.response.status === 422){
                            if(error.response.data.errors){
                                for (let key in error.response.data.errors) {
                                    error.response.data.errors[key].forEach(elem => {
                                        this.$toaster.error(elem);
                                    });
                                }
                            }
                            if(error.response.data.error){
                                this.$toaster.error(error.response.data.error.message);
                            }
                        }

                    })
                    .finally(() => {});
            }

        },
        updatePassword(){
            this.$v.old_password.$touch();
            this.$v.password.$touch();
            this.$v.password_confirmation.$touch();
            if (
                !this.$v.old_password.$invalid &&
                !this.$v.password.$invalid &&
                !this.$v.password_confirmation.$invalid
            ) {
                this.updateUserPassword({
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    old_password: this.old_password,
                })
                    .then(response => {
                        this.resetEditPassword();
                        this.$toaster.success(response.data.message);
                        this.changeEditPassword();
                    }).catch(error => {
                        if(error.response.status === 422){
                            if(error.response.data.error){
                                this.$toaster.error(error.response.data.error.message);
                            }
                            if(error.response.data.errors){
                                for (let key in error.response.data.errors) {
                                    error.response.data.errors[key].forEach(elem => {
                                        this.$toaster.error(elem);
                                    });
                                }
                            }
                        }
                    }).finally(() => {});
            }
        }
    }
}
