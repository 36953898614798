import {$http} from '@/https'
import {
    FAQ_LOAD_CONTENT,
    FAQ_LOAD_CATEGORIES,
    FAQ_RESET_CONTENT,
} from '../mutation-types';

const state = {
    list: null,
    showAsk: false,
    categories: [],
};

const getters = {
    showAsk: state => state.showAsk,
    list: state => state.list,
    categories: state => state.categories,
};

const actions = {
    [FAQ_LOAD_CONTENT]: async ({commit}, payload) => {
        try {
            const response = await $http.get(`v1/faq/${payload}`);
            commit(FAQ_RESET_CONTENT);
            commit(FAQ_LOAD_CONTENT, response.data.data);
            return response;
        } catch (e) {
            throw e;
        }
    },
    [FAQ_LOAD_CATEGORIES]: async ({commit}) => {
        try {
            const response = await $http.get('v1/faq_category');
            commit(FAQ_LOAD_CATEGORIES, response.data.data);
            return response;
        } catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [FAQ_LOAD_CONTENT](state, content) {
        state.list = content;
    },
    [FAQ_RESET_CONTENT](state) {
        state.list = [];
    },
    [FAQ_LOAD_CATEGORIES](state, category) {
        state.categories = category;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
