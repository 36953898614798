import {mapMutations, mapActions, mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate';
import {required, email } from 'vuelidate/lib/validators';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: 'registration',
    mixins: [validationMixin],
    data() {
        return {
            payload: {
                first_name: '',
                last_name: '',
                phone: '',
                message: '',
                hiddenPhone: '',
                email: '',
                prefer_feedback: '',
                amount: '',
                country: '',
                user_type: '1',
                // trace: b24Tracker.guest.getTrace()
            },
            validationErrors: {},
            showSuccess: false,
            phone: {
                isValid: false,
            },
            translations: {
                countrySelectorLabel: this.$t('phoneLabelsCountrySelectorLabel'),
                phoneNumberLabel: this.$t('phoneLabelsPhoneNumberLabel'),
                example: this.$t('phoneLabelsExample'),
            }
        }
    },
    components: {
        VuePhoneNumberInput
    },
    validations () {
        return {
            payload: {
                first_name: {
                    required,
                },
                last_name: {
                    required,
                },
                email: {
                    required,
                    email
                },
                hiddenPhone: {
                    required,
                },
                message: {
                    required,
                },
            }
        }
    },
    watch: {
        readPrivacyPolicy() {
            this.payload.checkbox = this.readPrivacyPolicy;
        },
    },
    computed: {
        ...mapGetters({
            readPrivacyPolicy: `privacyPolicy/readPrivacyPolicy`,
            setting: `main/setting`,
        }),
        firstNameErrors() {
            let error = [];
            if (!this.$v.payload.first_name.$dirty) {
                return error;
            }
            if (!this.$v.payload.first_name.required) {
                error.push(this.$t('validationRequired'));
            }
            if (this.validationErrors.first_name) {
                this.validationErrors.first_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        lastNameErrors() {
            let error = [];
            if (!this.$v.payload.last_name.$dirty) {
                return error;
            }
            if (!this.$v.payload.last_name.required) {
                error.push(this.$t('validationRequired'));
            }
            if (this.validationErrors.last_name) {
                this.validationErrors.last_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        messageErrors() {
            let error = [];
            if (!this.$v.payload.message.$dirty) {
                return error;
            }
            if (!this.$v.payload.message.required) {
                error.push(this.$t('validationRequired'));
            }
            return error;
        },
        emailErrors() {
            let error = [];
            if (!this.$v.payload.email.$dirty) {
                return error;
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('validationEmail'));
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                });
            }

            return error;
        },
        phoneErrors() {
            let error = [];
            if (!this.$v.payload.hiddenPhone.$dirty) {
                return error;
            }
            if (!this.phone.isValid) {
                error.push(this.$t('validationMinPhone'))
            }
            if (this.validationErrors.hiddenPhone) {
                this.validationErrors.hiddenPhone.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
    },
    methods: {
        ...mapActions({
            feedback: `auth/FEEDBACK_INVESTOR`,
            checkEmail: `auth/CHECK_FREE_EMAIL`,
        }),
        ...mapMutations({
            showPopup: `auth/FEEDBACK_INVESTOR_SHOW`,
            showPopupPrivacy: `privacyPolicy/SHOW_POPUP_PRIVACY`,
            changeReadPrivacyPolicy: `privacyPolicy/IS_READ_PRIVACY_POLICY`,
        }),
        onTelInput({formatNational, isValid, nationalNumber, phoneNumber, formattedNumber}) {
            this.payload.phone = formattedNumber;
            this.payload.hiddenPhone = phoneNumber;
            this.phone.isValid = isValid;
        },
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid && this.phone.isValid === true) {
                this.feedback(this.payload)
                    .then(response => {
                        if (response.status === 200) {
                            this.showSuccess = true
                        }
                    }).catch((error) => {
                    if (error.response.status === 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                });
            }
        },
    },
    destroyed() {
        this.changeReadPrivacyPolicy(false)
    }
}
