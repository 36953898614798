import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_POPUP_GIFT_LIMIT,
} from "@/store/mutation-types";
import {transformDate} from '@/helpers/variables/index'
export default{
    name: 'get-gift-popup',
    data(){
      return {

      }
    },
    computed: {
        ...mapGetters({
            loading: `gift/loading`,
            currentObjGift: `gift/currentObjGift`,
        })
    },
    methods: {
        ...mapActions({

        }),
        ...mapMutations({
            showPopup: `gift/${SHOW_POPUP_GIFT_LIMIT}`,
        }),
        rebuildDate(date){
            return transformDate(date)
        }
    }
}
