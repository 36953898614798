import _ from 'lodash';
import Vue from 'vue';
import {fixBody, globalLoader} from '@/helpers/variables/index'
import {$http} from '@/https'
import {
    SELL_CURRENT_CATEGORY,
    SELL_LOAD_TREES,
    SELL_RESET_TREES,
    SELL_SELECTED_TREES,
    SELL_SHOW_CATEGORIES,
    SELL_SHOW_TREES,
    SELL_REMOVE_TREES,
    SELL_INCREMENT_PRICE,
    SELL_DECREMENT_PRICE,
    SELL_LOAD_CATEGORY,
    SELL_CHANGE_PRICE,
    SELL_DECREMENT_PERCENT,
    SELL_INCREMENT_PERCENT,
    SELL_CHANGE_PERCENT,
    SELL_TREES_RESET,
    LOADING_POSITION,
    GET_POSITION,
    SELL_RESET,
    SELL_TREE_LENGTH,
    SELL_TREES,
    SELL_RESET_CATEGORY,
    SELL_CHANGE_CHECK,
    SELL_CHANGE_ALL,
    SELL_CHANGE_OFFSET,
    SELL_LOADING_TREES_POPUP,
    SHOP_COMISSION,

    SELL_SETTING
} from '../../mutation-types';
const helper = (obj) => {
    if(!obj.is_sold){
        let rest  = obj.initial_price - (obj.initial_price * (state.defaultCommissionSale / 100));
        obj.isSoldPrice = (obj.minPrice - obj.initial_price) + rest;

    }else{
        obj.isSoldPrice = obj.minPrice;
    }
    obj.commissionPrice = ((obj.commission / 100) * obj.isSoldPrice);
    obj.totalCommissionPrice = obj.isSoldPrice - obj.commissionPrice;
    let index = _.findIndex(state.list, obj);
    Vue.set(state.list, index, obj);
};
const state = {
    defaultCommissionSale: 25,
    list: [],
    trees: null,
    category: null,
    currentCategory: null,
    removeTrees: [],
    showCategory: false,
    showTrees: false,
    loadingPosition: false,
    loadingTreesPopup: false,
    shopComission: null,
    selectTrees: [],
    lengthListTree: 0,
    offset: 0,
    limit: 20,

    setting: null,
};

const getters = {
    selectTrees: state => state.selectTrees,
    loading: state => state.loading,
    loadingTreesPopup: state => state.loadingTreesPopup,
    lengthListTree: state => state.lengthListTree,
    loadingPosition: state => state.loadingPosition,
    currentCategory: state => state.currentCategory,
    list: state => state.list,
    trees: state => state.trees,
    category: state => state.category,
    removeTrees: state => state.removeTrees,
    showCategory: state => state.showCategory,
    showTrees: state => state.showTrees,
    shopComission: state => state.shopComission,
};

const actions = {
    [SELL_LOAD_CATEGORY]: async ({commit}) => {
        commit(SELL_RESET_CATEGORY);
        try {
            const response = await $http.get(`v1/trees?group_by=season&django=${1}`);
            commit(SELL_RESET_CATEGORY);
            commit(SELL_LOAD_CATEGORY, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    [SHOP_COMISSION]: async ({commit}) => {
        try {
            const response = await $http.get(`v1/configs`);
            commit(SHOP_COMISSION, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    [SELL_LOAD_TREES]: async ({commit}, payload) => {
        try {
            commit(SELL_LOADING_TREES_POPUP, true);

            const response = await $http.get(`v1/trees?seasons=${payload.categories}&is_young=false&django=${1}&limit=${payload.limit}&skip=${state.offset}`);
            commit(SELL_RESET_TREES);
            commit(SELL_LOAD_TREES, response.data.data);
            commit(SELL_TREE_LENGTH, response.data.meta.count);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(SELL_LOADING_TREES_POPUP, false);
        }
    },
    [SELL_TREES]: async ({commit}, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/shop/add_tree`, payload);
            commit(SELL_RESET);
            return response.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [GET_POSITION]: async ({commit}, payload) => {
        try {
            const response = await $http.post(`v1/shop/get_trees_positions`, payload);
            commit(GET_POSITION, response.data);
            return response.data;
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [SHOP_COMISSION](state,status){
        status.map((item)=>{
          if(item.key === 'shop_commission'){
              state.shopComission = item
          }
      });
    },
    [SELL_SETTING](state, setting) {
        state.setting = setting;
        state.defaultCommissionSale = setting.commissionFirstSale * 100
    },
    [SELL_TREES_RESET](state) {
        state.trees = null;
        state.list = [];
        state.selectTrees = [];
    },
    [LOADING_POSITION](state, status) {
        state.loadingPosition = status;
    },
    [GET_POSITION](state, list) {
        state.list.map(elem => {
            list.forEach(t => {
                if(elem.id === t.tree_id){
                    elem.position = t.position;
                }
            });
        });
    },
    [SELL_LOAD_TREES](state, list) {
        state.trees = list;
        state.trees.map(elem => {
            if(!elem.is_sold){
                let rest  = elem.initial_price - (elem.initial_price * (state.defaultCommissionSale / 100));
                elem.isSoldPrice = (+elem.current_price - elem.initial_price) + rest;

            }else{
                elem.isSoldPrice = Math.ceil(+elem.current_price);
            }
            elem.minPrice = Math.ceil(elem.current_price);
            elem.commission = state.setting.commissionSale * 100;
            elem.position = 0;
            let param = state.selectTrees.find(t => {
                if(elem.id === t.id){
                    return true
                }else{
                    return false
                }
            });
            elem.check = param !== undefined;
            elem.commissionPrice = ((elem.commission / 100) * elem.isSoldPrice);
            elem.totalCommissionPrice = elem.isSoldPrice - elem.commissionPrice;
        });
    },
    [SELL_LOAD_CATEGORY](state, list) {
        state.category = list;
    },
    [SELL_RESET_CATEGORY](state) {
        state.category = null;
    },
    [SELL_RESET_TREES](state) {
        state.trees = null;
        state.offset = 0;
    },
    [SELL_CHANGE_CHECK](state, obj) {
        obj.check = !obj.check;
        if(obj.check){
            state.selectTrees.push(obj);
        }else{
            let index = _.findIndex(state.selectTrees, e => e.id === obj.id );
            state.selectTrees.splice(index, 1);
        }
    },
    [SELL_SELECTED_TREES](state, trees) {
        state.list = trees.map(elem => elem);
        state.list = _.uniq(state.list);
    },
    [SELL_CHANGE_ALL](state, status) {
        if(status){
            let notCheckedTrees =_.filter(state.trees, e =>  !e.check);
            let checkedTreesAll = state.trees.map(e => {
                e.check = true;
                return e;
            });
            state.trees = checkedTreesAll;
            state.selectTrees.push(...notCheckedTrees);
        }else{
            let resetTreesAll = state.trees.map(e => {
                e.check = false;
                return e;
            });
            state.trees = resetTreesAll;
            state.trees.forEach((t) => {
                _.remove(state.selectTrees, elem => {
                    return elem.id === t.id
                });
            });
        }
    },
    [SELL_REMOVE_TREES](state, listDeleteTree) {
        if (state.list.length !== 0) {
            for (let i = state.list.length; i >= 0; i--) {
                for (let j = 0; j < listDeleteTree.length; j++) {
                    if (state.list[i] === listDeleteTree[j]) {
                        state.list.splice(i, 1);
                        state.selectTrees.splice(i, 1);
                    }
                }
            }
        }
    },
    [SELL_SHOW_TREES](state, status) {
        fixBody(status);
        state.showTrees = status;
    },
    [SELL_SHOW_CATEGORIES](state, status) {
        fixBody(status);
        state.showCategory = status;
    },
    [SELL_CURRENT_CATEGORY](state, category) {
        state.currentCategory = null;
        let payload = {
            categories: null,
            limit: state.limit,
            offset: state.offset,
        };
        let newArr =[];
        for(let i = 0; i < category.length; i++){
            let newVal = '';
            let year = category[i].planting_year;
            let season = category[i].season;
            newVal = year + '-' + season;
            newArr.push(newVal.toString())
        }
        payload.categories = newArr.join();
        payload.limit = state.limit;
        payload.offset = state.offset;
        state.currentCategory = payload;
    },
    [SELL_INCREMENT_PRICE](state, id) {
        let obj = state.list.find(elem => {
            return elem.id === id
        });

        obj.minPrice++;
        helper(obj);
    },
    [SELL_DECREMENT_PRICE](state, id) {
        let obj = state.list.find(elem => {
            return elem.id === id
        });

        obj.minPrice--;
        helper(obj);
    },
    [SELL_CHANGE_PRICE](state, payload) {
        let obj = state.list.find(elem => {
            return elem.id === payload.id
        });

        obj.minPrice = payload.minPrice;
        helper(obj);
    },
    [SELL_INCREMENT_PERCENT](state, id) {
        let obj = state.list.find(elem => {
            return elem.id === id
        });
        obj.commission++;
        helper(obj);
    },
    [SELL_DECREMENT_PERCENT](state, id) {
        let obj = state.list.find(elem => {
            return elem.id === id
        });
        obj.commission--;
        helper(obj);
    },
    [SELL_CHANGE_PERCENT](state, payload) {
        let obj = state.list.find(elem => {
            return elem.id === payload.id
        });
        obj.commission = payload.commission;
        helper(obj);
    },
    [SELL_TREE_LENGTH](state, count) {
        state.lengthListTree = count;
    },
    [SELL_CHANGE_OFFSET](state, count) {
        state.offset = count;
    },
    [SELL_LOADING_TREES_POPUP](state, status) {
        state.loadingTreesPopup = status;
    },
    [SELL_RESET](state) {
        state.defaultCommissionSale =  state.setting.commissionFirstSale * 100;
        state.list = [];
        state.trees = null;
        state.category = null;
        state.currentCategory = null;
        state.removeTrees = [];
        state.showCategory = false;
        state.showTrees = false;
        state.loadingPosition = false;
        state.loadingTreesPopup = false;
        state.selectTrees = [];
        state.lengthListTree = 0;
        state.offset = 0;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
