import { mapMutations, mapGetters, mapActions } from "vuex";
import {
    LABEL_DOCUMENTS,
    LABEL_DOCUMENTS_RESET
} from "@/store/mutation-types";
export default {
    name: 'legal-documents',
    data(){
        return {
        }
    },
    computed:{
        ...mapGetters({
            content: `labelDocuments/content`,
        })
    },
    created(){
        this.fetchContent();
    },
    methods: {
        ...mapActions({
            fetchContent: `labelDocuments/${LABEL_DOCUMENTS}`
        }),
        ...mapMutations({
            reset: `labelDocuments/${LABEL_DOCUMENTS_RESET}`,
        })
    },
    destroyed(){
        this.reset();
    }
}
