import {mapMutations, mapActions, mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate';
import {required, email} from 'vuelidate/lib/validators';
import _ from 'lodash';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


export default {
    name: 'popup-express',
    mixins: [validationMixin],
    data() {
        return {
            showloginlink:false,
            payload: {
                first_name: '',
                promoCode: '',
                last_name: '',
                phone: '',
                email: '',
                hiddenPhone: '',
                tree_count: '1',
                checkbox: false,
                countryCode: '',
                buy: '',
                promoArea: '',
                // trace: b24Tracker.guest.getTrace()
            },
            phone: {
                isValid: false,
            },
            count: [
                {
                    id: 1,
                    count: 1
                },
                {
                    id: 2,
                    count: 2
                },
                {
                    id: 3,
                    count: 3
                }
            ],
            validationErrors: {},
            translations: {
                countrySelectorLabel: this.$t('phoneLabelsCountrySelectorLabel'),
                phoneNumberLabel: this.$t('phoneLabelsPhoneNumberLabel'),
                example: this.$t('phoneLabelsExample'),
            }
        }
    },
    validations: {
        payload: {
            first_name: {
                required,
            },
            last_name: {
                required
            },
            phone: {
                required
            },
            tree_count: {
                required,
            },
            email: {
                required,
                email
            },
            hiddenPhone: {
                required
            },
            checkbox: {required}
        }
    },
    components: {
        VuePhoneNumberInput
    },
    watch: {
        readPrivacyPolicy() {
            this.payload.checkbox = this.readPrivacyPolicy;
        },
        'payload.promoCode'(){
            if(this.payload.promoCode === ''){
                this.setPromo(null);
            }
        }
    },
    mounted() {
        this.payload.buy = this.popup.key;
        if(this.popup.key === 'buy'){
            this.payload.promoArea = 'express_buy';
        }else{
            this.payload.promoArea = 'express_gift';
        }
    },
    computed: {
        ...mapGetters({
            readPrivacyPolicy: `privacyPolicy/readPrivacyPolicy`,
            popup: `expressActions/popup`,
            setting: `main/setting`,
            config: `main/config`,
            promoData: `profileBuyTree/promoData`,
            promoDataLoading: `profileBuyTree/promoDataLoading`,

        }),
        getLabelSelectCountExpress(){
            for( let i in this.config ){
                if(this.config[i].key.includes('selectCountExpress')){
                    return this.config[i].value;
                }
            }
        },
        firstNameErrors() {
            let error = [];
            if (!this.$v.payload.first_name.$dirty) {
                return error;
            }
            if (!this.$v.payload.first_name.required) {
                error.push(this.$t('validationRequired'));
            }
            if (this.validationErrors.first_name) {
                this.validationErrors.first_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;

        },
        lastNameErrors() {
            let error = [];
            if (!this.$v.payload.last_name.$dirty) {
                return error;
            }
            if (!this.$v.payload.last_name.required) {
                error.push(this.$t('validationRequired'));
            }
            if (this.validationErrors.last_name) {
                this.validationErrors.last_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        phoneErrors() {
            let error = [];
            if (!this.$v.payload.hiddenPhone.$dirty) {
                return error;
            }
            if (!this.phone.isValid) {
                error.push(this.$t('validationMinPhone'))
            }
            if (this.validationErrors.hiddenPhone) {
                this.validationErrors.hiddenPhone.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        emailErrors() {
            let error = [];
            if (!this.$v.payload.email.$dirty) {
                return error;
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('validationEmail'));
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                });
            }

            return error;
        },
        checkboxError() {
            let error = '';
            if (!this.$v.payload.checkbox.$dirty) {
                return error;
            }
            if (this.payload.checkbox === false) {
                error = this.$t('validationChecked');
            }

            return error;
        },
        totalCashBack(){
            let total = this.setting.defaultPriceTree * this.payload.tree_count;
            if(this.promoData){
                if(this.promoData.cashback > 0){
                    total = Math.round((total * (this.promoData.cashback/100))*100000) / 100000
                }
            }
            return total;
        },
    },
    methods: {
        onTelInput({formatNational, isValid, formattedNumber, phoneNumber}) {
            this.payload.phone = formattedNumber;
            this.payload.hiddenPhone = phoneNumber;
            this.phone.isValid = isValid;
        },
        ...mapActions({
            sendExpress: `expressActions/EXPRESS_ACTION`,
            checkEmail: `auth/CHECK_FREE_EMAIL`,
            checkPromoCode: `profileBuyTree/CHECK_PROMO_CODE`,
        }),
        ...mapMutations({
            changePopup: `expressActions/CHANGE_POPUP_EXPRESS`,
            showPopupPrivacy: `privacyPolicy/SHOW_POPUP_PRIVACY`,
            changeReadPrivacyPolicy: `privacyPolicy/IS_READ_PRIVACY_POLICY`,
            download: `trees/MY_TREE_LIST_FILE`,
            changePopupPromoCode: `expressActions/CHANGE_POPUP_EXPRESS_PROMO_CODE`,
            showRegistration: `auth/REGISTRATION_SHOW`,
            setPromo: `profileBuyTree/CHECK_PROMO_CODE`,
            showSingIn: `auth/SHOW_SING_IN`,

        }),
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'send',
                eventAction: 'form',
                eventLabel: label,
            });
        },
        goRegistration(){
            this.showRegistration(true);
            this.changePopup({
                show: false,
                key: ''
            });
        },
        facebookPixelPurchase(count){
            this.$analytics.fbq.event('Purchase', {value: count * 75, currency: 'USD'});
        },
        checkPromo: _.debounce(function () {
            if(this.payload.promoCode.length > 0) {
                this.checkPromoCode({
                    code: this.payload.promoCode,
                    promoArea: this.payload.promoArea
                });
            }
        }, 2000),
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid && this.payload.checkbox === true) {
                this.payload.countryCode = this.setting.countryCode;
                this.sendExpress(this.payload)
                    .then(() => {
                        if(this.payload.buy === 'buy'){
                            if(this.$route.fullPath.includes('expressBuy')){
                                this.gtm('expressbuy');
                            }else{
                                this.gtm('buy_ge');
                            }
                        }else{
                            if(this.$route.fullPath.includes('expressGift')){
                                this.gtm('expressgift');
                            }else{
                                this.gtm('gift_ge');
                            }
                        }

                        this.facebookPixelPurchase(parseInt(this.payload.tree_count));
                        this.$router.push({ name: 'personal', query: { isRegister: '1' } });

                    }).catch((error) => {
                    if (error.response.status === 422) {
                        if(error.response.data.hasOwnProperty('errors')){
                            this.validationErrors = error.response.data.errors;
                        }else{
                            this.$toaster.error(error.response.data.error);
                        }
                    }
                });
            }
        },
        changeEmailInput: _.debounce(function (email) {
            if (this.$v.payload.email.email) {
                this.checkEmail({email: email})
                    .then(response => {
                        this.validationErrors = {};
                        this.showloginlink =false
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors;
                            this.showloginlink =true
                        }
                    })
            }

        }, 1000),
    },
    destroyed() {
        this.changeReadPrivacyPolicy(false);
        this.setPromo(null);
    }
}
