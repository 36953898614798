import { $http } from '@/https';
import {
    SHOW_POPUP_PRIVACY,
    PRIVACY_POLICY_CONTENT,
    ABOUT_US_INFO,
    IS_READ_PRIVACY_POLICY
} from '../mutation-types.js';

const state = {
    showPopupPrivacy: false,
    readPrivacyPolicy: false,
    contentPrivacy: null,
    content: null,
    info: null
};

const getters = {
    content: state => state.content,
    info: state => state.info,
    showPopupPrivacy: state => state.showPopupPrivacy,
    contentPrivacy: state => state.contentPrivacy,
    readPrivacyPolicy: state => state.readPrivacyPolicy,
};

const actions = {
    [PRIVACY_POLICY_CONTENT]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/pages/privacy-policy');
            commit(PRIVACY_POLICY_CONTENT, response.data.data);
        }catch (e) {
            throw e;
        }
    },
    [ABOUT_US_INFO]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/widgets/about_us_contact');
            commit(ABOUT_US_INFO, response.data);
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [ABOUT_US_INFO](state, content) {
        state.info = content;
    },
    [SHOW_POPUP_PRIVACY](state, status) {
        state.showPopupPrivacy = status;
    },
    [PRIVACY_POLICY_CONTENT](state, status) {
        state.contentPrivacy = status;
    },
    [IS_READ_PRIVACY_POLICY](state, status) {
        state.readPrivacyPolicy = status;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
