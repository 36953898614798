import { mapMutations, mapGetters, mapActions } from 'vuex'
import { Carousel, Slide } from 'vue-carousel';
import {
    SHOP_LIST,
    SHOP_FILTER,
    IS_LOGIN_USER,
    SHOW_CASH_POPUP,
    BUY_TREE_POPUP,
    SHOP_FILTER_SEARCH,
    LOAD_USER_DATA,
    FILTER_POPUP,
    SHOW_TEXT_HELP_BONUS,
    ADD_BASKET,
    CHANGE_POPUP_EXPRESS,
    TEXT_POPUP_CONTENT
} from "@/store/mutation-types";
import VueAdsPagination from 'vue-ads-pagination';
import vPagination from 'vue-plain-pagination';
import { VueAdsPageButton } from 'vue-ads-pagination';
import shopTable from '@/components/shop-table/index.vue';
import TextHelp from '@/modules/shop/text-help/index.vue';
import { showTextPopup } from '@/helpers/variables/index';

import Moment from 'moment';
import {extendMoment} from 'moment-range';

const moment = extendMoment(Moment);

export default {
    name: 'shop-year',
    data(){
        return{
            page: 0,
            selected2: [],
            filter:{
                season: [],
                min_planting_year: this.$route.params.year,
                max_planting_year: this.$route.params.year,
                min_price:'',
                max_price:'',
                limit:12,
                skip:0,
            },
            currentPage: 5,
            total: 9,
        }

    },
    components:{
        VueAdsPagination,
        VueAdsPageButton,
        shopTable,
        Carousel,
        Slide,
        vPagination,
        TextHelp,
    },
    watch: {
        page(){
            this.filter.skip = this.page * this.filter.limit;
            this.sendRequest();
        },
    },
    computed: {
        ...mapGetters({
            config:'main/config',
            is_auth: `auth/is_auth`,
            list: `shop/shopFilterSearch`,
            setting: `main/setting`,
            shopFilter: 'shop/shopFilter',
            shopListInfo: 'shop/shopListInfo',
            loading: 'shop/loading',
            treeIds: `shop/treeIds`,
            user: 'profile/user',
            filterPop: `shop/filterPopUp`,
            shopListStatus: `shop/shopListStatus`,
            showTextHelpBonus: `statistic/showTextHelpBonus`,
            showVideoHelp: `helpCalculate/showVideoHelp`,
            helperContent: `helpCalculate/helperContent`,
        }),
    },
    mounted(){
      this.shopFilterList();
      this.sendRequest();

      if(this.is_auth){
          if(!this.user){
              this.fetchUser();
          }
      }
    },
    methods:{
        ...mapActions({
            fetchContent:`shop/${SHOP_LIST}`,
            shopFilterList: `shop/${SHOP_FILTER}`,
            shopFilterSearch:`shop/${SHOP_FILTER_SEARCH}`,
            fetchUser: `profile/${LOAD_USER_DATA}`,
        }),
        ...mapMutations({
            addBasket: `shop/${ADD_BASKET}`,
            cashPopUp: `system/${SHOW_CASH_POPUP}`,
            buyTreePopUp: `system/${BUY_TREE_POPUP}`,
            forEnter: `system/${IS_LOGIN_USER}`,
            filterPopUp: `shop/${FILTER_POPUP}`,
            toggleTextHelpBonus: `statistic/${SHOW_TEXT_HELP_BONUS}`,
            changePopupExpress: `expressActions/${CHANGE_POPUP_EXPRESS}`,
            setPopupContent: `popups/${TEXT_POPUP_CONTENT}`
        }),
        sendRequest(){
            this.shopFilterSearch(this.filter)
        },
        addToBasket(item, index){
            if(this.is_auth){
                this.gtm('buyavt');
                this.$analytics.fbq.event('AddToCart');
            }else{
                this.gtm('buy');
            }
            this.cashPopUp(true);
            this.addBasket({
                index: index,
                item: item
            });
        },
        changePopupText(status){
            showTextPopup(status);

            this.setPopupContent(this.getConfigContent('infoTreestore'));
        },
        getConfigContent(key){
            for( let i in this.config ){
                if(this.config[i].key === key){
                    return this.config[i];
                }
            }
        },
        changeWord(number){
            let titles = [this.$t('year'), this.$t('years'), this.$t('yearsOld')];
            let cases = [2, 0, 1, 1, 1, 2];
            return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
        },
        gtmSearch(years, price){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'search',
                eventAction: `years_${years}`,
                eventLabel: `priсe_${price}`,
            });
        },
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
        searchInShop(){
            this.gtmSearch(
                `${this.filter.min_planting_year}-${this.filter.max_planting_year}`,
                `${this.filter.min_price !== '' ? this.filter.min_price : 0}-${this.filter.max_price}`
            );
            this.page = 0;
            this.shopFilterSearch(this.filter)
        },
        addBasketNoAuth(){
            this.forEnter(true);
            this.gtm('buy')
        }


    },

}
