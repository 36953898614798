import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_EXCHANGE_TREES_POPUP,
    EXCHANGE_LOAD_TREES,
    EXCHANGE_CHANGE_OFFSET_POPUP,
    EXCHANGE_CHANGE_PAGE_POPUP,
    EXCHANGE_RESET_TREES_POPUP,
    EXCHANGE_CHANGE_CHECK_POPUP_TREES,
} from "@/store/mutation-types";
import VueAdsPagination, {VueAdsPageButton} from "vue-ads-pagination";
export default {
    name: 'exchange-trees-popup',
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            list: `exchangeTree/listTreePopup`,
            page: `exchangeTree/pageTreePopup`,
            limit: `exchangeTree/limitTreePopup`,
            loading: `exchangeTree/loadTreePopup`,
            trees: `exchangeTree/listTreePopup`,
            lengthListTree: `exchangeTree/lengthTreesPopup`,
            currentCategory: `exchangeTree/currentCategory`,
            selectCurrentTree: `exchangeTree/selectCurrentTree`,
        })
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.fetchTrees(this.currentCategory)
        }
    },
    created(){
        this.fetchTrees(this.currentCategory);
    },
    methods: {
        ...mapActions({
            fetchTrees: `exchangeTree/${EXCHANGE_LOAD_TREES}`,
        }),
        ...mapMutations({
            showPopup: `exchangeTree/${SHOW_EXCHANGE_TREES_POPUP}`,
            changeSkip: `exchangeTree/${EXCHANGE_CHANGE_OFFSET_POPUP}`,
            changePage: `exchangeTree/${EXCHANGE_CHANGE_PAGE_POPUP}`,
            changeCheckbox: `exchangeTree/${EXCHANGE_CHANGE_CHECK_POPUP_TREES}`,
            resetTreeList: `exchangeTree/${EXCHANGE_RESET_TREES_POPUP}`,
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
    },
    destroyed(){
        this.resetTreeList();
        this.changePage(0);
        this.changeSkip(0);
    }
}
