import { $http } from '@/https';
import {
    FETCH_TEAMS_OF_USE,
} from '../mutation-types.js';

const state = {
    content: null,
};

const getters = {
    content: state => state.content,
};

const actions = {
    [FETCH_TEAMS_OF_USE]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/pages/terms-of-use');
            commit(FETCH_TEAMS_OF_USE, response.data.data);
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [FETCH_TEAMS_OF_USE](state, content) {
        state.content = content;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
