import VideoMapItem from '../video-map-item/index.vue'

export default {
  name: 'bing-maps',
  props: ['polygons', 'zoom'],
  components: {
    VideoMapItem
  },
  data(){
    return{
      cadastral_number:'52.30.31.078'
    }

  },
  created() {
    if (document.getElementById("scriptBingMaps")) {
      return; // already loaded
    }

    // Add a global function for the callback from Bing Maps api
    window.OnLoadBingMapsApi = () => this.initMap();

    // Add programmaticaly the external Bing maps api script
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://www.bing.com/api/maps/mapcontrol?callback=OnLoadBingMapsApi";
    scriptTag.id = "scriptBingMaps";

    // Inject the dynamic script in the DOM
    document.head.appendChild(scriptTag);

    this.singlePoligonId = this.getPoligonid(this.polygons,this.cadastral_number)

  },
  methods: {

    getPoligonid(polygons,cadastral_number){
      let id;
      [...polygons].forEach((e,index) => {
        if(e.cadastral_number === cadastral_number){
          id = index
        }
      })
      return id
    },
    initMap: function () {
      this.setMap3()
      delete this.$refs[this.cadastral_number]

      console.log(this.$refs)
      let iteration = 0;
      for (let prop in this.$refs) {
        iteration++
      }
      iteration--


      const mapElement = this.$refs[0];
      this.map = new Microsoft.Maps.Map(mapElement, {
        mapTypeId: Microsoft.Maps.MapTypeId.aerial,
        zoom: 15,
        center: this.polygons[0].area[0],
        credentials: `AnnWKZqULr2VDmhnIXslI0dTcYq_0sG3RK5yxZMIMVPIRwRmwvta5LPd8ztqDsAd`,
        maxZoom: 25,
        showLocateMeButton: false,
        showMapTypeSelector: false,
        maxNetworkLinkDepth: 3
      });

      for (let prop in this.$refs) {
        this.getPolygons(iteration);
        iteration--
      }


      const divs = document.querySelectorAll('.bing-multi__item');
      divs.forEach(div => {
          let str = div.innerHTML
          let result = str.match(/MicrosoftMap/);
          if(result===null){
            div.remove();
          }
      });



      // this.setMap8()
    },
    getPolygons(iterator) {
      if (this.polygons) {
        const t = this.polygons[iterator];
        //TODO  iterator !== 0 скрыл поле 2024
        if (t !== undefined && iterator !== 0) {

          const polygon = new Microsoft.Maps.Polygon(t.area, {
            fillColor: 'rgba(110,160,40,0.8)',
            strokeColor: 'rgba(235, 87, 87,1)',
            strokeThickness: 2
          });
          //Add the polyline to map
          this.map.entities.push(polygon);

          //add infoBox
          let infobox = new Microsoft.Maps.Infobox(this.map.getCenter(),
            {visible: false});
          infobox.setMap(this.map);


          //add event
          Microsoft.Maps.Events.addHandler(polygon, 'click', e => {
            const shape = e.primitive;
            let loc;
            let desc;
            //Depending on the type of shape that was clicked.
            if (shape instanceof Microsoft.Maps.Polygon) {
              loc = e.location;
              desc = `
                        ${t.name ? t.name + '<br>' : ''}
                        ${t.location ? t.location + '.' : ''}
                        ${t.province ? t.province + '.' : ''}<br>
                        ${this.$t('field_cadastral_number')} — ${t.cadastral_number} <br>
                        ${t.planting_year ? `${this.$t('yearPlanting')} — ` + t.planting_year + '<br>' : ''}
                        ${this.$t('countTrees')} ${t.trees_count}<br>
                        ${t.description ? t.description : ''}`;
            }

            // Display the infobox with an updated location and description.
            infobox.setOptions({
              location: loc,
              description: desc,
              visible: true
            });


          });
        }
      }
    },


    setMap3(){
      const mapElement3 = this.$refs['52.30.31.078'];
      let polygonId = this.singlePoligonId;
      this.map3 = new Microsoft.Maps.Map(mapElement3, {
        mapTypeId: Microsoft.Maps.MapTypeId.aerial,
        zoom: 14,
        center: this.polygons[polygonId].area[0],
        credentials: `AnnWKZqULr2VDmhnIXslI0dTcYq_0sG3RK5yxZMIMVPIRwRmwvta5LPd8ztqDsAd`,
        maxZoom: 23,
        showLocateMeButton: false,
        showMapTypeSelector: false,
        maxNetworkLinkDepth: 3
      });
      const t = this.polygons[polygonId];
      const polygon = new Microsoft.Maps.Polygon(t.area, {
        fillColor: 'rgba(110,160,40,0.8)',
        strokeColor: 'rgba(235, 87, 87,1)',
        strokeThickness: 2
      });
      //Add the polyline to map
      this.map3.entities.push(polygon);
      //add infoBox
      let infobox3 = new Microsoft.Maps.Infobox(this.map3.getCenter(),
        {visible: false});
      infobox3.setMap(this.map3);
      //add event
      Microsoft.Maps.Events.addHandler(polygon, 'click', e => {
        const shape = e.primitive;
        let loc;
        let desc;
        //Depending on the type of shape that was clicked.
        if (shape instanceof Microsoft.Maps.Polygon) {
          loc = e.location;
          desc = `
                        ${t.name ? t.name + '<br>' : ''}
                        ${t.location ? t.location + '.' : ''}
                        ${t.province ? t.province + '.' : ''}<br>
                        ${this.$t('field_cadastral_number')} — ${t.cadastral_number} <br>
                        ${t.planting_year ? `${this.$t('yearPlanting')} — ` + t.planting_year + '<br>' : ''}
                        ${this.$t('countTrees')} ${t.trees_count}<br>
                        ${t.description ? t.description : ''}`;
        }

        // Display the infobox with an updated location and description.
        infobox3.setOptions({
          location: loc,
          description: desc,
          visible: true
        });
      });

      delete this.polygons[polygonId]
    },


    setMap8(){
      const mapElement8 = this.$refs[7];
      this.map8 = new Microsoft.Maps.Map(mapElement8, {
        mapTypeId: Microsoft.Maps.MapTypeId.aerial,
        zoom: 14,
        center: this.polygons[7].area[0],
        credentials: `AnnWKZqULr2VDmhnIXslI0dTcYq_0sG3RK5yxZMIMVPIRwRmwvta5LPd8ztqDsAd`,
        maxZoom: 23,
        showLocateMeButton: false,
        showMapTypeSelector: false,
        maxNetworkLinkDepth: 3
      });
      const t = this.polygons[7];
      const polygon = new Microsoft.Maps.Polygon(t.area, {
        fillColor: 'rgba(110,160,40,0.8)',
        strokeColor: 'rgba(235, 87, 87,1)',
        strokeThickness: 2
      });
      //Add the polyline to map
      this.map8.entities.push(polygon);
      //add infoBox
      let infobox8 = new Microsoft.Maps.Infobox(this.map8.getCenter(),
        {visible: false});
      infobox8.setMap(this.map8);
      //add event
      Microsoft.Maps.Events.addHandler(polygon, 'click', e => {
        const shape = e.primitive;
        let loc;
        let desc;
        //Depending on the type of shape that was clicked.
        if (shape instanceof Microsoft.Maps.Polygon) {
          loc = e.location;
          desc = `
                        ${t.name ? t.name + '<br>' : ''}
                        ${t.location ? t.location + '.' : ''}
                        ${t.province ? t.province + '.' : ''}<br>
                        ${this.$t('field_cadastral_number')} — ${t.cadastral_number} <br>
                        ${t.planting_year ? `${this.$t('yearPlanting')} — ` + t.planting_year + '<br>' : ''}
                        ${this.$t('countTrees')} ${t.trees_count}<br>
                        ${t.description ? t.description : ''}`;
        }

        // Display the infobox with an updated location and description.
        infobox8.setOptions({
          location: loc,
          description: desc,
          visible: true
        });
      });
    },




  },
  destroyed() {
    // Update Bing maps (ez)
    document.getElementById('scriptBingMaps').remove();
  }
}
