import { mapMutations, mapGetters, mapActions } from 'vuex'
import store from "@/store";

export default{
    name: 'user-status-popup',
    data(){
        return {
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'currentLanguage',
        ]),
        ...mapGetters('new_user',{
            uuid: "uuid",
        }),
        insuranceTitle(){
            return this.currentLanguage.key === 'ru'
                ? 'Страхование'
                : 'languages'
        },
        insuranceText(){
            return this.currentLanguage.key === 'ru'
                ? 'Актуально для деревьев 2018, 2019 годов посадки'
                : 'Relevant for trees planted in 2018 and 2019'
        },
        insuranceTextBtn(){
            return this.currentLanguage.key === 'ru'
                ? 'Оставить заявку'
                : 'Submit your application'
        }
    },
    created(){
    },
    methods: {
        ...mapActions('new_common', {
            createInsuranceRequestAsync: 'createInsuranceRequestAsync'
        }),
        ...mapMutations({
            changeStatus: 'popups/SHOW_USER_NFT'
        }),
        sendRequest(){
            let lang = this.currentLanguage.key === 'ru' ? 'rus' : 'eng'

            let headers = {
                auth: this.uuid,
                lang: lang,
            }
            this.createInsuranceRequestAsync({headers:headers}).then(result => {
                if(result.isSuccess){
                    this.$toaster.success(
                        this.currentLanguage.key === 'ru'
                            ? 'Заявка успешно отправлена!'
                            : 'Request sent successfully!'
                    );
                }else{
                    this.$toaster.error(result.exception.textError)
                }
            })
        },
    },
}
