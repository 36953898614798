import { mapMutations } from 'vuex'
import {
    POPUP_BUY_SUCCESS,
    RESET_BASKET
} from "@/store/mutation-types";

export default {
    name: 'shop',
    methods:{
        ...mapMutations({
            cashPopUpSuccess: `shop/${POPUP_BUY_SUCCESS}`,
            resetBasket :`shop/${RESET_BASKET}`
        }),
        closePopup(){
            this.cashPopUpSuccess(false);
            this.$router.push({ name: 'personal' })
        }
    },
    destroyed(){
        this.resetBasket();
    }
}
