import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_POPUP_HELP,
    FETCH_HELP_INFO,
    RESET_POPUP_HELP
} from "@/store/mutation-types";
export default {
    name: 'help-popup',
    data(){
        return {
            loading: true
        }
    },
    computed:{
        ...mapGetters({
            content: 'popups/contentHelpPopup'
        })
    },
    created(){
        this.fetchContent().finally(() => {this.loading = false});
    },
    methods: {
        ...mapActions({
            fetchContent: `popups/${FETCH_HELP_INFO}`,
        }),
        ...mapMutations({
            showPopup: `popups/${SHOW_POPUP_HELP}`,
            reset: `popups/${RESET_POPUP_HELP}`,
        }),
    },
    destroyed(){
        this.reset();
    }
}