import {mapActions, mapGetters, mapMutations} from 'vuex'
import {EXPRESS_PURCHASES, POPUP_BUY_IFRAME, RESET_BASKET} from "@/store/mutation-types";

export default {
  name: 'shop',
  data() {
    return {
      externalScriptSrc: process.env.VUE_APP_PAYMENT_EXTERNAL_HTML_SCRIPT_SRC,
      externalScriptLoaded: false,
    }
  },
  computed: {
    ...mapGetters({
      paymentLeogaming: `shop/paymentLeogaming`,
      redirectTransaction: `shop/redirectTransaction`,
      user: `profile/user`,
    })
  },
  mounted() {
    if (this.paymentLeogaming && this.paymentLeogaming.type === 'html') {
      this.loadExternalScript();
    }
  },
  methods: {
    ...mapActions({
      fetchExpressList: `trees/${EXPRESS_PURCHASES}`,
    }),
    ...mapMutations({
      cashPopUpIframe: `shop/${POPUP_BUY_IFRAME}`,
      resetBasket: `shop/${RESET_BASKET}`,
      changePopupAfterRegister: `popups/SHOW_POPUP_AFTER_REGISTRATION`,
    }),
    closePopup() {
      this.cashPopUpIframe(false);
      if (this.user.express_buy.data.count > 0) {
        this.fetchExpressList();
      }
      if (this.redirectTransaction) {
        this.$router.push({name: 'personal.history'})
      } else {
        this.$router.push({name: 'personal'})
      }
      if (this.$route.query.hasOwnProperty('isRegister')) {
        this.changePopupAfterRegister(true)
      }
      this.gtm('pay');
      this.resetBasket();
    },
    loadExternalScript() {
      this.$loadScript(this.externalScriptSrc)
        .then(() => {
          this.externalScriptLoaded = true;
        })
        .catch(() => {
          this.externalScriptLoaded = false;
        });
    },
    gtm(label) {
      this.$gtm.trackEvent({
        event: 'biodep',
        eventCategory: 'send',
        eventAction: 'form',
        eventLabel: label,
      });
    },
  }

}
