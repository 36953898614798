import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    ABOUT_US_LOAD,
    ABOUT_US_INFO,
    SHOW_FEEDBACK
} from "@/store/mutation-types";

export default {
    name: 'about-us',
    computed: {
        ...mapGetters({
            showAsk: `faq/showAsk`,
            content: `aboutUs/content`,
            info: `aboutUs/info`,
        }),
        quoteCutLength(){
            const quote = this.content.settings[0].quote;
            if(quote.length > 700){
                let text = quote.slice(0, 700);
                return `${text}...`
            }else{
                return quote
            }

        }
    },
    metaInfo: {
        title: 'О нас | Инвестиционный проект BioDeposit',
        meta: [
            { name: 'description', content: '【О платформе BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
        ]
    },
    created(){
        this.fetchContent();
        this.fetchInfo();
    },
    beforeRouteLeave (to, from, next) {
        next()
    },
    methods: {
        ...mapActions({
            fetchContent: `aboutUs/${ABOUT_US_LOAD}`,
            fetchInfo: `aboutUs/${ABOUT_US_INFO}`
        }),
        ...mapMutations({
            changeFeedback: `feedback/${SHOW_FEEDBACK}`,
        }),
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
    }

}
