import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    POPUP_REMOVE_PROFILE,
    REMOVE_USER,
    AUTH_LOGOUT
} from "@/store/mutation-types";
export default{
    name: 'popup-remove-profile',
    methods: {
        ...mapActions({
            deleteUser: `profile/${REMOVE_USER}`
        }),
        ...mapMutations({
            changePopupRemoveProfile: `profile/${POPUP_REMOVE_PROFILE}`,
            logout: `auth/${AUTH_LOGOUT}`
        }),
        deleteProfile(){
            this.deleteUser()
                .then(() => {
                    this.$router.push({ name: 'home' });
                    this.logout();
                })
                .catch(e => {
                    this.$toaster.error(e.response.data.error.message);
            })
        }
    }
}
