import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_TREES_MAP_POPUP_AREA,
    TREES_MAP_POPUP_AREA,
    TREES_MAP_LIST_RESET
} from '@/store/mutation-types';
import BingMaps from '@/components/bing-maps/index.vue'
export default {
    name: 'map-popup',
    data(){
        return{
            loading: true,
        }
    },
    components: {
        BingMaps
    },
    created(){
        this.fetchContent()
          .finally(() => {
              this.loading = false
          })
    },
    computed: {
        ...mapGetters({
            popupCurrentArea: `map/popupCurrentArea`,
            popupParamInitArea: `map/popupParamInitArea`,
        }),
        cordsCenter(){
            let arr = [];
            let obj = {};
            obj.latitude = this.popupParamInitArea.coordinates.lat;
            obj.longitude = this.popupParamInitArea.coordinates.lng;
            arr.push(obj);
            return arr;
        }
    },
    methods: {
        ...mapActions({
            fetchContent: `map/${TREES_MAP_POPUP_AREA}`
        }),
        ...mapMutations({
            changePopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
            reset: `map/${TREES_MAP_LIST_RESET}`,
        })
    },
    destroyed(){
        this.reset();
    }
}
