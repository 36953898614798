import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_TEXT_HELP_CALCULATE,
    BONUS_ACCOUNT_INFO,
    SHOW_INFO_CALC_TEXT,
    SHOW_TEXT_HELP_BONUS
} from "@/store/mutation-types";
export default{
    name: 'help-text',
    data(){
        return{
            infoShow:{
                treeAppreciationInfo: false,
                totalProfitFromTheCropInfo: false,
                expectedProfitInfo: false,
            }
        }
    },
    computed: {
        ...mapGetters({
            bonusAccountInfo: `statistic/bonusAccountInfo`,

        })
    },
    created(){
        this.fetchHelp();
    },
    methods: {
        ...mapActions({
            fetchHelp: `statistic/${BONUS_ACCOUNT_INFO}`,
        }),
        ...mapMutations({
            closeTextHelp: `helpCalculate/${SHOW_TEXT_HELP_CALCULATE}`,
            toggleTextHelpBonus: `statistic/${SHOW_TEXT_HELP_BONUS}`,
        })
    }
}
