import {mapGetters, mapMutations, mapActions} from 'vuex';
import {
    EXCHANGE_LIST_TREES,
    EXCHANGE_TREES_CHANGE_SKIP,
    EXCHANGE_TREES_CHANGE_PAGE,
    EXCHANGE_TREES_RESET,
    SHOW_TREES_MAP_POPUP_AREA
} from '@/store/mutation-types';
import VueAdsPagination from 'vue-ads-pagination';
import { VueAdsPageButton } from 'vue-ads-pagination';
export default{
    name: 'exchange-tree',
    data() {
        return {

        }
    },
    created(){
        this.fetchList();
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.fetchList()
        }
    },
    computed: {
        ...mapGetters({
            list: `exchangeTree/list`,
            loading: `exchangeTree/loading`,
            limit: `exchangeTree/limit`,
            skip: `exchangeTree/skip`,
            page: `exchangeTree/page`,
            length: `exchangeTree/length`,
        })
    },
    methods: {
        ...mapActions({
            fetchList: `exchangeTree/${EXCHANGE_LIST_TREES}`
        }),
        ...mapMutations({
            changeSkip: `exchangeTree/${EXCHANGE_TREES_CHANGE_SKIP}`,
            changePage: `exchangeTree/${EXCHANGE_TREES_CHANGE_PAGE}`,
            reset: `exchangeTree/${EXCHANGE_TREES_RESET}`,
            changeMapPopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
        })
    },
    destroyed(){
        this.reset();
    }
}