import { mapMutations, mapGetters, mapActions } from 'vuex'

export default{
    name: 'user-status-popup',
    data(){
        return {
        }
    },
    computed: {
        ...mapGetters({
            user: 'profile/user',
        })
    },
    created(){
    },
    methods: {
        ...mapActions({
        }),
        ...mapMutations({
            changeStatus: 'popups/SHOW_USER_STATUS'
        })
    },
}
