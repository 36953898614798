import { $http } from '@/https'
import {
    USER_TOKEN,
    RESET_USER_TOKEN,
} from '../../mutation-types';

const state = {
    token: null,
};

const getters = {
    token: state => state.token,
};

const actions = {
    [USER_TOKEN]: async ({ commit }) => {
        commit(RESET_USER_TOKEN);
        try {
            const response = await $http.get('v1/me');
            //test
            let param = {
                count: 125,
                token: 52625,
                price: 421
            };
            commit(USER_TOKEN, param);
            return param;
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [USER_TOKEN](state, param) {
        state.token = param;
    },
    [RESET_USER_TOKEN](state) {
        state.token = null;
    },

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
