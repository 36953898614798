import { mapMutations, mapGetters, mapActions } from 'vuex'
import { showTextPopup } from '@/helpers/variables/index';
import {
    RESET_TEXT_POPUP_CONTENT
} from "@/store/mutation-types";
export default{
    name: 'help-text',
    props: ['content'],
    computed: {
        ...mapGetters({
            helperContent: `popups/popupTextContent`
        })
    },
    methods: {
        ...mapMutations({
           resetContent: `popups/${RESET_TEXT_POPUP_CONTENT}`
        }),
        closePopup(status){
            showTextPopup(status)
        }
    },
}
