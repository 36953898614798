import {mapGetters, mapActions, mapMutations} from 'vuex';

import Moment from 'moment';

export default {
  data() {
    return {
      dateDiff: '',
    }
  },
  computed: {
    ...mapGetters({
      statistic: `statistic/statistic`,
      plantingTime: `statistic/plantingTime`,
      harvestTime: `statistic/harvestTime`,
      salesTime: `statistic/salesTime`,
      is_auth: `auth/is_auth`,
      statisticPrice: `statistic/statisticPrice`,
      alltrees: `statistic/alltrees`,
    }),
  },
  watch: {
    harvestTime: function (newVal) {
      console.log('1', this.harvestTime[59]);
      if (newVal) {
        this.getDate();

      }
    },
  },
  created() {
    this.fetchStatistic();
  },
  mounted() {
    if(this.harvestTime){
      this.getDate();
    }
  },
  methods: {
    ...mapActions({
      fetchStatistic: `statistic/MAIN_STATISTIC`,
      // fetchVariable: `statistic/${HOME_ALL_VARIABLES}`
    }),
    ...mapMutations({
      forEnter: `system/IS_LOGIN_USER`,
      // changeTickTimer: `statistic/${STATISTIC_TICK}`,
    }),
    getDate() {
      if (this.harvestTime[59]) {
        this.dateDiff = Moment(this.harvestTime[59].value, 'DD.MM.YYYY').diff(Moment(), "days").toString().split('')
      }
    }
  }
}
