import {$http} from '@/https';
import {
    NOTIFICATIONS_REMOVE,
    RESET_NOTIFICATIONS,
    NOTIFICATIONS
} from '../mutation-types.js';

const state = {
    counter: 0,
    notifications_list: null
};

const getters = {
    notifications_list: state => state.notifications_list
};

const actions = {
    [NOTIFICATIONS]: async ({commit}) => {
        setInterval( async ()  =>{
            try {
                const response = await $http.get('v1/new_notifications');
                commit(RESET_NOTIFICATIONS);
                commit(NOTIFICATIONS, response.data.data);
            }catch (e) {
                throw e
            }
        },7000);
    },
    [NOTIFICATIONS_REMOVE]: async ({commit}, payload) => {
        try {
            await $http.post(`v1/read_notification/${payload.id}`);
            commit(NOTIFICATIONS_REMOVE, payload);
        }catch (e) {
            throw e;
        }
    }
};

const mutations = {
    [NOTIFICATIONS](state, notifications_list){
        state.notifications_list = notifications_list;
    },
    [RESET_NOTIFICATIONS](state){
        state.notifications_list = [];
    },
    [NOTIFICATIONS_REMOVE](state, payload){
        state.notifications_list.forEach((elem, index) => {
            if(elem.id === payload.id){
                state.notifications_list.splice(index, 1);
            }
        });

    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
