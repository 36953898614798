import axios from "axios";

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    getAxios: async ({commit}, payload) => {
        return axios.create({
            baseURL: process.env.VUE_APP_NEW_API_URL,
            headers: {
                Accept: 'application/json',
                'X-Lang-Header': payload.lang,
                Authorization: payload.auth,
            },
        });
    },
    buyYoungOliveTreesStripeAsync: async ({dispatch,commit}, {payload,headers}) => {
        const myAxios = await dispatch('getAxios', headers);
        return await myAxios.post('/api/services/stripe/buy-young-olive-trees',payload)
            .then(({data}) => {
                return {
                    isSuccess: true,
                    pay_url: data.data.pay_url
                }
            })
            .catch(e => {
                return {
                    isSuccess: false,
                    exception: e.response.data
                }
            });
    },
    topUpBalanceStripeAsync: async ({dispatch , commit}, {payload,headers}) => {
        const myAxios = await dispatch('getAxios', headers);
        return await myAxios.post('/api/services/stripe/top-up-balance',payload)
            .then(({data}) => {
                return {
                    isSuccess: true,
                    pay_url: data.data.pay_url
                }
            })
            .catch(e => {
                return {
                    isSuccess: false,
                    exception: e.response.data
                }
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
