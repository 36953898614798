import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    PUT_UP_SHOW_CATEGORY,
    PUT_UP_TYPES_SALE,
    PUT_UP_LIST
} from "@/store/mutation-types";
export default {
    name: 'put-up-category-gift',
    data() {
        return {
            selectCategories: []
        }
    },
    computed: {
        ...mapGetters({
            category: `putUpGift/areasSale`
        })
    },
    created(){
        this.fetchCategory();
    },
    methods: {
        ...mapMutations({
            showPopup: `putUpGift/${PUT_UP_SHOW_CATEGORY}`,
            currentCategory: `putUpGift/${PUT_UP_LIST}`,
        }),
        ...mapActions({
            fetchCategory: `putUpGift/${PUT_UP_TYPES_SALE}`
        }),
        selectCategory(){
            this.currentCategory(this.selectCategories);
            this.showPopup(false);
        }
    }
}
