import {mapGetters, mapMutations, mapActions} from 'vuex';
import SliderChange from '@/components/slider-change/index.vue';
import SliderNews from '@/components/slider-news/index.vue';
import SliderVideo from '@/components/slider-video/index.vue';
import TextHelp from '@/modules/calculateincome/components/text-help/index.vue';
import _ from 'lodash';
import {
    BUY_SELECT_YOUNG_TREES,
    SHOW_TEXT_HELP_CALCULATE,
    NOT_AVAIBLE,
    IS_LOGIN_USER,
    CALCULATE_RESET_LIST_TREE,
    LOAD_USER_DATA,
    FEEDBACK_INVESTOR_SHOW,
    SHOW_INFO_CALC_TEXT
} from '@/store/mutation-types';

export default {
    name: 'calculate-income',
    data(){
        return{
            countValue: 20,
            yearsValue: 10,
            countValueInput: 20,
            showSpecialInvestor: false,
            totalYieldAllTrees: 0
        }
    },
    components: {
        TextHelp,
        SliderChange,
        SliderNews,
        SliderVideo

    },
    computed: {
        ...mapGetters({
            paramsTree: `calculate/paramsTree`,
            showTextHelpCalc: `helpCalculate/showTextHelpCalc`,
            is_auth: `auth/is_auth`,
            userData: `profile/user`,
        }),
        priceTree(){
            return this.countValue * this.paramsTree.defaultPriceTree
        },
        priceUpdateTree(){
            let currentPriceTree = this.paramsTree.defaultPriceTree;
            for (let i = 0; i <= this.yearsValue; i++) {
                if (i > 0 && i <= 3) {
                    currentPriceTree = currentPriceTree * this.paramsTree.upPriceTree
                }else if(i <= 9 && i >= 4){
                    currentPriceTree = currentPriceTree * this.paramsTree.upPriceTreeAdult
                }
            }
            let result = (this.countValue * currentPriceTree);
            if (this.countValue === 0 || this.yearsValue === 0) {
                return 0
            } else {
                let resultTotal = result *100;
                resultTotal = Math.round(resultTotal);
                return +(resultTotal/100).toFixed(2);
            }
        },
        yieldsTree(){
            const priceOil = (this.paramsTree.priceOil / 1000) / 5;
            let yieldCurrent = 0;
            let totalSumIteration = 0;
            let totalSum = 0;

            for (let i = 0; i <= this.yearsValue; i++) {
                if (i <= 9 && i >= 3) {
                    yieldCurrent = this.yieldCurrentTime(i);
                    totalSumIteration = Math.round((1 - (this.countValue >= 1000 ? this.paramsTree.commission : this.paramsTree.commissionInvestor)) * ((yieldCurrent * this.countValue) * priceOil) * 10000) / 10000;
                    totalSum += totalSumIteration
                } else if (i < 3) {
                    totalSum = 0;
                }
                else {
                    totalSumIteration = Math.round((1 - (this.countValue >= 1000 ? this.paramsTree.commission : this.paramsTree.commissionInvestor)) * ((yieldCurrent * this.countValue) * priceOil) * 10000) / 10000;
                    totalSum += totalSumIteration;
                }
            }
            if (this.yearsValue > 0) {
                let result = totalSum *100;
                result = Math.round(result);
                return +(result/100).toFixed(2)
            } else {
                return 0
            }
        },
        getTotalOil(){
            let total = 0;
            for (let i = 0; i <= this.yearsValue; i++) {
                if (i <= 9 && i >= 3) {
                    total = total+ this.yieldCurrentTime(i);
                } else if (i < 3) {

                }
                else {
                    total = total + this.yieldCurrentTime(10);
                }
            }
            total = total * 0.6;

            return this.countValue * (total / 5);
        },
        watchInvestor(){
          if(this.userData) {
              if(this.userData.investor){
                  return false
              }else{
                  return true
              }
          } else{
              return true
          }
        },
        total(){
            if(this.countValue === 0 || this.yearsValue === 0){
                return 0
            }else{
                let result = (this.yieldsTree + this.priceUpdateTree - this.priceTree);
                result = (result * 100);
                return +(result/100).toFixed(2)
            }
        }
    },
    watch: {
        is_auth(){
            if(this.is_auth === true){
                this.fetchUser();
            }
        },
        countValue(){
            if(this.countValue >= 1000){
                this.countValue = 1000;
                this.countValueInput = 1000;
                this.showSpecialInvestor = true;
            }else{
                this.countValueInput = this.countValue;
                this.showSpecialInvestor = false;
            }
        },
        yearsValue(){
            if(this.yearsValue > 100){
                this.yearsValue = 100;
            }
        }
    },
    created(){
        if(this.is_auth){
            if(!this.userData){
                this.fetchUser();
            }
        }
    },
    methods: {
        ...mapMutations({
            notAvaible: `system/${NOT_AVAIBLE}`,
            forEnter: `system/${IS_LOGIN_USER}`,
            toggleTextHelp: `helpCalculate/${SHOW_TEXT_HELP_CALCULATE}`,
            resetListTree: `calculate/${CALCULATE_RESET_LIST_TREE}`,
            changeSelectYoungTrees: `profileBuyTree/${BUY_SELECT_YOUNG_TREES}`,
            goInvestor: `auth/${FEEDBACK_INVESTOR_SHOW}`,
            showInfoCalc: `statistic/${SHOW_INFO_CALC_TEXT}`,
        }),
        ...mapActions({
            fetchUser: `profile/${LOAD_USER_DATA}`
        }),
        gtm(profit, count, years){
            this.$analytics.fbq.event('InitiateCheckout');
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: `revenue_${profit}`,
                eventAction: `trees_${count}`,
                eventLabel: `years_${years}`,
            });
        },
        yieldCurrentTime(i){
            return this.paramsTree.upperYieldTree.find((elem, index) => {
                if(i === index){
                    return elem;
                }
            })
        },
        selectYoungTrees(){
            if(this.is_auth && !this.userData.investor){
                this.changeSelectYoungTrees(this.countValue);
            }
        },
        changeCountTrees: _.debounce(function (event) {
            if(event.length !== 0){
                if(+event > 1000){
                    this.countValue = 1000;
                    this.countValueInput = 1000;
                }else{
                    this.countValue = +event;
                    this.countValueInput = +event;
                }
            }else{
                this.countValue = 0;
                this.countValueInput = 0;
            }
        }, 1000),
    },
    destroyed () {
        this.resetListTree();
    },
};
