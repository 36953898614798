import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
    TREES_MAP_LIST,
    ACTIVE_AREA,
    MAP_CURRENT_LENGTH,
    TREES_MAP_AREA,
    GET_COORDINATES_TREES_FIELD
} from '@/store/mutation-types';
import VueAdsPagination from 'vue-ads-pagination';
import { VueAdsPageButton } from 'vue-ads-pagination';
import BingMaps from '@/components/bing-maps/index.vue'
export default {
    name: 'trees',
    data(){
        return{
            page: 0,
            start: 1,
            end: 8,
            offset: 0,
            loading: false,
            selectIndex: 0,
            showTrees: false,
        }
    },
    description: {

    },
    computed: {
        ...mapGetters({
            limit: 'trees/limit',
            list: `map/list`,
            activeId: `map/activeArea`,
            currentArea: `map/currentArea`,
            user: `profile/user`,
            lengthList: `map/length`,
            coordinatesTreesField: `map/coordinatesTreesField`,
            coordinatesTreesFieldLoading: `map/coordinatesTreesFieldLoading`,
        }),
        markers(){
            if(this.list[0].trees){
                let arr = [];
                this.list[0].trees.forEach(e => {
                    arr.push(e.coordinates)
                });
                return arr;
            }
            return false;
        }
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton,
        BingMaps
    },
    watch: {
        page(){
            this.loading = true;
            this.offset = this.page * this.limit;
            this.fetchListTree({
                id: this.activeId,
                offset: this.offset
            }).finally(
                () => {
                    this.loading = false;
                    this.start = this.page * this.limit+1;
                }
            );
        }
    },
    created(){

        this.fetchListArea()
            .then(response => {
                this.fetchCoordinatesTress(response[0].id);
                this.activeArea(response[0].id);
                this.fetchListTree({
                    id: this.activeId,
                    limit: this.limit,
                    offset: this.offset
                }).then(() => {});
                this.$refs.row[0].classList.add('active');
            });
    },
    methods: {
        ...mapActions({
            fetchListTree: `map/${TREES_MAP_LIST}`,
            fetchListArea: `map/${TREES_MAP_AREA}`,
            fetchCoordinatesTress: `map/${GET_COORDINATES_TREES_FIELD}`,
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        ...mapMutations({
            activeArea: `map/${ACTIVE_AREA}`,
            storeMapListLength: `map/${MAP_CURRENT_LENGTH}`
        }),
        toggleCurrentArea(index, id){
            this.$refs.row.forEach(elem => {
                elem.classList.remove('active');
            });
            this.storeMapListLength(0);
            this.fetchCoordinatesTress(id);
            this.page = 0;
            this.$refs.row[index].classList.add('active');
            this.activeArea(id);
            this.fetchListTree({
                id: this.activeId,
                limit: this.limit,
                offset: this.offset
            }).then(resp => {
            })
        },
    }
}
