import { mapMutations, mapGetters } from 'vuex'
import {NOT_AVAIBLE} from "@/store/mutation-types";
export default{
    name: 'server-error',
    computed: {
        ...mapGetters({
            errorMessage: `system/errorServerMessage`
        })
    },
    methods: {
        ...mapMutations({
            notAvaible: `system/${NOT_AVAIBLE}`
        }),
    }
}