import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SELL_SHOW_TREES,
    SELL_LOAD_TREES,
    SELL_SELECTED_TREES,
    LOADING_POSITION,
    GET_POSITION,
    SELL_CHANGE_OFFSET,
    SELL_CHANGE_ALL,
    SELL_RESET_TREES,
    SELL_CHANGE_CHECK
} from "@/store/mutation-types";
import VueAdsPagination, {VueAdsPageButton} from "vue-ads-pagination";
export default {
    name: 'sell-trees-popup',
    data() {
        return {
            page: 0,
            limit: 20,
        }
    },
    computed: {
        ...mapGetters({
            list: `sell/list`,
            loading: `sell/loadingTreesPopup`,
            trees: `sell/trees`,
            lengthListTree: `sell/lengthListTree`,
            currentCategory: `sell/currentCategory`,
            selectTrees: `sell/selectTrees`,
        })
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    watch: {
        page(){
            this.changeOffset(this.page * this.limit);
            this.sendRequest();
            this.fetchTrees(this.currentCategory)
        }
    },
    created(){
        this.fetchTrees(this.currentCategory);
    },
    methods: {
        ...mapActions({
            fetchTrees: `sell/${SELL_LOAD_TREES}`,
            getPosition: `sell/${GET_POSITION}`
        }),
        ...mapMutations({
            showPopup: `sell/${SELL_SHOW_TREES}`,
            changeOffset: `sell/${SELL_CHANGE_OFFSET}`,
            loadingPosition: `sell/${LOADING_POSITION}`,
            currentTrees: `sell/${SELL_SELECTED_TREES}`,
            changeCheckbox: `sell/${SELL_CHANGE_CHECK}`,
            changeAllTrees: `sell/${SELL_CHANGE_ALL}`,
            resetTreeList: `sell/${SELL_RESET_TREES}`,
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        sendRequest(){
            this.currentTrees(this.selectTrees);
            let arr = [];
            this.list.forEach(elem => {
                let obj ={
                    tree_id: elem.id,
                    price: elem.minPrice,
                    commission: elem.commission
                };
                arr.push(obj)
            });
            this.loadingPosition(true);
            this.getPosition(arr)
                .finally(() => {
                    this.loadingPosition(false);
                });
        },
        addTrees(){
            this.sendRequest();
            this.showPopup(false)
        },
    },
    destroyed(){
        this.resetTreeList();
    }
}
