import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_FEEDBACK,
    SHOW_SING_IN,
    SHOW_HEADER_MENU,
    NOT_AVAIBLE,
    AUTH_LOGOUT,
    SHOW_CASH_POPUP,
    REGISTRATION_SHOW,
    LANG_LIST,
    LABEL_DOCUMENTS,
    LOAD_PACKAGE_OFFER,
    CONFIG
} from "@/store/mutation-types";
export default{
    name: 'main-header',
    data(){
      return{
          lActive: false,
          tooltip: {
              feedback: 'dsfsdfsfds'
          }
      }
    },
    computed: {
        ...mapGetters({
            addTreeForCash:'shop/addTreeForCash',
            isInvestorPage: `system/isInvestorPage`,
            is_auth: `auth/is_auth`,
            isActive: `system/openMenu`,
            notifications_list: 'notifications/notifications_list',
            locales: `statistic/locales`,
            imgButton: `statistic/imgButton`,
            config: `main/config`,
            user: `profile/user`,
            countElementBasket: `shop/countElement`,
        }),
        showContacts(){
            let showContact = false;

            this.config.forEach(elem => {
                if(elem.key === 'component_contacts'){
                    showContact = true
                }
            });

            return showContact;
        },
        ...mapGetters([
            'languages',
            'currentLanguage',
        ])
    },
    updated(){
            document.addEventListener('click', () => {
                if( this.lActive === 'active'){
                    this.lActive = false;
                }
            });
    },
    created(){
        this.fetchInfo();
    },
    methods: {
        ...mapActions({
            logOutPersonal: `auth/${AUTH_LOGOUT}`,
            fetchInfo: `statistic/${LANG_LIST}`,
            fetchConfig: `main/${CONFIG}`,
            fetchPackageOffer: `packageOfferSlider/${LOAD_PACKAGE_OFFER}`,
            loadLegalDocuments: `labelDocuments/${LABEL_DOCUMENTS}`
        }),
        ...mapMutations({
            cashPopUp: `system/${SHOW_CASH_POPUP}`,
            changeFeedback: `feedback/${SHOW_FEEDBACK}`,
            showSingIn: `auth/${SHOW_SING_IN}`,
            notAvaible: `system/${NOT_AVAIBLE}`,
            showMenu: `system/${SHOW_HEADER_MENU}`,
            showRegistration: `auth/${REGISTRATION_SHOW}`,
        }),
        goRegistration(){
            this.showRegistration(true);
        },
        changeLanguage (code) {
            this.$setLanguage(code);
            // no location reload
            this.fetchPackageOffer();
            this.fetchConfig();
            if(this.$route.fullPath.includes('legal-documents')){
                this.loadLegalDocuments();
            }
        },
        logOut(){
            this.logOutPersonal()
                .then(() => {

                })
                .catch(() => {

                })
                .finally(() => {
                    this.$router.push({ name: 'home' })
                })
        },
        langActive(){
            setTimeout(()=>{
                this.lActive = 'active'
            },200);
        }
    }
}
