import { Carousel, Slide } from 'vue-carousel';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import VueGallery from 'vue-gallery';
import {
    GALLERY_PHOTO_ALBUM,
    GALLERY_PHOTO_ALBUM_LIMIT,
    GALLERY_VIDEO_ALBUM,
} from '@/store/mutation-types'

export default {
    data(){
        return{
            activePhoto:true,
            activeVideo:false,
            sliderPhoto: [],
            index: null,
        }
    },
    computed:{
        ...mapGetters({
            galleryPhotoAlbum: 'gallery/galleryPhotoAlbum',
            galleryPhotoCol: 'gallery/galleryPhotoAlbumCol',
            galleryVideoAlbum: 'gallery/galleryVideoAlbum',
            galleryVideoLink: 'gallery/galleryVideoLink',
            galleryLink: 'gallery/galleryLink',
            galleryLimit: 'gallery/galleryLimit'
        })
    },
    created(){
        this.photoContent();
        this.videoContent();
    },
    metaInfo: {
        title: 'Галерея | Инвестиционный проект BioDeposit',
        meta: [
            { name: 'description', content: '【Галерея - BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
        ]
    },
    methods:{
        ...mapActions({
            photoContent: `gallery/${GALLERY_PHOTO_ALBUM}`,
            videoContent: `gallery/${GALLERY_VIDEO_ALBUM}`
        }),
        ...mapMutations({
            galleryPhotoAlbumCol: `gallery/${GALLERY_PHOTO_ALBUM_LIMIT}`
        }),
        activeItem(item){
            // reset all elemets active status
            this.sliderItem.map(element =>{
                return element.active = false;
            })
            //find clicked element by id
            const foundElement = this.sliderItem.find(element => {
                return element.id === item.id;
            })
            foundElement.active = true;
        },
        startPhoto(){
          this.activePhoto = true;
           this.activeVideo = false;
        },
        startVideo(){
            this.activePhoto = false;
            this.activeVideo = true;
        },
        openMorePhoto(){
            this.galleryPhotoAlbumCol(this.galleryLimit.count + 2);
            this.photoContent();
        },
        openMoreLinks(){
            alert('11');
        }
    },
    components: {
        Carousel,
        Slide,
        'gallery': VueGallery
    }
};
