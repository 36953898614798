import {$http} from '@/https'
import {fixBody, globalLoader} from '@/helpers/variables/index'
import router from '@/router'
import {
    SHOW_GET_GIFT_POPUP,
    SHOW_POPUP_GIFT_LIMIT,
    CURRENT_GIFT_OBJ,
    GIFT_GET_TREES_LIST_RESET,
    GIFT_GET_TREES_LIST_STORY_RESET,
    GIFT_GET_TREES_LIST,
    GIFT_GET_TREES_LIST_LENGTH,
    GIFT_GET_TREES_LIST_LENGTH_STORY,
    CANCEL_GIFT_GET_LIST,
    CANCEL_GIFT_GET_LIST_LENGTH,
    CANCEL_GIFT_GET_LIST_RESET,
    GET_GIFT_TREE,
    WITHDRAW_GIFT,
    CHANGE_GIFT_CODE,
    CHANGE_CANCEL_GIFT_PAGE,
    CHANGE_CANCEL_GIFT_SKIP,
    CHANGE_GIFT_LOADING,
    CANCEL_GIFT_RESET,
    CHANGE_GET_GIFT_SKIP,
    CHANGE_GET_GIFT_SKIP_STORY,
    CHANGE_GET_GIFT_PAGE,
    CHANGE_GET_GIFT_PAGE_STORY,
    GET_GIFT_RESET,
    DOWNLOAD_CERTIFICATE,
    GET_GIFT_TREE_PROMO,
    GET_GIFT_TREE_STORY
} from '@/store/mutation-types';


const state = {
    cancelGiftList: null,
    giftList: null,
    giftListLength: null,
    cancelGiftListLength: null,
    showPopupGetGift: false,
    currentObjGift: null,
    showPopupGiftLimit: false,
    giftListStory: null,
    giftListLengthStory: null,
    cancelGiftListLengthStory: null,
    loading: false,
    code: '',

    cancelLimit: 13,
    cancelSkip: 0,
    cancelPage: 0,

    getLimit: 8,
    getSkip: 0,
    getSkipStory: 0,
    getPage: 0,
    getPageStory: 0,
};

const getters = {
    getLimit: state => state.getLimit,
    getSkip: state => state.getSkip,
    getSkipStory: state => state.getSkipStory,
    getPage: state => state.getPage,
    getPageStory: state => state.getPageStory,
    cancelPage: state => state.cancelPage,
    cancelLimit: state => state.cancelLimit,
    cancelSkip: state => state.cancelSkip,
    code: state => state.code,
    giftList: state => state.giftList,
    cancelGiftList: state => state.cancelGiftList,
    giftListLength: state => state.giftListLength,
    cancelGiftListLength: state => state.cancelGiftListLength,
    currentObjGift: state => state.currentObjGift,
    showPopupGiftLimit: state => state.showPopupGiftLimit,
    showPopupGetGift: state => state.showPopupGetGift,
    loading: state => state.loading,
    giftListStory: state => state.giftListStory,
    giftListLengthStory: state => state.giftListLengthStory,
    cancelGiftListLengthStory: state => state.cancelGiftListLengthStory,
};
const actions = {
    [CANCEL_GIFT_GET_LIST]: async ({commit}) => {
        try {
            commit(CHANGE_GIFT_LOADING, true);

            const response = await $http.get(`v1/gifted_trees?limit=${state.cancelLimit}&skip=${state.cancelSkip}`);
            commit(CANCEL_GIFT_GET_LIST_RESET);
            commit(CANCEL_GIFT_GET_LIST, response.data.data);
            commit(CANCEL_GIFT_GET_LIST_LENGTH, response.data.meta.count);
            if(response.data.meta.count <= 0){
                router.push({ name: 'personal' })
            }
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(CHANGE_GIFT_LOADING, false);
        }
    },
    [GET_GIFT_TREE]: async ({ dispatch, commit }, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/get_my_gifted_tree`, payload);
            dispatch(GIFT_GET_TREES_LIST, response.data.data);
            if(response.data.type !== "money"){
                commit(`trees/${DOWNLOAD_CERTIFICATE}`, response.data.link, {root: true});
            }
            commit(GET_GIFT_RESET);
            commit(SHOW_GET_GIFT_POPUP, false);
            commit(CHANGE_GIFT_CODE, '');
            return response.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [GET_GIFT_TREE_PROMO]: async ({ dispatch, commit }, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/get_my_gifted_tree`, payload);
            dispatch(GIFT_GET_TREES_LIST, response.data.data);
            commit(GET_GIFT_RESET);
            commit(SHOW_GET_GIFT_POPUP, false);
            commit(CHANGE_GIFT_CODE, '');
            return response.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [WITHDRAW_GIFT]: async ({ dispatch, commit }, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/withdraw_gift`, payload);
            dispatch(CANCEL_GIFT_GET_LIST, response.data.data);
            commit(CANCEL_GIFT_RESET);
            return response.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [GIFT_GET_TREES_LIST]: async ({ commit }) => {
        try {
            commit(CHANGE_GIFT_LOADING, true);

            const response = await $http.get(`v1/gifted_to_me_trees?limit=${state.getLimit}&skip=${state.getSkip}`);
            commit(GIFT_GET_TREES_LIST_RESET);
            commit(GIFT_GET_TREES_LIST, response.data.data);
            commit(GIFT_GET_TREES_LIST_LENGTH, response.data.meta.count);
            if(response.data.meta.count <= 0){
                router.push({ name: 'personal' });
            }
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(CHANGE_GIFT_LOADING, false);
        }
    },
    [GET_GIFT_TREE_STORY]: async ({ commit }) => {
        try {
            commit(CHANGE_GIFT_LOADING, true);

            const response = await $http.get(`v1/my_gifts_history?limit=${state.getLimit}&skip=${state.getSkipStory}`);
            commit(GIFT_GET_TREES_LIST_STORY_RESET);
            commit(GET_GIFT_TREE_STORY, response.data.data);
            commit(GIFT_GET_TREES_LIST_LENGTH_STORY, response.data.meta.count);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(CHANGE_GIFT_LOADING, false);
        }
    },
};



const mutations = {
    [SHOW_GET_GIFT_POPUP](state, status) {
        fixBody(status);
        state.showPopupGetGift = status;
    },
    [CANCEL_GIFT_GET_LIST](state, list) {
        state.cancelGiftList = list;
    },
    [CANCEL_GIFT_GET_LIST_LENGTH](state, length) {
        state.cancelGiftListLength = length;
    },
    [CURRENT_GIFT_OBJ](state, obj) {
        state.currentObjGift = obj;
    },
    [GIFT_GET_TREES_LIST](state, list) {
        state.giftList = list;
    },
    [GET_GIFT_TREE_STORY](state, list){
        state.giftListStory = list;
    },
    [GIFT_GET_TREES_LIST_RESET](state) {
        state.giftList = null;
    },
    [GIFT_GET_TREES_LIST_STORY_RESET](state) {
        state.giftListStory = null;
    },
    [CANCEL_GIFT_GET_LIST_RESET](state) {
        state.cancelGiftList = null;
    },
    [CANCEL_GIFT_RESET](state) {
        state.cancelGiftList = null;
        state.cancelLimit = 13;
        state.cancelSkip = 0;
        state.cancelPage = 0;
    },
    [GET_GIFT_RESET](state) {
        state.giftList = null;
        state.getLimit = 8;
        state.getSkip = 0;
        state.getPage = 0;
    },
    [GIFT_GET_TREES_LIST_LENGTH](state, length) {
        state.giftListLength = length;
    },
    [GIFT_GET_TREES_LIST_LENGTH_STORY](state, length) {
        state.giftListLengthStory = length;
    },
    [SHOW_POPUP_GIFT_LIMIT](state, status) {
        fixBody(status);
        state.showPopupGiftLimit = status;
    },
    [CHANGE_GIFT_CODE](state, code) {
        state.code = code;
    },
    [CHANGE_CANCEL_GIFT_SKIP](state, cancelSkip) {
        state.cancelSkip = cancelSkip;
    },
    [CHANGE_CANCEL_GIFT_PAGE](state, cancelPage) {
        state.cancelPage = cancelPage;
    },
    [CHANGE_GET_GIFT_SKIP](state, getSkip) {
        state.getSkip = getSkip;
    },
    [CHANGE_GET_GIFT_SKIP_STORY](state, getSkipStory) {
        state.getSkipStory = getSkipStory;
    },
    [CHANGE_GET_GIFT_PAGE](state, getPage) {
        state.getPage = getPage;
    },
    [CHANGE_GET_GIFT_PAGE_STORY](state, getPageStory) {
        state.getPageStory = getPageStory;
    },
    [CHANGE_GIFT_LOADING](state, status) {
        state.loading = status;
    },
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
