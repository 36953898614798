import {mapActions, mapGetters} from 'vuex';
import { transformDate } from '@/helpers/variables/index'
import {
    NOTIFICATIONS_REMOVE
} from '@/store/mutation-types'

export default {
    name:'notifications',
    data(){
        return{
            loader: false
        }
    },
    computed:{
        ...mapGetters({
            notifications_list: 'notifications/notifications_list'
        })
    },
    methods:{
        ...mapActions({
           remove: `notifications/${NOTIFICATIONS_REMOVE}`
        }),
        rebuildFormatDate(val){
            return transformDate(val)
        },
        removeNotification(id){
            this.loader = true;
            let obj = {
                id: id
            };
            this.remove(obj)
                .finally(() => {
                    this.loader = false;
                });
        }
    },

}
