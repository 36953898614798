import { $http } from '@/https';
import {
    ABOUT_US_LOAD,
    ABOUT_US_INFO
} from '../mutation-types.js';

const state = {
    content: null,
    info: null
};

const getters = {
    content: state => state.content,
    info: state => state.info
};

const actions = {
    [ABOUT_US_LOAD]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/pages/about-magazine-biodeposit');
            commit(ABOUT_US_LOAD, response.data.data);
        }catch (e) {
            throw e;
        }
    },
    [ABOUT_US_INFO]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/widgets/about_us_contact');
            commit(ABOUT_US_INFO, response.data);
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [ABOUT_US_LOAD](state, content) {
        state.content = content;
    },
    [ABOUT_US_INFO](state, content) {
        state.info = content;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
