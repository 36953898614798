import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    GIVE_SHOW_CATEGORIES,
    TREES_LOAD_ALL_CATEGORIES,
    CALCULATE_CURRENT_CATEGORY_PERSONAL,
    GIVE_SHOW_TREES
} from "@/store/mutation-types";
export default {
    name: 'popup-all-category',
    data() {
        return {
            selectCategories: []
        }
    },
    computed: {
        ...mapGetters({
            category: `trees/allCategories`
        })
    },
    created(){
        this.fetchCategory();
    },
    methods: {
        ...mapMutations({
            showPopup: `give/${GIVE_SHOW_CATEGORIES}`,
            showTreesPopup: `give/${GIVE_SHOW_TREES}`,
            currentCategory: `calculate/${CALCULATE_CURRENT_CATEGORY_PERSONAL}`,
        }),
        ...mapActions({
            fetchCategory: `trees/${TREES_LOAD_ALL_CATEGORIES}`
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        selectCategory(){
            this.currentCategory(this.selectCategories);
            this.showPopup(false);
            this.showTreesPopup(true);
        }
    }
}