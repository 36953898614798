import {
    SHOW_EXCHANGE_TREE_POPUP,
    EXCHANGE_CURRENT_TREE,
    RESET_EXCHANGE_CURRENT_TREE,
    SHOW_GET_QR_CODE,
    GET_QR_ID
} from '@/store/mutation-types';
import {mapActions, mapGetters, mapMutations} from 'vuex';

import Vue from 'vue';
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        function event(event) {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        }
        document.body.addEventListener('click', event)
    },
    unbind: function (el, binding, vnode) {
        function event(event) {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        }
        document.body.removeEventListener('click', event)
    },
});

export default {
    name: 'tree-item-menu',
    props: ['item'],
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {

        ...mapGetters({
            numbQrIdNow: `getQrCode/numbQrId`
        })
    },
    methods: {
        ...mapMutations({
           showPopup: `exchangeTree/${SHOW_EXCHANGE_TREE_POPUP}`,
           currentTree: `exchangeTree/${EXCHANGE_CURRENT_TREE}`,
           resetCurrentTree: `exchangeTree/${RESET_EXCHANGE_CURRENT_TREE}`,
            showPopupQr: `getQrCode/${SHOW_GET_QR_CODE}`,
            numbQrId: `getQrCode/${GET_QR_ID}`,
        }),
        show(){
            this.isOpen = true;
        },
        hide(){
            this.isOpen = false;
        },
        exchangeTree(obj){
            this.currentTree(obj);
            this.showPopup(true);
        }
    },
    destroyed() {
        this.resetCurrentTree();
    }
}