import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    CANCEL_PUT_UP_GIFT_PIECE,
    CANCEL_PUT_UP_GIFTTAKE_OFF_LIST,
    SHOW_POPUP_CANCEL_PUT_UP_GIFT,
    FETCH_USER_SALES
} from "@/store/mutation-types";
export default{
    name: 'cancel-put-up-gift-popup-documents',
    data(){
      return {
          check: '',
          count: '',
          type: 'gift',
          payload: {
              limit: 11,
              skip: 0,
          }
      }
    },
    created(){
        this.fetchUserSales();
    },
    computed: {
        ...mapGetters({
            loading: `cancelPutUpGift/loading`,
            user: `profile/user`,
            userSales: `profile/userSales`,
        })
    },
    methods: {
        ...mapActions({
            withdrawPiece: `cancelPutUpGift/${CANCEL_PUT_UP_GIFT_PIECE}`,
            fetchList: `cancelPutUpGift/${CANCEL_PUT_UP_GIFTTAKE_OFF_LIST}`,
            fetchUserSales: `profile/${FETCH_USER_SALES}`,
        }),
        ...mapMutations({
            showPopup: `cancelPutUpGift/${SHOW_POPUP_CANCEL_PUT_UP_GIFT}`
        }),
        cancelGiftPiece() {
            let obj = {
                type: this.type,
                is_young: 1,
                tree_count: this.count
            };
            this.withdrawPiece(obj)
                .then(response => {
                    this.$toaster.success(response.message);
                    this.showPopup(false);
                })
                .catch(error => {
                    if(error.response.status === 424){
                        this.showPopup(false);
                    }
                    this.$toaster.error(error.response.data.error.message)
                })
        },
    }
}
