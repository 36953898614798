import Vue from 'vue';
import { $http } from '@/https';
import {
    SHOW_TEXT_HELP,
    TEXT_POPUP_CONTENT,
    RESET_TEXT_POPUP_CONTENT,
    SHOW_POPUP_HELP,
    FETCH_HELP_INFO,
    RESET_POPUP_HELP,
    SHOW_POPUP_INTRO,
    SHOW_USER_STATUS,
    SHOW_USER_NFT,
    SHOW_POPUP_AFTER_REGISTRATION
} from '@/store/mutation-types';
import { fixBody } from '@/helpers/variables/index'

const state = {
    popupTextContent: null,
    showPopupIntro: false,
    showTextHelp: false,
    openHelpPopup: false,
    contentHelpPopup: null,
    showUserNft: false,
    popupAfterRegistrationStatus: false,
    showUserStatus: false,
};

const getters = {
    showTextHelp: state => state.showTextHelp,
    showUserStatus: state => state.showUserStatus,
    showUserNft: state => state.showUserNft,
    showPopupIntro: state => state.showPopupIntro,
    contentHelpPopup: state => state.contentHelpPopup,
    openHelpPopup: state => state.openHelpPopup,
    popupTextContent: state => state.popupTextContent,
    popupAfterRegistrationStatus: state => state.popupAfterRegistrationStatus,
};
const actions = {
    [FETCH_HELP_INFO]: async ({ commit }) => {
        try {
            const result = await $http.get('v1/helpers');
            commit(FETCH_HELP_INFO, result.data.data);
        }catch (e) {
            throw e
        }
    },
};

const mutations = {
    [SHOW_POPUP_HELP](state, status) {
        state.openHelpPopup = status;
    },
    [SHOW_USER_STATUS](state, status) {
        state.showUserStatus = status;
    },
    [SHOW_USER_NFT](state, status) {
        state.showUserNft = status;
    },
    [RESET_POPUP_HELP](state) {
        state.contentHelpPopup = null;
    },
    [SHOW_TEXT_HELP](state, status) {
        state.showTextHelp = status
    },
    [SHOW_POPUP_INTRO](state, status) {
        Vue.set(state, 'showPopupIntro', status);
        fixBody(status);
    },
    [SHOW_POPUP_AFTER_REGISTRATION](state, status) {
        // Vue.set(state, 'popupAfterRegistrationStatus', status);
        // fixBody(status);
    },
    [FETCH_HELP_INFO](state, content) {
        state.contentHelpPopup = content
    },
    [TEXT_POPUP_CONTENT](state, content) {
        state.popupTextContent = content
    },
    [RESET_TEXT_POPUP_CONTENT](state) {
        state.popupTextContent = null;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
