import {mapActions, mapGetters, mapMutations} from 'vuex';
import {
    CANCEL_GIFT_GET_LIST,
    SHOW_POPUP_TAKE_OFF,
    WITHDRAW_GIFT,
    LOAD_USER_DATA,
    CHANGE_CANCEL_GIFT_PAGE,
    CHANGE_CANCEL_GIFT_SKIP,
    CANCEL_GIFT_RESET
} from '@/store/mutation-types';
import VueAdsPagination from 'vue-ads-pagination';
import {VueAdsPageButton} from 'vue-ads-pagination';

export default {
    name: 'take-off',
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    computed: {
        ...mapGetters({
            loading: `gift/loading`,
            page: `gift/cancelPage`,
            list: `gift/cancelGiftList`,
            limit: `gift/cancelLimit`,
            skip: `gift/cancelSkip`,
            length: `gift/cancelGiftListLength`,

        }),
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.fetchList()
        }
    },
    created() {
        this.fetchList()
    },
    methods: {
        ...mapActions({
            fetchList: `gift/${CANCEL_GIFT_GET_LIST}`,
            withdrawGift: `gift/${WITHDRAW_GIFT}`,
            fetchUser: `profile/${LOAD_USER_DATA}`,
        }),
        ...mapMutations({
            showPopup: `takeOff/${SHOW_POPUP_TAKE_OFF}`,
            changePage: `gift/${CHANGE_CANCEL_GIFT_PAGE}`,
            changeSkip: `gift/${CHANGE_CANCEL_GIFT_SKIP}`,
            reset: `gift/${CANCEL_GIFT_RESET}`,

        }),
        cancel(id) {
            let obj = {
                gift_id: id
            };
            this.withdrawGift(obj)
                .then(response => {
                    this.$toaster.success(response.message)
                })
                .catch(error => {
                    this.$toaster.error(error.response.data.error.message)
                })
        },
    },
    destroyed() {
        this.reset();
    }
}
