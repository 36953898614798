<template>
    <div class="page__404">
        <div class="wrap__404">
            <div class="container">
                <div class="not__found">
                    <h3>404</h3>
                    <p>
                        {{ $t('page404Main') }}
                    </p>
                    <router-link :to="{ name: 'home' }" class="btn">
                        <span>
                            {{ $t('goMainPage') }}
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    @import "../../assets/style/vars";
    .not__found{
        position: relative;
        z-index: 2;
    }
    #app{
        overflow: hidden;
    }
    .page__404{

    }
    .wrap__404 {
        text-align: center;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 66%;
            left: 51%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 150%;
            background-image: url('../../assets/images/about-us-bg-2.png');
            background-position: center;
            background-repeat: no-repeat;
        }
        h3{
            font: $FiraSans-Bold;
            font-size: 220px;
            color: $green2;
            line-height: .9;
        }
        p{
            font-size: 22px;
        }
        .btn{
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            font-size: 20px;
            height: 50px;
            max-width: 70%;
            margin: 0 auto;
            margin-top: 30px;
            span{
                font-size: 26px;
            }
        }
    }
    @media screen and (max-width: 425px){
        .wrap__404 h3{
            font-size: 170px;
        }
        .wrap__404 p{
            font-size: 18px;
        }
        .wrap__404 .btn{
            height: 40px;
            max-width: 80%;
            margin: 0 auto;
            margin-top: 30px;
            span{
                font-size: 18px;
            }
        }
    }
</style>
<script>
    export default {

    }
</script>
