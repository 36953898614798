import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    HELPER_CONTENT_FILTER,
    SHOW_TEXT_HELP_BONUS
} from "@/store/mutation-types";
export default{
    name: 'help-text',
    computed: {
        ...mapGetters({
            helpTextFilter: `helpCalculate/helperContentFilter`,
            helperContentFilterLoading: `helpCalculate/helperContentFilterLoading`,
        })
    },
    created(){
        this.fetchHelp();
    },
    methods: {
        ...mapActions({
            fetchHelp: `helpCalculate/${HELPER_CONTENT_FILTER}`,
        }),
        ...mapMutations({
            showPopup: `statistic/${SHOW_TEXT_HELP_BONUS}`,
        })
    }
}