import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_POPUP_STATISTIC,
    FETCH_STATISTIC_INFO,
    RESET_POPUP_STATISTIC
} from "@/store/mutation-types";
export default {
    name: 'statistic-popup',
    data(){
        return {
            loading: true
        }
    },
    computed:{
        ...mapGetters({
            content: 'statistic/contentStatisticPopup',
            user: 'profile/user',
        })
    },
    created(){
        this.fetchContent().finally(() => {this.loading = false});
    },
    methods: {
        ...mapActions({
            fetchContent: `statistic/${FETCH_STATISTIC_INFO}`,
        }),
        ...mapMutations({
            showPopup: `statistic/${SHOW_POPUP_STATISTIC}`,
            reset: `statistic/${RESET_POPUP_STATISTIC}`,
        }),
    },
    destroyed(){
        this.reset();
        this.showPopup(false);
    }
}
