import axios from "axios";

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    getAxiosByPdf: async ({commit}, payload) => {
        return axios.create({
            baseURL: process.env.VUE_APP_NEW_API_URL,
            headers: {
                Accept: 'application/pdf',
                'X-Lang-Header': payload.lang,
                Authorization: payload.auth,
            },
        });
    },
    downloadCertificateAsync: async ({dispatch,commit}, {payload,headers}) => {
        const myAxios = await dispatch('getAxiosByPdf', headers);
        return await myAxios.get('/api/services/download-certificates/'+payload.id, {
            responseType: 'blob',
        })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'certificate.pdf';
                link.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(e => {
                console.log('e', e);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
