import { mapMutations, mapGetters } from 'vuex'
import {
    SHOW_TEXT_HELP_CALCULATE,
} from "@/store/mutation-types";
export default{
    name: 'help-text',
    computed: {
        ...mapGetters({
            config:'main/config',
            key: 'statistic/showInfoCalcKey'
        }),
        getContent(){
            for( let i in this.config ){
                if(this.config[i].key.includes(this.key)){
                    return this.config[i].value;
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            closeTextHelp: `helpCalculate/${SHOW_TEXT_HELP_CALCULATE}`
        }),

    }
}
