import { mapGetters, mapActions, mapMutations } from "vuex";
import {
    SET_SHOW_DETAIL
} from '@/store/mutation-types';
import {
    GET_STAR_PARTNERS
} from '@/store/action-types';
import detail from './detail/index.vue'
export default {
    name: 'star-partners',
    data(){
        return {

        }
    },
    components: {
        detail
    },
    created(){
        if(this.list.length <= 0){
            this.fetchList();
        }
    },
    mounted() {
        window.addEventListener('scroll', this.lazyLoad);
    },
    computed:{
        ...mapGetters({
            list: 'starPartners/list',
            paramsDetail: 'starPartners/paramsDetail',
            loading: 'starPartners/loading',
            lengthList: 'starPartners/lengthList',
            skip: 'starPartners/skip',
        }),
        watchLength(){
            if(this.lengthList > this.skip){
                return true
            }else{
                return false
            }
        }
    },
    methods:{
        ...mapActions({
            fetchList: `starPartners/${GET_STAR_PARTNERS}`
        }),
        ...mapMutations({
            setShowDetail: `starPartners/${SET_SHOW_DETAIL}`
        }),
        detailPreview(id){
            this.setShowDetail({ show: true, id: id });
        },
        lazyLoad() {
            this.bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 200;
            if (this.bottomOfWindow && this.watchLength && !this.loading) {
                this.fetchList();
            }
        },
    },
    destroyed(){
        window.removeEventListener('scroll', this.lazyLoad);
    }
}
