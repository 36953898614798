const state = {
    uuid: null,
};

const getters = {
    uuid: (state) => {
        return state.uuid;
    },
};

const mutations = {
    setUuid: (state, payload) => {
        state.uuid = payload;
    },
};

const actions = {
    setUuidAsync: async ({commit}, payload) => {
        commit('setUuid', payload);
    },
    isSafari: ()=> {
        let userAgent = navigator.userAgent;
        let isAppleDevice = /Mac|iPad|iPhone|iPod/.test(userAgent);
        let isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
        return !!(isAppleDevice && isSafari)
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
