import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_POPUP_DOCUMENTS,
    GENERATED_CERTIFICATE,
    GENERATED_CERTIFICATES,
    RESET_DOCUMENT_POPUP,
    SING_DOCUMENT,
    PERSONAL_TYPE_SEARCH,
    TREES_SEARCH,
    PURCHASES_CURRENT,
    PURCHASES_INFO
} from "@/store/mutation-types";
export default{
    name: 'popup-documents',
    data(){
      return {
          certificate: false,
          certificates: false,
          document: false,
          payload: {
              order_id:'',
          }
      }
    },
    computed: {
        ...mapGetters({
            certificateCountType: `trees/certificateCountType`,
            loading: `trees/loading`,
            termOfUse: `trees/termOfUse`,
            termOfUseId: `trees/termOfUseId`,
            location: `trees/location`,
            purchase: `trees/purchasePeriod`,
            planting: `trees/plantingPeriod`,
            status: `trees/status`,
            skip: `trees/skip`,
            limit: `trees/limit`,
            sortTypeCategory: `trees/sortTypeCategory`,
        }),
        watchDocument(){
            if(!this.certificate && !this.certificates){
                return false
            }else{
                return true
            }
        }
    },
    created(){
    },
    methods: {
        ...mapActions({
            generatedCertificate: `trees/${GENERATED_CERTIFICATE}`,
            generatedCertificates: `trees/${GENERATED_CERTIFICATES}`,
            singDocument: `trees/${SING_DOCUMENT}`,
            searchTrees: `trees/${TREES_SEARCH}`,
            fetchList: `purchases/${PURCHASES_CURRENT}`,
            fetchInfo: `purchases/${PURCHASES_INFO}`,
        }),
        ...mapMutations({
            showPopup: `trees/${SHOW_POPUP_DOCUMENTS}`,
            reset: `trees/${RESET_DOCUMENT_POPUP}`,
            typeSearchStatus: `trees/${PERSONAL_TYPE_SEARCH}`,
        }),
        submit() {
            if(this.certificate){
                this.generatedCertificate();
            }
            if(this.certificates){
                this.generatedCertificates();
            }
        },
        search(status){
            let obj ={
                location: this.location,
                purchase_period: this.purchase,
                planting_period: this.planting,
                status: this.status,
                skip: this.skip,
                limit: this.limit,
                group_by: this.sortTypeCategory
            };
            this.searchTrees(obj)
                .then(() => {
                    this.typeSearchStatus(status);
                    this.showPopup(false);
                });
        },
        signDock(){
            let obj = {
                    packet_type:  this.termOfUseId.packet_type,
                    packet_id:  this.termOfUseId.packet_id,
                    confirmed: 1
                };
            this.singDocument(obj)
                .then(() =>{
                    if(this.$route.fullPath.includes('purchases')){
                        this.fetchList({id: this.termOfUseId.packet_id});
                        this.fetchInfo({id: this.termOfUseId.packet_id});
                    }
                    this.search();
                })
        }
    },
    destroyed(){
        this.reset();
    }
}
