//auth
export const SING_IN_WITH_GOGGLE = 'SING_IN_WITH_GOGGLE';
export const SING_IN_WITH_FACEBOOK = 'SING_IN_WITH_FACEBOOK';
//
export const GET_WITHDRAWAL = 'GET_WITHDRAWAL';
//star partners
export const GET_STAR_PARTNERS = 'GET_STAR_PARTNERS';
export const GET_STAR_INFO = 'GET_STAR_INFO';
//promotions
export const FETCH_PROMOTIONS = 'FETCH_PROMOTIONS';
export const FETCH_PROMOTION = 'FETCH_PROMOTION';
