import _ from 'lodash';
import { $http } from '@/https'
import { globalLoader, fixBody } from '@/helpers/variables/index'
import {
    GIVE_LOAD_CATEGORY,
    GIVE_SELECTED_TREES,
    GIVE_SHOW_TREES,
    GIVE_SHOW_CATEGORIES,
    GIVE_CURRENT_CATEGORY,
    GIVE_REMOVE_TREES,
    GIVE_LOAD_TREES,
    GIVE_RESET_TREES,
    GIVE_TREES,
    CHARITY_TREES,
    GIVE_LIST_LENGTH,
    GIVE_CHANGE_OFFSET,
    GIVE_RESET_CATEGORY,
    GIVE_CHANGE_CHECK,
    GIVE_CHANGE_ALL,
    GIVE_RESET_AFTER_RESPONSE,
    GIVE_RESET,
    GIVE_LOADING_POPUP_TREE,
    GIVE_LOAD_RULES_POPUP,
    FIND_USER_IN_EMAIL_LOADING,
    TEXT_POPUP_CONTENT,
    GIVE_CHANGE_PHONE,
    GIVE_CHANGE_MAIL,
    GIVE_CHANGE_NOTIFICATION_TIME,
    GIVE_CHANGE_BAN,
    GIVE_CHANGE_FROST,
    GIVE_CHANGE_TYPE_SELECT,
    GIVE_DECREMENT_BAN,
    GIVE_INCREMENT_BAN,
    GIVE_DECREMENT_FROST,
    GIVE_INCREMENT_FROST,
    GIVE_RESET_PROMO,
    LOAD_USER_DATA,
    GIVE_FETCH_PROMO_CODE,
    FIND_USER_IN_EMAIL,

    GIVE_SETTING
} from '../../mutation-types';

const state = {
    list: [],
    userList: [],
    userListLoading: false,
    trees: null,
    infoRules: null,
    category: null,
    removeTrees: [],
    showCategory: false,
    loadingPopupTrees: false,
    showTrees: false,
    lengthListTree: 0,
    currentCategory: null,
    offset: 0,
    selectTrees: [],
    limit: 20,
    setting: {},
    params: {
        phone: '',
        email: '',
        notificationTime: '',
        ban: 3,
        frost: 0,
        giveTypeCheck: '',
    },
};

const getters = {
    phone: state => state.params.phone,
    notificationTime: state => state.params.notificationTime,
    ban: state => state.params.ban,
    frost: state => state.params.frost,
    giveTypeCheck: state => state.params.giveTypeCheck,
    email: state => state.params.email,
    userList: state => state.userList,
    selectTrees: state => state.selectTrees,
    infoRules: state => state.infoRules,
    loadingPopupTrees: state => state.loadingPopupTrees,
    lengthListTree: state => state.lengthListTree,
    currentCategory: state => state.currentCategory,
    list: state => state.list,
    trees: state => state.trees,
    category: state => state.category,
    removeTrees: state => state.removeTrees,
    showCategory: state => state.showCategory,
    showTrees: state => state.showTrees,
    userListLoading: state => state.userListLoading,
};

const actions = {
    [GIVE_TREES]: async ({ dispatch, commit }, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post('v1/shop/gift_trees', payload);
            commit(GIVE_RESET);
            dispatch(`profile/${LOAD_USER_DATA}`, response.data.data,
                {
                    root: true
                });
            return response;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [CHARITY_TREES]: async ({ dispatch, commit }, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post('v1/shop/charity_trees', payload);
            commit(GIVE_RESET);
            dispatch(`profile/${LOAD_USER_DATA}`, response.data.data, {root: true});
            return response;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [GIVE_FETCH_PROMO_CODE]: async ({ dispatch, commit }, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post('v1/shop/generate_promo_code_for_trees', payload);
            commit(GIVE_RESET);
            dispatch(`profile/${LOAD_USER_DATA}`, response.data.data, {root: true});
            return response;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [GIVE_LOAD_CATEGORY]: async ({ commit }) => {
        try {
            const response = await $http.get(`v1/trees?group_by=season&django=${1}`);
            commit(GIVE_RESET_CATEGORY);
            commit(GIVE_LOAD_CATEGORY, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    [FIND_USER_IN_EMAIL]: async ({ commit }, payload) => {
        try {
            commit(FIND_USER_IN_EMAIL_LOADING, true);
            const response = await $http.get(`v1/users/search?search_text=${payload}`);
            commit(FIND_USER_IN_EMAIL, response.data.data);
        }catch (e) {
            throw e;
        }finally {
            commit(FIND_USER_IN_EMAIL_LOADING, false);
        }
    },
    [GIVE_LOAD_TREES]: async ({ commit }, payload) => {
        try {
            commit(GIVE_LOADING_POPUP_TREE, true);

            const response = await $http.get(`v1/trees?seasons=${payload.categories}&django=${1}&limit=${payload.limit}&skip=${state.offset}`);
            commit(GIVE_RESET_TREES);
            commit(GIVE_LIST_LENGTH, response.data.meta.count);
            commit(GIVE_LOAD_TREES, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(GIVE_LOADING_POPUP_TREE, false);
        }
    },
    [GIVE_LOAD_RULES_POPUP]: async ({ commit }, payload) => {
        try {
            const response = await $http.get(`v1/pages/terms-of-use`);
            commit(`popups/${TEXT_POPUP_CONTENT}`, response.data.data, {root: true});
            return response.data.data;
        }catch (e) {
           throw e;
        }
    },
};

const mutations = {
    [GIVE_CHANGE_PHONE](state, phone) {
        state.params.phone = phone;
    },
    [GIVE_SETTING](state, obj) {
        state.setting = obj;
        state.params.ban = obj.giveDefaultBan;
        state.params.frost = obj.giveDefaultFrost;
    },
    [GIVE_CHANGE_MAIL](state, email) {
        state.params.email = email;
    },
    [GIVE_CHANGE_NOTIFICATION_TIME](state, notificationTime) {
        state.params.notificationTime = notificationTime;
    },
    [GIVE_CHANGE_BAN](state, ban) {
        state.params.ban = ban;
    },
    [GIVE_DECREMENT_BAN](state) {
        if (state.params.ban <= state.setting.giveDefaultBan) {
            state.params.ban = state.setting.giveDefaultBan
        } else {
            Math.abs(state.params.ban--);
        }
    },
    [GIVE_INCREMENT_BAN](state) {
        state.params.ban++;
    },
    [GIVE_CHANGE_FROST](state, frost) {
        state.params.frost = frost;
    },
    [GIVE_DECREMENT_FROST](state) {
        if (state.params.frost <= state.setting.giveDefaultFrost) {
            state.params.frost = state.setting.giveDefaultFrost
        } else {
            Math.abs(state.params.frost--);
        }
    },
    [GIVE_INCREMENT_FROST](state) {
        state.params.frost++;
    },
    [GIVE_CHANGE_TYPE_SELECT](state, giveTypeCheck) {
        state.params.giveTypeCheck = giveTypeCheck;
    },
    [GIVE_LOAD_CATEGORY](state, category) {
        state.category = category;
    },
    [FIND_USER_IN_EMAIL](state, list) {
        state.userList = list;
    },
    [FIND_USER_IN_EMAIL_LOADING](state, status) {
        state.userListLoading = status;
    },
    [GIVE_LOAD_RULES_POPUP](state, content) {
        state.infoRules = content;
    },
    [GIVE_RESET_AFTER_RESPONSE](state) {
        state.list = [];
        state.selectTrees = [];
    },
    [GIVE_RESET_CATEGORY](state, category) {
        state.category = null;
    },
    [GIVE_REMOVE_TREES](state, listDeleteTree) {
        if (state.list.length !== 0) {
            for(let i = state.list.length; i >= 0;i--){
                for(let j = 0; j < listDeleteTree.length; j++){
                    if(state.list[i] === listDeleteTree[j]){
                        state.list.splice(i, 1);
                        state.selectTrees.splice(i, 1);
                    }
                }
            }
        }
    },
    [GIVE_LOAD_TREES](state, list) {
        let filterArr = list.filter(elem => {
            if(elem.sale_frozen_to === null
                && elem.signed_documents === true
                && elem.dividend_frozen_to === null
                && elem.gift_status.data.id !== 2){
                return elem;
            }
        });
        state.trees = filterArr;
        state.trees.map(elem => {
            let param = state.selectTrees.find(t => {
                if(elem.id === t.id){
                    return true
                }else{
                    return false
                }
            });
            elem.check = param === undefined ? false : true;
        });
    },
    [GIVE_RESET_TREES](state) {
        state.trees = null;
        state.offset = 0;
    },
    [GIVE_CHANGE_CHECK](state, obj) {
        obj.check = !obj.check;
        if(obj.check){
            state.selectTrees.push(obj);
        }else{
            let index = _.findIndex(state.selectTrees, e => e.id === obj.id );
            state.selectTrees.splice(index, 1);
        }
    },
    [GIVE_CHANGE_ALL](state, status) {
        if(status){
            let notCheckedTrees =_.filter(state.trees, e =>  !e.check);
            let checkedTreesAll = state.trees.map(e => {
                e.check = true;
                return e;
            });
            state.trees = checkedTreesAll;
            state.selectTrees.push(...notCheckedTrees);
        }else{
            let resetTreesAll = state.trees.map(e => {
                e.check = false;
                return e;
            });
            state.trees = resetTreesAll;
            state.trees.forEach((t) => {
                _.remove(state.selectTrees, elem => {
                    return elem.id === t.id
                });
            });
        }
    },
    [GIVE_SELECTED_TREES](state, trees) {
        state.list = trees.map(elem => elem);
        state.list = _.uniq(state.list);
    },
    [GIVE_SHOW_TREES](state, status) {
        fixBody(status);
        state.showTrees = status;
    },
    [GIVE_SHOW_CATEGORIES](state, status) {
        fixBody(status);
        state.showCategory = status;
    },
    [GIVE_LIST_LENGTH](state, count) {
        state.lengthListTree = count;
    },
    [GIVE_RESET_PROMO](state, count) {
        state.params.email = '';
        state.params.phone = '';
    },
    [GIVE_CHANGE_OFFSET](state, val) {
        state.offset = val;
    },
    [GIVE_LOADING_POPUP_TREE](state, status) {
        state.loadingPopupTrees = status;
    },
    [GIVE_RESET](state) {
        state.list = [];
        state.trees = null;
        state.category = null;
        state.removeTrees = [];
        state.showCategory = false;
        state.showTrees = false;
        state.lengthListTree = 0;
        state.currentCategory = null;
        state.offset = 0;
        state.selectTrees = [];
        state.params.giveTypeCheck = '';
        state.params.frost = state.setting.giveDefaultFrost;
        state.params.ban = state.setting.giveDefaultBan;
        state.params.email = '';
        state.params.phone = '';
        state.params.notificationTime = '';
        state.list = [];
    },
    [GIVE_CURRENT_CATEGORY](state, category) {
        state.currentCategory = null;
        let payload = {
            categories: null,
            limit: state.limit,
            offset: state.offset,
        };
        let newArr =[];
        for(let i = 0; i < category.length; i++){
            let newVal = '';
            let year = category[i].planting_year;
            let season = category[i].season;
            newVal = year + '-' + season;
            newArr.push(newVal.toString())
        }
        payload.categories = newArr.join();
        payload.limit = state.limit;
        payload.offset = state.offset;
        state.currentCategory = payload;
    }

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
