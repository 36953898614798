import packageOfferSlider from './modules/package-offer-slider';
import expressActions from './modules/express-actions';
import labelDocuments from './modules/label-documents';
import helpCalculate from './modules/help-calculate';
import privacyPolicy from './modules/privacy-policy';
import notifications from './modules/notifications';
import starPartners from './modules/star-partners';
import termsOfUse from './modules/terms-of-use';
import howItWorks from './modules/howItWorks';
import guarantees from './modules/guarantees';
import promotion from './modules/promotions';
import getQrCode from './modules/get-qr-code';
import statistic from './modules/statistic';
import calculate from './modules/calculate';
import giftTree from './modules/gift-tree';
import feedback from './modules/feedback';
import investor from './modules/investor';
import contacts from './modules/contacts';
import aboutUs from './modules/about-us';
import gallery from './modules/gallery';
import system from './modules/system';
import popups from './modules/popups';
import search from './modules/search';
import promo from  './modules/promo';
import auth from './modules/auth';
import news from './modules/news';
import shop from './modules/shop';
import main from './modules/main';
import faq from './modules/faq';
import new_user from './for_new_api/user';
import new_acquiring from './for_new_api/acquiring';
import new_certificate from './for_new_api/certificate';
import new_common from './for_new_api/common';

//profile
import withdrawalHistory from './modules/personal/withdrawal-history';
import cancelPutUpGift from './modules/personal/cancel-put-up-gift';
import exchangeToken from './modules/personal/exchange-token';
import exchangeTree from './modules/personal/exchange-tree';
import withdrawal from './modules/personal/withdrawal';
import buyForGift from './modules/personal/buy-for-gift';
import purchases from './modules/personal/purchases';
import putUpGift from './modules/personal/put-up-gift';
import profileBuyTree from './modules/personal/buy';
import takeOff from './modules/personal/take-off';
import history from './modules/personal/history';
import profile from './modules/personal/profile';
import field from './modules/personal/field';
import trees from './modules/personal/trees';
import gift from './modules/personal/gift';
import give from './modules/personal/give';
import sell from './modules/personal/sell';
import map from './modules/personal/map';

export default {
    packageOfferSlider,
    withdrawalHistory,
    cancelPutUpGift,
    expressActions,
    profileBuyTree,
    labelDocuments,
    privacyPolicy,
    notifications,
    helpCalculate,
    exchangeToken,
    starPartners,
    exchangeTree,
    guarantees,
    buyForGift,
    howItWorks,
    withdrawal,
    termsOfUse,
    statistic,
    calculate,
    promotion,
    purchases,
    getQrCode,
    putUpGift,
    feedback,
    contacts,
    giftTree,
    investor,
    gallery,
    takeOff,
    profile,
    history,
    aboutUs,
    search,
    system,
    popups,
    field,
    trees,
    promo,
    main,
    news,
    auth,
    sell,
    give,
    gift,
    shop,
    map,
    faq,
    new_user,
    new_acquiring,
    new_certificate,
    new_common,
};
