import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    RESTORE_PASSWORD_EMAIL,
    SHOW_RESTORE_PASSWORD_EMAIL,
} from "@/store/mutation-types";
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
export default{
    name: 'restore-password-email',
    mixins: [validationMixin],
    data(){
      return {
          payload: {
              email: ''
          },
          validationErrors: {},
          showSuccess: false
      }
    },
    validations: {
        payload: {
            email: {
                required,
                email
            },
        }
    },
    computed: {
        emailErrors() {
            let error = [];
            if (!this.$v.payload.email.$dirty) {
                return error;
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('validationEmail'));
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
    },
    methods: {
        ...mapActions({
            restore: `auth/${RESTORE_PASSWORD_EMAIL}`
        }),
        ...mapMutations({
            showPopup: `auth/${SHOW_RESTORE_PASSWORD_EMAIL}`,
        }),
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.restore(this.payload)
                    .then(response => {
                        if(response.status === 200){
                            this.showSuccess = true
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors;
                        }
                    });
            }
        },

    }
}