import {$http} from '@/https'
import {fixBody, globalLoader} from '@/helpers/variables/index'
import {
    SHOW_POPUP_CANCEL_PUT_UP_GIFT,
    CANCEL_PUT_UP_GIFT_LIST,
    CANCEL_PUT_UP_GIFT_CHANGE_LOADING,
    CANCEL_PUT_UP_GIFT_RESET_LIST,
    CANCEL_PUT_UP_GIFT_PIECE,
    CANCEL_PUT_UP_GIFT_LENGTH_LIST,
    CANCEL_PUT_UP_GIFT_PACK,
    CANCEL_PUT_UP_GIFT_CHANGE_SKIP,
    CANCEL_PUT_UP_GIFT_CHANGE_PAGE,
    CANCEL_PUT_UP_GIFT_RESET_PAGINATION
} from '@/store/mutation-types';


const state = {
    showTakeOff: false,
    list: null,
    loading: false,
    length: 0,
    limit: 11,
    skip: 0,
    page: 0,
};

const getters = {
    list: state => state.list,
    page: state => state.page,
    showTakeOff: state => state.showTakeOff,
    skip: state => state.skip,
    limit: state => state.limit,
    loading: state => state.loading,
    length: state => state.length,
};
const actions = {
    [CANCEL_PUT_UP_GIFT_LIST]: async ({commit}) => {
        try {
            commit(CANCEL_PUT_UP_GIFT_CHANGE_LOADING, true);

            const response = await $http.get(`v1/withdraw_list?type=gift&limit=${state.limit}&skip=${state.skip}`);
            commit(CANCEL_PUT_UP_GIFT_LIST, response.data.data);
            commit(CANCEL_PUT_UP_GIFT_LENGTH_LIST, response.data.meta.count);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            commit(CANCEL_PUT_UP_GIFT_CHANGE_LOADING, false);
        }
    },
    [CANCEL_PUT_UP_GIFT_PACK]: async ({dispatch, commit}, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/withdraw_pack`, payload);
            commit(CANCEL_PUT_UP_GIFT_RESET_PAGINATION);
            dispatch(CANCEL_PUT_UP_GIFT_LIST);
            return response.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [CANCEL_PUT_UP_GIFT_PIECE]: async ({dispatch, commit}, payload) => {
        try {
            globalLoader(true);

            const response = await $http.post(`v1/withdraw`, payload);
            commit(CANCEL_PUT_UP_GIFT_RESET_PAGINATION);
            dispatch(CANCEL_PUT_UP_GIFT_LIST);
            return response.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
};



const mutations = {
    [SHOW_POPUP_CANCEL_PUT_UP_GIFT](state, status) {
        fixBody(status);
        state.showTakeOff = status
    },
    [CANCEL_PUT_UP_GIFT_LIST](state, list) {
        state.list = list;
    },
    [CANCEL_PUT_UP_GIFT_CHANGE_LOADING](state, status) {
        state.loading = status
    },
    [CANCEL_PUT_UP_GIFT_RESET_LIST](state) {
        state.list = null
    },
    [CANCEL_PUT_UP_GIFT_LENGTH_LIST](state, length) {
        state.length = length
    },
    [CANCEL_PUT_UP_GIFT_CHANGE_SKIP](state, skip) {
        state.skip = skip
    },
    [CANCEL_PUT_UP_GIFT_CHANGE_PAGE](state, page) {
        state.page = page
    },
    [CANCEL_PUT_UP_GIFT_RESET_PAGINATION](state) {
        state.page = 0;
        state.skip = 0;
    },
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
