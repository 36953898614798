import { mapMutations, mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import {
    RESTORE_PASSWORD,
    SHOW_SING_IN
} from "@/store/mutation-types";
export default{
    name: 'restore-password',
    mixins: [validationMixin],
    data(){
      return {
          password: '',
          password_confirmation: '',
          showSuccess: false,
          validationErrors: {}
      }
    },
    validations: {
        password: {
            required,
            minLength: minLength(8)
        },
        password_confirmation: {
            required,
            sameAsPassword: sameAs('password')
        },
    },
    computed: {
        passwordErrors() {
            let error = [];
            if (!this.$v.password.$dirty) {
                return error;
            }
            if (!this.$v.password.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.password.minLength) {
                error.push(this.$t('validationMin8'));
            }
            if (this.validationErrors.password) {
                this.validationErrors.password.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
        passwordRepeatErrors() {
            let error = [];
            if (!this.$v.password_confirmation.$dirty) {
                return error;
            }
            if (!this.$v.password_confirmation.sameAsPassword) {
                error.push(this.$t('validationSameAs'));
            }
            if (this.validationErrors.password_confirmation) {
                this.validationErrors.password_confirmation.forEach((row) => {
                    error.push(row);
                })
            }

            return error;
        },
    },
    methods: {
        ...mapActions({
            restore: `auth/${RESTORE_PASSWORD}`
        }),
        ...mapMutations({
           goSingIn: `auth/${SHOW_SING_IN}`
        }),
        singIn(){
            this.$router.push({name: 'home'});
            this.goSingIn(true);
        },
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let obj = {
                    email: this.$route.query.email,
                    code: this.$route.query.code,
                    password: this.password,
                    password_confirmation: this.password_confirmation
                };
                this.restore(obj)
                    .then(response => {
                        if(response.status === 200){
                            this.showSuccess = true;
                        }
                    }).catch(error => {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors.password;
                        }
                    });
            }
        },
    }
}