import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    GIVE_SHOW_TREES,
    GIVE_LOAD_TREES,
    GIVE_CHANGE_CHECK,
    GIVE_CHANGE_ALL,
    GIVE_CHANGE_OFFSET,
    GIVE_SELECTED_TREES,
    GIVE_RESET_TREES
} from "@/store/mutation-types";
import VueAdsPagination, {VueAdsPageButton} from "vue-ads-pagination";
export default {
    name: 'give-trees-popup',
    data() {
        return {
            page: 0,
            limit: 20,
        }
    },
    computed: {
        ...mapGetters({
            list: `give/list`,
            loading: `give/loadingPopupTrees`,
            trees: `give/trees`,
            lengthListTree: `give/lengthListTree`,
            currentCategory: `give/currentCategory`,
            selectTrees: `give/selectTrees`,
        })
    },
    watch: {
        page(){
            this.currentTrees(this.selectTrees);
            this.changeOffset(this.page * this.limit);
            this.fetchTrees(this.currentCategory)
        }
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    created(){
        this.fetchTrees(this.currentCategory);
    },
    methods: {
        ...mapActions({
            fetchTrees: `give/${GIVE_LOAD_TREES}`
        }),
        ...mapMutations({
            showPopup: `give/${GIVE_SHOW_TREES}`,
            changeOffset: `give/${GIVE_CHANGE_OFFSET}`,
            currentTrees: `give/${GIVE_SELECTED_TREES}`,
            changeCheckbox: `give/${GIVE_CHANGE_CHECK}`,
            changeAllTrees: `give/${GIVE_CHANGE_ALL}`,
            resetTreesList: `give/${GIVE_RESET_TREES}`,
        }),
        addTrees(){
            this.currentTrees(this.selectTrees);
            this.showPopup(false)
        },
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
    },
    destroyed(){
        this.resetTreesList();
    }
}
