import VueAdsPagination, {VueAdsPageButton} from "vue-ads-pagination";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {
    NOT_AVAIBLE,
    TERM_OF_USE,
    SHOW_TREES_MAP_POPUP_AREA,
    PURCHASES_CURRENT,
    SING_DOCUMENT,
    GET_ORDER,
    PURCHASES_INFO,
    DOCUMENT_TREE_ID,
    SHOW_POPUP_DOCUMENTS,
    CERTIFICATE_TYPE,
    SHOW_GET_GIFT_POPUP,
    DOCUMENT_TREE_ORDER_ID,
    CURRENT_GIFT_OBJ,
    SHOW_POPUP_GIFT_LIMIT,
    DOWNLOAD_DOCUMENTS,
    GET_CERTIFICATE
} from "@/store/mutation-types";
import store from "@/store";
export default {
    name: 'purchases-details',
    data() {
        return {
            idOrder: null,
            page: 0,
            loadingInfo: true,
            payload: {
                skip: 0,
                id: this.$route.params.id
            },
        }
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    computed: {
        ...mapGetters({
            currentItem: `purchases/currentItem`,
            selectItem: `purchases/selectItem`,
            loading: `purchases/loading`,
            info: `purchases/info`,
            certificateLoading: `trees/loading`,
            lengthList: `purchases/lengthList`,
            certificateCountType: `trees/certificateCountType`,
            limit: 'trees/limit',
            fetchUserData: `profile/user`,
        }),
        ...mapGetters('new_user',{
            uuid: "uuid",
        }),
        showPagination(){
            if(10 > this.lengthList){
                return false
            }else{
                return true
            }
        }
    },
    watch: {
        page(){
            this.payload.skip = this.page * this.limit;
            this.fetchList(this.payload)
        }
    },
    created(){
        this.loadingInfo = true;
        this.fetchList(this.payload);
        this.fetchInfo(this.payload)
            .finally(() => {
                this.loadingInfo = false;
            })
    },
    mounted() {
        this.fetchUser().then(() => {
            this.setUuidAsync(this.fetchUserData.uuid)
        })
    },
    methods: {
         ...mapActions('new_certificate',{
            downloadCertificateAsync: 'downloadCertificateAsync',
        }),
        ...mapActions('new_user',{
            setUuidAsync: 'setUuidAsync',
        }),
        ...mapActions({
            fetchList: `purchases/${PURCHASES_CURRENT}`,
            fetchInfo: `purchases/${PURCHASES_INFO}`,
            singDocument: `trees/${SING_DOCUMENT}`,
            loadOrderId: `trees/${GET_ORDER}`,
            getTermOfUse: `trees/${TERM_OF_USE}`,
            getCertificate: `trees/${GET_CERTIFICATE}`,
            download: `trees/${DOWNLOAD_DOCUMENTS}`,
        }),
        ...mapMutations({
            changePopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
            notAvaible: `system/${NOT_AVAIBLE}`,
            changeTreeId: `trees/${DOCUMENT_TREE_ID}`,
            changeTreeOrderId: `trees/${DOCUMENT_TREE_ORDER_ID}`,
            showPopupDocuments: `trees/${SHOW_POPUP_DOCUMENTS}`,
            certificateType: `trees/${CERTIFICATE_TYPE}`,
            changeGetGiftPopup: `gift/${SHOW_GET_GIFT_POPUP}`,
            currentObjGift: `gift/${CURRENT_GIFT_OBJ}`,
            showPopupGiftLimit: `gift/${SHOW_POPUP_GIFT_LIMIT}`,
        }),
        changeDocumentPopup(id){
            this.loadOrder(id)
        },
        changeDocumentGroupPopup(id){
            let obj = {
                packet_type: 'order',
                packet_id: id
            };
            if(this.info.signed_documents){
                this.download(obj);
            }else{
                this.changeTreeOrderId(obj);
                this.showPopupDocuments(true);
                this.getTermOfUse(obj);
                this.certificateType(true);
            }


        },
        showGiftLimitPopup(item){
            this.currentObjGift(item);
            this.showPopupGiftLimit(true);
        },
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        loadOrder(id){
            this.loadOrderId(id)
                .then(response => {
                    this.getCertificate(id);
                })
        },
        goBack(){
          this.$router.go(-1)
        },
        downloadCertificate(id){
            let lang = store.state['vue-i18n-manager'].currentLanguage.translationKey;
            lang = lang === 'ru' ? 'rus' : 'eng'

            let payload = {
                id: id
            }
            let headers = {
                auth: this.uuid,
                lang: lang,
            }
            this.downloadCertificateAsync({
                payload:payload,
                headers:headers,
            });
        },
    },

}
