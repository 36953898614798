import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    CONTACTS_LOAD,
} from "@/store/mutation-types";


export default {
    name: 'how-it-works',
    data(){
      return{
          telegram: require('../../assets/images/svg/contacts_social_telegram.svg'),
          facebook: require('../../assets/images/svg/contacts_social_facebook.svg'),
          instagram: require('../../assets/images/svg/contacts_social_instagram.svg'),
      }
    },
    computed: {
        ...mapGetters({
            content: `contacts/content`,
        })
    },
    created(){
        this.fetchContent();
    },
    beforeRouteLeave (to, from, next) {
        next()
    },
    metaInfo: {
        title: 'Способы связи с нами | BioDeposit',
        meta: [
            { name: 'description', content: '【Контакты - BioDeposit】Инвестиции в оливковые плантации на территории Грузии ✔ Оливковый бизнес ✔ Выращивание оливок в Грузии ✔ Экологический вид инвестиций ✔ БиоДепозит — ликвидность вне времени!' },
        ]
    },
    methods: {
        ...mapActions({
            fetchContent: `contacts/${CONTACTS_LOAD}`
        }),
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        }
    }

}
