import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    POPUP_ENABLE_TWO_FACTOR
} from "@/store/mutation-types";
export default{
    name: 'popup-enable-two-factor',
    methods: {
        ...mapActions({

        }),
        ...mapMutations({
            showPopup: `profile/${POPUP_ENABLE_TWO_FACTOR}`
        }),
        goProfile(){
            this.$router.push({ name: 'personal.profile' });
            this.showPopup(false);
        }
    }
}
