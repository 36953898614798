import Vue from 'vue';
import _ from 'lodash';
import { fixBody, globalLoader } from '@/helpers/variables/index'
import { $http } from '@/https'
import {
    PUT_UP_SHOW_CATEGORY,
    PUT_UP_TYPES_RESET,
    PUT_UP_TYPES,
    PUT_UP_LIST,
    PUT_UP_RESET_LIST,
    PUT_UP_RESET_CURRENT,
    PUT_UP_INCREMENT_COUNT,
    PUT_UP_DECREMENT_COUNT,
    PUT_UP_CHANGE_COUNT,
    PUT_UP_REMOVE_TREES,
    PUT_UP_DETAIL,
    PUT_UP_SELL_YOUNG_TREE,
    PUT_UP_TYPES_SALE,
    PUT_UP_TYPES_SALE_RESET,
    PUT_UP_FIELD_RESET,

    PUT_UP_SETTING
} from '../../mutation-types';

const helper = (obj) => {
    obj.price_each_trees = (obj.price * obj.selectCount) - ((obj.price * obj.selectCount) * (obj.commission / 100));
    let index = _.findIndex(state.list, obj);
    Vue.set(state.list, index, obj);
};

const state = {
    list: [],
    selectedField: [],
    removeField: [],
    areas: null,
    areasSale: null,
    showCategory: false,
    detailTrees: null,

    setting: null
};

const getters = {
    areasSale: state => state.areasSale,
    detailTrees: state => state.detailTrees,
    list: state => state.list,
    removeField: state => state.removeField,
    selectedField: state => state.selectedField,
    showCategory: state => state.showCategory,
    areas: state => state.areas,
};

const actions = {
    [PUT_UP_TYPES]: async ({ commit }) => {
        try {
            commit(PUT_UP_TYPES_RESET);
            const response = await $http.get(`v1/trees?group_by=location&is_young=${1}`);
            commit(PUT_UP_TYPES, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [PUT_UP_TYPES_SALE]: async ({ commit }) => {
        try {
            commit(PUT_UP_TYPES_SALE_RESET);
            const response = await $http.get(`v1/trees?group_by=location&is_young=${1}&django=${1}`);
            commit(PUT_UP_TYPES_SALE_RESET);
            commit(PUT_UP_TYPES_SALE, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }
    },
    [PUT_UP_SELL_YOUNG_TREE]: async ({ commit }, payload) => {
        try {
            globalLoader(true);
            const result = await $http.post(`v1/put_up_gift`, payload);
            commit(PUT_UP_FIELD_RESET);
            return result;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
};

const mutations = {
    [PUT_UP_TYPES](state, areas) {
        state.areas = areas;
    },
    [PUT_UP_SETTING](state, obj) {
        state.setting = obj;
    },
    [PUT_UP_LIST](state, area) {
        if (state.list.length <= 0) {
            state.list = area;
        } else {
            state.list.forEach((elem, index) => {
                area.forEach(t => {
                    if (elem.field_id !== t.field_id) {
                        state.list.push(t)
                    } else {
                        state.list.splice(index, 1, t);
                    }
                })
            });
            let newArr = _.uniq(state.list);
            state.list = newArr;
        }
    },
    [PUT_UP_TYPES_RESET](state) {
        state.areas = null;
    },
    [PUT_UP_RESET_CURRENT](state, trees) {
        state.currentField = null;
    },
    [PUT_UP_SHOW_CATEGORY](state, param) {
        fixBody(param);
        state.showCategory = param;
    },
    [PUT_UP_REMOVE_TREES](state, listDeleteTree) {
        if (state.list.length !== 0) {
            for (let i = state.list.length; i >= 0; i--) {
                for (let j = 0; j < listDeleteTree.length; j++) {
                    if (state.list[i] === listDeleteTree[j]) {
                        state.list.splice(i, 1);
                    }
                }
            }
        }
    },
    [PUT_UP_INCREMENT_COUNT](state, id) {
        let obj = state.list.find(elem => {
            return elem.field_id === id
        });
        obj.selectCount++;
        helper(obj);
    },
    [PUT_UP_DECREMENT_COUNT](state, id) {
        let obj = state.list.find(elem => {
            return elem.field_id === id
        });
        obj.selectCount--;
        helper(obj);
    },
    [PUT_UP_CHANGE_COUNT](state, payload) {
        let obj = state.list.find(elem => {
            return elem.field_id === payload.field_id
        });
        obj.selectCount = payload.selectCount;
        helper(obj);
    },
    [PUT_UP_DETAIL](state, trees) {
        state.detailTrees = trees;
    },
    [PUT_UP_TYPES_SALE](state, list) {
        state.areasSale = list;
        state.areasSale.map(elem => {
            return list.map(t => {
                if (elem.field_id === t.field_id) {
                    elem.price = state.setting.putUpGiftPrice;
                    elem.commission = state.setting.putUpGiftCommission;
                    elem.selectCount = 1;
                    elem.price_each_trees = (elem.price * elem.selectCount) - ((elem.price * elem.selectCount) * (elem.commission / 100));
                    elem.commissionPrice = ((elem.commission / 100) * elem.minPrice);
                    elem.totalCommissionPrice = elem.minPrice - elem.commissionPrice;
                }
            })
        });
    },
    [PUT_UP_RESET_LIST](state) {
        state.list = [];
    },
    [PUT_UP_TYPES_SALE_RESET](state) {
        state.areasSale = null;
    },
    [PUT_UP_FIELD_RESET](state) {
        state.list = [];
        state.selectedField = [];
        state.removeField = [];
        state.areas = null;
        state.areasSale = null;
        state.showCategory = false;
        state.detailTrees = null;
    },

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
