import {mapGetters, mapActions, mapMutations} from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import {
    BUY_SELECT_YOUNG_TREES,
    IS_LOGIN_USER,
    LOAD_PACKAGE_OFFER,
    GALLERY_NEWS_VIDEO,
} from '@/store/mutation-types';

export default {
    name: 'slider-change',
    components: {
        Carousel,
        Slide,
    },
    computed:{
        ...mapGetters({
            is_auth: `auth/is_auth`,
            content:'packageOfferSlider/list',
            homeVideo: `gallery/homeVideo`,
            videoLinks: 'gallery/videoLinks'
        })
    },
    created(){
        this.fetchContent().then(()=> {
            // console.log(this.homeVideo)
        });
        if(!this.content){
            this.fetchList();
        }
    },
    methods:{
        ...mapActions({
            fetchContent: `gallery/${GALLERY_NEWS_VIDEO}`,
            fetchList: `packageOfferSlider/${LOAD_PACKAGE_OFFER}`
        }),
        ...mapMutations({
            forEnter: `system/${IS_LOGIN_USER}`,
            changeSelectYoungTrees: `profileBuyTree/${BUY_SELECT_YOUNG_TREES}`
        }),
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
    }
};
