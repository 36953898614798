import { $http } from '@/https'
import {fixBody, globalLoader} from '@/helpers/variables/index';
import store from '@/store'
import {
    FETCH_PAYMENTS_SYSTEM,
    RESET_PAYMENTS_SYSTEM,
    BUY_DEFAULT_SHOW_POPUP,
    BUY_SELECT_YOUNG_TREES,
    BUY_COUNT_FREE_TREES,
    CHECK_PROMO_CODE,
    CHECK_PROMO_CODE_LOADING,
} from '../../mutation-types';

const state = {
    payments: null,
    showPopup: false,
    selectYoungTrees: 0,
    countFreeTrees: null,
    promoData: null,
    promoDataLoading: false,
};

const getters = {
    selectYoungTrees: state => state.selectYoungTrees,
    countFreeTrees: state => state.countFreeTrees,
    showPopup: state => state.showPopup,
    payments: state => state.payments,
    promoData: state => state.promoData,
    promoDataLoading: state => state.promoDataLoading,
};

const actions = {
    [BUY_COUNT_FREE_TREES]: async ({ commit }) => {
        try {
            const response = await $http.get(`v1/young_trees_count`);
            commit(BUY_COUNT_FREE_TREES, response.data);
            return response;
        }catch (e) {
            throw e
        }
    },
    [FETCH_PAYMENTS_SYSTEM]: async ({ commit }, payload) => {
        commit(RESET_PAYMENTS_SYSTEM);
        try {
            const response =  await $http.post('v1/payments/local', payload);
            commit(FETCH_PAYMENTS_SYSTEM, response.data.data);
        }catch (e) {
            throw e
        }
    },
    [CHECK_PROMO_CODE]: async ({ commit }, payload) => {
        commit(CHECK_PROMO_CODE_LOADING, true);
        try {
            const response =  await $http.get(`v1/check_promo?promoCode=${payload.code}&location=${payload.location}&promoArea=${payload.promoArea}&tree_count=${payload.tree_count}`);
            commit(CHECK_PROMO_CODE, response.data);
            return response.data
        }catch (e) {
            throw e
        }finally {
            commit(CHECK_PROMO_CODE_LOADING, false);
        }
    },
};

const mutations = {
    [FETCH_PAYMENTS_SYSTEM](state, param) {
        state.payments = param;
    },
    [CHECK_PROMO_CODE](state, param) {
        state.promoData = param;
        if(state.promoData){
            state.promoData.cashback = 0;
        }
    },
    [RESET_PAYMENTS_SYSTEM](state) {
        state.payments = null;
    },
    [BUY_DEFAULT_SHOW_POPUP](state, param) {
        fixBody(param);
        state.showPopup = param;
    },
    [BUY_SELECT_YOUNG_TREES](state, count) {
        state.selectYoungTrees = count;
    },
    [CHECK_PROMO_CODE_LOADING](state, status) {
        state.promoDataLoading = status;
    },
    [BUY_COUNT_FREE_TREES](state, count) {
        state.countFreeTrees = count;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
