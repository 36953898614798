import {$http} from '@/https';
import {
    LOAD_PACKAGE_OFFER,
} from '../mutation-types';

const state = {
    list: null,
};

const getters = {
    list: state => state.list,
};

const actions = {
    [LOAD_PACKAGE_OFFER]: async ({ commit }) => {
        try {
            const response = await $http.get('v1/shop/bonus');
            commit(LOAD_PACKAGE_OFFER, response.data.data);
        }catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [LOAD_PACKAGE_OFFER](state, list) {
        state.list = list;
    },

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
