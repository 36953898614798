import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SHOW_EXCHANGE_TREE_POPUP,
    NOT_AVAIBLE
} from "@/store/mutation-types";
export default{
    name: 'popup-documents',
    data(){
      return {
          loading: false
      }
    },
    computed: {
        ...mapGetters({
            currentTree: `exchangeTree/currentTree`
        }),
    },
    methods: {
        ...mapActions({

        }),
        ...mapMutations({
            showPopup: `exchangeTree/${SHOW_EXCHANGE_TREE_POPUP}`,
            notAvaible: `system/${NOT_AVAIBLE}`,
        }),
        submit() {

        },
    },
    destroyed(){

    }
}
