import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SELECT_TREES_IN_SHOP,
    SHOW_CALCULATE_FILTER_RESULT,
    CALCULATE_POPUP_CHANGE_OFFSET,
    CHANGE_FILTER_SEARCH_PARAMS,
    LOAD_TREE_FILTER,
    CALCULATE_SHOP_CHANGE_CHECK,
    CALCULATE_SHOP_CHANGE_ALL
} from "@/store/mutation-types";
import VueAdsPagination, {VueAdsPageButton} from "vue-ads-pagination";
export default {
    name: 'calculate-trees-popup',
    data() {
        return {
            loading: false,
            page: 0,
            limit: 20,
            localNumber: 0,
        }
    },
    computed: {
        ...mapGetters({
            selectTrees: `calculate/selectTreesShop`,
            searchParams: `calculate/searchParams`,
            trees: `calculate/listShop`,
            showPopup: `calculate/showCalculateFilterResult`,
            lengthListTreeShop: `calculate/lengthListTreeShop`,
            currentCategoryShop: `calculate/currentCategoryShop`,
        })
    },
    components: {
        VueAdsPagination,
        VueAdsPageButton
    },
    watch: {
        page(){
            this.currentTrees(this.selectTrees);
            this.localNumber = this.page * this.limit;
            this.searchParams.offset = this.page * this.limit;
            this.loading = true;
            this.fetchTrees(this.searchParams)
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    created(){
        this.loading = true;
        this.fetchTrees(this.searchParams)
            .finally(() => {
                this.loading = false;
            })
    },
    methods: {
        ...mapActions({
            fetchTrees: `calculate/${LOAD_TREE_FILTER}`
        }),
        ...mapMutations({
            currentTrees: `calculate/${SELECT_TREES_IN_SHOP}`,
            changeOffsetShop: `calculate/${CALCULATE_POPUP_CHANGE_OFFSET}`,
            showPopupResult: `calculate/${SHOW_CALCULATE_FILTER_RESULT}`,
            changeSearchParams: `calculate/${CHANGE_FILTER_SEARCH_PARAMS}`,
            changeCheckbox: `calculate/${CALCULATE_SHOP_CHANGE_CHECK}`,
            changeAllTrees: `calculate/${CALCULATE_SHOP_CHANGE_ALL}`,
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        addTrees(){
            this.currentTrees(this.selectTrees);
            this.showPopupResult(false)
        },
        transformDate(date){
            const day = date.substr(8,2);
            const mounth = date.substr(5,2);
            const year = date.substr(0,4);
            if(date.length > 0){
                return `${day}.${mounth}.${year}`;
            }else{
                return '';
            }
        },
    },
}
