import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    FIELD_SHOW_CATEGORY,
    FIELD_TYPES_SALE,
    FIELD_LIST
} from "@/store/mutation-types";
export default {
    name: 'field-category-popup-documents',
    data() {
        return {
            selectCategories: []
        }
    },
    computed: {
        ...mapGetters({
            category: `field/areasSale`
        })
    },
    created(){
        this.fetchCategory();
    },
    methods: {
        ...mapMutations({
            showPopup: `field/${FIELD_SHOW_CATEGORY}`,
            currentCategory: `field/${FIELD_LIST}`,
        }),
        ...mapActions({
            fetchCategory: `field/${FIELD_TYPES_SALE}`
        }),
        selectCategory(){
            this.currentCategory(this.selectCategories);
            this.showPopup(false);
        }
    }
}
