import Vue from 'vue'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import vueSmoothScroll from 'vue2-smooth-scroll'
import GPay from '@/components/widgets/google-pay/index.vue'
import {
  BUY_COUNT_FREE_TREES,
  BUY_SELECT_YOUNG_TREES,
  BUY_YOUNG_TREE,
  CHECK_PROMO_CODE,
  FETCH_PAYMENTS_SYSTEM,
  LOAD_USER_DATA,
  PAYMENT_LEOGAMING,
  POPUP_BUY_IFRAME
} from '@/store/mutation-types';
import _ from 'lodash'
import store from "@/store";

Vue.use(vueSmoothScroll);

export default {
  name: 'buy-default-tree',
  data() {
    return {
      countLoader: false,
      sliderValue: 0,
      showPayment: false,
      value: '',
      paySystem: null,
      payload: {
        tree_count: 0,
        payment_system: null,
        promoCode: '',
        promoArea: 'firstsale_buy'
      },
    }
  },
  components: {
    GPay
  },
  computed: {
    ...mapGetters({
      user: `profile/user`,
      setting: 'main/setting',
      isLoadingCountryCode: 'main/isLoadingCountryCode',
      countFreeTrees: `profileBuyTree/countFreeTrees`,
      payments: `profileBuyTree/payments`,
      selectYoungTrees: `profileBuyTree/selectYoungTrees`,
      promoData: `profileBuyTree/promoData`,
      promoDataLoading: `profileBuyTree/promoDataLoading`,
      fetchUserData: `profile/user`,
    }),
    ...mapGetters('new_user',{
      uuid: "uuid",
    }),
    totalCashBack() {
      let total = this.setting.defaultPriceTree * this.payload.tree_count;
      if (this.promoData) {
        if (this.promoData.cashback > 0) {
          total = Math.round((total * (this.promoData.cashback / 100)) * 10000) / 10000
        }
      }
      return total;
    },
    totalPrice() {
      let total = this.setting.defaultPriceTree * this.payload.tree_count;
      if (this.promoData) {
        if (this.promoData.discount > 0) {
          total = total - (total * (this.promoData.discount / 100));
          total = Math.round(total * 100) / 100
        }
      }
      if (this.payload.promoCode.length === 0) {
        total = this.setting.defaultPriceTree * this.payload.tree_count
      }
      return total;
    },
    watchCount() {
      if (this.payload.tree_count > this.countFreeTrees.all_young_trees_count) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.fetchUser().then(() => {
      this.setUuidAsync(this.fetchUserData.uuid)
    })
    this.fetchParams()
      .then(response => {
        if (this.selectYoungTrees !== 0) {
          if (response.data.all_young_trees_count < this.selectYoungTrees) {
            this.payload.tree_count = response.data.all_young_trees_count;
          } else {
            this.payload.tree_count = this.selectYoungTrees;
          }
        }
      })
      .finally(() => {
        this.countLoader = true;

      });
    if (this.isLoadingCountryCode) {
      this.fetchPayments({countryCode: this.setting.countryCode});
    }
    if (window.innerWidth < 981) {
      this.$smoothScroll({
        hash: '',
        duration: 1000,
        updateHistory: false,
        scrollTo: document.getElementById('buy__default'),
      })
    }
  },
  watch: {
    isLoadingCountryCode() {
      if (this.isLoadingCountryCode) {
        this.fetchPayments({countryCode: this.setting.countryCode});
      }
    }
  },
  methods: {
    ...mapActions('new_acquiring',{
      buyYoungOliveTreesStripeAsync: 'buyYoungOliveTreesStripeAsync',
    }),
    ...mapActions('new_user',{
      setUuidAsync: 'setUuidAsync',
      isSafari: 'isSafari',
    }),
    requestBuy() {
      this.gtm('buytr');
      this.buyYoungTrees(this.payload)
        .then(() => {
          this.setPromo(null);
        })
        .catch(e => {
          this.$toaster.error(e.response.data.error.message);
        });
    },
    butTree() {
      if (!this.payload.payment_system) {
        this.$toaster.error(this.$t('payMethod'));
        return false;
      }

      // find current item in payment system
      const currentItemPayment = this.payments.find(e => e.payment_system === this.payload.payment_system);
      if(currentItemPayment.payment_system === 'betatrans'){
        let lang = store.state['vue-i18n-manager'].currentLanguage.translationKey;
        lang = lang === 'ru' ? 'rus' : 'eng'

        let payload = {
          success_url: `${window.location.origin}${this.$router.resolve({ name: 'personal'}).href}`,
          count_trees: this.payload.tree_count,
        }
        if(!!this.payload.promoCode){
          payload.promo_code = this.payload.promoCode;
        }
        let headers = {
          auth: this.uuid,
          lang: lang,
        }
        this.buyYoungOliveTreesStripeAsync({
          payload:payload,
          headers:headers,
        }).then((result) => {
          this.payload.tree_count = 0
          this.setPromo(null);
          if(result.isSuccess){
            this.isSafari().then((res) => {
              if(res){
                window.location.href = result.pay_url;
              }else{
                window.open(result.pay_url, '_blank')

              }
            })
          }else{
            this.$toaster.error(result.exception.textError)
          }
        });
      }else{
        // has restriction item
        if (currentItemPayment.restriction) {

          // check max price payment system for selected payment
          if (this.totalPrice < parseInt(currentItemPayment.restriction.max.restriction)) {
            this.requestBuy();
          } else {
            this.$toaster.error(currentItemPayment.restriction.max.error);
          }
        } else {
          // not restriction object (balance)
          const total = this.user.balance + this.user.bonus_balance + this.user.futures_balance;


          // check user balance
          if (currentItemPayment.payment_system === 'balance') {
            if (total < this.totalPrice) {
              this.$toaster.error(this.$t('notEnoughMoney'));
            } else {
              this.requestBuy();
            }
          } else {
            this.requestBuy();
          }
        }
      }
    },
    ...mapActions({
      fetchPayments: `profileBuyTree/${FETCH_PAYMENTS_SYSTEM}`,
      fetchParams: `profileBuyTree/${BUY_COUNT_FREE_TREES}`,
      fetchData: `profile/${LOAD_USER_DATA}`,
      buyYoungTrees: `shop/${BUY_YOUNG_TREE}`,
      checkPromoCode: `profileBuyTree/${CHECK_PROMO_CODE}`,
      fetchUser: `profile/LOAD_USER_DATA`,
    }),
    ...mapMutations({
      resetSelectYoungTrees: `profileBuyTree/${BUY_SELECT_YOUNG_TREES}`,
      setPromo: `profileBuyTree/${CHECK_PROMO_CODE}`,
      iframeLink: `shop/${PAYMENT_LEOGAMING}`,
      cashPopUpIframe: `shop/${POPUP_BUY_IFRAME}`,
      showPopupFuturesBalance: `profile/CHANGE_TEXT_FUTURES_BALANCE`,
    }),
    gtm(label) {
      this.$gtm.trackEvent({
        event: 'biodep',
        eventCategory: 'userclicks',
        eventAction: 'button',
        eventLabel: label,
      });
    },
    checkPromo: _.debounce(function () {
      if (this.payload.promoCode.length > 0 ) {
        this.checkPromoCode({
          code: this.payload.promoCode,
          promoArea: this.payload.promoArea,
          tree_count: this.payload.tree_count,
        })
          .finally(() => {
            if (this.promoData.discount === null) {
              this.$toaster.error(this.$t('errorPromoCode'));
            }
          })
      }
    }, 2000),

  },
  destroyed() {
    this.setPromo(null);
    this.resetSelectYoungTrees(0);
  }
}
