import {$http} from '@/https'
import {
    SHOW_SERVER_ERROR,
    IS_INVESTOR_PAGE,
    FIX_BODY,
    SHOW_GLOBAL_LOADER,
    SHOW_HEADER_MENU,
    NOT_AVAIBLE,
    BUY_TREE_POPUP,
    IS_LOGIN_USER,
    SHOW_CASH_POPUP,
    SHOW_PAYMENT_POPUP,
} from '@/store/mutation-types';
import {fixBody} from '@/helpers/variables/index'

const state = {
    errorServerMessage: '',
    serverError: false,
    globalLoader: false,
    notAvaible: false,
    forEnter: false,
    buyTreePopUp: false,
    openMenu: false,
    fixBody: false,
    isInvestorPage: false,
    cashPopUp: false,
    paymentPopup: false,
};

const getters = {
    fixBody: state => state.fixBody,
    openMenu: state => state.openMenu,
    errorServerMessage: state => state.errorServerMessage,
    notAvaible: state => state.notAvaible,
    forEnter: state => state.forEnter,
    buyTreePopUp: state => state.buyTreePopUp,
    globalLoader: state => state.globalLoader,
    serverError: state => state.serverError,
    isInvestorPage: state => state.isInvestorPage,
    cashPopUp: state => state.cashPopUp,
    paymentPopup: state => state.paymentPopup,

};

const mutations = {
    [SHOW_SERVER_ERROR](state, status) {
        state.serverError = status;
    },
    [SHOW_GLOBAL_LOADER](state, status) {
        state.globalLoader = status
    },
    [IS_INVESTOR_PAGE](state, status) {
        state.isInvestorPage = status
    },
    [NOT_AVAIBLE](state, status) {
        state.notAvaible = status;
        fixBody(status)
    },
    [IS_LOGIN_USER](state, status) {
        fixBody(status);
        state.forEnter = status
    },
    [BUY_TREE_POPUP](state, status) {
        fixBody(status);
        state.buyTreePopUp = status
    },
    [SHOW_HEADER_MENU](state) {
        state.openMenu = !state.openMenu;
    },
    [FIX_BODY](state, param) {
        const body = document.querySelector('body');
        if (param) {
            body.classList.add('fix');
        } else {
            body.classList.remove('fix');
        }
        state.fixBody = param
    },
    [SHOW_CASH_POPUP](state, param) {
        fixBody(param);
        state.cashPopUp = param
    },
    [SHOW_PAYMENT_POPUP](state, param) {
        fixBody(param);
        state.paymentPopup = param
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
