import Calculate from './components/calculate/index.vue'
import PackageOffer from './components/package-offer/index.vue'
import {mapGetters, mapMutations} from 'vuex'
import {
    CALCULATE_ADD_TREE,
} from "@/store/mutation-types";


//intro
import '@/assets/js/intro.min.js';
import '@/assets/style/_introjs.scss';
//components
import Categories from './components/category-popup/index.vue'
import ShopFilter from './components/popup-shop-filter/index.vue'
import ShopFilterResult from './components/popup-result-search/index.vue'
import Trees from './components/trees-popup/index.vue'


export default{
    name: 'buy-tree',
    data(){
        return{
        }
    },
    computed: {
        ...mapGetters({
            showCalculateFilter: `calculate/showCalculateFilter`,
            list: `calculate/list`,
            watchCategory: `give/showCategory`,
            watchTrees: `give/showTrees`,
            showCalculateFilterResult: `calculate/showCalculateFilterResult`,
        })
    },
    mounted(){

    },
    components: {
        Calculate,
        Trees,
        PackageOffer,
        ShopFilterResult,
        ShopFilter,
        Categories
    },
    methods: {
        ...mapMutations({
            addTree: `calculate/${CALCULATE_ADD_TREE}`,
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },

    }
}
