import {mapActions, mapMutations, mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate';
import {email, minLength, required} from 'vuelidate/lib/validators';

import FacebookWidget from '@/components/widgets/facebook/index.vue';
import GoogleWidget from '@/components/widgets/google/index.vue';

export default {
  name: 'sing-in',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        password: '',
      },
      serverError: '',
      visiblePassword: false,
      validationErrors: {},
      timer: '',
      disabled: false,
      showReActivated: false
    }
  },
  components: {
    FacebookWidget,
    GoogleWidget
  },
  validations: {
    payload: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(5)
      },
    }
  },
  computed: {
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push('Введите корректный email!')
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin8'));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    ...mapGetters({

    })
  },
  methods: {
    ...mapActions({
      fetchContent: `notifications/NOTIFICATIONS`,
      singIn: `auth/AUTH_LOGIN`,
      reActivation: `auth/RE_ACTIVATION`,
    }),
    ...mapMutations({
      showPopup: `auth/SHOW_SING_IN`,
      showRegistration: `auth/REGISTRATION_SHOW`,
      showForgot: `auth/SHOW_RESTORE_PASSWORD_EMAIL`,
    }),
    goRegistration() {
      this.showPopup(false);
      this.showRegistration(true);
    },
    goForgot() {
      this.showPopup(false);
      this.showForgot(true)
    },
    reSend() {
      this.reActivation(this.payload.email)
        .then(response => {
          this.disabled = true;
          this.timer = 60;
          this.callbackTimer();
          this.$toaster.success(response.data.message);
        })
        .catch(error => {
          this.$toaster.error(error.response.data.message);
        })
    },
    callbackTimer() {
      setInterval(() => {
        this.timer = this.timer - 1;
        if (this.timer <= 0) {
          this.disabled = false;
          this.timer = '';
        }
      }, 1000);
    },
    gtm(label) {
      this.$gtm.trackEvent({
        event: 'biodep',
        eventCategory: 'send',
        eventAction: 'form',
        eventLabel: label,
      });
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {
          email: this.payload.email,
          password: this.payload.password,
        };
        this.singIn(obj)
          .then(resp => {
            if (resp.data.access_token) {
              this.gtm('authorization');
              this.showPopup(false);
              this.fetchContent();
              if (this.$route.query.hasOwnProperty('showSingIn') && this.$route.query.hasOwnProperty('link')) {
                this.$router.push(this.$route.query.link);
              } else {
                this.$router.push({name: 'personal'});
              }
            }
          }).catch(error => {
          if (error.response.status === 422) {
            this.serverError = this.$t('validationSingError');
            this.$toaster.error(error.response.data.message);
            this.showReActivated = false;
          }
          if (error.response.status === 427) {
            this.showReActivated = true;
            this.$toaster.error(error.response.data.message);
          }
        });
      }
    },

  }
}
