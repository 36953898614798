import { mapMutations, mapGetters, mapActions } from "vuex";
import {
    EXPRESS_PURCHASES,
    EXPRESS_RE_BUY_TREES
} from '@/store/mutation-types';

export default {
    name: 'express-trees',
    data(){
        return {

        }
    },
    computed:{
        ...mapGetters({
            expressPurchases: 'trees/expressPurchases',
            expressPurchasesLoading: 'trees/expressPurchasesLoading',
        })
    },
    created(){
        this.fetchExpressList();
    },
    methods: {
        ...mapActions({
            fetchExpressList: `trees/${EXPRESS_PURCHASES}`,
            user: `profile/user`,
            reBuyTrees: `expressActions/${EXPRESS_RE_BUY_TREES}`,
        }),
        changePayment(id){
            const obj = {
                purches_id: id
            };
            this.reBuyTrees(obj)
                .then(resp => {
                    this.fetchExpressList();
                })
                .catch(e => {
                    this.$toaster.error(e.response.data.error.message);
                });
        },
        hasBans(elem) {
            let divined = false;
            let sale = false;
            if (elem.dividend_frozen_to && elem.dividend_frozen_to !== null) {
                divined = true
            } else {
                divined = false
            }
            if (elem.sale_frozen_to && elem.sale_frozen_to !== null) {
                sale = true
            } else {
                sale = false
            }
            if (divined || sale) {
                return true
            }
        },
    },
    destroyed(){

    }
}