import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    PRIVACY_POLICY_CONTENT,
    SHOW_POPUP_PRIVACY,
    IS_READ_PRIVACY_POLICY
} from "@/store/mutation-types";
export default{
    name: 'help-text',
    data(){
        return {
            scrolled: false
        }
    },
    computed: {
        ...mapGetters({
            content: `privacyPolicy/contentPrivacy`,
            readPrivacyPolicy: `privacyPolicy/readPrivacyPolicy`,
        }),
    },
    watch: {

    },
    created(){
        this.fetchContent();
        window.addEventListener('scroll', this.handleScroll);
    },
    updated(){
        const elementBlock = document.querySelector('.popup__privacy');
        const elementScroll = document.querySelector('.popup__privacy-area');
        const heightBlock = elementBlock.clientHeight;
        const heightElement = elementScroll.clientHeight;
        if(heightBlock > heightElement){
            this.changeReadPrivacyPolicy(true)
        }
    },
    methods: {
        ...mapActions({
            fetchContent: `privacyPolicy/${PRIVACY_POLICY_CONTENT}`
        }),
        ...mapMutations({
            closePopup: `privacyPolicy/${SHOW_POPUP_PRIVACY}`,
            changeReadPrivacyPolicy: `privacyPolicy/${IS_READ_PRIVACY_POLICY}`,
        }),
        changePopup(){
            this.closePopup(false);
        },
        handleScroll () {
            const elementBlock = document.querySelector('.popup__privacy');
            const elementScroll = document.querySelector('.popup__privacy-area');
            const heightBlock = elementBlock.clientHeight;
            const heightElement = elementScroll.clientHeight;
            const scrollTop = elementBlock.scrollTop + heightBlock;
            if(scrollTop > heightElement){
                this.changeReadPrivacyPolicy(true)
            }
        }
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
