import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
    SELL_SHOW_CATEGORIES,
    SELL_LOAD_CATEGORY,
    SELL_CURRENT_CATEGORY,
    SELL_SHOW_TREES
} from "@/store/mutation-types";
export default {
    name: 'sell-category-popup-documents',
    data() {
        return {
            selectCategories: []
        }
    },
    computed: {
        ...mapGetters({
            category: `sell/category`
        })
    },
    created(){
        this.fetchCategory();
    },
    methods: {
        ...mapMutations({
            showPopup: `sell/${SELL_SHOW_CATEGORIES}`,
            showTreesPopup: `sell/${SELL_SHOW_TREES}`,
            currentCategory: `sell/${SELL_CURRENT_CATEGORY}`,
        }),
        ...mapActions({
            fetchCategory: `sell/${SELL_LOAD_CATEGORY}`
        }),
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        selectCategory(){
            this.currentCategory(this.selectCategories);
            this.showPopup(false);
            this.showTreesPopup(true);
        },
    }
}
