import { required, minLength, sameAs    } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapMutations} from 'vuex'
import {
    TWO_AUTH_DATA,
    TWO_AUTH_ON,
    TWO_AUTH_OFF,
    LOAD_USER_DATA,
    RESET_TWO_AUTH_DATA,
    TWO_FACTOR_AUTHENTICATION
} from '@/store/mutation-types';
import Vue from 'vue'
import VueClipboard from 'v-clipboard'
Vue.use(VueClipboard);
export default {
    name: 'two-factor-auntentificate',
    data(){
        return{
            code: '',
            showTwoAuthField: false,
            validationErrors: {}
        }
    },
    validations:{
        code: {
            required,
            minLength: minLength(6),
        }
    },
    created(){
        this.fetchData();
        this.fetchtwoFactorAuthentication();
    },
    watch: {
        showTwoAuthField(){
            if(this.showTwoAuthField === false){
               this.resetCode();
            }
          //для попапов
          // this.changeTwoAuthCode(this.code)
        }
    },
    computed: {
        ...mapGetters({
            twoFactorData: `profile/twoFactorData`,
            user: `profile/user`,
            twoFactorAuthentication: `statistic/twoFactorAuthentication`,
        }),
        codeErrors() {
            let error = [];
            if (!this.$v.code.$dirty) {
                return error;
            }
            if (!this.$v.code.required) {
                error.push (this.$t('validationRequired'))
            }
            if (!this.$v.code.minLength) {
                error.push(this.$t('validationMin6'))
            }
            if (this.validationErrors.code) {
                this.validationErrors.code.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
    },
    methods:{
        ...mapActions({
            fetchUser: `profile/${LOAD_USER_DATA}`,
            fetchData: `profile/${TWO_AUTH_DATA}`,
            twoAuthOn: `profile/${TWO_AUTH_ON}`,
            twoAuthOff: `profile/${TWO_AUTH_OFF}`,
            fetchtwoFactorAuthentication: `statistic/${TWO_FACTOR_AUTHENTICATION}`,
        }),
        ...mapMutations({
            resetTwoFactorData: `profile/${RESET_TWO_AUTH_DATA}`
        }),
        clipboardCopy(){
            this.$toaster.success(this.$t('clipboardCode'));
        },
        resetCode(){
            this.code = '';
            this.$v.code.$reset();
            this.validationErrors = {};
        },
        addDevice(){
          //тут колись буде код
        },



        twoAuthOnEvent(){
            this.$v.code.$touch();
            if(!this.$v.code.$invalid){
                let obj = {
                    one_time_password: this.code
                };
                this.twoAuthOn(obj)
                    .then(response => {
                        this.fetchUser();
                        this.fetchData();
                        this.$toaster.success(response.data.message);
                        this.resetCode();
                        this.validationErrors = false;
                    })
                    .catch(error => {
                        this.$toaster.error(error.response.data.message);
                    })
            }

        },
        twoAuthOffEvent(){
            this.$v.code.$touch();
            if(!this.$v.code.$invalid){
                let obj = {
                    one_time_password: this.code
                };
                this.twoAuthOff(obj)
                    .then(response => {
                        this.fetchUser();
                        this.fetchData();
                        this.$toaster.success(response.data.message);
                        this.resetCode();
                    })
                    .catch(error => {
                        this.$toaster.error(error.response.data.message);
                    })
            }
        }
    },
    destroyed(){
        this.resetTwoFactorData();
    }
}
