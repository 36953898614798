import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import Toaster from 'v-toaster';
import Vuex from 'vuex';
import router from './router';
import store from './store';
import axios from 'axios';
import VueCarousel from 'vue-carousel';
import VueYoutube from 'vue-youtube';
import Vuelidate from 'vuelidate'
import VueGallery from 'vue-gallery';
import VueCircleSlider from 'vue-circle-slider'
import VueTheMask from 'vue-the-mask'
import VueI18nManager from 'vue-i18n-manager';
import VueIntro from 'vue-introjs';
import 'v-toaster/dist/v-toaster.css';
import VTooltip from 'v-tooltip';
import {$http} from '@/https'
import VueTelInput from 'vue-tel-input';
import myVideo from 'vue-video';
import VueScrollTo from 'vue-scrollto';
import SocialSharing from 'vue-social-sharing';
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import VueBingMaps from 'vue-bing-maps'
import VueGtm from 'vue-gtm'
import LoadScript from 'vue-plugin-load-script';
import VueMeta from "vue-meta";
import VueHead from 'vue-head'
import VueSessionStorage from "vue-sessionstorage";

Vue.config.productionTip = false;

//mutation
import {AUTH_LOGIN} from '@/store/mutation-types';
//interceptors
import './https';
//components
import Loader from './components/helpers/loader/index.vue'
import Share from './components/misc/sharing/index.vue'
import PopupWrap from './components/helpers/popup-wrap/index.vue'
import TreeItem from './components/helpers/trees-item/index.vue'
import ViewCounter from './components/helpers/view-conter/index.vue'
import TreeDetailMenu from './components/helpers/tree-detail-menu/index.vue'
import Btn from './components/helpers/btn/index.vue'

Vue.use(VueGtm, {
  id: 'GTM-M5SHBW9', // Your GTM ID
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [] // If router, you can exclude some routes name (case insensitive) (optional)
});

Vue.component('loader', Loader);
Vue.component('share', Share);
Vue.component('popup-wrap', PopupWrap);
Vue.component('btn', Btn);
Vue.component('tree-item', TreeItem);
Vue.component('view-counter', ViewCounter);
Vue.component('tree-detail-menu', TreeDetailMenu);
Vue.component('v-pagination', window['vue-plain-pagination']);

//use
Vue.use(Vuelidate);
Vue.use(Vuex, axios);
Vue.config.productionTip = false;
Vue.use(VueCarousel);
Vue.use(VueYoutube);
Vue.use(VueGallery);
Vue.use(VueCircleSlider);
Vue.use(VueTheMask);
Vue.use(VueIntro);
Vue.use(Toaster, {timeout: 5000});
Vue.use(VueScrollTo);
Vue.use(VTooltip);
Vue.use(VueTelInput);
Vue.use(myVideo);
Vue.use(SocialSharing);
Vue.use(VueFacebookPixel);
Vue.use(VueBingMaps, {debug: false});
Vue.use(LoadScript);
Vue.use(VueMeta)
Vue.use(VueSessionStorage);

Vue.use(VueHead)

Vue.use(VueScrollTo, {
  container: "body",
  duration: 800,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

//localization
const proxy = {
  getTranslation: () => {
    return $http({
      method: 'get',
      url: `${process.env.VUE_APP_ROOT_API}v1/translations`,
    })
      .then(response => {
        const localTranslations = {

        };
        let totalTranslation = Object.assign(response.data, localTranslations);

        return totalTranslation;
      })
      .catch(() => {
      })
      .finally(() => {
      });
  }
};

Vue.use(VueI18nManager, {
  store,
  router,
  proxy,
  config: {
    defaultCode: 'ru-RU',
    languages: [
      {
        name: 'Russian',
        title: 'Rus.',
        key: 'ru',
        code: 'ru-RU',
        urlPrefix: 'ru',
        translationKey: 'ru',
      },
      {
        name: 'English',
        title: 'Eng.',
        key: 'eng',
        code: 'en-US',
        urlPrefix: 'en',
        translationKey: 'en'
      },
    ],
    translations: proxy,
  },
});


if (localStorage.getItem('user_token')) {
  store.commit(`auth/${AUTH_LOGIN}`, localStorage.getItem('user_token'));
}

const app = new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
});

Vue.initI18nManager().then(() => {
  app.$mount('#app')
});
