import {videoPlayer} from 'vue-video-player';
import 'video.js/dist/video-js.css'
import _ from 'lodash';

export default {
  name: 'strimm-player',
  data() {
    return {
      currentIndexVideo: 0,
      playerOptions: {
        autoplay: true,
        controls: false,
        muted: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        }
      },
      listStrimm: [
        {
          url: 'https://video.biodeposit.ge/2020/07/nvr01/ch3/main/nvr01_ch3_main_20200709.mp4'
        },
        {
          url: 'https://video.biodeposit.ge/2020/07/nvr01/ch2/main/nvr01_ch2_main_20200709.mp4'
        },
        {
          url: 'https://video.biodeposit.ge/2020/07/nvr01/ch1/main/nvr01_ch1_main_20200712.mp4'
        },
      ]
    }
  },
  components: {
    videoPlayer
  },
  watch: {
    currentIndexVideo() {
      setTimeout(() => {
        this.randomIndexVideo();
      }, 10000)
    }
  },
  mounted() {
    this.randomIndexVideo();
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
    // getId () {
    //   return this.$youtube.getIdFromUrl('https://www.youtube.com/watch?v=DmZBBzWXAwk')
    // },
  },
  created() {

  },
  methods: {
    randomIndexVideo() {
      let index = _.random(0, this.listStrimm.length - 1);
      if (index !== this.currentIndexVideo) {
        this.currentIndexVideo = index;
        this.playVideo(this.listStrimm[index].url.toLowerCase());
      } else {
        this.randomIndexVideo();
        return false;
      }
    },
    onPlayerPlay(player) {

    },
    onPlayerReady(player) {
      this.player.play()
    },
    playVideo: function (source) {
      const video = {
        // withCredentials: false,
        type: "video/mp4",
        src: source
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      this.player.load();
      this.player.play();
    }
  },
  destroyed() {

  }
}
