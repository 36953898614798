import { $http } from '@/https';
import {
    CONTACTS_LOAD,
    SOCIAL_LOAD
} from '../mutation-types.js';

const state = {
    content: null,
    social: null,
};

const getters = {
    content: state => state.content,
    social: state => state.social,
};

const actions = {
    [CONTACTS_LOAD]: async ({ commit }) => {
        try {
            const result = await $http.get('v1/pages/contacts?include=addresses,countries,departments,socials');
            commit(CONTACTS_LOAD, result.data.data);
        } catch (e) {
            throw e;
        }
    },
    [SOCIAL_LOAD]: async ({ commit }) => {
        try {
            const result = await $http.get('v1/pages/contacts?include=socials');
            commit(SOCIAL_LOAD, result.data.data);
        } catch (e) {
            throw e;
        }
    },
};

const mutations = {
    [CONTACTS_LOAD](state, content) {
        state.content = content;
    },
    [SOCIAL_LOAD](state, content) {
        state.social = content;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
