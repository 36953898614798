import VideoPlayerPlaylist from '../strimm-player-playlist/index.vue';
import {showTextPopup} from '@/helpers/variables/index';
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'stream-video-item',
  data() {
    return {
      activeCam: 2,
      isActiveLive: false
    }
  },
  props: ['item','singlePage'],
  components: {
    VideoPlayerPlaylist
  },
  computed: {
    ...mapGetters({
      config: 'main/config',
    }),
  },
  mounted() {

  },
  methods: {
    changeActiveCam(index) {
      this.activeCam = index;
      if (this.item.stream.data[index - 1].live === '') {
        this.isActiveLive = false;
      }
    },
    changeLive() {
      this.isActiveLive = !this.isActiveLive;
    },
    getConfigContent(key) {
      for (let i in this.config) {
        if (this.config[i].key === key) {
          return this.config[i];
        }
      }
    },
    ...mapMutations({
      setPopupContent: `popups/TEXT_POPUP_CONTENT`
    }),
    changePopupLiveInfo(status) {
      showTextPopup(status);

      this.setPopupContent(this.getConfigContent('infoStream'));
    },
  },
  destroyed() {

  }
}
