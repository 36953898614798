import {mapGetters, mapActions, mapMutations} from 'vuex'
import {
    POPUP_BUY_IFRAME,
    FETCH_PAYMENTS_SYSTEM
} from '@/store/mutation-types';
import {required, between} from "vuelidate/lib/validators";
import { helpers } from 'vuelidate/lib/validators'
import {
    GET_WITHDRAWAL
} from "@/store/action-types";
import VuePhoneNumberInput from 'vue-phone-number-input';

const alpha = helpers.regex('alpha', /^[A-Za-z\s]+$/);
const LOCALE = {
    ru: {
        text: "Минимальный вывод средств 50$",
        linc: "Правила и условия пользования платформы"
    },
    eng: {
        text: "Minimum withdrawal is $50",
        linc: "Terms and conditions of use of the platform"
    },
}
export default {
    name: 'cash-withdrawal',
    data() {
        return {
            LOCALE,
            value: '',
            paySystem: null,
            currentWithdrawalType: null,
            typesWithdrawal: [
                {
                    name: this.$t('transferCard'),
                    type: 'card'
                },
                {
                    name: 'SWIFT',
                    type: 'swift'
                }
            ],
            payload:{
                amount: '',
                //card
                card_number: '',
                bank: '',
                full_name: '',
                //swift
                full_name_en: '',
                account_number: '',
                phone: '',
                hiddenPhone: '',
            },
            translations: {
                countrySelectorLabel: this.$t('phoneLabelsCountrySelectorLabel'),
                phoneNumberLabel: this.$t('phoneLabelsPhoneNumberLabel'),
                example: this.$t('phoneLabelsExample'),
            },
            phone: {
                isValid: false,
            },
            validationErrors: {},
        }
    },
    validations () {
        if(this.userData){
            if(this.currentWithdrawalType.type === 'card'){
                return {
                    payload: {
                        amount:{
                            required,
                            between: between(0, this.userData.balance)
                        },
                        card_number:{
                            required
                        },
                        full_name:{
                            required
                        },
                        bank:{
                            required
                        },
                    }
                }
            }else{
                return {
                    payload: {
                        amount:{
                            required,
                            between: between(0, this.userData.balance)
                        },
                        account_number:{
                            required
                        },
                        full_name_en:{
                            required,
                            alpha
                        },
                        phone: {
                            required
                        },
                        hiddenPhone: {
                            required,
                        },
                    }
                }
            }

        }
    },
    components: {
        VuePhoneNumberInput
    },
    computed: {
        ...mapGetters({
            payments: `profileBuyTree/payments`,
            setting: 'main/setting',
            userData: `profile/user`,
            isLoadingCountryCode: 'main/isLoadingCountryCode'
        }),
        ...mapGetters([
            'currentLanguage',
        ]),
        currentLocale(){
            return !!this.currentLanguage.key ? this.currentLanguage.key : 'ru'
        },
        watchField(){
            if(this.value !== 0 && this.value !== '' && this.paySystem !== null){
                return true
            }else{
                return false
            }
        },
        amountErrors(){
            let error= [];
            if (!this.$v.payload.amount.$dirty) {
                return error;
            }
            if (!this.$v.payload.amount.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.payload.amount.between) {
                error.push(this.$t('notEnoughMoney'))
            }
            return error;
        },
        minAmount(){
            if(!!this.payload.amount){
                return this.payload.amount < 50
            }
           return true
        },
        cardNumberErrors(){
            let error= [];
            if (!this.$v.payload.card_number.$dirty) {
                return error;
            }
            if (!this.$v.payload.card_number.required) {
                error.push(this.$t('validationRequired'))
            }
            if (this.validationErrors.card_number) {
                this.validationErrors.card_number.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        accountNumberErrors(){
            let error= [];
            if (!this.$v.payload.account_number.$dirty) {
                return error;
            }
            if (!this.$v.payload.account_number.required) {
                error.push(this.$t('validationRequired'))
            }
            if (this.validationErrors.account_number) {
                this.validationErrors.account_number.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        phoneErrors(){
            let error= [];
            if (!this.$v.payload.phone.$dirty) {
                return error;
            }
            if (!this.phone.isValid) {
                error.push(this.$t('validationMinPhone'))
            }
            if (this.validationErrors.phone) {
                this.validationErrors.phone.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        bankErrors(){
            let error= [];
            if (!this.$v.payload.bank.$dirty) {
                return error;
            }
            if (!this.$v.payload.bank.required) {
                error.push(this.$t('validationRequired'))
            }
            if (this.validationErrors.bank) {
                this.validationErrors.bank.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        fullNameErrors(){
            let error= [];
            if (!this.$v.payload.full_name.$dirty) {
                return error;
            }
            if (!this.$v.payload.full_name.required) {
                error.push(this.$t('validationRequired'))
            }
            if (this.validationErrors.full_name) {
                this.validationErrors.full_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        fullNameEnErrors(){
            let error= [];
            if (!this.$v.payload.full_name_en.$dirty) {
                return error;
            }
            if (!this.$v.payload.full_name_en.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.payload.full_name_en.alpha) {
                error.push(this.$t('validationOnlyEngLetters'))
            }
            if (this.validationErrors.full_name_en) {
                this.validationErrors.full_name_en.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
    },
    created() {
        this.currentWithdrawalType = this.typesWithdrawal[0];
        if(this.isLoadingCountryCode){
            this.fetchPayments({countryCode: this.setting.countryCode});
        }
    },
    watch: {
        isLoadingCountryCode(){
            if(this.isLoadingCountryCode){
                this.fetchPayments({countryCode: this.setting.countryCode});
            }
        }
    },
    methods: {
        resetFields(){
            this.payload.amount = '';
            this.payload.card_number = '';
            this.payload.bank = '';
            this.payload.full_name = '';
            this.payload.full_name_en = '';
            this.payload.phone = '';
            this.payload.account_number = '';
            this.$nextTick(() => { this.$v.$reset() })
        },
        onTelInput({formatNational, isValid, formattedNumber, phoneNumber}) {
            this.payload.phone = formattedNumber;
            this.payload.hiddenPhone = phoneNumber;
            this.phone.isValid = isValid;
        },
        submit(){
            this.$v.$touch();
            if(!this.$v.$invalid){
                this.getWithdrawal({
                    ...this.payload,
                    type: this.currentWithdrawalType.type
                })
                    .then(() => {
                        this.$toaster.success(this.$t('operationSuccess'));
                        this.resetFields();
                    })
                    .catch(error => {
                        this.validationErrors = error.response.data.errors
                    })
            }

        },
        ...mapActions({
            fetchPayments: `profileBuyTree/${FETCH_PAYMENTS_SYSTEM}`,
            getWithdrawal: `withdrawal/${GET_WITHDRAWAL}`,
        }),
        ...mapMutations({
            cashPopUpIframe: `shop/${POPUP_BUY_IFRAME}`,
        }),
    }
}
