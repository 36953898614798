import {mapMutations, mapActions, mapGetters} from 'vuex'
import {
    SHOW_POPUP_INTRO,
    CHECK_POPUP_INTRO
} from "@/store/mutation-types";


export default {
    name: 'popup-intro',
    data() {
        return {
            checkbox: false,
        }
    },
    mounted() {

    },
    computed: {
        ...mapGetters({
            social:'contacts/social',
            config:'main/config',
            is_auth: `auth/is_auth`,
        }),
        getDescription(){
            for( let i in this.config ){
                if(this.config[i].key.includes('introDesc')){
                    return this.config[i];
                }
            }
        },
        getDataButton(){
            for( let i in this.config ){
                if(this.config[i].key.includes('intro_button')){
                    return this.config[i];
                }
            }
        },
    },
    methods: {
        ...mapActions({
           checkPopupIntro: `profile/${CHECK_POPUP_INTRO}`
        }),
        ...mapMutations({
            changePopup: `popups/${SHOW_POPUP_INTRO}`,
        }),
        closeIntro(){
            if(this.checkbox){
                localStorage.setItem('introView', '1');
                if(this.is_auth){
                    this.checkPopupIntro({ checked: true });
                }
                this.changePopup(false);
            }else{
               this.changePopup(false);
            }
        },
        gtm(label){
            this.$gtm.trackEvent({
                event: 'biodep',
                eventCategory: 'userclicks',
                eventAction: 'button',
                eventLabel: label,
            });
        },
    },
    destroyed() {

    }
}
