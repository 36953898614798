import {mapActions, mapGetters, mapMutations} from 'vuex'
import {FETCH_PAYMENTS_SYSTEM, POPUP_BUY_IFRAME, REPLENIST_BALANCE} from '@/store/mutation-types';
import store from "@/store";

export default {
  name: 'top-up-wallet',
  data() {
    return {
      value: '',
      paySystem: null,
      isFutureFund: this.$route.name === 'personal.future_top_up_wallet',
      payload: {
        payment_system: null,
        amount: 0,
        type: 'refill_wallet'
      },

    }
  },
  mounted() {
    this.fetchUser().then(() => {
      this.setUuidAsync(this.fetchUserData.uuid)
    })
  },
  computed: {
    ...mapGetters({
      payments: `profileBuyTree/payments`,
      setting: 'main/setting',
      isLoadingCountryCode: 'main/isLoadingCountryCode',
      fetchUserData: `profile/user`,
    }),
    ...mapGetters('new_user',{
      uuid: "uuid",
    }),
    watchField() {
      if (this.value !== 0 && this.value !== '' && this.paySystem !== null) {
        return true
      } else {
        return false
      }
    },
    countTress() {
      return Math.round(((this.payload.amount * this.setting.futuresMultiplier).toFixed(3) / this.setting.futuresPrice) * 100) / 100
    }
  },
  created() {
    if (this.isLoadingCountryCode) {
      this.fetchPayments({countryCode: this.setting.countryCode});
    }
  },
  watch: {
    isLoadingCountryCode() {
      if (this.isLoadingCountryCode) {
        this.fetchPayments({countryCode: this.setting.countryCode});
      }
    }
  },
  methods: {
    ...mapActions('new_user',{
      setUuidAsync: 'setUuidAsync',
    }),
    ...mapActions('new_acquiring',{
      topUpBalanceStripeAsync: 'topUpBalanceStripeAsync',
    }),
    refillBalance() {
      if(this.payload.type === 'refill_wallet' && this.payload.payment_system === 'betatrans'){
        let lang = store.state['vue-i18n-manager'].currentLanguage.translationKey;
        lang = lang === 'ru' ? 'rus' : 'eng'

        let payload = {
          success_url: `${window.location.origin}${this.$router.resolve({ name: 'personal'}).href}`,
          top_up_amount: this.payload.amount,
        }
        let headers = {
          auth: this.uuid,
          lang: lang,
        }
        this.topUpBalanceStripeAsync({
          payload:payload,
          headers:headers,
        }).then((result) => {
          this.payload.amount = 0
          this.payload.type = 'refill_wallet'
          if (result.isSuccess) {
            window.open(result.pay_url, '_blank')
          } else {
            this.$toaster.error(result.exception.textError)
          }
        });
      }else{
        if (this.isFutureFund) {
          this.payload.type = 'refill_future_wallet'
        }
        this.upBalance(this.payload)
            .then(() => {
              this.cashPopUpIframe(true);
            }).catch(error => {
          this.cashPopUpIframe(false);
          this.$toaster.error(error.response.data.error.message);
        })
      }
    },
    ...mapActions({
      fetchPayments: `profileBuyTree/${FETCH_PAYMENTS_SYSTEM}`,
      upBalance: `shop/${REPLENIST_BALANCE}`,
      fetchUser: `profile/LOAD_USER_DATA`,
    }),
    ...mapMutations({
      cashPopUpIframe: `shop/${POPUP_BUY_IFRAME}`,
    }),
  }
}
