import { mapActions, mapGetters, mapMutations } from "vuex";
import {
    SING_IN_WITH_FACEBOOK,
} from '@/store/action-types';
import {
    LOAD_USER_DATA,
    SHOW_SING_IN,
    REGISTRATION_SHOW
} from '@/store/mutation-types';

import VFacebookLogin from "vue-facebook-login-component";

export default {
    name: 'facebook-widget',
    data() {
        return {
            facebook: {
                FB: {},
                model: {},
                appId: process.env.VUE_APP_FACEBOOK_IDENTITY,
            },
            updateRender: true,
        }
    },
    components: {
        VFacebookLogin
    },
    methods: {
        ...mapActions({
            auth: `auth/${SING_IN_WITH_FACEBOOK}`,
            fetchUser: `profile/${LOAD_USER_DATA}`,
        }),
        ...mapMutations({
            showSingIn: `auth/${SHOW_SING_IN}`,
            showRegistration: `auth/${REGISTRATION_SHOW}`,
        }),
        getUserData() {
            const {api} = this.facebook.FB;
            api('/me', {fields: 'id, name, email, first_name, last_name'}, (response) => {
                const token = this.facebook.FB.getAccessToken();
                this.auth({ access_token: token })
                    .then(() => {
                        this.$toaster.success(this.$t('successAuth'));
                        this.fetchUser().then(() => {
                            this.showSingIn(false);
                            this.showRegistration(false);
                            this.$router.push({ name: 'personal' });
                        });
                        this.handleLogout();
                    })
                    .catch(error => {
                        if(error.response.status === 418){
                            this.$toaster.error(error.response.data.data.error.message);
                        }
                        this.handleLogout();
                    })
            })
        },
        handleSdkInit({FB}) {
            FB.init({
                xfbml: true,
                version: 'v2.8',
                appId: process.env.VUE_APP_FACEBOOK_IDENTITY,
                status: false,
                cookie: false
            });
            this.facebook.FB = FB;
        },
        mounted(){
            this.handleLogout();
        },
        handleConnect() {

        },
        handleLogout() {
            this.facebook.FB.getLoginStatus(response => {
                if (response.status === 'connected') {
                    this.facebook.FB.logout(resp => {
                        this.updateRender = false;
                        setTimeout(() => {
                            this.updateRender = true;
                        }, 10)
                    });
                }
            })
        },
        login(){
            this.getUserData();
        },
        handleClick() {
            // eslint-disable-next-line
        },
    },
    destroyed(){
        this.handleLogout();
    }
}
