import {mapActions, mapGetters, mapMutations} from 'vuex'
import {FETCH_PROMOTION} from "@/store/action-types";

import {SET_PROMOTION} from "@/store/mutation-types";

export default {
  name: 'promotion-detail',
  computed: {
    ...mapGetters({
      content: `promotion/contentItem`,
      contentItemLoading: `promotion/contentItemLoading`,
    })
  },
  created() {
    this.fetchContent(this.$route.params.slug)
      .catch(() => {
        this.$router.push({name: 'not-found'});
      });
  },
  methods: {
    ...mapActions({
      fetchContent: `promotion/${FETCH_PROMOTION}`
    }),
    ...mapMutations({
      setPromotion: `promotion/${SET_PROMOTION}`
    })
  },
  destroyed() {
    this.setPromotion(null)
  }
}
