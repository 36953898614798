import {fixBody, globalLoader} from '@/helpers/variables/index'
import {$http} from '@/https'
import {
    TREES_LOAD_ALL_CATEGORIES,
    TREES_SKIP,
    SHOW_POPUP_DOCUMENTS,
    CHANGE_TREES_LIMIT,
    LOADING_SEARCH_TREE,
    OPEN_SEARCH_TREE,
    PERSONAL_TREES,
    RESET_SEARCH_TREE,
    TREES_SEARCH,
    PERSONAL_TREES_TYPE,
    PERSONAL_SELECT_CATEGORY,
    PERSONAL_SORT_CATEGORY,
    TREE_SEARCH_LOCATION,
    TREE_SEARCH_PURCHASE,
    TREE_SEARCH_PLANTING,
    TREE_SEARCH_STATUS,
    PERSONAL_TYPE_SEARCH,
    SEARCH_PARAMS_LOCATIONS,
    SEARCH_PARAMS_STATUSES,
    RESULT_SEARCH,
    RESET_SEARCH_TREE_DETAIL,
    TREES_DETAIL,
    TREE_COUNT,
    TREE_COUNT_DETAIL,
    GENERATED_CERTIFICATE,
    DOWNLOAD_CERTIFICATE,
    GENERATED_CERTIFICATES,
    GET_CERTIFICATE,
    CERTIFICATE_TYPE,
    SING_DOCUMENT,
    GET_ORDER,
    CERTIFICATE_LOADING,
    DOCUMENT_TREE_ID,
    RESET_DOCUMENT_POPUP,
    DOCUMENT_TREE_ORDER_ID,
    TERM_OF_USE,
    TERM_OF_USE_ID,
    MY_TREE_LIST_FILE,
    PERSONAL_LOADING_TREES_DOCUMENT,
    CHANGE_FILTER_SEASON,
    SEARCH_PARAMS_GIFT_STATUSES,
    DOWNLOAD_DOCUMENTS,

    EXPRESS_PURCHASES,
    EXPRESS_PURCHASES_LOADING
} from '../../mutation-types';

const transformDate = (elem) => {
    let toYear = elem.substr(0, 4);
    let toMonth = elem.substr(5, 2);
    let toDay = elem.substr(8, 2);
    let fromYear = elem.substr(13, 4);
    let fromMonth = elem.substr(18, 2);
    let fromDay = elem.substr(21, 2);
    let totalDate = `${toDay}.${toMonth}.${toYear}-${fromDay}.${fromMonth}.${fromYear}`;
    if (elem.length > 10) {
        return totalDate;
    } else {
        return '';
    }
};

const state = {
    expressPurchases: null,
    expressPurchasesLoading: false,

    allCategories: null,
    loadingTreesDocument: false,
    openSearchPopup: false,
    listStatuses: [],
    listLocations: null,
    sortList: null,
    lengthList: 0,
    lengthListCurrent: null,
    loading: false,
    trees: null,
    list: null,
    typeSearch: false,
    selectTypeCategory: '',
    sortTypeCategory: '',
    sortType: false,
    location: '',
    purchasePeriod: '',
    plantingPeriod: '',
    status: {
        id: '',
        title: '',
        type: ''
    },
    filterSeason: {name: '', value: ''},
    currentStatus: '',
    currentLocation: '',
    purchasePeriodLocal: '',
    plantingPeriodLocal: '',
    resultSearchPlanting: '',
    resultSearchPurchase: '',
    resultSearchStatus: '',
    resultSearchSeason: '',
    resultSearchLocation: '',
    treesSearchDetail: null,
    skip: 0,
    certificateLoading: false,
    //
    showPopupDocuments: false,
    certificateCountType: false,
    treeDocumentId: null,
    treeDocumentOrderId: null,
    termOfUse: null,
    termOfUseId: null,
    myTreeListFyle: null,
    //global Count View
    limit: parseInt(localStorage.getItem('countView')) || 10,

};

const getters = {
    expressPurchases: state => state.expressPurchases,
    expressPurchasesLoading: state => state.expressPurchasesLoading,


    loadingTreesDocument: state => state.loadingTreesDocument,
    resultSearchSeason: state => state.resultSearchSeason,
    filterSeason: state => state.filterSeason,
    certificateCountType: state => state.certificateCountType,
    allCategories: state => state.allCategories,
    showPopupDocuments: state => state.showPopupDocuments,
    certificateLoading: state => state.certificateLoading,
    skip: state => state.skip,
    limit: state => state.limit,
    lengthListCurrent: state => state.lengthListCurrent,
    lengthList: state => state.lengthList,
    resultSearchPlanting: state => state.resultSearchPlanting,
    treesSearchDetail: state => state.treesSearchDetail,
    resultSearchPurchase: state => state.resultSearchPurchase,
    resultSearchStatus: state => state.resultSearchStatus,
    resultSearchLocation: state => state.resultSearchLocation,
    plantingPeriodLocal: state => state.plantingPeriodLocal,
    purchasePeriodLocal: state => state.purchasePeriodLocal,
    currentStatus: state => state.currentStatus,
    currentLocation: state => state.currentLocation,
    listStatuses: state => state.listStatuses,
    listLocations: state => state.listLocations,
    typeSearch: state => state.typeSearch,
    selectTypeCategory: state => state.selectTypeCategory,
    sortTypeCategory: state => state.sortTypeCategory,
    sortType: state => state.sortType,
    loading: state => state.loading,
    trees: state => state.trees,
    openSearchPopup: state => state.openSearchPopup,
    searchParams: state => state.searchParams,
    sortList: state => state.sortList,
    list: state => state.list,
    location: state => state.location,
    purchasePeriod: state => state.purchasePeriod,
    plantingPeriod: state => state.plantingPeriod,
    status: state => state.status,
    termOfUse: state => state.termOfUse,
    termOfUseId: state => state.termOfUseId,
    myTreeListFyle: state => state.myTreeListFyle,
};

const actions = {
    [PERSONAL_TREES]: async ({commit}, payload) => {
        try {
            commit(LOADING_SEARCH_TREE, true);

            const response = await $http.get(`v1/trees?limit=${parseInt(localStorage.getItem('countView'))}&skip=${payload.skip}`);
            commit(PERSONAL_TREES, response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_SEARCH_TREE, false);
        }
    },
    [EXPRESS_PURCHASES]: async ({commit}) => {
        commit(EXPRESS_PURCHASES_LOADING, true);
        try {
            const result = await $http.get(`v1/express/purches`);
            commit(EXPRESS_PURCHASES, result.data.data);
            return result.data.data;
        } catch (e) {
            throw e;
        } finally {
            commit(EXPRESS_PURCHASES_LOADING, false);
        }
    },
    [TREES_SEARCH]: async ({commit}, payload) => {
        try {
            commit(LOADING_SEARCH_TREE, true);
            commit(RESET_SEARCH_TREE);

            const response = await $http.get(`v1/trees?location=${payload.location}&purchase_period=${payload.purchase_period}&planting_period=${payload.planting_period}&tree_status=${state.status.type}_${state.status.id}&group_by=${payload.group_by}&season=${state.filterSeason.value}&limit=${parseInt(localStorage.getItem('countView'))}&skip=${payload.skip}`);
            commit(TREES_SEARCH, response.data.data);
            commit(RESULT_SEARCH, payload);
            commit(TREE_COUNT, response.data.meta.count);
            return response;
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_SEARCH_TREE, false)
        }
    },
    [TREES_DETAIL]: async ({commit}, payload) => {
        try {
            const response = await $http.get(`v1/trees?location=${payload.location}&purchase_period=${payload.purchase_period}&planting_period=${payload.planting_period}&tree_status=${state.status.type}_${state.status.id}&limit=${parseInt(localStorage.getItem('countView'))}&skip=${payload.skip}&seasons=${payload.planting_year}${payload.season !== '' ? '-' : ''}${payload.season}&field_ids=${payload.field_id}`);
            commit(RESET_SEARCH_TREE_DETAIL);
            commit(TREES_DETAIL, response.data.data);
            commit(RESULT_SEARCH, payload);
            commit(TREE_COUNT_DETAIL, response.data.meta.count);
            return response;
        } catch (e) {
            throw e;
        }
    },
    [SEARCH_PARAMS_LOCATIONS]: async ({commit}) => {
        return new Promise((resolve, reject) => {
            $http.get('v1/locations')
                .then(response => {
                    commit(SEARCH_PARAMS_LOCATIONS, response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {

                });
        });

    },
    [SEARCH_PARAMS_GIFT_STATUSES]: async ({commit}) => {
        try {
            const result = await $http.get('v1/tree_gift_statuses');
            commit(SEARCH_PARAMS_GIFT_STATUSES, result.data.data);
            return result;
        } catch (e) {
            throw e;
        }
    },

    [GET_CERTIFICATE]: async ({commit}, payload) => {
        try {
            commit(CERTIFICATE_LOADING, true);

            const response = await $http.get(`v1/trees/certificates/${payload}`);
            commit(DOWNLOAD_CERTIFICATE, response.data.link);
            return response;
        } catch (e) {
            throw e;
        } finally {
            commit(CERTIFICATE_LOADING, false);
        }
    },

    [GENERATED_CERTIFICATE]: async ({commit}) => {
        try {
            commit(CERTIFICATE_LOADING, true);
            commit(CERTIFICATE_TYPE, false);

            const response = await $http.get(`v1/trees/certificates/${state.treeDocumentId}`);
            commit(DOWNLOAD_CERTIFICATE, response.data.link);
            return response;
        }catch (e) {
            throw e;
        }finally {
            commit(CERTIFICATE_LOADING, false);
            commit(SHOW_POPUP_DOCUMENTS, false);
        }
    },
    [GENERATED_CERTIFICATES]: async ({commit}, payload) => {
        try {
            commit(CERTIFICATE_LOADING, true);
            commit(CERTIFICATE_TYPE, true);

            const response = await $http.get(`v1/trees/certificates?order_id=${state.treeDocumentOrderId}`);
            commit(DOWNLOAD_CERTIFICATE, response.data.link);
            return response;
        }catch (e) {
            throw e;
        }finally {
            commit(CERTIFICATE_LOADING, false);
            commit(SHOW_POPUP_DOCUMENTS, false);
        }
    },
    [DOWNLOAD_DOCUMENTS]: async ({commit}, payload) => {
        try {
            globalLoader(true);
            const response = await $http.post(`v1/trees/term_of_use/download`, payload);
            commit(DOWNLOAD_CERTIFICATE, response.data.link);
            return response;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [SEARCH_PARAMS_STATUSES]: async ({dispatch, commit}) => {
        dispatch(SEARCH_PARAMS_GIFT_STATUSES);
        try {
            const result = await $http.get('v1/tree_sale_statuses');
            commit(SEARCH_PARAMS_STATUSES, result.data.data);
            return result;
        } catch (e) {
            throw e
        }
    },
    [SING_DOCUMENT]: async ({commit}, payload) => {
        try {
            globalLoader(true);

            return await $http.post('v1/trees/sign_documents', payload);
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [GET_ORDER]: async ({commit}, payload) => {
        try {
            globalLoader(true);

            const response = await $http.get(`v1/get_packet_by_tree/${payload}`);
            return response.data.data;
        }catch (e) {
            throw e;
        }finally {
            globalLoader(false);
        }
    },
    [TREES_LOAD_ALL_CATEGORIES]: async ({commit}) => {
        try{
            const response = await $http.get(`v1/trees?group_by=season`);
            commit(TREES_LOAD_ALL_CATEGORIES, response.data.data);
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    [TERM_OF_USE]: async ({commit}, payload) => {
        try {
            const response = await $http.post('v1/trees/term_of_use', payload);
            commit(TERM_OF_USE_ID, payload);
            commit(TERM_OF_USE, response.data);
            return response.data;
        } catch (e) {
            throw e
        }
    },

    [MY_TREE_LIST_FILE]: async ({commit}) => {
        try {
            commit(PERSONAL_LOADING_TREES_DOCUMENT, true);

            const response = await $http.get(`v1/trees/my_trees_list_file`);
            commit(MY_TREE_LIST_FILE, response.data.link);
            return response.data.link;
        }catch (e) {
            throw e;
        }finally {
            commit(PERSONAL_LOADING_TREES_DOCUMENT, false);
        }
    },
};

const mutations = {
    [MY_TREE_LIST_FILE](state, token) {

        const link = document.createElement('a');
        link.href = `${process.env.VUE_APP_ROOT_API}v1/downloads/${token}`;
        link.setAttribute('type', 'hidden');
        link.setAttribute('download', 'download');
        document.body.appendChild(link);
        link.click();
    },
    [TERM_OF_USE_ID](state, status) {
        state.termOfUseId = status;
    },
    [TERM_OF_USE](state, status) {
        state.termOfUse = status;
    },
    [EXPRESS_PURCHASES_LOADING](state, status) {
        state.expressPurchasesLoading = status;
    },
    [EXPRESS_PURCHASES](state, list) {
        state.expressPurchases = list;
    },
    [OPEN_SEARCH_TREE](state, status) {
        state.openSearchPopup = status;
        fixBody(status)
    },
    [TREES_LOAD_ALL_CATEGORIES](state, list) {
        state.allCategories = list;
    },
    [CHANGE_FILTER_SEASON](state, season) {
        state.filterSeason = season;
    },
    [SEARCH_PARAMS_GIFT_STATUSES](state, list) {
        let transformData = list.map(e => {
            e.type = 'gift';
            return e;
        });
        state.listStatuses.push(...transformData);
    },
    [SING_DOCUMENT](state, payload) {
    },
    [CERTIFICATE_LOADING](state, status) {
        state.loading = status;
    },
    [DOWNLOAD_CERTIFICATE](state, token) {
        //loading pdf, methods => jwt token, expires_in 3 sec
        const link = document.createElement('a');
        link.href = `${process.env.VUE_APP_ROOT_API}v1/downloads/${token}`;
        link.setAttribute('type', 'hidden');
        link.setAttribute('download', 'download');
        document.body.appendChild(link);
        link.click();
    },
    [TREES_SEARCH](state, type) {
        state.list = type;
    },
    [RESET_SEARCH_TREE](state) {
        state.list = null;
    },
    [LOADING_SEARCH_TREE](state, status) {
        state.loading = status;
    },
    [PERSONAL_TREES_TYPE](state, status) {
        state.sortType = status;
    },
    [PERSONAL_SELECT_CATEGORY](state, status) {
        state.selectTypeCategory = status;
    },
    [PERSONAL_SORT_CATEGORY](state, status) {
        state.sortTypeCategory = status;
    },
    [PERSONAL_TYPE_SEARCH](state, status) {
        state.typeSearch = status;
    },
    [TREE_SEARCH_LOCATION](state, status) {
        state.location = status.id;
        state.currentLocation = status.name;
    },
    [TREE_SEARCH_PURCHASE](state, status) {
        state.purchasePeriodLocal = status;
        state.purchasePeriod = transformDate(status);
    },
    [TREE_SEARCH_PLANTING](state, date) {
        state.plantingPeriodLocal = date;
        state.plantingPeriod = date;
    },
    [TREE_SEARCH_STATUS](state, status) {
        state.status = status;
        state.currentStatus = status.title;
    },
    [SEARCH_PARAMS_LOCATIONS](state, status) {
        state.listLocations = status;
    },
    [SEARCH_PARAMS_STATUSES](state, status) {
        let transformData = status.map(e => {
            e.type = 'sale';
            return e;
        });
        state.listStatuses.push(...transformData);
    },
    [TREES_DETAIL](state, list) {
        state.treesSearchDetail = list;
    },
    [RESET_SEARCH_TREE_DETAIL](state) {
        state.treesSearchDetail = null;
    },
    [PERSONAL_LOADING_TREES_DOCUMENT](state, status) {
        state.loadingTreesDocument = status;
    },
    [TREE_COUNT](state, length) {
        state.lengthList = length;
    },
    [TREE_COUNT_DETAIL](state, length) {
        state.lengthListCurrent = length;
    },
    [TREES_SKIP](state, skip) {
        state.skip = skip;
    },
    [CHANGE_TREES_LIMIT](state, limit) {
        state.limit = limit;
    },
    [SHOW_POPUP_DOCUMENTS](state, status) {
        fixBody(status);
        state.showPopupDocuments = status;
    },
    [CERTIFICATE_TYPE](state, status) {
        state.certificateCountType = status;
    },
    [DOCUMENT_TREE_ID](state, id) {
        state.treeDocumentId = id;
    },
    [DOCUMENT_TREE_ORDER_ID](state, id) {
        state.treeDocumentOrderId = id;
    },
    [RESET_DOCUMENT_POPUP](state) {
        state.treeDocumentOrderId = null;
        state.treeDocumentId = null;
        state.certificateCountType = false;
    },
    [RESULT_SEARCH](state) {
        state.resultSearchLocation = state.currentLocation;
        state.resultSearchPlanting = state.plantingPeriodLocal;
        state.resultSearchPurchase = state.purchasePeriodLocal;
        state.resultSearchStatus = state.currentStatus;
        state.resultSearchSeason = state.filterSeason.name;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
